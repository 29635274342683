import React from 'react'
import {
  faTrashAlt,
  faCog,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import { CellProps } from 'react-table'
import CopyableLink from 'components/CopyableLink/CopyableLink'
import ActionIconGroup from 'components/common/Table/ActionIconGroup'
import { TranslateFunction } from 'react-localize-redux'
import history from 'components/common/history'

export interface IRow {
  id: string
  name: string
  managedBy: string
  applicationCount: number
  rejectedApplicationCount: number
  publishedAt: string
  applicationDeadline: React.ReactNode
  url: string
}

interface IProps {
  translate: TranslateFunction
  handleDeleteModalOpen: (profileId: string) => () => void
  handleExportApplications: (challengeId: string) => () => void
  userRank: number
}

export const getColumns = ({
  translate,
  handleDeleteModalOpen,
  handleExportApplications,
  userRank,
}: IProps) => {
  // prepare configs for the name column
  const actionIconConfigs = (id: string) => [
    ...(userRank >= 2
      ? [
          {
            id: 'settings-icon',
            icon: faCog,
            tooltip: 'challenge.settings',
            onClick: () => history.push(`/programs/${id}/settings`),
          },
          {
            id: 'download-icon',
            tooltip: 'admin.buttonExportApplications',
            icon: faDownload,
            onClick: handleExportApplications(id),
          },
          {
            id: 'delete-icon',
            tooltip: 'delete',
            icon: faTrashAlt,
            onClick: handleDeleteModalOpen(id),
          },
        ]
      : []),
  ]
  return [
    {
      Header: ' ',
      columns: [
        {
          Header: translate('dashboard.tables.name').toString(),
          accessor: 'name',
          width: 400,
          Cell: ({ cell, row }: CellProps<IRow>) => {
            const { url } = row.original

            return <CopyableLink to={url}>{cell.value}</CopyableLink>
          },
        },
        {
          Header: 'Facilitator',
          accessor: 'managedBy',
          width: 400,
        },
        {
          Header: 'Applications',
          accessor: 'applicationCount',
          width: 80,
          Cell: ({ cell, row }: CellProps<IRow>) => (
            <CopyableLink to={`/programs/${row.original.id}/applications`}>
              {cell.value}
            </CopyableLink>
          ),
        },
        {
          Header: 'Rejected',
          accessor: 'rejectedApplicationCount',
          width: 80,
        },
        {
          Header: 'Published',
          accessor: 'publishedAt',
          width: 100,
        },
        {
          Header: 'Remaining',
          accessor: 'applicationDeadline',
          width: 100,
        },
        {
          Header: '',
          accessor: 'id',
          Cell: ({ cell }: CellProps<IRow>) => (
            <ActionIconGroup actions={actionIconConfigs(cell.value)} />
          ),
          width: 100,
        },
      ],
    },
  ]
}
