import { Action } from 'redux';
import { AUTH_ACTIONS } from '../actions/authActions'
import { api } from '../../../api'
import { AuthState } from '../utils/state'
import { Reducer } from "./types";
import { createReducer } from "./createReducer";

const defaultState: AuthState = {
  token: undefined,
  loggedIn: undefined,
  tokenExpires: undefined,
  loginLoading: false,

  resetPasswordSuccess: false,
  resetPasswordFailure: false,

  createNewPasswordSuccess: false,
  createNewPasswordFailure: false,
}

const reducer: Reducer<AuthState, Action> = {
[AUTH_ACTIONS.LOGIN_LOADING]: (state, { payload }) => ({ ...state, loginLoading: payload }),
[AUTH_ACTIONS.AUTH_USER]: (state, { payload: { expires, token } }) => {
    api.defaults.headers.common['Authorization'] = token
    return {
      ...state,
      token,
      loggedIn: true,
      loginLoading: false,
      tokenExpires: expires,
    }
  },
[AUTH_ACTIONS.RESET_PASSWORD_SUCCESS]: (state) => ({
  ...state,
  resetPasswordSuccess: true,
  resetPasswordFailure: false
}),
[AUTH_ACTIONS.RESET_PASSWORD_FAILED]: (state) => ({
      ...state,
      resetPasswordSuccess: false,
      resetPasswordFailure: true,

  }),
[AUTH_ACTIONS.CREATE_NEW_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      createNewPasswordSuccess: true,
      createNewPasswordFailure: false,

  }),
[AUTH_ACTIONS.CREATE_NEW_PASSWORD_FAILED]: (state) => ({
      ...state,
      createNewPasswordSuccess: false,
      createNewPasswordFailure: true,

  }),
[AUTH_ACTIONS.AUTHORIZE_EMAIL]: (state, {payload}) => {
    api.defaults.headers.common['Authorization'] = payload
    return {
      ...state,
      token: payload,
    }
  },
[AUTH_ACTIONS.LOGOUT]: () => {
    delete api.defaults.headers.common['Authorization']
    return { ...defaultState, loggedIn: false }
  }
}

export default createReducer<AuthState, Action>(reducer, defaultState);
