import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import Loader from 'components/common/Loader/Loader'
import jwtDecode from 'jwt-decode'
import { authorizeEmail } from 'components/common/actions/authActions'
import {
  verifyApplicationToken,
} from './ApplicationForm/utils/api'

const ValidateEmailToken = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const { email, programId } = useParams()

  const { token } = queryString.parse(location.search)
  const { applicationId } = jwtDecode(token as string)

  useEffect(() => {
    const verify = async () => {
      const { data } = await verifyApplicationToken({
        email,
        applicationId,
        token: token as string,
      })

      const { token: newToken } = data

      dispatch(authorizeEmail(newToken))
      history.replace(`/programs/${programId}/application/`)
    }

    if (token) {
      verify()
    }
  }, [token, history, email, programId, applicationId, dispatch])

  return <Loader />
}

export default ValidateEmailToken
