import { useEffect, useState } from 'react'
import { detectColorScheme } from './detectColorScheme'
import Cookies from 'js-cookie'

const useTheme = () => {
  const storedTheme = Cookies.get('color-scheme') as 'light' | 'dark' | 'auto'
  const [theme, setThemeState] = useState<'light' | 'dark' | 'auto'>(
    storedTheme ?? 'auto',
  )

  useEffect(() => {
    if (theme) {
      Cookies.set('color-scheme', theme, {
        domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
      })

      const detectedTheme = detectColorScheme(theme)
      if (theme === 'dark' || detectedTheme === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark')
      } else if (theme === 'light' || detectedTheme === 'light') {
        document.documentElement.setAttribute('data-theme', 'light')
      }
    }
  }, [theme])

  const toggleTheme = () => {
    const detectedTheme = detectColorScheme(theme)
    setThemeState(detectedTheme === 'light' ? 'dark' : 'light')
  }

  return { toggleTheme, theme, setTheme: setThemeState }
}

export default useTheme
