import { useState, useEffect } from 'react'
import { detectColorScheme } from './detectColorScheme'

const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState(detectColorScheme())
  useEffect(() => {
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)')
      const onChange = (event: MediaQueryListEvent) => {
        setColorScheme(detectColorScheme())
      }
      mediaQueryList.addListener(onChange)
    }

    if (window.MutationObserver) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-theme'
          ) {
            const target = mutation.target as Element
            //@ts-ignore
            setColorScheme(
              detectColorScheme(
                // @ts-ignore
                target.attributes['data-theme'].value,
              ),
            )
          }
        })
      })

      observer.observe(document.documentElement, {
        attributes: true,
      })
    }
  }, [])
  return colorScheme as 'light' | 'dark'
}

export default useColorScheme
