import React from 'react'
import styles from './Toast.module.scss'
import { ToastProps } from "./types";

const Toast = ({
  onToastClick,
  id,
  message,
  buttonText,
  buttonHandler,
  button2Text,
  button2Handler,
  icon,
  backgroundColor,
  textColor,
  alert,
}: ToastProps) => {
  return (
    <div
      className={styles.Toast}
      onClick={onToastClick}
      style={{
        backgroundColor,
        color: textColor,
        zIndex: id,
      }}
      role={alert ? 'alert' : undefined}
    >
      {icon && <img src={icon} alt='Warning Icon' className={styles.icon} />}
      {message}
      {buttonText && buttonHandler && (
        <button type='button' onClick={buttonHandler}>
          {buttonText}
        </button>
      )}
      {button2Text && button2Handler && (
        <button type='button' onClick={button2Handler}>
          {button2Text}
        </button>
      )}
    </div>
  )
}

export default Toast
