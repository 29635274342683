import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useFlexLayout, useRowSelect, useSortBy, useTable } from 'react-table'
import styles from './Table.module.scss'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Button from 'components/common/shared/Button'
import { withLocalize } from 'react-localize-redux'

function TableLayout({
  columns,
  data,
  title,
  moreLink,
  onAdd,
  addLabel,
  modes,
  onModeChange,
  activeModeIndex,
  clientSorting,
  disableSorting,
  // boolean flag indicating whether the last row should align to the right
  alignLastColumnToRight,
  // translate function
  translate,

  className,
}: any) {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 50, // minWidth is only used as a limit for resizing
      width: 80, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    [],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    state: { sortBy },
  } = useTable(
    // @ts-ignore
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      disableMultiSort: !clientSorting,
      manualSortBy: !clientSorting,
    },
    useFlexLayout,
    useSortBy,
    useRowSelect,
  )
  const tBodyRef = useRef<HTMLDivElement>()
  const [scrollBarWidth, setScrollBarWidth] = useState(0)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (tBodyRef.current) {
      // @ts-ignore
      const width = tBodyRef.current.offsetWidth - tBodyRef.current.clientWidth
      if (width !== scrollBarWidth) {
        setScrollBarWidth(width)
      }
    }
  }, [scrollBarWidth, setScrollBarWidth])

  useEffect(() => {
    if (!disableSorting && !clientSorting && sortBy.length > 0) {
      const oldQueries = queryString.parse(location.search)
      const sortQuery = sortBy.length > 0 && {
        sortBy: sortBy[0].id,
        orderBy: sortBy[0].desc ? 'desc' : 'asc',
      }
      const newQueries =
        sortBy && sortBy.length > 0
          ? {
              ...oldQueries,
              ...sortQuery,
            }
          : {
              ...oldQueries,
              sortBy: undefined,
              orderBy: undefined,
            }
      history.push({
        search: `?${queryString.stringify(newQueries)}`,
      })
    }
    // only want to do this if the sort choice changes, otherwise we'll loop
    // eslint-disable-next-line
  }, [sortBy, clientSorting, disableSorting])

  const handleAddClicked = () => onAdd()
  const handleModeChange = (index: number) => () => {
    onModeChange(index)
    history.push({
      search: '',
    })
  }

  return (
    <div {...getTableProps()} className={classNames(styles.table, className)}>
      <div className={styles.headline}>
        <div className={styles.title}>{title}</div>
        {modes && (
          <div className={styles.dataSwitch}>
            {modes.map((mode: string, index: number) => (
              <button
                key={mode}
                onClick={handleModeChange(index)}
                className={classNames(styles.modeButton, {
                  [styles.active]: activeModeIndex === index,
                })}
              >
                {mode}
              </button>
            ))}
          </div>
        )}
        <div>
          {moreLink && data.length > 0 && (
            <Link to={moreLink} className={styles.more}>
              more &gt;
            </Link>
          )}
          {onAdd && (
            <Button
              type='solid'
              className={styles.addButton}
              onClick={handleAddClicked}
            >
              {addLabel}
            </Button>
          )}
        </div>
      </div>
      {rows.length ? (
        <div className={styles.innerTableContainer}>
          <div>
            {headerGroups.map((headerGroup: any, i: any) => (
              <div
                {...headerGroup.getHeaderGroupProps({
                  style: { paddingRight: `${scrollBarWidth}px` },
                })}
                className={classNames(styles.tr, styles.headerRow)}
                key={i}
              >
                {headerGroup.headers.map((column: any, j: any) => (
                  <div
                    {...(disableSorting
                      ? {}
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                    className={classNames(
                      styles.th,
                      // determine if this is the last column
                      // and that the `alignLastColumnToRight` flag is enabled
                      // if yes then add the "alignRightColumn" class
                      j === headerGroup.headers.length - 1 &&
                        alignLastColumnToRight &&
                        styles.alignRightColumn,
                    )}
                    key={`header${i}_${j}`}
                  >
                    <span>
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={styles.arrow}>&darr;</span>
                        ) : (
                          <span className={styles.arrow}>&uarr;</span>
                        )
                      ) : null}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            {...getTableBodyProps()}
            className={styles.tbody}
            ref={(el) => (tBodyRef.current = el || undefined)}
          >
            {rows.map((row: any, i: any) => {
              prepareRow(row)
              return (
                <div key={row.id} {...row.getRowProps()} className={styles.tr}>
                  {row.cells.map((cell: any, j: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={classNames(
                          styles.td,
                          j === row.cells.length - 1 &&
                            alignLastColumnToRight &&
                            styles.alignRightColumn,
                        )}
                        key={`${row.id}_${j}`}
                      >
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        // in case there are no records, render an "nothing there" message

        <div className={styles.emptyMessage}>
          <FontAwesomeIcon icon={faSearch} />
          <div>{translate('utility.tableEmpty')}</div>
        </div>
      )}
    </div>
  )
}

const Table = ({
  data,
  columns,
  title,
  moreLink,
  onAdd,
  addLabel,
  modes,
  onModeChange,
  activeModeIndex,
  clientSorting,
  alignLastColumnToRight,
  disableSorting,
  translate,

  className,
}: any) => {
  const columnsMemo = React.useMemo(() => columns, [columns])

  return (
    <TableLayout
      className={className}
      disableSorting={disableSorting}
      alignLastColumnToRight={alignLastColumnToRight}
      columns={columnsMemo}
      data={data}
      title={title}
      moreLink={moreLink}
      onAdd={onAdd}
      addLabel={addLabel}
      modes={modes}
      onModeChange={onModeChange}
      activeModeIndex={activeModeIndex}
      clientSorting={clientSorting}
      translate={translate}
    />
  )
}

export default withLocalize(Table)
