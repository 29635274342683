import React from 'react'
import classNames from 'classnames'
import wrappedInputStyles from './WrappedInput.module.scss'
import styles from './Fieldset.module.scss'
import { ReactComponent as LogoTick } from '../assets/icons/checkmark.svg'
import { FieldsetProps } from "./types";

const Fieldset = (props: FieldsetProps) => {
  const {
    label,
    description,
    disabled,
    keepDisplaying,
    rtl,
    children,
    showValidation = true,
    isValid,
    className,
    fullHeight,
    inline,
  } = props

  return (
    <fieldset
      className={classNames(styles.container, wrappedInputStyles.questionContainer, className, {
        [wrappedInputStyles.disabled]: disabled && !keepDisplaying,
        [wrappedInputStyles.disabledWithDisplay]: keepDisplaying && disabled,
        [wrappedInputStyles.rtl]: rtl,
        [wrappedInputStyles.fullHeight]: fullHeight,
        [wrappedInputStyles.inline]: inline,
      })}
    >
      <legend className={wrappedInputStyles.questionLabel}>{label}</legend>
      {description && (
        <div className={wrappedInputStyles.questionDescription}>{description}</div>
      )}
      {showValidation && (
        <div className={styles.validationContainer}>
          <LogoTick
            className={classNames(wrappedInputStyles.logoTick, {
              [wrappedInputStyles.disabled]: !isValid,
            })}
          />
        </div>
      )}
      {children}
    </fieldset>
  )
}

export default Fieldset
