import React from 'react'
import { CellProps } from 'react-table'
import { Translate } from 'react-localize-redux'
import CopyableLink from '../../../../CopyableLink/CopyableLink'
import { FacilitatorRow } from "../../types";

export const ColumnsFacilitatorsTable = [
  {
    Header: ' ',
    columns: [
      {
        Header: <Translate id='dashboard.tables.name' />,
        accessor: 'facilitator',
        disableSortBy: true,
        width: 100,
        Cell: ({ cell }: CellProps<FacilitatorRow>) => {
          const { name, id } = cell.value
          return <CopyableLink to={`facilitators/${id}/settings`}>{name}</CopyableLink>
        },
      },
      {
        Header: <Translate id='dashboard.tables.programNumber' />,
        accessor: 'programs',
        disableSortBy: true,
        width: 50,
        Cell: ({ cell, row }: CellProps<FacilitatorRow>) => {
          const { facilitator } = row.original
          return (
            <CopyableLink to={`programs-list/?managedBy=${facilitator.name}`}>
              {cell.value}
            </CopyableLink>
          )
        },
      },
      {
        Header: <Translate id='dashboard.tables.applicationNumber' />,
        accessor: 'applications',
        width: 60,
        disableSortBy: true,
      },
    ],
  },
]
