import classNames from "classnames";
import styles from "./SearchHighlight.module.scss";
import React from "react";
import { highLightSearch } from "./utils";
import { NameProps } from "./types";

const Name = ({className, onClick, name, regex}: NameProps) => (
  <div
    className={classNames(styles.name, className)}
    onClick={onClick}
  >
    {name ? highLightSearch(name, regex) : ''}
  </div>
)

export default Name;
