/* global localStorage */
import { push } from 'connected-react-router'
import { api, auth, requestSuccess } from '../../../api'
import { setNewUser, ACTIONS } from './userActions'
import { IRole } from '../reducers/userReducer'

import { handleError } from './errorHandlerActions'
import { get } from 'lodash'
import { addSuccessToast } from './toastActions'
import { translate } from '../utils/translations'
import { selectOrganization } from './applicationActions'
import Axios from 'axios'
import { AppThunk } from "./types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../reducers";
import {
  deleteLoggedInCookie,
  deleteSessionCookie,
  deleteTokenCookie,
  getTokenCookie,
  setCookieItem
} from "../utils/cookies";

export const LSKEY_LANGUAGE = 'language'
export const LSKEY_TOKEN = 'token'
export const LSKEY_TOKEN_EXPIRES = 'token_expires'
export const LSKEY_USER_ID = 'user_id'
export const LSKEY_USER_EMAIL = 'user_email'
export const LSKEY_USER_NAME = 'user_name'
export const LSKEY_USER_PICTURE = 'user_picture'
export const LSKEY_USER_ACCEPTED_TNC = 'user_tnc'

// *** ACTIONS ***
export const AUTH_USER = '@dff_common_action/login_user'
export const LOGIN_SUCCESS = '@dff_common_action/login_success'
export const LOGIN_FAILURE = '@dff_common_action/login_failure'
export const LOGIN_LOADING = '@dff_common_action/login_loading'
export const LOGOUT = '@dff_common_action/logout'
export const AUTHORIZE_EMAIL = '@dff_common_action/authorize_email'
export const SHOW_TNC_POPUP = '@dff_common_action/show_tnc_popup'

export const AUTH_ACTIONS = {
  LOGIN_LOADING: '@dff_common_action/login_loading',
  LOGOUT: '@dff_common_action/logout',
  RESET_PASSWORD_SUCCESS: '@dff_common_action/reset_password_success',
  RESET_PASSWORD_FAILED: '@dff_common_action/reset_password_failed',
  CREATE_NEW_PASSWORD_SUCCESS: '@dff_common_action/create_new_password_success',
  CREATE_NEW_PASSWORD_FAILED: '@dff_common_action/create_new_failed',
  AUTH_USER: '@dff_common_action/login_user',
  AUTHORIZE_EMAIL: '@dff_common_action/authorize_email',
}

export const CREATE_NEW_PASSWORD_SUCCESS =
  '@dff_common_action/create_new_password_success'
export const CREATE_NEW_PASSWORD_FAILED = '@dff_common_action/create_new_failed'
export const CREATE_NEW_PASSWORD_LOADING =
  '@dff_common_action/create_new_loading'

export const RESET_PASSWORD_SUCCESS =
  '@dff_common_action/reset_password_success'
export const RESET_PASSWORD_FAILED = '@dff_common_action/reset_password_failed'
export const RESET_PASSWORD_LOADING =
  '@dff_common_action/reset_password_loading'

export const UPDATE_PASSWORD_SUCCESS =
  '@dff_common_action/update_password_success'
export const UPDATE_PASSWORD_FAILED =
  '@dff_common_action/update_password_failed'
export const UPDATE_PASSWORD_LOADING =
  '@dff_common_action/update_password_loading'

interface AuthorizeEmail {
  type: typeof AUTHORIZE_EMAIL
  payload: string
}

export const authorizeEmail = (token: string): AuthorizeEmail => ({
  type: AUTHORIZE_EMAIL,
  payload: token,
})

const fakeStartup = process.env.REACT_APP_FAKE_STARTUP === 'true'

export interface IUser {
  email: string
  id: string
  firstName?: string
  lastName?: string
  displayName?: string
  profilePicture?: string
  role: IRole
  skills: string[]
  jobTitles: (string | null)[]
  location?: string
  dob?: string
  nationality?: string
  headline?: string
  highestEducation?: string
  description?: string
  isMale?: boolean
  gender?: string
}

export interface IUserInfo {
  token: string
  expires: string
  userId: string
  email: string
}

export interface IUserPayload {
  id?: string
  email?: string
  displayName?: string
  lastName?: string
  firstName?: string
  profilePicture?: string
  preferredLanguage?: string
}

export interface ILoginProps {
  email: string
  password: string
  setActiveLanguage: Function
  verifyRedirect: boolean
}

export const login = ({
                        email,
                        password,
                        setActiveLanguage,
                        verifyRedirect,
                      }: ILoginProps): AppThunk => async(dispatch: ThunkDispatch<RootState, unknown, Action>, getState) => {
  try {
    const {data} = await auth.post('/login', {email, password}, {})
    const loginData = {
      token: data.token,
      expires: data.expires,
      userId: data.user.id,
      email: data.user.email,
    }
    dispatch(loginSuccess(loginData, setActiveLanguage))
  } catch (response) {
    const errorMsg = get(
      response,
      'response.data.errors',
      translate('errorGeneric', getState()),
    )
    console.error(errorMsg)
    if (verifyRedirect && get(response, 'response.status', 0) === 403) {
      dispatch(push(`/verify?email=${email}`))
    } else {
      dispatch(handleError(errorMsg))
    }
  }
}

export const loginSuccess = (
  data: IUserInfo,
  setActiveLanguage: Function,
  next?: string | null,
) => async(dispatch: ThunkDispatch<RootState, unknown, Action>, getState: () => RootState) => {
  const translateFunction = (text: string, data: Object) =>
    translate(text, getState(), data)
  try {
    dispatch(authUser(data))
    const startup = fakeStartup
      ? (await getUserStartup(data.userId, translateFunction, dispatch)) ||
      (await createNewStartup(data.userId, translateFunction, dispatch))
      : ''
    if (fakeStartup) {
      dispatch(selectOrganization(startup))
    }
    const user = await getUser(data.userId, dispatch)

    const {preferredLanguage, tncAccepted: rawTncAccepted} = user
    if (preferredLanguage) {
      setActiveLanguage(preferredLanguage)
      window.localStorage.setItem(LSKEY_LANGUAGE, preferredLanguage)
    }

    // check if tncAccepted is a non-empty array. Means tncAccepted && tncAccepted.length > 0
    const tncAccepted = !!rawTncAccepted?.length
    // this line will indicate (by setting variables to the store)
    // that whether the TNC popup will be shown if the TNC is NOT accepted
    dispatch({type: ACTIONS.SHOW_TNC_POPUP, payload: !tncAccepted})
    dispatch(setNewUser(user))

    // save the "TNC accepted" status into local storage so that the check will be effective even after referesh
    markTNCAccepted(tncAccepted)
    // dispatch()
    if (next) {
      window.location.assign(next);
    } else {
      dispatch(push('/'))
    }
  } catch (error) {
    dispatch(handleError(error))
  }
}

export const logout = () => ({
  type: LOGOUT,
})

export const getUserStartup = async(
  userId: string,
  translate: Function,
  dispatch: ThunkDispatch<RootState, unknown, Action>,
): Promise<string | null> => {
  try {
    const {data} = await api.get(`/users/${userId}/startups/owner`)
    if (data[0]) {
      return data[0].id
    }
    return null
  } catch (error) {
    dispatch(handleError(translate('auth.messages.startup.error') as string))

    return null
  }
}

export const createNewStartup = async(
  userId: string,
  translate: Function,
  dispatch: ThunkDispatch<RootState, unknown, Action>,
): Promise<string> => {
  try {
    const {data} = await api.post(`/startups`, {
      name: `${userId}-startup`,
    })
    return data.id
  } catch (error) {
    dispatch(handleError(translate('auth.messages.startup.error') as string))
    return ''
  }
}

export const getUser = async(userId: string, dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  try {
    const {data} = await api.get(`/users/${userId}`)
    return data
  } catch (error) {
    dispatch(handleError(error))
  }
}

export const authUser = (userInfo: IUserInfo) => (
  dispatch: ThunkDispatch<RootState, unknown, Action>,
) => {
  dispatch({type: AUTH_USER, payload: userInfo})
  const {token, expires, userId, email} = userInfo

  addUserToLocalStorage(token, expires, email, userId)
}

export const persistantLoginCheck = (): AppThunk => async(
  dispatch,
  getState,
) => {
  const {
    token,
    expires,
    email,
    userId,
    acceptedTnc,
  } = getUserFromLocalStorage()

  if (token && expires && userId) {
    // some early requests in challenges need this information immediately
    dispatch({
      type: AUTH_USER, payload: {
        token,
        expires,
        email,
        userId,
      },
    })
    dispatch({type: ACTIONS.SET_USER_ID, payload: userId})
  }

  // check for the TNC acceptance status from the persistent store
  dispatch({type: ACTIONS.SHOW_TNC_POPUP, payload: !acceptedTnc})

  if (token && email && userId) {
    try {
      dispatch({
        type: AUTH_USER, payload: {
          token,
          expires,
          loggedIn: true,
        },
      })

      if (fakeStartup) {
        const {data: organizations} = await api.get(
          `/users/${userId}/startups/owner`,
        )
        if (organizations.length !== 1) {
          throw new Error('failed to retrieve fake startup')
        }
        dispatch(selectOrganization(organizations[0].id))
      }

      const {data} = await api.get(`/users/${userId}`)
      dispatch({type: ACTIONS.SET_USER, payload: data})
    } catch (e) {
      console.error(e)
      logoutUser(dispatch)
      await handleError(e)(dispatch, getState, false);
    }
  }
}

export const performLogout = (): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    await Axios.post(
      `${process.env.REACT_APP_FID_BACKEND_URL}/auth/logout`,
      {},
      {
        headers: {
          Authorization: getState().auth.token,
        },
      },
    )
    dispatch({type: ACTIONS.RESET_USER})
    logoutUser(dispatch)
  } catch (e) {
    logoutUser(dispatch)
  }
}

export const createNewPassword = (newPassword: string, token: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch({type: CREATE_NEW_PASSWORD_LOADING, payload: true})
    const res = await auth.post(
      '/resetPassword',
      {newPassword},
      {
        headers: {
          token,
          'Accept-Language': getState().localize.languages.filter(
            (v: any) => v.active,
          )[0].code,
        },
      },
    )
    if (!requestSuccess(res)) {
      throw new Error('pageInfo.newPassword.messages.error')
    }
    dispatch({type: CREATE_NEW_PASSWORD_LOADING, payload: false})
    dispatch({type: CREATE_NEW_PASSWORD_SUCCESS})
    dispatch(push('/login'))
  } catch (e) {
    dispatch({type: CREATE_NEW_PASSWORD_LOADING, payload: false})
    dispatch({type: CREATE_NEW_PASSWORD_FAILED})
    dispatch(handleError(e))
  }
}

export const resetPassword = (email: string, token: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch({type: RESET_PASSWORD_LOADING, payload: true})
    const res = await auth.post(
      'sendPasswordResetMail',
      {
        email,
      },
      {
        headers: {
          'Accept-Language': getState().localize.languages.filter(
            (v: any) => v.active,
          )[0].code,
          Authorization: token,
        },
      },
    )
    if (!requestSuccess(res)) {
      throw new Error('pageInfo.resetPassword.messages.sendEmail.error')
    }
    dispatch({type: RESET_PASSWORD_LOADING, payload: false})
    dispatch({type: RESET_PASSWORD_SUCCESS})
    // dispatch(addSuccessToast(res.data.message));
  } catch (e) {
    dispatch({type: RESET_PASSWORD_LOADING, payload: false})
    dispatch({type: RESET_PASSWORD_FAILED})
    dispatch(handleError(e))
  }
}

// *** SHARED FUNCTIONALITY ***
export const logoutUser = (dispatch: ThunkDispatch<RootState, unknown, Action>) => {
  removeUserFromLocalStorage()
  dispatch(logout())
}

export const addUserToLocalStorage = (
  token: string,
  expires: string,
  email: string,
  userId: string,
) => {
  setCookieItem(LSKEY_TOKEN, token)
  localStorage.setItem(LSKEY_TOKEN_EXPIRES, expires)
  localStorage.setItem(LSKEY_USER_ID, userId)
  localStorage.setItem(LSKEY_USER_EMAIL, email)
  // accepter TNC is not done here because such info should be aquired by a separate get request on the user info
}

export const markTNCAccepted = (accepted: boolean) => {
  if (accepted) {
    localStorage.setItem(LSKEY_USER_ACCEPTED_TNC, 'true')
  } else {
    // if it's not a 'true-sy' value, just remove the mark
    localStorage.removeItem(LSKEY_USER_ACCEPTED_TNC)
  }
}

const removeUserFromLocalStorage = () => {
  deleteTokenCookie()
  deleteSessionCookie()
  deleteLoggedInCookie()
  localStorage.removeItem(LSKEY_TOKEN)
  localStorage.removeItem(LSKEY_TOKEN_EXPIRES)
  localStorage.removeItem(LSKEY_USER_EMAIL)
  localStorage.removeItem(LSKEY_USER_ID)
  localStorage.removeItem(LSKEY_USER_NAME)
  localStorage.removeItem(LSKEY_USER_PICTURE)
  localStorage.removeItem(LSKEY_USER_ACCEPTED_TNC)
}

const getUserFromLocalStorage = () => {
  const token = getTokenCookie()
  return {
    token,
    expires: localStorage.getItem(LSKEY_TOKEN_EXPIRES),
    email: localStorage.getItem(LSKEY_USER_EMAIL),
    userId: localStorage.getItem(LSKEY_USER_ID),
    userName: localStorage.getItem(LSKEY_USER_NAME),
    profilePicture: localStorage.getItem(LSKEY_USER_PICTURE),
    acceptedTnc: localStorage.getItem(LSKEY_USER_ACCEPTED_TNC),
  }
}

// Update Personal Password from Personal Details form on Personal Profile route
export const updatePassword = (
  userId: string = '',
  newPassword: string,
  currentPassword: string,
): AppThunk => async(dispatch, getState) => {
  try {
    dispatch({type: UPDATE_PASSWORD_LOADING, payload: true})
    const body = {
      newPassword,
      currentPassword,
    }
    const header = {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    }
    const res = await api.patch(`/users/${userId}/password`, body, header)
    if (!requestSuccess(res)) {
      throw new Error('pageInfo.newPassword.messages.setPassword.error')
    }
    dispatch({type: UPDATE_PASSWORD_LOADING, payload: false})
    dispatch({type: UPDATE_PASSWORD_SUCCESS})

    dispatch(
      addSuccessToast(
        translate('actions.pwUpdateSuccessfull', getState()) as string,
      ),
    )
  } catch (e) {
    dispatch({type: UPDATE_PASSWORD_LOADING, payload: false})
    dispatch({type: UPDATE_PASSWORD_FAILED})
    dispatch(handleError(e))
  }
}

export const sendVerificationEmail = (email: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const response = await auth.post(
      '/sendEmailVerificationEmail',
      {
        email,
      },
      {
        headers: {
          'Accept-Language': getState().localize.languages.filter(
            (v: any) => v.active,
          )[0].code,
        },
      },
    )

    if (!requestSuccess(response)) {
      throw new Error(
        'Verification email could not be sent. Please try again later.',
      )
    }

    return response.data
  } catch (e) {
    dispatch(handleError(e))
  }
}
