import React, { useEffect, useState } from 'react'
import { withRouter, RouteComponentProps, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Column } from '../Layout'
import Form from '../Form/Form'
import FormSection from '../Form/FormSection'
import Dropdown from '../FormInput/Dropdown'
import TextInput from '../FormInput/TextInput'
import SectionList from '../SectionList/SectionList'
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux'
import { RootState } from '../../../reducers'
import {
  selectOrganization,
  selectChallenge,
  setGoldenTicket,
} from '../actions/applicationActions'
import { handleError } from '../actions/errorHandlerActions'
import { api } from '../../../api'
import styles from './OrganizationSelector.module.scss'
import Button from '../shared/Button'
import { IParticipant } from '../models/application/IParticipant'
import {
  createApplicationWithFidPrefill,
  updateApplicationField,
  getApplicationFromOrganization,
} from './ApplicationForm/utils/api'
import { debounce } from 'throttle-debounce'
import { IApplication } from 'models/application/application'
import { goToFid } from 'service/FidService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { fetchChallenge } from '../actions/challengesActions'
import { currentProgramUrl } from "./utils";

export const singleChallenge = !!process.env.REACT_APP_CHALLENGE_ID
export const usesGoldenTicket =
  process.env.REACT_APP_USES_GOLDEN_TICKET === 'true'

interface SyncGoldenTicket {
  applicationId: string
  value: string
}

const syncGoldenTicket = debounce(
  1000,
  ({ applicationId, value }: SyncGoldenTicket) => {
    updateApplicationField({
      value,
      applicationId,
      field: 'goldenTicket',
    })
  },
)

interface IOrganization {
  id: string
  name: string
  owners: IParticipant[]
  members: IParticipant[]
}

interface IOrganizationSelectorProps
  extends LocalizeContextProps,
    RouteComponentProps {}

const OrganizationSelector = ({
  translate,
  history,
}: IOrganizationSelectorProps) => {
  const { user } = useSelector((state: RootState) => state)
  const { userId } = user
  const { selectedOrganization, selectedChallenge, goldenTicket } = useSelector(
    (state: RootState) => state.application,
  )

  const [organizations, setOrganizations] = useState<IOrganization[]>([])
  const [application, setApplication] = React.useState(
    null as IApplication | null,
  )
  const applicationExists = !!application

  const selectedOrgIndex = organizations.findIndex(
    (org) => org.id === selectedOrganization,
  )

  const dispatch = useDispatch()

  const { id: challengeId } = useParams()

  if (challengeId && challengeId !== selectedChallenge) {
    dispatch(selectChallenge(challengeId))
  }

  useEffect(() => {
    let timeout: number | undefined

    const getOrganizationList = async () => {
      try {
        const ownerPromise = api.get(`/users/${userId}/startups/owner`)
        const memberPromise = api.get(`/users/${userId}/startups/member`)
        const [{ data: ownerData }, { data: memberData }] = await Promise.all([
          ownerPromise,
          memberPromise,
        ])
        const allOrgs = [...ownerData, ...memberData]
        setOrganizations(allOrgs)
        if (allOrgs.length > 0) {
          if (selectedOrganization === undefined) {
            dispatch(selectOrganization(allOrgs[0].id))
          }
        }
      } catch (e) {
        dispatch(handleError(e))
      }
    }

    const tryGetOrganizationList = async () => {
      await getOrganizationList()
      if (organizations.length === 0) {
        timeout = window.setTimeout(getOrganizationList, 500)
      }
    }

    tryGetOrganizationList()

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
    // We explicitly don't want to run this when selectedOrgnaiztion changes. That is only used to
    // set the default on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, dispatch])

  useEffect(() => {
    const testApplicationExistence = async () => {
      const existingApplicationOrNull = await getApplicationFromOrganization({
        userId,
        selectedChallenge,
        selectedOrganization: selectedOrganization || '',
      })

      // checck
      setApplication(existingApplicationOrNull)
      dispatch(setGoldenTicket(existingApplicationOrNull?.goldenTicket || ''))
    }

    testApplicationExistence()
  }, [userId, selectedOrganization, selectedChallenge, dispatch])

  const sections = [
    {
      id: 'organization',
      title: translate('visaApplication.orgSection') as string,
    },
    {
      id: 'application',
      title: translate('visaApplication.applicationForm') as string,
    },
  ]

  const handleSectionSelected = (index: number) => {
    if (index === 1 && selectedOrganization) {
      startApplication()
    }
  }

  const handleOrganizationSelection = (index: number | number[]) => {
    if (Array.isArray(index)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    dispatch(selectOrganization(organizations[index].id))
  }

  const handleGoldenTicketChange = (newValue: string) => {
    dispatch(setGoldenTicket(newValue))
    if (applicationExists) {
      syncGoldenTicket({
        applicationId: application?.id,
        value: newValue,
      })
    }
  }

  const startApplication = async () => {
    try {
      if (!applicationExists) {
        await createApplicationWithFidPrefill({
          selectedChallenge,
          selectedOrganization,
          goldenTicket,
          user,
          handleError,
          participants: [user.userId],
          applicationMode: 'FID_ORGANISATION',
        })
      }
    } catch (err) {
      console.error(
        'Trying to create an application that you have created already',
      )
    } finally {
      if (singleChallenge) {
        history.push('/application')
      } else {
        history.push(`/programs/${selectedChallenge}/application`)
      }
    }
  }

  React.useEffect(() => {
    if (selectedChallenge) {
      dispatch(fetchChallenge(selectedChallenge))
    }
  }, [selectedChallenge, dispatch])

  const { challenges } = useSelector((state: RootState) => state.challenges)
  const challenge = challenges?.[challengeId]

  return (
    <div>
      <Row className={styles.breadcrumbsContainer}>
        <Breadcrumbs
          crumbs={[
            {
              link: '/',
              label: <FontAwesomeIcon icon={faHome} />,
              ariaLabel: translate('utility.home') as string,
            },
            {
              link: process.env.REACT_APP_PROGRAMS_URL,
              label: translate('applications.programs'),
            },
            {
              link: currentProgramUrl(challenge?.name),
              label: challenge?.name || translate('applications.program'),
            },
            {
              label: translate('applications.apply'),
            },
          ]}
        />
      </Row>
      <Row>
        <Column nav>
          <SectionList
            sections={sections}
            onSelected={handleSectionSelected}
            selectedSection={0}
            displayStatus={false}
          />
        </Column>
        <Column>
          <Form activeSectionIndex={0}>
            <FormSection>
              <Dropdown
                id='organization-select'
                label={
                  translate('visaApplication.organizationSelect') as string
                }
                description={
                  translate(
                    'visaApplication.organizationSelectDescription',
                  ) as string
                }
                value={selectedOrgIndex}
                onChange={handleOrganizationSelection}
                options={organizations.map((org) => org.name)}
              />

              <Button
                className={styles.addOrgButton}
                type='border'
                onClick={() => {
                  const appId = process.env.REACT_APP_PROJECT_NAME
                  goToFid(
                    `?newOrganisation=true&appId=${appId}&redirectUrl=${window.location.href}`,
                  )
                }}
              >
                <Translate id='visaApplication.createAnOrganisation' />
              </Button>

              {usesGoldenTicket && (
                <TextInput
                  id='golden-ticket'
                  type='text'
                  value={goldenTicket || ''}
                  label={
                    translate(
                      'visaApplication.goldenTicketQuestionTitle',
                    ) as string
                  }
                  description={
                    translate(
                      'visaApplication.goldenTicketQuestionDescription',
                    ) as string
                  }
                  onChange={handleGoldenTicketChange}
                />
              )}
            </FormSection>
          </Form>
        </Column>
        <footer className={styles.footer}>
          <div className={styles.footerContainer}>
            <div className={styles.footerButtonWrapper}>
              <div>
                <Button
                  type='solid'
                  hardClassOverride={styles.btn}
                  disabled={!selectedOrganization}
                  onClick={startApplication}
                >
                  <Translate id='visaApplication.startApplication' />
                </Button>
              </div>
            </div>
          </div>
        </footer>
      </Row>
    </div>
  )
}

export default withRouter(withLocalize(OrganizationSelector))
