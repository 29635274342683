import React, { useEffect, useState } from 'react'
import styles from './Pagination.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { range } from 'lodash'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons'
import { PaginationProps } from "./types";
import { getCurrentPage } from "./utils";

const Pagination = ({ resultCount }: PaginationProps) => {
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(1)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setTotalPages(Math.ceil(resultCount / limit))
  }, [resultCount, limit])

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    if (parsed.keys && !Array.isArray(parsed.keys)) {
      setLimit(parseInt(parsed.keys, 10))
    }
  }, [location.search])

  const handlePageChange = (pageNumber: number) => () => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      const search = queryString.parse(location.search)
      const newSearch = {
        ...search,
        page: pageNumber,
      }
      history.push({
        search: queryString.stringify(newSearch),
      })
    }
  }

  const currentPage = getCurrentPage(location.search)
  const pageSpan = 2
  const minNum = currentPage - pageSpan <= 3 ? 2 : currentPage - pageSpan
  const maxNum =
    currentPage + pageSpan >= totalPages - 2
      ? totalPages - 1
      : currentPage + pageSpan

  return (
    <div className={styles.paginationContainer}>
      <div className={styles.linkContainer}>
        {totalPages > 1 && (
          <>
            <span
              className={classNames(styles.link, {
                [styles.disabled]: currentPage === 1,
              })}
              onClick={handlePageChange(currentPage - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </span>
            <div
              className={classNames(styles.link, {
                [styles.active]: currentPage === 1,
              })}
              onClick={handlePageChange(1)}
            >
              1
            </div>
            {minNum > 2 && <span>...</span>}
            {range(minNum, maxNum + 1).map((page) => (
              <div
                key={page}
                className={classNames(styles.link, {
                  [styles.active]: page === currentPage,
                })}
                onClick={handlePageChange(page)}
              >
                {page}
              </div>
            ))}
            {maxNum < totalPages && (
              <>
                {maxNum < totalPages - 1 && <span>...</span>}
                <div
                  className={classNames(styles.link, {
                    [styles.active]: currentPage === totalPages,
                  })}
                  onClick={handlePageChange(totalPages)}
                >
                  {totalPages}
                </div>
              </>
            )}
            <span
              className={classNames(styles.link, {
                [styles.disabled]: currentPage === totalPages,
              })}
              onClick={handlePageChange(currentPage + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default Pagination
