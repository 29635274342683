import moment from 'moment'
import { requestSuccess, ROUTES } from '../api'
import { handleError } from '../components/common/actions/errorHandlerActions'
import { Dispatch } from "redux";
import { RootState } from "../reducers";
import ApiWrapperService from "../service/ApiWrapperService";

const FileDownload = require('js-file-download')

// *** ACTIONS ***
export const ACTIONS = {
  LOADING_USERS: '@dff_challenges_action/loading_users',
  LOADING_USERS_SUCCESS: '@dff_challenges_action/loading_users_success',
  LOADING_ROLES: '@dff_challenges_action/loading_roles',
  LOADING_ROLES_SUCCESS: '@dff_challenges_action/loading_roles_success',
  UPDATE_USER_SUCCESS: '@dff_challenges_action/admin_update_user_success',
  DELETE_USER_SUCCESS: '@dff_challenges_action/admin_delete_user_success',
  LOADING_STARTUPS: '@dff_challenges_action/admin/loading_startups',
  GET_ALL_STARTUPS_SUCCESS: '@dff_challenges_action/admin/get_startups_success',
  UPDATE_STARTUP_SUCCESS: '@dff_challenges_action/admin/update_startup_success',
  DELETE_STARTUP_SUCCESS: '@dff_challenges_action/admin/delete_startup_success',
};

/**
 * Default behaviour:
 * - hide low rank users: hideUsers = true
 */
export const getAllUsers = (search: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({type: ACTIONS.LOADING_USERS, payload: true})
    // compute search query string according to what is given as params of this function
    // if there are no params, hide users...
    const searchParams = new URLSearchParams(search)
    // check if there are any query params passed by checking the list of entries
    const searchQueryString = [...searchParams.entries()].length
      ? // if there is something then query as is
      search
      : // otherwise by default hide all users
      '?hideUsers=true'

    const res = await ApiWrapperService.get({url: `${ROUTES.USERS}${searchQueryString}`, state: getState()})
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch users.')
    }
    dispatch({type: ACTIONS.LOADING_USERS, payload: false})
    dispatch({type: ACTIONS.LOADING_USERS_SUCCESS, payload: res.data})
  } catch (e) {
    dispatch({type: ACTIONS.LOADING_USERS, payload: false})
    await handleError(e)(dispatch, getState, false)
  }
}

export const getAllRoles = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({type: ACTIONS.LOADING_ROLES, payload: true})
    const res = await ApiWrapperService.get({url: ROUTES.USER_ROLES, state: getState()})
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch roles.')
    }
    dispatch({type: ACTIONS.LOADING_ROLES, payload: false})

    dispatch({type: ACTIONS.LOADING_ROLES_SUCCESS, payload: res.data})
  } catch (e) {
    dispatch({type: ACTIONS.LOADING_ROLES, payload: false})
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateRole = (userId: string, role: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.USER_ROLES}/${userId}`,
      body: role,
      state: getState()
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to update user role.')
    }
    dispatch({type: ACTIONS.UPDATE_USER_SUCCESS, payload: res.data})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteUser = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.delete(`${ROUTES.USERS}/${id}`, getState())
    if (!requestSuccess(res)) {
      throw new Error('Failed to delete user.')
    }
    dispatch({type: ACTIONS.DELETE_USER_SUCCESS, payload: id})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const activateUser = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.USERS}/${id}/activate`,
      body: {},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to activate user.')
    }
    dispatch({type: ACTIONS.UPDATE_USER_SUCCESS, payload: res.data})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deactivateUser = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.USERS}/${id}/deactivate`,
      body: {},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to deactivate user.')
    }
    dispatch({type: ACTIONS.UPDATE_USER_SUCCESS, payload: res.data})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const getAllStartups = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({type: ACTIONS.LOADING_STARTUPS, payload: true})
    const res = await ApiWrapperService.get({url: ROUTES.STARTUPS, state: getState()})
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch startups.')
    }
    dispatch({type: ACTIONS.LOADING_STARTUPS, payload: false})
    dispatch({type: ACTIONS.GET_ALL_STARTUPS_SUCCESS, payload: res.data})
  } catch (e) {
    dispatch({type: ACTIONS.LOADING_STARTUPS, payload: false})
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteStartup = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.delete(`${ROUTES.STARTUPS}/${id}`, getState())
    if (!requestSuccess(res)) {
      throw new Error('Failed to delete startup.')
    }
    dispatch({type: ACTIONS.DELETE_STARTUP_SUCCESS, payload: id})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const activateStartup = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.STARTUPS}/${id}/activate`,
      body: {},
      state: getState()
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to activate startup.')
    }
    dispatch({type: ACTIONS.UPDATE_STARTUP_SUCCESS, payload: res.data})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deactivateStartup = ({id}: any) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.STARTUPS}/${id}/deactivate`,
      body: {},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to deactivate user.')
    }
    dispatch({type: ACTIONS.UPDATE_STARTUP_SUCCESS, payload: res.data})
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deactivateStartupAndFetchStartups = (
  id: any,
  userId: any,
) => async (dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.patch({
      url: `${ROUTES.STARTUPS}/${id}/deactivate`,
      body: {},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to deactivate user.')
    }
    dispatch({type: ACTIONS.UPDATE_STARTUP_SUCCESS, payload: res.data})
    // TODO: uncomment
    // if (userId) dispatch(fetchUserStartups(userId))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const exportStartups = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    ApiWrapperService
      .get({
        url: `${ROUTES.STARTUPS}/export/csv`,
        state: getState(),
        optionalConfig: {responseType: 'blob'}
      })
      .then((response) => {
        FileDownload(response.data, 'startups.csv')
      })
      .catch((error) => {
        throw new Error('Failed to download exported file.')
      })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const exportUsers = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    ApiWrapperService
      .get({
        url: `${ROUTES.USERS}/export/csv`,
        state: getState(),
        optionalConfig: {responseType: 'blob'}
      })
      .then((response) => {
        FileDownload(response.data, 'users.csv')
      })
      .catch((error) => {
        throw new Error('Failed to download exported file.')
      })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const exportChallenges = () => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const response = await ApiWrapperService.get({
      url: ROUTES.EXPORT_CHALLENGES_CSV,
      state: getState(),
      optionalConfig: {responseType: 'blob'}
    })
    FileDownload(response.data, 'challenges.csv')
  } catch (error) {
    await handleError(new Error('Failed to download exported file.'))(
      dispatch,
      getState,
      false,
    )
  }
}

export const exportChallenge = (challengeId: string) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const response = await ApiWrapperService.get({
      url: `/export/challenges/csv/${challengeId}`,
      state: getState(),
      optionalConfig: {responseType: 'blob'},
    })
    FileDownload(
      response.data,
      `program_${challengeId || ''}_${moment().format('YYYYMMDDTHHMM')}.xlsx`,
    )
  } catch (error) {
    await handleError(new Error('Failed to download exported file.'))(
      dispatch,
      getState,
      false,
    )
  }
}
