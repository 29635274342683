import React from 'react'
import styles from './AppTabBar.module.scss'
import { IBarProps } from "./types";
import { Tab } from "./Tab";
import { AddTab } from "./AddTab";

export default ({
  tabs,
  activeIndex,
  setIndex,
  addTab,
  showProgressBar,
  onAddClick,
  getTabDelete,
  addTabLabel,
  showActiveTabIndicator,
}: IBarProps) => {
  return (
    <div className={styles.container}>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          label={tab.label}
          setIndex={setIndex}
          index={index}
          maxValue={tab.maxValue}
          value={tab.value}
          showProgressBar={showProgressBar}
          showActiveTabIndicator={showActiveTabIndicator}
          active={activeIndex === index}
          onDelete={
            getTabDelete && tabs.length > 2 ? getTabDelete(index) : undefined
          }
        />
      ))}
      {addTab && onAddClick && (
        <AddTab onAddClick={onAddClick} label={addTabLabel || 'Add Tab'} />
      )}
    </div>
  )
}
