import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import warningIcon from '../../assets/icons/warning.svg'
import styles from './CreateChallengeModal.module.scss'
import Modal from '../Modal/Modal'
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux'
import { RejectionMode, rejectionModeOptions } from '../../models/challenge/challenge'
import ModalSelect from './ModalSelect'
import { RootState } from 'reducers'
import {
  fetchOwnedProgramOwnerProfiles,
  createChallengeFromModal,
} from '../../actions/programOwnerActions'
import Checkbox from '../common/FormInput/Checkbox'
import Button from '../common/shared/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import classnames from 'classnames'

interface IProps extends LocalizeContextProps {
  onSuccess: (id: string) => void
  onClose: () => void
  forceProgramOwnerProfileId?: string
}

const CreateChallengeModal = ({
  translate,
  onSuccess: rawOnSuccess,
  onClose,
  forceProgramOwnerProfileId,
}: IProps) => {
  // flag indicating the the challenge is being created
  const [isCreatingChallenge, setIsCreatingChallenge] = useState(false)

  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [reviewStageTemplateId, setReviewStageTemplateId] = useState('')
  const [applicationTemplateId, setApplicationTemplateId] = useState('')
  const [programOwnerProfileId, setProgramOwnerProfileId] = useState('')
  const [applicationMode, setApplicationFlow] = useState('FID_INDIVIDUAL')
  const [rejectionMode, setRejectionMode] = useState<RejectionMode>(
    RejectionMode.onApplication,
  )
  const [fidIntegration, setFidIntegration] = useState(false)
  const [fidIntegrationDisabled, setFidIntegrationDisabled] = useState(false)

  const { programOwnerProfiles } = useSelector(
    (state: RootState) => state.programOwner,
  )
  const selectedProfile =
    programOwnerProfiles &&
    programOwnerProfiles.find((profile) => profile.id === programOwnerProfileId)
  const challenges = selectedProfile ? selectedProfile.challenges : []

  const dispatch = useDispatch()

  useEffect(() => {
    if (forceProgramOwnerProfileId) {
      setProgramOwnerProfileId(forceProgramOwnerProfileId)
    } else {
      setProgramOwnerProfileId('')
      dispatch(fetchOwnedProgramOwnerProfiles(null, null))
    }
  }, [dispatch, forceProgramOwnerProfileId])

  const inputRef = useRef(null)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value.trim() && !name.length) {
      return
    }
    setName(e.currentTarget.value)
    setError('')
  }

  const onSuccess = (id: string) => {
    setIsCreatingChallenge(false)
    rawOnSuccess(id)
  }
  const onError = (e: string) => {
    setIsCreatingChallenge(false)
    setError(e)
  }

  const isFormValid = !(
    !name.length ||
    !programOwnerProfileId ||
    isCreatingChallenge
  )
  const handleSubmit = (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault()
    if (!name.trim() || !(name.length > 0)) {
      setError('admin.min1Chars')
    }
    if (programOwnerProfileId) {
      setIsCreatingChallenge(true)
      dispatch(
        createChallengeFromModal(
          programOwnerProfileId,
          name.trim(),
          reviewStageTemplateId,
          applicationTemplateId,
          rejectionMode,
          applicationMode,
          fidIntegration,
          onSuccess,
          onError,
        ),
      )
    }
  }

  const handleRejectionModeChange = (newValue: string) => {
    if (rejectionModeOptions.some((option) => newValue === option.value)) {
      // @ts-ignore it's been checked for validity above
      setRejectionMode(newValue)
    }
  }

  useEffect(() => {
    if (inputRef && inputRef.current) {
      // @ts-ignore i just checked that
      inputRef.current.focus()
    }
  }, [inputRef])

  const challengeOptions = challenges
    ? challenges.map((challenge) => ({
        value: challenge.id as string,
        label: challenge.name as string,
      }))
    : []

  const programOwnerProfileOptions = programOwnerProfiles
    ? programOwnerProfiles.map((profile) => {
        return {
          value: profile.id,
          label: profile.name || '',
        }
      })
    : []

  const handleCopyApplicationChange = (newValue: string) => {
    setApplicationTemplateId(newValue)
    if (newValue === '') {
      setFidIntegrationDisabled(false)
    } else {
      setFidIntegrationDisabled(true)
      setFidIntegration(false)
    }
  }

  return (
    <>
      <Modal close={onClose} requireAction>
        <div className={styles.container}>
          <h4 className={styles.title}>
            <Translate id='admin.newChallenge' />
          </h4>
          <div>
            <div className='inputGroup'>
              <input
                type='text'
                className={classnames(styles.input, styles.nameInput)}
                placeholder={translate('admin.nameOfChallenge').toString()}
                onChange={onChange}
                value={name}
                ref={inputRef}
              />
              {error && (
                <div className='inputErrorLabel'>
                  <img src={warningIcon} alt='Warning Icon' />
                  <span>
                    <Translate id={error} />
                  </span>
                </div>
              )}
              <ModalSelect
                label={translate('admin.programOwnerProfile').toString()}
                value={programOwnerProfileId}
                onChange={setProgramOwnerProfileId}
                options={programOwnerProfileOptions}
                allowBlank
              />
              <ModalSelect
                label={translate('admin.copyWorkflow').toString()}
                value={reviewStageTemplateId}
                onChange={setReviewStageTemplateId}
                options={challengeOptions}
                allowBlank
                blankLabel={translate('admin.noTemplate').toString()}
                disabled={!programOwnerProfileId}
              />
              <ModalSelect
                label={translate('admin.copyApplication').toString()}
                value={applicationTemplateId}
                onChange={handleCopyApplicationChange}
                options={challengeOptions}
                allowBlank
                blankLabel={translate('admin.noTemplate').toString()}
                disabled={!programOwnerProfileId}
              />
              <div className={styles.fieldWithTooltip}>
                <ModalSelect
                  label={translate('admin.rejectionMode').toString()}
                  value={rejectionMode}
                  onChange={handleRejectionModeChange}
                  options={rejectionModeOptions.map((option) => ({
                    value: option.value,
                    label: option.label,
                  }))}
                  disabled={!programOwnerProfileId}
                />
                <InfoTooltip
                  overlay={
                    <p>
                      Configures the trigger of rejection email to applicant.
                      Automatic: Sends email automatically on application
                      rejection. Manual: Email is triggered manually by Admin on
                      application process completion
                    </p>
                  }
                >
                  <FontAwesomeIcon
                    icon={faQuestion}
                    className={styles.tooltipIcon}
                  />
                </InfoTooltip>
              </div>
              <ModalSelect
                label={translate('admin.applicationMode.label').toString()}
                value={applicationMode}
                onChange={setApplicationFlow}
                options={[
                  {
                    value: 'FID_INDIVIDUAL',
                    label: translate(
                      'admin.applicationMode.individual',
                    ).toString(),
                  },
                  {
                    value: 'FID_ORGANISATION',
                    label: translate(
                      'admin.applicationMode.organisation',
                    ).toString(),
                  },
                  {
                    value: 'E-MAIL',
                    label: translate('admin.applicationMode.email').toString(),
                  },
                ]}
                disabled={!programOwnerProfileId}
              />
              <div className={styles.fieldWithTooltip}>
                <Checkbox
                  id='fid-integration'
                  value={fidIntegration}
                  onChange={setFidIntegration}
                  label={<Translate id='admin.fidIntegration' />}
                  className={styles.checkbox}
                  disabled={fidIntegrationDisabled}
                />
                <InfoTooltip
                  overlay={
                    <p>
                      If checked, sections about personal and company profile
                      will be created automatically in the application form.
                    </p>
                  }
                >
                  <FontAwesomeIcon
                    icon={faQuestion}
                    className={styles.tooltipIcon}
                  />
                </InfoTooltip>
              </div>
            </div>
            <Button
              disabled={!isFormValid}
              type='solid'
              className={styles.button}
              onClick={handleSubmit}
            >
              <Translate id='create' />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default withLocalize(CreateChallengeModal)
