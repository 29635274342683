export const boolToYesNoOption = (bool?: boolean) => {
  if (bool === undefined) return 0
  if (bool) return 1
  return 2
}

export const yesNoOptionToBool = (optionIndex: number) => {
  if (optionIndex === 0) return undefined
  if (optionIndex === 1) return true
  return false
}
