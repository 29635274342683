import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from '../AdminTables.module.scss'
import programStyles from './Programs.module.scss'
import { fetchUserChallenges } from 'components/common/actions/userActions'
import { exportChallenge } from 'actions/adminActions'
import { RootState } from 'reducers'
import Table from 'components/common/Table/Table'
import CreateChallengeModal from 'components/ProgramOwner/CreateChallengeModal'
import ConfirmActionModal from 'components/Modal/ConfirmActionModal'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { IChallenge } from 'models/challenge/challenge'
import { DateTime } from 'luxon'
import { HumanReadableRemainingDays } from 'utils/timeUtils'
import { getColumns, IRow } from './Columns'
import { useLocation, useHistory } from 'react-router-dom'
import FilterSearch from 'components/common/TableFilter/FilterSearch'
import Pagination from 'components/common/TableFilter/Pagination'
import classNames from 'classnames'
import { deleteChallenge } from 'actions/programOwnerActions'
import FitScreen from 'components/common/Layout/FitScreen'

const convertData = (rawData: IChallenge[] | null, userRank: number): IRow[] => {
  if (rawData) {
    const now = DateTime.local()

    return rawData.map((challenge) => {
      const remainingDays = challenge.applicationDeadline
        ? Math.round(
            // @ts-ignore  TODO applicationDeadline is just not a Date
            DateTime.fromISO(challenge.applicationDeadline)
              .diff(now)
              .as('days'),
          )
        : undefined
      return {
        // @ts-ignore
        id: challenge._id,
        name: challenge.name || 'Unknown',
        managedBy:
          (challenge.programOwner && challenge.programOwner.name) || '-',
        applicationCount:
          (challenge.metaData && challenge.metaData.applicationCount) || 0,
        rejectedApplicationCount:
          (challenge.metaData && challenge.metaData.rejectedApplicationCount) ||
          0,
        // @ts-ignore
        publishedAt:
          (challenge.publishedAt &&
            // @ts-ignore  TODO applicationDeadline is just not a Date
            DateTime.fromISO(challenge.publishedAt).toLocaleString()) ||
          '-',
        applicationDeadline: (
          <HumanReadableRemainingDays daysRemaining={remainingDays} />
        ),
        // include remaining days for comparison
        remainingDays,
        url: userRank >= 2
        // @ts-ignore
          ? `/programs/${challenge._id}/settings`
        // @ts-ignore
          : `/programs/${challenge._id}`,
      }
    })
  }
  return []
}

const Programs = ({ translate }: LocalizeContextProps) => {
  const dispatch = useDispatch()
  const [selectedRow, selectRow] = useState<string | null>(null)
  const [addChallengeModalVisible, setAddChallengeModalVisible] = useState(
    false,
  )
  const [
    deleteChallengeModalVisible,
    setDeleteChallengeModalVisible,
  ] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const { challenges, challengesCount, userId, role } = useSelector(
    (state: RootState) => state.user,
  )
  const { rank } = role
  const data = convertData(challenges, rank)

  const toggleChallengeModal = () => {
    setAddChallengeModalVisible(!addChallengeModalVisible)
  }

  const toggleDeleteChallengeModal = () => {
    setDeleteChallengeModalVisible(!deleteChallengeModalVisible)
  }

  useEffect(() => {
    dispatch(
      fetchUserChallenges({
        userId,
        query: location.search,
      }),
    )
  }, [dispatch, userId, location.search])

  const handleAddChallenge = () => {
    toggleChallengeModal()
  }

  const handleDeleteModalOpen = (profileId: string) => () => {
    selectRow(profileId)
    toggleDeleteChallengeModal()
  }

  const handleExportApplications = (challengeId: string) => () => {
    dispatch(exportChallenge(challengeId))
  }

  const handleDelete = (profileId: string | null) => () => {
    if (profileId) {
      dispatch(deleteChallenge(profileId))
      toggleDeleteChallengeModal()
    }
  }

  if (data) {
    return (
      <FitScreen>
        <div className={classNames(styles.container, programStyles.container)}>
          <FilterSearch
            dark
            supportedFilters={{
              programName: 'Name',
              managedBy: 'Facilitator',
            }}
            defaultFilter='programName'
          />
          <div
            className={classNames(
              styles.tableContainer,
              styles.tableWithSearch,
            )}
          >
            <Table
              data={data}
              columns={getColumns({
                translate,
                handleExportApplications,
                handleDeleteModalOpen,
                userRank: rank,
              })}
              title={translate('admin.programs').toString()}
              onAdd={rank >= 2 ? handleAddChallenge : undefined}
              addLabel={translate('admin.newChallenge').toString()}
            />
            <Pagination resultCount={challengesCount} />
          </div>
        </div>
        {addChallengeModalVisible && (
          <CreateChallengeModal
            onSuccess={(id: string) => {
              history.push(`/programs/${id}/settings`)
            }}
            onClose={toggleChallengeModal}
          />
        )}
        {deleteChallengeModalVisible && (
          <ConfirmActionModal
            title={translate('admin.deleteChallengeConfirmation').toString()}
            confirmText={translate('delete').toString()}
            cancelText={translate('cancel').toString()}
            close={toggleDeleteChallengeModal}
            confirmAction={handleDelete(selectedRow)}
          />
        )}
      </FitScreen>
    )
  }
  return null
}

export default withLocalize(Programs)
