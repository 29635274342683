import React from 'react'
import { CellProps } from 'react-table'
import { Translate } from 'react-localize-redux'
import CopyableLink from '../../../../CopyableLink/CopyableLink'
import { HumanReadableRemainingDays } from 'utils/timeUtils'
import { IProgramsTableRow } from "../../types";

export const ColumnsProgramsTable = ({ goToSettings }: {
  goToSettings: boolean,
}) => [
  {
    Header: ' ',
    columns: [
      {
        Header: <Translate id='dashboard.tables.name' />,
        accessor: 'name',
        disableSortBy: true,
        width: 100,
        Cell: ({ cell, row }: CellProps<IProgramsTableRow>) => {
          const { id } = row.original
          return (
            <CopyableLink
              to={goToSettings
                ? `/programs/${id}/settings`
                : `/programs/${id}`}
            >
              {cell.value}
            </CopyableLink>
          )
        },
      },
      {
        Header: <Translate id='dashboard.tables.remainingDays' />,
        accessor: 'remainingDays',
        disableSortBy: true,
        width: 50,
        Cell: ({ cell }: CellProps<IProgramsTableRow>) => {
          return <HumanReadableRemainingDays daysRemaining={cell.value} />
        },
      },
      {
        Header: <Translate id='dashboard.tables.applicationNumber' />,
        accessor: 'applicationCount',
        disableSortBy: true,
        width: 60,
        Cell: ({ cell, row }: CellProps<IProgramsTableRow>) => {
          return (
            <CopyableLink
              reverse
              to={`/programs/${row.original.id}/applications`}
            >
              {cell.value}
            </CopyableLink>
          )
        },
      },
    ],
  },
]
