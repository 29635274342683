import React, { Component } from 'react'
import styles from './Login.module.scss'
import layout from 'scss/modules/layout.module.scss'
import mainStyles from 'components/common/Main/Main.module.scss'
import { Translate, withLocalize } from 'react-localize-redux'
import Checkbox from 'components/common/FormInput/Checkbox'
import queryString from 'query-string'
import { LoginProps } from "./types";

const TERMS_CHECKED = 'terms_checked'

class Login extends Component<LoginProps> {
  state = {
    isTermsChecked: window.localStorage.getItem(TERMS_CHECKED) === 'true',
  }

  handleTermsCheck = (checked: boolean) => {
    this.setState({ isTermsChecked: checked })
    window.localStorage.setItem(TERMS_CHECKED, checked.toString())
  }

  authorize() {
    if (process.env.REACT_APP_AUTH_URL) {
      const parsedQueryString = queryString.parse(window.location.search)
      const fidQuery = {
        ...parsedQueryString,
        app_id: 'programs',
      }
      const authUrl = `${
        process.env.REACT_APP_AUTH_URL
      }?${queryString.stringify(fidQuery)}`

      window.location.assign(authUrl)
    }
  }

  render() {
    return (
      <div className={layout.container}>
        <div className={layout.areaLeft}>
          <div className={mainStyles.headingContainer}>
            <h1 className={mainStyles.heading}>
              <Translate
                id='login.hline1'
                options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
              />
            </h1>
          </div>
        </div>
        <div className={layout.areaRight}>
          <div className={layout.formContainer}>
            <form className={styles.form}>
              <h2 className={styles.heading}>
                <Translate id='login.registerLogin' />
              </h2>
              <button
                type='button'
                onClick={this.authorize}
                className={styles.button}
                disabled={!this.state.isTermsChecked}
              >
                <Translate id='login.connectWithFutureId' />
              </button>
              <div className={styles.termsParagraph}>
                <Checkbox
                  id='terms'
                  className={styles.termsCheckbox}
                  value={this.state.isTermsChecked}
                  onChange={this.handleTermsCheck}
                  label={(
                    <Translate id='register.form.confirmTermsInfo' options={{ renderToStaticMarkup: false, renderInnerHtml: true }} />

                  )}
                />
              </div>
              <p className={styles.description}>
                <Translate id='login.description' />
              </p>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default withLocalize(Login)
