import React from 'react'
import styles from './Terms.module.scss'
import layout from 'scss/modules/layout.module.scss'
import classnames from 'classnames'
import { TermsProps } from "./types";

export default ({ asTNCContent }: TermsProps) => (
  <>
    <div
      className={classnames(
        layout.container,
        styles.container,
        asTNCContent && styles.tnc,
      )}
    >
      <div className={[layout.contentFullContainer].join(' ')}>
        <h3>
          <strong>
            DUBAI FUTURE PROGRAMS WEBSITE TERMS AND CONDITIONS OF USE
          </strong>
        </h3>
        <p>
          <strong>Please read carefully before accessing this website</strong>
        </p>
        <p>
          <strong>Disclaimer and Acknowledgment</strong>
        </p>
        <p>
          This Dubai Future Programs website (this “<strong>Website</strong>”)
          is managed and operated by or on behalf of Dubai Future Foundation (“
          <strong>DFF</strong>”). This Website is a platform for providing
          information in connection with various innovative programs,
          applications and submission request areas for private and public
          sector organisations both within and outside Dubai and the United Arab
          Emirates (the “<strong>Programs</strong>”, and each a “
          <strong>Program</strong>”).
        </p>
        <p>
          The terms “<strong>we</strong>”, “<strong>us</strong>” or “
          <strong>our(s)</strong>” hereunder refer to the DFF and its affiliates
          and the terms “<strong>user</strong>”, “<strong>you</strong>” and “
          <strong>your</strong>” hereunder refer to the users of this Website.
        </p>
        <p>
          The term “<strong>Content</strong>” means the texts, documents,
          statements, information, photographs, videos, drawings, questions,
          answers, contact details, and any and all other materials of any
          nature whatsoever in whatever form or format which is available via
          this Website, created by us or by any third party (including the
          relevant public or private sector entity that is the owner or sponsor
          of a Program).
        </p>
        <p>
          The term “<strong>Intellectual Property Rights”</strong> means any and
          all current and/or future intellectual property rights, including all
          forms of copyright and authors rights (including any and all rights
          that may be considered financial rights provided under United Arab
          Emirates Federal Law No. 7 of 2002), computer and software code,
          scripts, patents, design elements, graphics, logos, interactive
          features, artwork, text communication, brand names, trademarks,
          service marks, rights in trade dress or get-up, goodwill, domain
          names, website addresses (URL), know-how, trade secret, rights to sue
          for passing off, unfair competition rights, moral rights and any other
          content that may be found on or in this Website created by us or on
          our behalf.
        </p>
        <p>
          Any words following the terms “<strong>including”</strong>, “
          <strong>include”</strong>, “<strong>in particular”</strong> or “
          <strong>for example”</strong> or any similar phrase shall be construed
          as illustrative and shall not limit the generality of the related
          general words.
        </p>
        <p>
          These terms and conditions of use (the “<strong>Terms of Use</strong>
          ”) explain the terms on which you may access and/or use this Website.
        </p>
        <p>
          <strong>
            By accessing and/or using this Website, you hereby agree that you
            have read, understood and shall abide by all of the terms and
            conditions as set forth in these Term of Use, which shall be binding
            on you.
          </strong>
        </p>
        <p>
          Please note, if you choose to apply to participate for any one or more
          Programs via this Website, such participation may be subject to
          separate terms which you may need to enter into with the relevant
          public or private sector entity that is the owner or sponsor of that
          Program. If there is any conflict or inconsistency between a term of
          these Terms of Use and a term of any separate terms which you may need
          to enter into with the relevant public or private sector entity that
          is the owner or sponsor of that Program, the term of these Terms of
          Use shall prevail to the extent of such conflict or inconsistency.
        </p>
        <p>
          Please also note our privacy policy which is set out on this Website
          (the <strong>Privacy Policy</strong>). The Privacy Policy explains how
          we collect, process and use your personal information. Please refer to
          the <a href='/privacy'>Privacy Policy</a> for further details. If
          there is any conflict or inconsistency between a term of these Terms
          of Use and a term of the Privacy Policy, the term of the Privacy
          Policy shall prevail to the extent of such conflict or inconsistency.
        </p>
        <p>
          The Privacy Policy is subject to and is incorporated by reference into
          these Terms of Use (collectively, this “<strong>Agreement”</strong>).
        </p>
        <p>
          You represent that you are of legal age to form a binding contract.
          You must be at least 21 years old to be eligible to use this Website
          and apply to participate for a Program. If you are under 21 years of
          age, you may only use this Website subject to you obtaining the
          express consent of your parent or guardian who agrees to the terms of
          this Agreement.
        </p>
        <p>
          <strong>
            If you do not agree to abide by this Agreement, please do not access
            or use this Website or apply to participate for a Program. You are
            responsible for ensuring that all persons who access this Website
            through your internet connection are aware of this Agreement and
            other applicable terms and conditions and that they comply with them
            in all respects.
          </strong>
        </p>
        <p>
          We provide you with a limited right to access and use this Website
          subject to the terms and conditions of this Agreement. We remain the
          owners of this Website at all times.
        </p>
        <p>
          <strong>System Requirements</strong>
        </p>
        <p>
          We do not guarantee this Website will be bug or virus free nor do we
          guarantee any minimum uptime or service levels in relation to this
          Website. From time to time this Website may be updated in accordance
          with the relevant sections set out below. Depending on the update, you
          may not be able to use this Website (or any part thereof). Please see
          the Intellectual Property Rights section below for further information
          on how you are permitted to make use of this Website and the Content.
        </p>
        <p>
          <strong>Content and the Programs</strong>
        </p>
        <p>
          The Content is for information only and is not intended to amount to
          advice on which you should rely on. This Website may include content
          or links of, or from, third parties, including in relation to a
          Program. We do not endorse or guarantee the accuracy, reliability or
          appropriateness of any opinion, statement, information or material of
          any third party uploaded to, linked to or posted on this Website,
          including in relation to a Program.
        </p>
        <p>
          You acknowledge that by using this Website you may be exposed to
          information that is inaccurate or unreliable and in this respect your
          use of this Website and your reliance on the Content (including any
          such third party information or material) is entirely at your own
          risk.
        </p>
        <p>
          We will not have any responsibility or liability for the conduct of
          any user on or through this Website and we assume no responsibility or
          liability for any lack of disclosure of data, mistakes, misstatements
          of law, defamation, libel, omissions, opinions, representations or
          anything else contained in any Content or in any information or
          materials that are provided by any third party.
        </p>
        <p>
          We will not have any responsibility or liability for any on-boarding
          process for this Website or any Program, the selection or shortlisting
          process for any Program, the awarding of any Program or for
          information that you provide through this Website or that you share
          with any third party (including with the relevant public or private
          sector entity that is the owner or sponsor of a Program). We assume no
          responsibility or liability for any on-boarding, selection,
          shortlisting or awarding process(es) in relation to a Program or the
          lack of disclosure of data, mistakes, misstatements of law,
          defamation, libel, omissions, acts, opinions, representations or
          anything else that is provided or made by any third parties (including
          the relevant public or private sector entity that is the owner or
          sponsor of a Program).
        </p>
        <p>
          We reserve the right, but disclaim any obligation, to monitor content
          from users of this Website, or take any action to restrict any access
          to material displayed or distributed through this Website that
          violates this Agreement.
        </p>
        <p>
          Any content you upload to our Website will be considered
          non-confidential and non-proprietary. You retain all of your ownership
          rights in your content, but you are required to grant us a perpetual,
          irrevocable, worldwide and royalty-free licence to use, store and copy
          that content and to distribute and make it available to third parties
          (including to the relevant public or private sector entity that is the
          owner or sponsor of a Program), if any content is provided to us or
          via this Website, you hereby grant the above licence to us and consent
          for us to use, store, copy, share and distribute your content for such
          purposes. You are solely responsible for securing and backing up your
          content. We also have the right to disclose your identity to any third
          party who is claiming that any content posted or uploaded by you to
          our Website constitutes a violation of their Intellectual Property
          Rights or of their right to privacy.
        </p>
        <p>
          We, at our sole discretion, reserve the right to pre-screen, refuse or
          remove any content (whether from you or others) from this Website
          without giving any reasons.
        </p>
        <p>
          You understand and agree that we may retain server and backup copies
          of any of your submitted content even if you have altered, removed or
          deleted your content from public display.
        </p>
        <p>
          You agree that any information provided by you on this Website and in
          relation to any Program is correct, accurate and not misleading, to
          the best of your knowledge.
        </p>
        <p>
          <strong>Links</strong>
        </p>
        <p>
          If you have registered on this Website or accessed this Website via a
          link or connection (including plug-ins or like applications) from
          another website owned or operated by a third party (including social
          media platforms), please be informed that we have no control over such
          third party links, connections (including plug-ins or like
          applications), information or website(s) and we are not responsible or
          liable for its content and make no representations about (or in any
          way endorse) any material or services available at or from such third
          party website (including login or registration access). Such link or
          connection (including plug-ins or like applications) does not imply
          sponsorship, affiliation or endorsement by us of the third party or
          the third party website. We do not in any way guarantee that such
          links or connections (including plug-ins or like applications) will be
          up to date, free from any errors, inaccuracies or omissions or be fit
          for any purpose. If you access any website owned or operated by a
          third party (including social media platforms) via this Website,
          please be informed that we have no control over such third party
          website and we are not responsible or liable for its content and make
          no representations about (or in any way endorse) any material or
          services available at or from such third party website. Such access to
          such website(s) does not imply sponsorship, affiliation or endorsement
          by us of the third party or the third party website. We do not in any
          way guarantee that such links or connections (including plug-ins or
          like applications) will be up to date, free from any errors,
          inaccuracies or omissions or be fit for any purpose.
        </p>
        <p>
          <strong>
            When you leave this Website, we encourage you to read the privacy
            policy and terms and conditions of use of every website you visit.
          </strong>
        </p>
        <p>
          <strong>No Warranties or Endorsements</strong>
        </p>
        <p>
          This Website is provided subject to all of the terms and conditions of
          this Agreement.
        </p>
        <p>
          To the fullest extent permitted by law, we exclude all conditions,
          warranties, representations or other terms which may otherwise apply
          to this Website, the Programs and the Content whether express or
          implied.
        </p>
        <p>
          We do not warrant that this Website, the Programs or the Content (or
          any part thereof) will be:
        </p>
        <ul>
          <li>
            <p>constantly available, or available at all;</p>
          </li>
          <li>
            <p>correct, complete, true, suitable, up to date or accurate; or</p>
          </li>
          <li>
            <p>free of defects or errors.</p>
          </li>
        </ul>
        <p>
          This Website may contain Content uploaded or otherwise provided by or
          related to third parties, including the relevant public or private
          sector entity that is the owner or sponsor of a Program.{' '}
          <strong>
            Such Content is not in any way endorsed by us and does not
            constitute the opinion or advice or services of us.
          </strong>
        </p>
        <p>
          <strong>Limitation of Liability and Indemnification</strong>
        </p>
        <p>
          The use of this Website and reliance on the Content is entirely at
          your own risk and in no event shall we nor any of our affiliates be
          liable (whether under the law, breach of statutory duty or otherwise)
          for any direct, indirect, incidental, consequential, special,
          exemplary, punitive or any other monetary or other damages, fees,
          fines, penalties or liabilities, nor for any loss or disclosure of
          data or loss or damage to reputation or goodwill (collectively “
          <strong>Damage”</strong>) whatsoever (even if such Damage is
          foreseeable) arising out of or relating to the use or inability to use
          this Website or for any reliance on this Website or the Content. Your
          sole and exclusive remedy for dissatisfaction with this Website and/or
          the Content is to stop using this Website and/or Content.
        </p>
        <p>
          We will not be liable for any loss or damage caused by any virus,
          distributed denial-of-service attack or other technologically harmful
          material that may affect or infect your computer, equipment, computer
          programs, data, tablet or mobile devices or other proprietary material
          due to your use of this Website or to your downloading of any Content
          on this Website or the use or downloading of any information or
          materials of any other website linked to this Website or by reason of
          any disclosure of such material or data by any third party.
        </p>
        <p>
          <strong>
            You agree to defend, indemnify and hold us, and our affiliates,
            officers, directors, employees, representatives, successors, assigns
            and agents (and their licensors, advertisers, suppliers, and
            operational service providers) harmless from and against any and all
            claims, expenses, costs, actions, demands, liabilities, judgments
            and settlements (including reasonable legal fees) resulting from or
            alleged to result from your use of this Website, the Content and/or
            any violation of the terms of this Agreement.
          </strong>
        </p>
        <p>
          We reserve the right to assume the exclusive defence and control of
          any demand, claim or action arising hereunder or in connection with
          this Website and all negotiations for settlement or compromise.  You
          agree to fully cooperate with us in the defence of any such demand,
          claim, action, settlement or compromise negotiations, as requested by
          us.
        </p>
        <p>
          Neither we, nor any of our affiliates, assumes liability or
          responsibility for any content or services supplied by you or any
          third parties nor for the content of any other websites linked or
          carrying a link to this Website.
        </p>
        <p>
          This Website is provided on an “as is” and “as available” basis. We do
          not make any warranty, and disclaim all responsibility and liability
          for the availability, timeliness, security, reliability, quality of
          this Website, any related software, or other products, services,
          information or Content obtained through this Website. We have no
          responsibility or liability for the deletion of, or the failure to
          store or to transmit, any Content and other information maintained or
          transmitted by this Website. We are not responsible for the accuracy
          or reliability of any information or Content transmitted through this
          Website. We may, at any time, limit your use or discontinue this
          Website (or any aspect thereof) entirely at our sole discretion with
          no liability to you.
        </p>
        <p>
          <strong>Intellectual Property Rights</strong>
        </p>
        <p>
          We own or are the licensee of all Intellectual Property Rights in this
          Website and the Content which are protected by the United Arab
          Emirates and international copyright, trademark and/or patent laws and
          treaties. All such rights are reserved. All trademarks, service marks
          and trade names are owned, registered and/or licensed by us. You are
          not permitted to make any use of any of our trademarks, services marks
          or trade names.
        </p>
        <p>
          No part of the protected materials available on or in this Website
          (including any Content) may be copied, reproduced, translated or
          reduced to any electronic medium without permission<strong>.</strong>
        </p>
        <p>
          <strong>
            Enforcement and protection of Intellectual Property Rights
          </strong>
        </p>
        <p>
          If we discover that you have used any materials on this Website
          protected by Intellectual Property Rights belonging to us or others in
          contravention of the terms of the license below or otherwise in breach
          of our rights or this Agreement, we and any of our affiliates may
          bring legal proceedings against you, seeking monetary damages and an
          injunction, or any other equitable remedies, against you. You could
          also be ordered to pay legal fees and costs. If you become aware of
          any use of materials in which we own or are the licensee of
          Intellectual Property Rights and that contravenes or may contravene
          the terms of this Agreement, you agree to immediately report this by
          email to{' '}
          <a href='mailto:info@dubaifuture.gov.ae'>info@dubaifuture.gov.ae</a>.
        </p>
        <p>
          <strong>Limited License</strong>
        </p>
        <p>
          In consideration of you agreeing to abide by the terms of this
          Agreement, we grant to you a non-transferable, non-exclusive,
          royalty-free and revocable license to: (i) use this Website as herein
          set forth; (ii) copy and store this Website in your web browser cache
          memory; and (iii) print pages from this Website for your own personal
          and non-commercial use. You must not modify the paper or digital
          copies of any materials you have printed off or downloaded in any way,
          and you must not use any illustrations, photographs, video or audio
          sequences or any graphics separately from any accompanying text.
        </p>
        <p>
          We do not grant to you any other rights whatsoever in relation to this
          Website. All other rights are expressly reserved by us.
        </p>
        <p>
          You acknowledge and agree that the rights to access and use this
          Website are licensed (not sold) to you, and that you have no rights
          in, or to, this Website other than the right to use it in accordance
          with the terms of this Agreement. You acknowledge that you have no
          right to have access to this Website in source-code form. You agree
          that we cannot licence you to make use of Content generated by third
          parties and to which we do not have any rights of ownership or
          sub-licensing rights.
        </p>
        <p>
          You undertake not to gain or attempt to gain any access to any aspect
          of this Website or the Content which you are not authorised to so
          access under the terms and conditions of this Agreement.
        </p>
        <p>
          <strong>Licence Restrictions</strong>
        </p>
        <p>
          You may use this Website and the Content subject always to terms and
          conditions of this Agreement. You agree in relation to this Website
          and the Content (and for each of them):
        </p>
        <ul>
          <li>
            <p>
              not to copy them, except where such copying is incidental to
              normal use, or where it is necessary for the purpose of back-up or
              operational security;
            </p>
          </li>
          <li>
            <p>
              not to rent, lease, sub-license, loan, translate, merge, adapt,
              vary or modify them;
            </p>
          </li>
          <li>
            <p>
              not to make alterations to, or modifications of, the whole or any
              part of them, or permit them or any part of them to be combined
              with, or become incorporated in, any other programs;
            </p>
          </li>
          <li>
            <p>
              not to alter, destroy, obscure, or otherwise remove any copyright
              or proprietary notices or labels contained within them;
            </p>
          </li>
          <li>
            <p>
              not to disassemble, decompile, reverse-engineer, or otherwise
              attempt to gain access to the source code of them or create
              derivative works based on the whole or any part of them or attempt
              to do any such thing;
            </p>
          </li>
          <li>
            <p>
              not to provide or otherwise make them available in whole or in
              part (including object and source code), in any form to any person
              without prior written consent from us;
            </p>
          </li>
          <li>
            <p>
              not to use them in any unlawful manner, for any unlawful purpose,
              or in any manner inconsistent with this Agreement, or act
              fraudulently or maliciously, for example, by hacking into or
              inserting malicious code, including viruses, or harmful data, into
              any of them or any operating system;
            </p>
          </li>
          <li>
            <p>
              not to infringe our Intellectual Property Rights or those of any{' '}
              <strong>third party</strong> in relation to your use of them (to
              the extent that such use is not licensed by this Agreement) or in
              relation to any content or information that you may provide
              through this Website;
            </p>
          </li>
          <li>
            <p>not provide or publish any content or information that:</p>
            <ul>
              <li>
                <p>
                  infringes the Intellectual Property Rights of DFF or any third
                  party;
                </p>
              </li>
              <li>
                <p>
                  defames others, is obscene, abusive, discriminatory,
                  offensive, hateful or inflammatory; and/or
                </p>
              </li>
              <li>
                <p>promotes or incites violence or illegal activities;</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              not to collect or harvest any information or data from them or our
              systems or attempt to decipher any transmissions to or from the
              servers running them; and
            </p>
          </li>
          <li>
            <p>
              not to use any automated data collection methods, data mining,
              robots, or scraping or any data gathering methods of any kind in
              relation to, or in connection with, them.
            </p>
          </li>
        </ul>
        <p>
          <strong>No Unlawful or Prohibited Use</strong>
        </p>
        <p>
          <strong>
            You agree not to in any way make use of this Website and/or any of
            the Content in any unauthorised manner or in violation of any local,
            federal or international laws or regulations.
          </strong>
        </p>
        <p>
          <strong>
            We reserve the right at all times to revoke or restrict your access
            to this Website in our sole discretion for any other reason
            whatsoever.
          </strong>
        </p>
        <p>
          <strong>
            You may link to our home page, provided you do so in a way that is
            fair, lawful, and does not damage or take advantage of our
            reputation. We reserve the right to withdraw this permission at any
            time. You must not establish a link to our Website or Content in
            such a way to suggest any form of association, approval or
            endorsement on our part.
          </strong>
        </p>
        <p>
          <strong>Monitoring</strong>
        </p>
        <p>
          We reserve the right to collect and use anonymous non-personal
          information pertaining to your use of this Website, including to
          anonymously track and report to our third party statistical service
          providers your activity involving this Website to monitor the
          performance of this Website. By using this Website, you consent to us
          collecting and using your technical information, including the
          software, hardware and peripherals, to improve this Website and to
          provide any services to you.
        </p>
        <p>
          <strong>Termination of Use</strong>
        </p>
        <p>
          <strong>
            We may, in our sole discretion, terminate or suspend your access to
            and use of this Website without notice, and for any reason,
            including violation of this Agreement or other conduct which we, in
            our sole discretion, believe is unlawful or harmful to us or others.
            In the event of termination, you will no longer be authorised to
            access or use this Website and we will use any means available or
            necessary to enforce this termination.
          </strong>
        </p>
        <p>
          <strong>Access and Passwords</strong>
        </p>
        <p>
          If you choose, or you are provided with, a user identification code,
          password or any other piece of information as part of our security
          procedures, you must treat such information as confidential. You must
          not disclose it to any third party and you are fully responsible for
          all activities that occur on this Website under your user account.
        </p>
        <p>
          We have the right to disable any user identification code or password,
          whether chosen by you or allocated by us, at any time, if in our
          reasonable opinion you have failed to comply with any of the
          provisions of this Agreement.
        </p>
        <p>
          If you know or suspect that anyone other than you knows your user
          identification code or password, you must promptly notify us at{' '}
          <a href='mailto:info@dubaifuture.gov.ae'>info@dubaifuture.gov.ae</a>.
        </p>
        <p>
          <strong>Changes to this Agreement</strong>
        </p>
        <p>
          We reserve the right, without any liability to you, to modify this
          Agreement without notice and at any time. Updated versions of this
          Agreement will appear on this Website and are effective immediately.
          You are responsible for regularly reviewing this Agreement and your
          continued use of this Website following any modifications to this
          Agreement will be deemed as acceptance of any such modifications to
          this Agreement. If you do not accept and abide by the terms of this
          Agreement or any modification to it, you may not use this Website or
          download or use any of the Content. Your use of this Website is at
          your sole risk and liability.
        </p>
        <p>
          <strong>Changes to this Website and Content</strong>
        </p>
        <p>
          <strong>
            We may update or alter this Website from time to time (including all
            Content) at any time. The Content on this Website may be out of date
            at any given time, and we are under no obligation to update it (or
            any part thereof). We do not in any way guarantee that our Website
            or any Content will be up to date, free from any errors,
            inaccuracies or omissions or be fit for any purpose.
          </strong>
        </p>
        <p>
          <strong>Applicable Law</strong>
        </p>
        <p>
          This Agreement and your use of this Website is governed by and shall
          be construed in accordance with the laws of the Emirate of Dubai and,
          where applicable, the Federal Laws of the United Arab Emirates. Such
          laws will govern these disclaimers, terms and conditions and this
          Agreement, without giving effect to any principles of conflicts of
          laws. We reserve the right to make changes to this Agreement, and our
          Website at any time. You hereby irrevocably submit to the jurisdiction
          of the Courts of the Emirate of Dubai (
          <strong>
            as established by Law No. 3 of 1992 and Law No. 13 of 2016)
          </strong>{' '}
          in relation to all disputes relating to this Website and this
          Agreement.
        </p>
        <p>
          If any provision of this Agreement is, or is found to be,
          unenforceable under any applicable law, that will not affect the
          enforceability of the other provisions of this Agreement.
        </p>
        <p>
          <strong>Notification of Objectionable Content and Take - Down</strong>
        </p>
        <p>
          If you wish to object to any Content on this Website, please do so
          immediately upon becoming aware of objectionable Contents and in
          writing to{' '}
          <a href='mailto:info@dubaifuture.gov.ae'>info@dubaifuture.gov.ae</a>.
          Please ensure you clearly identify the Content objected to and
          detailing the basis for your objection and the action(s) you request
          we take in relation to such Content and provide your full contact
          details to enable us to discuss with you and act appropriately upon
          any such objection. We will in all reasonable cases attempt to
          investigate objections and, if appropriate, remove the relevant
          Content. However, as set out above, we are not in any way liable for
          any third party service providers.
        </p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>
          To contact us, please send your email to{' '}
          <a href='mailto:info@dubaifuture.gov.ae'>info@dubaifuture.gov.ae</a>.
        </p>
        <h3 id='copyright'>
          <strong>Copyright</strong>
        </h3>
        <p>
          This Website and its Content are subject to copyright. All rights
          reserved. You may not, except with our express written permission,
          distribute or commercially exploit this Website or the Content.
        </p>
      </div>
    </div>
  </>
)
