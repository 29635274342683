import React from 'react'
import styles from './HeaderButtons.module.scss'
import useTheme from '../utils/useTheme'
import { ReactComponent as MoonIcon } from 'components/common/assets/icons/moon.svg'
import { ReactComponent as SunIcon } from 'components/common/assets/icons/sun.svg'
import { ReactComponent as ProfileIcon } from 'components/common/assets/icons/icon_profile.svg'
import { Translate } from 'react-localize-redux'
import Hamburger from './Hamburger'
import classNames from 'classnames'
import { MenuTriggerButtonProps, ProfileButtonProps } from "./types";

export const ReachUsButton = () => (
  <a className={styles.link} href={`${process.env.REACT_APP_DFF_URL}/reach-us`}>
    <Translate id='header.reachUs' />
  </a>
)

export const ProfileButton = ({ children, onClick, isForced }: ProfileButtonProps) => (
  <>
    <button className={styles.profileButton} onClick={onClick} aria-label='Dropdown Menu'>
      <ProfileIcon />
    </button>
    <ul
      className={classNames(styles.dropdownMenu, {
        [styles.forcedOpen]: isForced,
      })}
    >
      {children}
    </ul>
  </>
)

export const ThemeButton = () => {
  const { toggleTheme, theme } = useTheme()
  return (
    <button className={styles.themeButton} onClick={toggleTheme} aria-label='Toggle Theme'>
      {theme === 'dark' ? <SunIcon /> : <MoonIcon />}
    </button>
  )
}

export const MenuTriggerButton = ({
  onClick,
  isOpen,
}: MenuTriggerButtonProps) => (
  <div className={styles.menuTrigger} onClick={onClick}>
    <Hamburger isOpen={isOpen} />
  </div>
)
