import React, { useEffect } from 'react'
import classes from './TemplateList.module.scss'
import Accordion from 'components/common/Accordion/Accordion'
import Summary from './Summary'
import Button from 'components/common/shared/Button'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { getAutoReviewCriteria } from 'components/common/actions/gateSettingsActions'
import { RootState } from 'reducers'
import { TemplateListProps } from "./types";

const TemplateList = ({ onEdit, programOwnerId }: TemplateListProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAutoReviewCriteria(programOwnerId))
  }, [dispatch, programOwnerId])

  const { autoReviewCriteria } = useSelector((state: RootState) => state.gate)

  return (
    <>
      {Object.values(autoReviewCriteria).map(template => (
        <Accordion key={template.id} label={template.name}>
          <Summary template={template} />
          <Button
            type='solid'
            onClick={() => onEdit(template.id || '')}
            className={classes.editButton}
          >
            <Translate id='utility.edit' />
          </Button>
        </Accordion>
      ))}
    </>
  )
}

export default TemplateList
