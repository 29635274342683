import React, { createContext } from 'react'
import { HeaderContextProps, HeaderContextProviderProps } from "./types";

export const HeaderContext = createContext<HeaderContextProps>({
  collapsed: false,
  setCollapsed: () => {},
})

export const HeaderContextProvider = ({
  children,
}: HeaderContextProviderProps) => {
  const [collapsed, setCollapsed] = React.useState(false)

  return (
    <HeaderContext.Provider
      value={{
        collapsed,
        setCollapsed,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

