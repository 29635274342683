import React from 'react'
import { DateTime } from 'luxon'
import DatePicker, { registerLocale } from 'react-datepicker'
import WrappedInput from './WrappedInput'
import arabic from 'date-fns/locale/ar-SA'
import englishUk from 'date-fns/locale/en-GB'
import classNames from 'classnames'
import styles from './DateTimePicker.module.scss'
import inputStyles from './TextInput.module.scss'
import { withLocalize } from 'react-localize-redux'
import Dropdown from './Dropdown'
import { zones } from "./constants";
import { DateTimePickerProps } from "./types";

registerLocale('ar', arabic)
registerLocale('en', englishUk)

const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    value,
    disabled,
    onChange,
    readOnly,
    id,
    onBlur,
    withTime,
    activeLanguage,
    translate,
  } = props

  const valueAsDateTime =
    value === '' ? null : DateTime.fromISO(value as string, { setZone: true })

  const valueAsDate =
    valueAsDateTime && valueAsDateTime.isValid
      ? valueAsDateTime.setZone('local', { keepLocalTime: true }).toJSDate()
      : null

  const zone = valueAsDateTime?.zoneName
  const activeZoneIndex = zones.indexOf(zone || '')

  const handleDateChange = (date: Date | null) => {
    if (onChange) {
      if (date === null) {
        onChange('')
        if (onBlur) {
          onBlur('')
        }
      } else {
        const dateAsDateTime = DateTime.fromJSDate(date)
        const dateAsIso = dateAsDateTime.setZone(zones[activeZoneIndex], { keepLocalTime: true }).toISO()
        onChange(dateAsIso)
        if (onBlur) {
          onBlur(dateAsIso)
        }
      }
    }
  }

  const handleZoneChange = (zoneIndex: number | number[]) => {
    if (Array.isArray(zoneIndex)) {
      throw new Error('multi value found in single value context')
    }
    if (valueAsDateTime) {
      const dateAsIso = valueAsDateTime.setZone(zones[zoneIndex], { keepLocalTime: true }).toISO()
      onChange(dateAsIso)
    } else {
      const dateAsIso = DateTime.local().setZone(zones[zoneIndex]).toISO()
      onChange(dateAsIso)
    }
  }

  return (
    <div className={styles.horizontalFields}>
      <WrappedInput {...props}>
        <div className={styles.datePickerWrapper}>
          <DatePicker
            id={id}
            selected={valueAsDate}
            onChange={handleDateChange}
            className={classNames(inputStyles.textish, {
              [inputStyles.readOnly]: readOnly,
            })}
            placeholderText='DD/MM/YYYY HH:mm'
            calendarClassName={inputStyles.datePicker}
            showYearDropdown
            dropdownMode='select'
            peekNextMonth
            showMonthDropdown
            locale={activeLanguage.code}
            dateFormat={withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
            timeFormat='HH:mm'
            isClearable
            disabled={disabled || readOnly}
            readOnly={readOnly}
            showTimeSelect={withTime}
          />
        </div>
      </WrappedInput>
      <Dropdown
        id={`${id}-zone`}
        className={styles.zonePicker}
        showValidation={false}
        label={translate('dateTime.zone').toString()}
        onChange={handleZoneChange}
        options={zones}
        value={activeZoneIndex}
      />
    </div>
  )
}

export default withLocalize(DateTimePicker)
