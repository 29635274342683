import React, { useCallback, useEffect } from 'react'
import styles from './NotificationModal.module.scss'
import Button from 'components/common/shared/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Translate, } from 'react-localize-redux'
import { NotificationModalProps } from "./types";

const NotificationModal = ({
  onCancel,
  onAccept,
  header,
  children,
  acceptText,
}: NotificationModalProps) => {
  const closeOnEscape = useCallback((event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Escape') {
      onCancel()
    }
  }, [onCancel])

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('keyup', closeOnEscape)

    return () => {
      // @ts-ignore
      document.removeEventListener('keyup', closeOnEscape)
    }
  }, [closeOnEscape])

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        {/* The floating "cancel" button */}
        <div className={styles.cancelButtonContainer}>
          <div className={styles.cancelButton} onClick={onCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        <div className={styles.contentContainer}>
          <h3>{header || <Translate id='utility.areYouSure' />}</h3>
          {children}
        </div>
        <div className={styles.actionRow}>
          <Button
            onClick={onAccept}
            type='border'
            className={styles.agreeButton}
          >
            {acceptText ?? <Translate id='utility.confirm' />}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotificationModal
