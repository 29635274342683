import React, { useState, useEffect, useCallback } from 'react'
import styles from './EditFacilitator.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import Form from 'components/common/Form/Form'
import FormSection from 'components/common/Form/FormSection'
import TextInput from 'components/common/FormInput/TextInput'
import ReferenceList from 'components/common/FormInput/ReferenceList'
import WrappedInput from 'components/common/FormInput/WrappedInput'
import ImageUploadWithPreview from 'components/common/ImageUploadWithPreview/ImageUploadWithPreview'
import Button from 'components/common/shared/Button'
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs'
import { useParams } from 'react-router-dom'
import { ICommonState } from 'components/common/utils/state'
import Loader from 'components/common/Loader/Loader'
import {
  fetchFacilitator,
  setFacilitatorLogo,
  updateFacilitator,
} from '../actions/facilitatorActions'
import Tabs from 'components/common/Tabs/Tabs'
import ActionBar from 'components/common/ActionBar/ActionBar'
import { IUser } from 'models/user/user'
import { api } from 'api'
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux'
import classnames from 'classnames'
import HomeIcon from '../ProgramSettings/HomeIcon'
import FitScreen from 'components/common/Layout/FitScreen'

const EditFacilitator = ({ translate }: LocalizeContextProps) => {
  const [updatedName, setUpdatedName] = useState('')
  const [updatedWebsite, setUpdatedWebsite] = useState('')
  const [updatedAdmins, setUpdatedAdmins] = useState<IUser[]>([])
  const [allAdmins, setAllAdmins] = useState<IUser[]>([])

  const [hasChangesMade, setHasChangesMade] = useState(false)

  const [logo, setLogo] = useState<any>([])
  const [logoFile, setLogoFile] = useState<File | undefined>()

  const { id } = useParams()

  const dispatch = useDispatch()
  const { facilitators, updatingFacilitators } = useSelector(
    (state: ICommonState) => state.facilitators,
  )
  const selectedFacilitator = facilitators[id]
  const isUpdating = updatingFacilitators.includes(id)

  const availableAdmins = allAdmins.filter(
    (a) => !updatedAdmins.map((o) => o.id).includes(a.id),
  )

  const onSave = () => {
    if (updatedName) {
      const payload = {
        ...selectedFacilitator,
        owners: updatedAdmins.map((owner) => owner.id),
        name: updatedName,
        website: updatedWebsite,
      }
      dispatch(updateFacilitator(payload))
    }
    if (logoFile) {
      dispatch(setFacilitatorLogo({
        id,
        onUploadProgress: () => {},
        image: logoFile,
      }))
    }
  }

  const withDetectChange = (changeFunc: Function) => (value: any) => {
    setHasChangesMade(true)
    changeFunc(value)
  }

  const logoChangeWithDetection = useCallback((value: any) => {
    setHasChangesMade(true)
    setLogo(value)
  }, [])

  useEffect(() => {
    dispatch(fetchFacilitator(id))
  }, [id, dispatch])

  useEffect(() => {
    if (selectedFacilitator) {
      // disable the save button since the selected facilitator has been updated
      setHasChangesMade(false)
      setUpdatedName(selectedFacilitator.name)
      setUpdatedAdmins(selectedFacilitator.owners)
      setUpdatedWebsite(selectedFacilitator.website)
      // since it has just finished fetching, no changes are made (supposedly)
    }
  }, [selectedFacilitator])

  useEffect(() => {
    const getAdmins = async () => {
      const { data } = await api.get('/users/programOwners')
      setAllAdmins(data)
    }

    getAdmins()
  }, [])

  const handleAddAdmin = (index: number | number[]) => {
    if (Array.isArray(index)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    setUpdatedAdmins((oldList) => {
      const newAdmin = availableAdmins[index]
      if (newAdmin) {
        return [...oldList, newAdmin]
      }
      throw new Error('Could not find admin')
    })
    setHasChangesMade(true)
  }

  const handleRemoveAdmin = (id: string) => {
    setUpdatedAdmins((oldList) => oldList.filter((a) => a.id !== id))
    setHasChangesMade(true)
  }

  if (selectedFacilitator && availableAdmins) {
    return (
      <FitScreen>
        <div className={styles.container}>
          <div className={styles.breadcrumbsOuterContainer}>
            <div className={styles.breadcrumbsContainer}>
              <Breadcrumbs
                crumbs={[
                  {
                    label: <HomeIcon />,
                    link: '/',
                  },
                  {
                    link: '/facilitators',
                    label: translate('header.programOwners'),
                  },
                  { label: selectedFacilitator.name, bold: true },
                ]}
              />
            </div>
          </div>
          <div className={styles.tabContainer}>
            <Tabs
              tabs={[
                {
                  heading: <Translate id='facilitator.details' />,
                  content: (
                    <Form borderless className={styles.form}>
                      <FormSection className={classnames(styles.section)}>
                        <TextInput
                          id='facilitator-name'
                          type='text'
                          className={styles.nameInput}
                          label={<Translate id='facilitator.name' />}
                          value={updatedName}
                          onChange={withDetectChange(setUpdatedName)}
                          showValidation={false}
                          required
                        />
                        <TextInput
                          id='facilitator-website'
                          type='text'
                          className={styles.nameInput}
                          label={<Translate id='facilitator.website' />}
                          value={updatedWebsite}
                          onChange={withDetectChange(setUpdatedWebsite)}
                          showValidation={false}
                        />
                        <WrappedInput
                          id='facilitator-logo'
                          label={<Translate id='facilitator.logo' />}
                        >
                          <ImageUploadWithPreview
                            id='facilitator-logo'
                            className={styles.imageUploader}
                            images={logo}
                            setImages={logoChangeWithDetection}
                            links={selectedFacilitator.logo}
                            onLoad={(file: File) => {
                              setLogoFile(file)
                            }}
                          />
                        </WrappedInput>
                      </FormSection>
                      <FormSection className={styles.section}>
                        <ReferenceList
                          id='admin-list'
                          selected={updatedAdmins.map((o) => ({
                            id: o.id,
                            label:
                              o.displayName ||
                              o.email ||
                              (translate('faciltator.unknown') as string),
                          }))}
                          options={availableAdmins.map((a) => ({
                            id: a.id,
                            label:
                              a.displayName ||
                              a.email ||
                              (translate('facilitator.unknown') as string),
                          }))}
                          onAdd={handleAddAdmin}
                          onDelete={handleRemoveAdmin}
                          label={
                            <Translate
                              id='facilitator.manageAdmins'
                              data={{ count: updatedAdmins.length }}
                            />
                          }
                          newInputLabel={
                            <Translate id='facilitator.newAdmin' />
                          }
                          className={styles.adminList}
                        />
                      </FormSection>
                    </Form>
                  ),
                },
              ]}
              initialTab={0}
            />
          </div>
        </div>
        <ActionBar>
          <div />
          <Button
            type='solid'
            onClick={onSave}
            className={styles.saveButton}
            disabled={isUpdating || !hasChangesMade}
          >
            <Translate id='utility.save' />
          </Button>
        </ActionBar>
      </FitScreen>
    )
  }
  return <Loader />
}

export default withLocalize(EditFacilitator)
