import React from 'react'
import Form from '../../Form/Form'
import FormSection from '../../Form/FormSection'
import TextInput from '../../FormInput/TextInput'
import Dropdown from '../../FormInput/Dropdown'
import { withLocalize } from 'react-localize-redux'
import gateStyles from '../GateSettings.module.scss'
import Checkbox from '../../FormInput/Checkbox'
import { useSelector } from 'react-redux'
import { ICommonState } from '../../utils/state'
import { EditStageScoringProps } from "./types";
import { calculationMethods, calculationMethodsDisplayArray, scoringMethods } from "./constants";

const EditStageScoring = ({
  stages,
  translate,
  onChangeFastTrack,
  onChange,
  onGeneralFieldChange,
}: EditStageScoringProps) => {
  const { changedStages, stageIndex, unchangedStages } = useSelector(
    (state: ICommonState) => state.gate,
  )
  const currentStage = changedStages[stageIndex]
  const { fastTrack, scoringTool, optional, optionalStageLabel } = currentStage
  const blockUnsafe = !!unchangedStages[stageIndex].numberOfApplications

  const showOptional = stageIndex > 0 && stageIndex + 1 < unchangedStages.length

  const handleScoringToolChange = (newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    onChange(scoringMethods[newValue], 'name')
  }

  const handleCalculationMethodChange = (newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      throw new Error('Multi valued selection when expecting single value')
    }

    onChange(
      calculationMethods[calculationMethodsDisplayArray[newValue]],
      'method',
    )
  }

  const handleFastTrackChange = (index: number | number[]) => {
    if (Array.isArray(index)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    index === -1 ? onChangeFastTrack('') : onChangeFastTrack(stages[index].id)
  }

  const stageNames = stages.map((stage) => stage.name)

  return (
    <Form>
      <FormSection>
        <div className={gateStyles.field}>
          <Dropdown
            id='scoring-method'
            showValidation={false}
            label={translate('gate.scoringMethod') as string}
            value={scoringMethods.indexOf(scoringTool.name)}
            onChange={handleScoringToolChange}
            options={[
              translate('gate.yesNo') as string,
              translate('gate.rating') as string,
            ]}
            readOnly={blockUnsafe}
          />
        </div>
        {scoringTool.name === 'Rating' && (
          <>
            <div className={gateStyles.field}>
              <Dropdown
                id='calculation'
                showValidation={false}
                label={translate('gate.calculation') as string}
                value={calculationMethodsDisplayArray.findIndex(
                  (key) => calculationMethods[key] === scoringTool.method,
                )}
                onChange={handleCalculationMethodChange}
                options={[
                  translate('gate.sum') as string,
                  translate('gate.average') as string,
                ]}
                readOnly={blockUnsafe}
              />
            </div>
            <div className={gateStyles.field}>
              <TextInput
                id='threshold'
                showValidation={false}
                label={translate('gate.threshold') as string}
                value={scoringTool.threshold}
                min={0}
                onChange={(value) => onChange(parseInt(value, 10), 'threshold')}
                type='number'
                disabled={blockUnsafe}
                keepDisplaying
              />
            </div>
            <div className={gateStyles.field}>
              <TextInput
                id='minimum'
                showValidation={false}
                label={translate('gate.minimum') as string}
                min={0}
                value={scoringTool.min}
                onChange={(value) => onChange(parseInt(value, 10), 'min')}
                type='number'
                disabled={blockUnsafe}
                keepDisplaying
              />
            </div>
            <div className={gateStyles.field}>
              <TextInput
                id='maximum'
                showValidation={false}
                label={translate('gate.maximum') as string}
                min={0}
                value={scoringTool.max}
                onChange={(value) => onChange(parseInt(value, 10), 'max')}
                type='number'
                disabled={blockUnsafe}
                keepDisplaying
              />
            </div>
          </>
        )}
        <div className={gateStyles.field}>
          <TextInput
            id='description'
            showValidation={false}
            label={translate('gate.description') as string}
            value={scoringTool.description || ''}
            onChange={(value) => onChange(value, 'description')}
            type='text-area'
          />
        </div>
        <div className={gateStyles.field}>
          <Dropdown
            id='fast-track'
            isClearable
            showValidation={false}
            label={translate('gate.fastTrack') as string}
            value={stages.findIndex((stage) => stage.id === fastTrack)}
            onChange={handleFastTrackChange}
            options={stageNames}
          />
        </div>
        {showOptional && (
          <div className={gateStyles.field}>
            <Checkbox
              label={translate('gate.optional') as string}
              id='optional'
              value={optional}
              onChange={(value) =>
                onGeneralFieldChange({
                  newValue: value,
                  field: 'optional',
                })
              }
            />
            <TextInput
              id='optional-stage-target'
              showValidation={false}
              placeholder={translate('gate.optionalStageLabel') as string}
              value={optionalStageLabel}
              onChange={(value) =>
                onGeneralFieldChange({
                  newValue: value,
                  field: 'optionalStageLabel',
                })
              }
              type='text'
              disabled={!optional}
            />
          </div>
        )}
      </FormSection>
    </Form>
  )
}

export default withLocalize(EditStageScoring)
