import React, { forwardRef, Ref } from 'react'
import styles from './HeaderContainer.module.scss'
import classNames from 'classnames'
import { HeaderContainerProps } from "./types";

const HeaderContainer = (
  { children, isNavbarScrolledDown }: HeaderContainerProps,
  ref: Ref<any>,
) => {
  return (
    <header
      ref={ref}
      className={classNames(styles.container, {
        [styles.scrolledDown]: isNavbarScrolledDown,
      })}
    >
      <div
        className={classNames(styles.innerContainer, {
          [styles.scrolledDown]: isNavbarScrolledDown,
        })}
      >
        {children}
      </div>
    </header>
  )
}

export default forwardRef(HeaderContainer)
