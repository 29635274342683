import React from 'react'
import styles from './TextInput.module.scss'
import WrappedInput from './WrappedInput'
import { Translate, withLocalize, } from 'react-localize-redux'
import { registerLocale } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import arabic from 'date-fns/locale/ar-SA'
import englishUk from 'date-fns/locale/en-GB'
import TextInputField from './TextInputField'
import useTranslate from 'hooks/useTranslate'
import { htmlEmpty } from 'components/common/utils/htmlUtils'
import { TextInputProps } from "./types";

registerLocale('ar', arabic)
registerLocale('en', englishUk)

const TextInput = (props: TextInputProps) => {
  const { value, maxLength, rtl } = props
  const translate = useTranslate()

  // @ts-ignore
  const trimmedValue = (value && value.trim) ? value.trim() : value
  const isEmpty = (props.type === 'rich-text-area') ? htmlEmpty(trimmedValue as string) : !trimmedValue

  const isMissingRequiredValue = props.required && isEmpty

  const maxLengthExceeded =
    typeof value === 'string' && maxLength && value.length > maxLength

  const errorMessage = isMissingRequiredValue
    ? translate('register.form.error.reqField')
    : maxLengthExceeded
    ? translate('register.form.error.exceedLength')
    : props.invalidMessage

  const isValid = !errorMessage

  return (
    <WrappedInput rtl={rtl} isValid={isValid} {...props}>
      <TextInputField {...props} isValid={isValid} />
      {maxLength ? (
        <div className={styles.lengthNote}>
          <Translate id='input.maxLength' data={{ maxLength }} />
        </div>
      ) : null}
      {!props.readOnly && errorMessage ? (
        <div className={styles.errorMessage}>{errorMessage}</div>
      ) : <div className={styles.errorPlaceholder} />}
    </WrappedInput>
  )
}

export default withLocalize(TextInput)
