import React from 'react'
import styles from './ReviewHistory.module.scss'
import { getApplicationHistory } from './utils/api'
import { History } from './utils/model'
import FilterSearch from '../TableFilter/FilterSearch'
import useTranslate from 'hooks/useTranslate'
import moment from 'moment'
import { useLocation } from 'react-router'
import { HistoryProps, ReviewHistoryProps } from "./types";

const SubmissionHistoryRecord = ({ history }: HistoryProps) => {
  const translate = useTranslate()

  return (
    <div className={styles.historyContainer}>
      <p>
        {translate('review.submissionHistoryTitle', {
          type: <b>{history.type.toUpperCase()}</b>,
          forProgramName: history.programName
            ? `for ${history.programName}`
            : '',
        })}
      </p>
      <p>
        {
          // show this line only when one of them exists
          (history.triggeredBy?.name || history.date) &&
            translate('review.submissionDate', {
              byTriggerer: history.triggeredBy?.name || '',
              date: history.date
                ? translate('review.onDate', {
                    date: moment(history.date).format('DD/MM/YYYY'),
                  })
                : '',
            })
        }
      </p>
      <br />
      {history.submissionDetails?.goldenTicket && (
        <p>
          {translate('review.submissionGoldenTicket', {
            goldenTicket: history.submissionDetails?.goldenTicket,
          })}
        </p>
      )}
      <p>
        {history.submissionDetails?.currentGate &&
          translate('review.submissionStatus', {
            status: <b>{history.submissionDetails?.status}</b>,
            currentGate:
              history.submissionDetails?.currentGate ||
              translate('review.unknownGate'),
          })}
      </p>
    </div>
  )
}

const ReviewHistoryRecord = ({ history }: HistoryProps) => {
  const translate = useTranslate()
  // flag indicating whether this review is done by super admin on behalf of a reviewer
  const isOnBehalfOf =
    history.triggeredBy?.id !== history.reviewDetails?.reviewer?.id
  const reviewerName = !isOnBehalfOf
    ? history.triggeredBy?.name
    : `${
        // if its on behalf of a reviewer, show the display name if he has one
        history.reviewDetails?.reviewer?.displayName ||
        // or just show the firstname - lastname combo
        `${history.reviewDetails?.reviewer?.firstName || ''} ${
          history.reviewDetails?.reviewer?.lastName || ''
        }`.trim()
      }`
  const isYesNo = history.gate?.scoringTool.name === 'YesNo'
  /* 
      The rule for showing this line: "By <triggerer> (on behalf of <reviewer>) on <date>"
      1. if triggerer != reviewer, show "(on behalf of...)"
      2. if date is not given, hide "on <date>"
      3. if both triggerer and reviewer is not defined but the date is defined, show "on date"
      4. if trigger is defined but not reviewer, show "By <triggerer> on date"
      5. if trigger is not defined but reviewer is deinfed, show "By admin (on behalf of <reviewer>) on <date>"
      6. if everything is undefined, show an empty string
  */
  const getReviewerAndDateString = () => {
    // solely depends on the date, if it is defined then show "on <date>", otherwise ""
    const dateString = history.date
      ? translate('review.onDate', {
          date: moment(history.date).format('DD/MM/YYYY'),
        })
      : ''
    // figure out the triggerer and the reviewer being represented
    const triggerer = history.triggeredBy?.name
    // if the reviewer is not the same as triggerer and that reviewerName is not empty, show the bracket string
    const onBehalfOfString =
      isOnBehalfOf && !!reviewerName ? `(on behalf of ${reviewerName})` : ''
    // triggerer string is tricky: if there is a triggerer, show "By <triggerer>",
    // if reviewer string is not empty(there is reviewer), show "by Admin"
    // if both triggerer and reviewer is empty, shows nothing
    const triggererString = triggerer
      ? translate('review.byTriggerer', { triggerer })
      : reviewerName
      ? translate('review.byTriggerer', {
          triggerer: translate('review.admin'),
        })
      : ''

    return `${triggererString} ${onBehalfOfString} ${dateString}`.trim()
  }
  return (
    <div className={styles.historyContainer}>
      <p>
        {translate('review.reviewHistoryTitle', {
          type: <b>{history.type.toUpperCase()}</b>,
          gateName: history.gate?.name || translate('review.unknownGate'),
          programName: history.programName,
        })}
      </p>
      <p>{getReviewerAndDateString()}</p>
      <br />
      <p>{history.reviewDetails?.comment}</p>
      <p>----</p>
      <p>
        {isYesNo
          ? // yes/no scoring: show "Approved" / Rejected
            history.reviewDetails?.scoring
            ? translate('review.approved')
            : translate('review.rejected')
          : // rating score: show score / max
            `${history.reviewDetails?.scoring} / ${history.gate?.scoringTool.max}`}
      </p>
    </div>
  )
}

const ReviewHistory = ({ application }: ReviewHistoryProps) => {
  const [applicationHistory, setApplicationHistory] = React.useState<History[]>(
    [],
  )

  const translate = useTranslate()
  // retrieve search filter
  const { search } = useLocation()
  const filters = new URLSearchParams(search)

  const displayingHistoryRecords = applicationHistory.filter((history) =>
    [...filters.entries()].every(([filtKey, filtvalue]) => {
      // define how a filter is hit from the value of a particular field
      // the second compare string is filtvalue.toLowerCase(), therefore only 1 param is taken
      const compare = (filt: string | undefined) =>
        filt?.toLowerCase().includes(filtvalue.toLowerCase())
      switch (filtKey) {
        case 'type':
          return compare(history.type)
        case 'programName':
          return compare(history.programName)
        case 'triggerer':
          return compare(history.triggeredBy?.name)
      }
      // unrecognised key, let it pass
      return true
    }),
  )
  const fetchHistory = React.useCallback(async () => {
    const history = await getApplicationHistory(application.id)
    setApplicationHistory(
      history.sort((a, b) => moment(b.date || 0).diff(a.date || 0, 'days')),
    )
  }, [application])

  React.useEffect(() => {
    fetchHistory()
  }, [fetchHistory])

  return (
    <div className={styles.container}>
      <div className={styles.filterContainer}>
        <FilterSearch
          dark
          defaultFilter='programName'
          supportedFilters={{
            type: 'Type',
            programName: 'Program Name',
            triggerer: 'Triggered by',
          }}
        />
      </div>
      <div className={styles.contentContainer}>
        {!displayingHistoryRecords.length && (
          <div className={styles.emptyNotice}>
            {translate('utility.tableEmpty')}
          </div>
        )}
        {displayingHistoryRecords.map((history, i) =>
          history.type === 'submission' ? (
            <SubmissionHistoryRecord history={history} key={i} />
          ) : (
            <ReviewHistoryRecord history={history} key={i} />
          ),
        )}
      </div>
    </div>
  )
}

export default ReviewHistory
