import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { api } from '../../../../../api'
import QuestionBox from './QuestionBox'
import { IQuestion } from '../../../models/application/IQuestion'
import { IAnswer } from '../../../models/application/IAnswer'
import styles from './FormPanel.module.scss'
import FormSection from '../../../Form/FormSection'
import { useTransition, animated } from 'react-spring'
import { RootState } from '../../../../../reducers'
import { setFileLimits } from '../../../actions/applicationActions'
import ReadOnlyQuestion from '../../../ReadOnlyQuestion/ReadOnlyQuestion'
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import LogoTick from '../../../Icons/RoundCheck'
import LogoReset from '../../../Icons/RoundBlueReset'

/** This form panel will contain a list of QuestionBoxes  **/
export interface FormPanelProps extends LocalizeContextProps {
  questions: IQuestion[]
  answers: IAnswer[]
  updateAnswerMultiple: (answerIndex: number, value: number[]) => void
  updateAnswerSingle: (answerIndex: number, value: string) => void
  sectionCompleteness: boolean
  listOfJumpToQuestionsDisabled: number[]
  sectionNumber: number
  onFileChange?: (answerIndex: number, file?: File) => void
  downloadFile?: (answerIndex: number, onFinish: () => void) => void
  handleCompleteClick?: () => void
  isAltLang?: boolean
  readOnly?: boolean
}

export interface FormPanelState {
  completedQuestions: boolean[]
}

const FormPanel = ({
  listOfJumpToQuestionsDisabled,
  handleCompleteClick,
  sectionNumber,
  updateAnswerSingle,
  updateAnswerMultiple,
  questions,
  answers,
  onFileChange,
  sectionCompleteness,
  downloadFile,
  isAltLang,
  readOnly,
  translate,
}: FormPanelProps) => {
  const token = useSelector((state: RootState) => state.auth.token)
  const dispatch = useDispatch()

  useEffect(() => {
    const getAllowedFileTypes = async () => {
      try {
        const { data } = await api.get('/applicationFileTypes', {
          headers: {
            Authorization: token,
          },
        })
        dispatch(setFileLimits(data))
      } catch (error) {
        console.error(error)
      }
    }

    getAllowedFileTypes()
  }, [token, dispatch])

  const isDisabled = (questionIndex: number): boolean => {
    return listOfJumpToQuestionsDisabled.includes(questionIndex)
  }

  const questionsToRender = questions.filter(
    (question, questionIndex) =>
      question.section === sectionNumber && !isDisabled(questionIndex),
  )
  const transitions = useTransition(
    questionsToRender,
    (question) => question.id,
    {
      from: {
        opacity: 0,
        marginTop: -40,
      },
      enter: {
        opacity: 1,
        marginTop: 0,
      },
      leave: {
        opacity: 0,
        marginTop: 0,
        height: 0,
      },
      config: { mass: 1, tension: 2000, friction: 200 },
    },
  )

  /**
   * When clicking on the complete tick button,
   * scroll up and run the callback which will eventually
   * jump to the next section in the form.
   */
  const scrollAndJumpToNextSection = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    window.scrollTo(0, 0)
    handleCompleteClick && handleCompleteClick()
  }

  const handleReadOnlyDownload = downloadFile
    ? (index: number) => () => downloadFile(index, () => {})
    : () => () => undefined

  return (
    <FormSection>
      {transitions.map(({ item, props, key }) => {
        const question = item
        const questionIndex = questions.findIndex((q) => q.id === question.id)
        if (question.section === sectionNumber) {
          return readOnly ? (
            <animated.div
              style={props}
              key={key}
              className={styles.questionLine}
            >
              <ReadOnlyQuestion
                key={question.id}
                question={question}
                answer={answers[questionIndex]}
                isAltLang={isAltLang}
                isRTL={isAltLang}
                downloadFile={handleReadOnlyDownload(questionIndex)}
                downloading={false}
              />
            </animated.div>
          ) : (
            <animated.div
              style={props}
              key={key}
              className={styles.questionLine}
            >
              <QuestionBox
                index={questionIndex}
                key={question.id}
                question={question}
                updateAnswerSingle={updateAnswerSingle}
                updateAnswerMultiple={updateAnswerMultiple}
                questionAnswer={answers[questionIndex]}
                isDisabled={isDisabled(questionIndex)}
                onFileChange={onFileChange}
                downloadFile={downloadFile}
                isAltLang={isAltLang}
              />
            </animated.div>
          )
        }
        return <React.Fragment key={key} />
      })}
      {sectionCompleteness ? (
        <button
          className={styles.sectionLogoComplete}
          onClick={scrollAndJumpToNextSection}
          aria-label={translate('visaApplication.nextSectionButton') as string}
        >
          <LogoTick
            className={styles.sectionIconInner}
            color='var(--background)'
            backgroundColor='var(--primary)'
          />
        </button>
      ) : (
        <LogoReset className={styles.sectionLogoPending} />
      )}
    </FormSection>
  )
}

export default withLocalize(FormPanel)
