import React, { useEffect, useRef, useState } from 'react'
import styles from './FilterSearch.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { invert } from 'lodash'
import { withLocalize } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import classNames from 'classnames'
import { FilterSearchProps, HelpBoxConfig, IExistingFilter } from "./types";

const FilterSearch = ({
  supportedFilters,
  defaultFilter,
  translate,
  dark = false,
}: FilterSearchProps) => {
  const location = useLocation()
  const history = useHistory()
  const [
    helpBoxPositionConfig,
    setHelpBoxPositionConfig,
  ] = useState<HelpBoxConfig | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [existingFilters, setExistingFilters] = useState<IExistingFilter[]>([])
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [highlitIndex, setHighlitIndex] = useState<number | undefined>()

  const [hasSelectedFilter, setHasSelectedFilter] = useState(false)

  const node = useRef<HTMLFormElement>()
  const inputRef = useRef<HTMLInputElement>()

  const filteredList = Object.values(supportedFilters).filter((filterLabel) =>
    filterLabel.toLowerCase().includes(inputValue.toLowerCase()),
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputValue === '' && event.key === 'Backspace') {
      setExistingFilters(existingFilters.slice(0, -1))
      // filter cleared, so no filter has been selected
      setHasSelectedFilter(false)
      // eslint-disable-next-line no-unused-expressions
      node.current?.submit()
    }

    // the rest are to intercept arrow keys
    // but if there are filtered index, ignore these commands
    if (hasSelectedFilter) return

    if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      setHighlitIndex((current) => {
        if (current === undefined) {
          return 0
        }
        return (current + 1) % Object.keys(filteredList).length
      })
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      setHighlitIndex((current) => {
        if (current === undefined) {
          return Object.keys(filteredList).length - 1
        }
        const numFilter = Object.keys(filteredList).length
        // https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
        return (((current - 1) % numFilter) + numFilter) % numFilter
      })
    }
  }

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    event && event.preventDefault()
    // no filters are selected
    setHasSelectedFilter(false)

    if (highlitIndex !== undefined) {
      handleHelpItemClick(Object.values(filteredList)[highlitIndex])()
      setHighlitIndex(undefined)
    } else {
      updateSearch()
    }
  }

  const removeFilter = (filter: IExistingFilter) => {
    const queries = existingFilters
      .filter((filt) => filt.key !== filter.key)
      .reduce(
        (query, filt) => ({
          ...query,
          [filt.key]: filt.value,
        }),
        {},
      )
    history.push({
      search: `?${queryString.stringify(queries)}`,
    })
  }
  const updateSearch = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.blur()
    }
    setAnchorEl(null)

    const searchTerms = inputValue.split(',').map((term) => term.trim())
    const inputQueries = searchTerms.reduce((allTerms, term) => {
      const split = term.split(':').map((term) => term.trim())
      const [label, value] =
        !defaultFilter || !inputValue || split.length === 2
          ? [split[0], split[1]]
          : [supportedFilters[defaultFilter], split[0]]

      if (invert(supportedFilters)[label]) {
        return {
          ...allTerms,
          [invert(supportedFilters)[label]]: value,
        }
      }
      return allTerms
    }, {})

    const existingQueries = existingFilters.reduce((queries, filter) => {
      return {
        ...queries,
        [filter.key]: filter.value,
      }
    }, {})

    const allQueries = {
      ...existingQueries,
      ...inputQueries,
    }

    history.push({
      search: `?${queryString.stringify(allQueries)}`,
    })
  }

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleHelpItemClick = (label: string) => () => {
    if (label === undefined) return

    setHasSelectedFilter(true)
    // the list is collapsed, therefore no more highlight index
    setHighlitIndex(undefined)
    setInputValue(`${label}: `)
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleDocumentClick = (event: any) => {
    if (node && node.current && node.current.contains(event.target)) {
      return
    }

    setAnchorEl(null)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick)

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick)
    }
  }, [])

  useEffect(() => {
    const hiddenKeys = ['limit', 'page', 'sortBy', 'orderBy']
    const parsed = queryString.parse(location.search)
    /**
     * There are other data to be passed to the page using the search params
     * use the double underscore to indicate the param is NOT one of the filtering options.
     */
    const keys = Object.keys(parsed).filter((key) => !key.startsWith('__'))
    const limitedKeys = keys.filter((key) => !hiddenKeys.includes(key))
    setExistingFilters(
      // @ts-ignore - typescript seems confused - value cannot be undefined here...
      limitedKeys.map((key) => ({
        key,
        label: supportedFilters[key],
        value:
          !Array.isArray(parsed[key]) && parsed[key] !== undefined
            ? parsed[key]
            : '',
      })),
    )
    setInputValue('')
  }, [location.search, supportedFilters])

  useEffect(() => {
    const boundingRect = anchorEl && anchorEl.getBoundingClientRect()
    const helpBoxLeft = boundingRect ? boundingRect.left : 0
    const helpBoxTop = boundingRect ? boundingRect.bottom : 0

    setHelpBoxPositionConfig({
      helpBoxLeft,
      helpBoxTop,
    })
  }, [anchorEl, existingFilters])
  const { helpBoxLeft, helpBoxTop } = helpBoxPositionConfig || {}

  return (
    <form
      onSubmit={handleSubmit}
      ref={(el) => (node.current = el || undefined)}
    >
      <div
        className={classNames(styles.searchBar, {
          [styles.searchBarDark]: dark,
        })}
      >
        {existingFilters.map((filter, i) => (
          <span key={filter.value} className={styles.existingFilter}>
            <p>{`${filter.label}: ${filter.value}`}</p>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => removeFilter(filter)}
            />
          </span>
        ))}
        <input
          ref={(el) => (inputRef.current = el || undefined)}
          className={styles.searchInput}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          onClick={handleInputClick}
          placeholder={translate('utility.searchPlaceholder').toString()}
        />
        <div
          className={styles.helpBox}
          style={{
            top: helpBoxTop,
            left: helpBoxLeft,
            ...(!anchorEl && { display: 'none' }),
          }}
        >
          {filteredList.map((filterLabel, index) => (
            <div
              key={filterLabel}
              className={classNames(styles.helpItem, {
                [styles.highlit]: index === highlitIndex,
              })}
              onClick={handleHelpItemClick(filterLabel)}
              onMouseOver={() => setHighlitIndex(index)}
            >
              {filterLabel}
            </div>
          ))}
        </div>
      </div>
    </form>
  )
}

export default withLocalize(FilterSearch)
