import React from 'react'
import styles from './SectionTab.module.scss'
import LogoTick from '../Icons/RoundCheck'
import LogoReset from '../Icons/RoundBlueReset'
import { SectionStatusIndicatorProps } from "./types";

const SectionStatusIndicator = ({
  isVisible,
  isComplete,
}: SectionStatusIndicatorProps) => {
  if (!isVisible) {
    return null
  }

  return isComplete ? (
    <LogoTick
      className={styles.sectionTitleLogoComplete}
      color={styles.white}
      backgroundColor={styles.black}
    />
  ) : (
    <LogoReset className={styles.sectionTitleLogoPending} />
  )
}

export default SectionStatusIndicator
