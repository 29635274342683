import React from 'react'
import mainStyles from '../Main/Main.module.scss'
import styles from './LandingPage.module.scss'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { TwoButtonLandingPageProps } from "./types";

const TwoButtonLandingPage = ({
    onClickButton1,
    onClickButton2,
    headingKey,
    descriptionKey,
    button1Text,
    button2Text,
    isLoggedIn
  }: TwoButtonLandingPageProps) => (
  <div className={mainStyles.container}>
    <div className={mainStyles.headingContainer}>
      <h1 className={mainStyles.heading}>
        <Translate
          id={headingKey}
          options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
        />
      </h1>
    </div>
    {descriptionKey && (
      <div className={mainStyles.descriptionContainer}>
        <p className={mainStyles.description}>
          <Translate
            id={descriptionKey}
            options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
          />
        </p>
      </div>
    )}
    <div className={styles.buttonContainer}>
      <div
        className={classNames('button', 'button--white', styles.button)}
        onClick={onClickButton1}
      >
        <Translate id={button1Text} />
      </div>
      {!isLoggedIn && (
        <div
          className={classNames(styles.button, 'button', 'button--white--inverse')}
          onClick={onClickButton2}
        >
          <Translate id={button2Text} />
        </div>
      )}
    </div>
  </div>
)


export default TwoButtonLandingPage
