import React from 'react'
import { connect } from 'react-redux'
import appTheme from './Dropdown.module.scss'
import WrappedInput from './WrappedInput'
import Select from 'react-select'
import { ValueType } from 'react-select/src/types'
import { Translate, withLocalize } from 'react-localize-redux'
import { isRtl } from '../utils/translations'
import { DropdownProps, DropdownState } from "./types";
import { mapOptionForReactSelect, mapValueForReactSelect } from "./utils";
import { RootState } from 'reducers'

class Dropdown extends React.Component<DropdownProps, DropdownState> {
  constructor(props: DropdownProps) {
    super(props)
    this.state = {
      inputValue: '',
    }
  }

  render() {
    const {
      id,
      value,
      options,
      onChange,
      isClearable,
      readOnly,
      isMulti,
      menuPlacement,
      disabled,
      required,
      noPortal,
    } = this.props

    const filterOptions = options.length > 1000

    const getValidationValue = () => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          return 'valid'
        }
        return ''
      }
      return (value === undefined ? '' : 'valid')
    }
    const validationValue = getValidationValue()
    const isInvalid = required && !validationValue

    const selectStyles = {
      control: (provided: any, state: any) => ({
        ...provided,
        maxWidth: appTheme.selectMaxWidth,
        height: 45,
        fontSize: appTheme.selectFontSize,
        fontWeight: appTheme.selectFontWeight,
        backgroundColor: appTheme.white,
        border: state.isFocused ? '2px solid var(--brand)' : `2px solid ${appTheme.grey}`,
        '&:hover': {
          border: state.isFocused ? '2px solid var(--brand)' : `2px solid ${appTheme.grey}`,
        },
        cursor: 'pointer',
        boxShadow: 'none',
        '@media (max-width: 500px)': {
          height: 30,
          minHeight: 30,
        },
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? appTheme.contrast : appTheme.text,
      }),
      singleValue: (provided: any, state: any) => ({
        ...provided,
        color: readOnly ? appTheme.disabledText : appTheme.text,
      }),
      menu: (provided: any) => ({
        ...provided,
        borderRadius: appTheme.selectBorderRadius,
        cursor: 'pointer',
        fontSize: appTheme.selectFontSize,
        fontWeight: appTheme.selectFontWeight,
        color: appTheme.text,
        zIndex: 1001,
      }),
      menuList: (provided: any, state: any) => ({
        ...provided,
        '@media (max-width: 500px)': {
          height: 130,
        },
      }),
      indicatorSeparator: (provided: any) => ({
        ...provided,
        display: !isClearable ? 'none' : '',
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        '@media (max-width: 500px)': {
          height: 28,
          minHeight: 28,
        },
      }),
      input: (provided: any) => ({
        margin: '-3px 0 5px 0',
        fontSize: appTheme.selectFontSize,
      }),
      valueContainer: (provided: any) => ({
        ...provided,
        height: 45,
        fontSize: appTheme.selectFontSize,
        '@media (max-width: 500px)': {
          height: 30,
          minHeight: 30,
        },
        border: isInvalid ? '2px solid var(--error)' : 'unset',
      }),
    }

    type MyOptionType = { label: string; value: number }
    const handleChange = (value: ValueType<MyOptionType>) => {
      if (value === null && isMulti) {
        onChange([])
      } else if (Array.isArray(value)) {
        if (filterOptions) {
          const indicies = value.map((selection) =>
            options.indexOf(selection.label),
          )
          onChange(indicies)
        } else {
          onChange(value.map((selection) => selection.value))
        }
      } else {
        const newOption: MyOptionType = value as MyOptionType // it's necessarily singluar here
        if (filterOptions) {
          const index = newOption ? options.indexOf(newOption.label) : -1
          onChange(index)
        } else {
          onChange(newOption ? newOption.value : -1)
        }
      }
    }

    const handleInputChange = (input: string) => {
      this.setState({ inputValue: input })
    }

    const displayOptions = filterOptions
      ? options
          .filter((option: string, index: number) =>
            option.toLowerCase().includes(this.state.inputValue.toLowerCase()),
          )
          .filter((opt: string, index: number) => index < 500)
      : options

    return (
      <WrappedInput
        isValid={value !== undefined && value !== null && value !== -1}
        {...this.props}
      >
        <Select
          inputId={id}
          menuPortalTarget={noPortal ? undefined : document.body}
          menuPlacement={menuPlacement ?? 'auto'}
          inputValue={this.state.inputValue}
          onInputChange={handleInputChange}
          value={mapValueForReactSelect(options, value)}
          isDisabled={readOnly}
          onChange={handleChange}
          options={displayOptions.map(mapOptionForReactSelect)}
          styles={selectStyles}
          noOptionsMessage={() =>
            this.props.translate('utility.noOptions') as string
          }
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: appTheme.primary,
              primary25: appTheme.primary25,
              neutral0: appTheme.neutral,
            },
          })}
          isClearable={isClearable}
          isRtl={this.props.isRtl}
          isMulti={this.props.isMulti}
        />
        {!disabled && (
          <>
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ display: 'none' }}
              value={validationValue}
              required={required}
              aria-invalid={required && !(Array.isArray(value) ? value.join(',') : value)}
            />
            {isInvalid ? (
              <div className={appTheme.errorMessage}><Translate id='register.form.error.reqField' /></div>
            ) : (
              <div className={appTheme.errorPlaceholder} />
            )}
          </>
        )}
      </WrappedInput>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isRtl: isRtl(state.localize),
})
 
export default connect(mapStateToProps)(withLocalize(Dropdown))
