import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReviewedApplication } from 'components/common/actions/programManagerActions'
import { useParams } from 'react-router-dom'
import { RootState } from 'reducers'
import ScoringTool from './ScoringTool'
import styles from './ReviewApplication.module.scss'
import Tabs from 'components/common/Tabs/Tabs'
import ReviewHistory from './ReviewHistory'
import { Translate } from 'react-localize-redux'
import useTranslate from 'hooks/useTranslate'
import ReviewForm from './ReviewForm'
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs'
import GoBackButton from '../GoBackButton/GoBackButton'
import HomeIcon from '../ProgramSettings/HomeIcon'
import FitScreen from 'components/common/Layout/FitScreen'

const ReviewApplication = () => {
  const { applicationId } = useParams()
  const translate = useTranslate()

  const dispatch = useDispatch()

  useEffect(() => {
    if (applicationId) {
      dispatch(getReviewedApplication(applicationId))
    }
  }, [dispatch, applicationId])

  const { v2ReviewApplication, v2ReviewApplicationError } = useSelector(
    (state: RootState) => state.review,
  )

  if (v2ReviewApplicationError) {
    return (
      <div className={styles.container}>
        <h3>
          <Translate id='review.messages.retrieveApplication' />
        </h3>
      </div>
    )
  }

  if (
    !v2ReviewApplication ||
    !v2ReviewApplication.application ||
    !v2ReviewApplication.reviewedApplications ||
    v2ReviewApplication.reviewedApplications.length === 0
  ) {
    return null
  }

  const { application, reviewedApplications } = v2ReviewApplication
  // @ts-ignore
  const { challenge } = application.applicationTemplate
  const { stage } = reviewedApplications[0]
  const { startupName, applicantName } = application.metaData

  const getApplicantLabel = () => {
    if (startupName) {
      return `Company: ${startupName}`
    }
    if (applicantName) {
      return `Applicant: ${applicantName}`
    }
    return 'Unknown'
  }

  return (
    <FitScreen>
      <div className={styles.container}>
        <div className={styles.navContainer}>
          <GoBackButton />
          <Breadcrumbs
            crumbs={[
              {
                label: <HomeIcon />,
                link: '/',
              },
              {
                link: `/programs-list/?managedBy=${challenge?.programOwner?.name}`,
                label: challenge?.programOwner?.name,
              },
              {
                link: `/programs/${challenge?.id}/settings`,
                label: challenge?.name,
              },
              { label: getApplicantLabel(), bold: true },
            ]}
          />
        </div>
        <div className={styles.content}>
          <div className={styles.mainSection}>
            <Tabs
              tabs={[
                {
                  heading: translate('review.application'),
                  content: (
                    <ReviewForm
                      application={application}
                      status={v2ReviewApplication.status}
                    />
                  ),
                },
                {
                  heading: 'History',
                  content: <ReviewHistory application={application} />,
                },
              ]}
              initialTab={0}
            />
          </div>
          <div className={styles.scoreSection}>
            <ScoringTool stage={stage!} />
          </div>
        </div>
      </div>
    </FitScreen>
  )
}

export default ReviewApplication
