import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './ReferenceList.module.scss'
import WrappedInput from './WrappedInput'
import Dropdown from './Dropdown'
import { IListOption, ReferenceListProps } from "./types";

const ReferenceList = (props: ReferenceListProps) => {
  const { id, onDelete, onAdd, options, selected, newInputLabel } = props
  const wrappedInputProps = {
    ...props,
    fullHeight: true,
  }

  return (
    <div className={styles.container}>
      <WrappedInput {...wrappedInputProps}>
        <ul className={styles.list}>
          {selected.map(({ id, label }: IListOption) => (
            <li key={id} className={styles.listItem}>
              <div className={styles.innerLi}>
                <span>{label}</span>
                {selected.length > 1 && (
                  <button
                    onClick={() => onDelete(id)}
                    className={styles.deleteButton}
                  >
                    <FontAwesomeIcon icon={faTimes} className='fa' />
                  </button>
                )}
              </div>
              <div className={styles.liDivider} />
            </li>
          ))}
        </ul>
        <div className={styles.newInput}>
          <Dropdown
            id={id}
            showValidation={false}
            label={newInputLabel}
            value={-1}
            options={options.map((option) => option.label)}
            onChange={onAdd}
            menuPlacement='top'
          />
        </div>
      </WrappedInput>
    </div>
  )
}

export default ReferenceList
