import { ScoringMethod } from 'models/review/scoring-tool'

import { CalculationMethodsDisplay } from "./types";

export const scoringMethods = ['YesNo', 'Rating']

export const calculationMethodsDisplayArray: CalculationMethodsDisplay[] = [
  'Sum',
  'Average',
]
export const calculationMethods: {
  [method in CalculationMethodsDisplay]: ScoringMethod
} = {
  Sum: ScoringMethod.SUM,
  Average: ScoringMethod.AVG,
}
