import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './Table.module.scss'
import classNames from 'classnames'
import { Translate } from 'react-localize-redux'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { ActionIconProps } from "./types";

const ActionIcon = ({ id, icon, tooltip, onClick }: ActionIconProps) => {
  return (
    <>
      <InfoTooltip
        overlay={(
          <Translate id={tooltip} />
        )}
      >
        <FontAwesomeIcon
          data-tip
          data-for={id}
          icon={icon}
          onClick={onClick}
          className={classNames(styles.clickableIcon, styles.showOnHover)}
        />
      </InfoTooltip>
    </>
  )
}

export default ActionIcon
