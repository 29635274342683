import React, { useState } from "react";
import styles from "./SearchHighlight.module.scss";
import classNames from "classnames";
import { UserImageProps } from "./types";
import { getFirstLetter } from "./utils";

const UserImage = ({ user, className, onClick }: UserImageProps) => {
  const [available, setAvailability] = useState(true);

  return (available && user?.profilePicture) ?
    (
      <div className={styles.imageWrapper}>
        <img
          alt='User Profile'
          className={classNames(styles.img, className)}
          src={user.profilePicture}
          onClick={onClick}
          onError={() => setAvailability(false)}
        />
      </div>
    )
    : (
      <div
        className={classNames(styles.imageBox, className)}
        onClick={onClick}
      >
        <span className={styles.letter}>
          {getFirstLetter(user?.firstName || '')}
        </span>
        <span className={styles.letter}>
          {getFirstLetter(user?.lastName || '')}
        </span>
      </div>
    )
}

export default UserImage;
