import { IApplicationToReview } from '../../../../../models/application/application-to-review'
import { DateTime } from 'luxon'
import { IAssignedApplicationsRow } from "../../types";

export function convertDataAssignedApplications(
  profiles: IApplicationToReview[] | null,
): IAssignedApplicationsRow[] {
  if (profiles == null) return []
  return profiles.map((profile) => {
    const submittedAt = DateTime.fromISO(profile.application.submittedAt)
    const today = DateTime.local()
    const received = Math.round(today.diff(submittedAt).as('days'))
    return {
      received,
      applicant: profile.application.applicant,
      program: profile.application.program,
      gate: profile.stage.name,
      applicationId: profile.application._id,
    }
  })
}
