import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import { RootState } from '../../../reducers'
import { Row, Column } from '../Layout'
import Form from '../Form/Form'
import FormSection from '../Form/FormSection'
import TextInput from '../FormInput/TextInput'
import SectionList from '../SectionList/SectionList'
import styles from './OrganizationSelector.module.scss'
import { withLocalize, LocalizeContextProps } from 'react-localize-redux'
import { handleError } from '../actions/errorHandlerActions'
import { selectChallenge, setGoldenTicket } from '../actions/applicationActions'
import {
  createApplicationWithFidPrefill,
  updateApplicationField,
  getApplicationFromIndividual,
} from './ApplicationForm/utils/api'
import { debounce } from 'throttle-debounce'
import { IApplication } from 'models/application/application'

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { fetchChallenge } from '../actions/challengesActions'
import ApplicationFooter from './ApplicationFooter'
import HomeIcon from '../ProgramSettings/HomeIcon'
import { currentProgramUrl } from "./utils";

type SyncGoldenTicketProps = {
  applicationId: string
  value: string
}
const syncGoldenTicket = debounce(
  1000,
  ({ applicationId, value }: SyncGoldenTicketProps) => {
    updateApplicationField({
      value,
      applicationId,
      field: 'goldenTicket',
    })
  },
)

const singleChallenge = !!process.env.REACT_APP_CHALLENGE_ID
const usesGoldenTicket = process.env.REACT_APP_USES_GOLDEN_TICKET === 'true'

type Props = LocalizeContextProps & {}

/**
 * Component for rendering the page for configuring application for individual FID applicant
 */
const IndividualApplicant = ({ translate }: Props) => {
  /********************************************************************
   * Redux, routers, and route params
   *******************************************************************/
  const history = useHistory()
  const dispatch = useDispatch()
  const { id: challengeId } = useParams()
  const { user } = useSelector((state: RootState) => state)
  const { userId } = user

  // getting application from the store
  const { selectedChallenge, goldenTicket } = useSelector(
    (state: RootState) => state.application,
  )

  const { challenges } = useSelector((state: RootState) => state.challenges)
  const challenge = challenges?.[challengeId]

  /********************************************************************
   * Side effects
   *******************************************************************/
  React.useEffect(() => {
    const checkApplicationExists = async () => {
      // check if application exists by trying to get the application itself
      const individualApplication = await getApplicationFromIndividual({
        userId,
        selectedChallenge,
      })

      setApplication(individualApplication)
      dispatch(setGoldenTicket(individualApplication?.goldenTicket || ''))
    }

    if (selectedChallenge) {
      dispatch(fetchChallenge(selectedChallenge))
      checkApplicationExists()
    }
  }, [userId, selectedChallenge, dispatch])

  /********************************************************************
   * Component state
   *******************************************************************/
  const [application, setApplication] = React.useState(
    null as IApplication | null,
  )
  if (challengeId && challengeId !== selectedChallenge) {
    dispatch(selectChallenge(challengeId))
  }

  const applicationExists = !!application

  const sections = [
    {
      id: 'individual',
      title: translate('visaApplication.goldenTicket') as string,
    },
    {
      id: 'application',
      title: translate('visaApplication.applicationForm') as string,
    },
  ]

  const handleSectionSelected = (index: number) => {
    if (index === 1) {
      startApplication()
    }
  }

  const startApplication = async () => {
    try {
      if (!applicationExists) {
        await createApplicationWithFidPrefill({
          selectedChallenge,
          selectedOrganization: '',
          goldenTicket,
          user,
          handleError,
          participants: [userId],
          applicationMode: 'FID_INDIVIDUAL',
        })
      }
    } catch (err) {
      console.error(
        'Trying to create an application that you have created already',
      )
    } finally {
      if (singleChallenge) {
        history.push('/application')
      } else {
        history.push(`/programs/${selectedChallenge}/application`)
      }
    }
  }

  const handleGoldenTicketChange = (newValue: string) => {
    dispatch(setGoldenTicket(newValue))
    if (applicationExists) {
      syncGoldenTicket({
        applicationId: application?.id,
        value: newValue,
      })
    }
  }

  return (
    <div>
      <Row className={styles.breadcrumbsContainer}>
        <Breadcrumbs
          crumbs={[
            {
              link: '/',
              label: <HomeIcon />,
              ariaLabel: translate('utility.home') as string,
            },
            {
              link: process.env.REACT_APP_PROGRAMS_URL,
              label: translate('applications.programs'),
            },
            {
              link: currentProgramUrl(challenge?.name),
              label: challenge?.name || translate('applications.program'),
            },
            {
              label: translate('applications.apply'),
            },
          ]}
        />
      </Row>
      <Row>
        <Column nav>
          <SectionList
            sections={sections}
            onSelected={handleSectionSelected}
            selectedSection={0}
            displayStatus={false}
          />
        </Column>
        <Column>
          <Form activeSectionIndex={0}>
            <FormSection>
              {usesGoldenTicket ? (
                <TextInput
                  id='goldenTicket'
                  type='text'
                  value={goldenTicket || ''}
                  label={
                    translate(
                      'visaApplication.goldenTicketQuestionTitle',
                    ) as string
                  }
                  description={
                    translate(
                      'visaApplication.goldenTicketQuestionDescription',
                    ) as string
                  }
                  onChange={handleGoldenTicketChange}
                />
              ) : null}
            </FormSection>
          </Form>
        </Column>
        <ApplicationFooter
          submitLabel='visaApplication.startApplication'
          onSubmit={startApplication}
        />
      </Row>
    </div>
  )
}

export default withLocalize(IndividualApplicant)
