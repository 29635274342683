import React from 'react'
import classNames from 'classnames'

import styles from './Toggle.module.scss'
import { ToggleProps } from "./types";

const Toggle = ({
  onLabel,
  offLabel,
  value,
  onClick,
  elementWidth,
  disabled,
}: ToggleProps) => {
  const handleClick = !disabled ? onClick : () => {}

  return (
    <div
      className={styles.container}
    >
      <span
        onClick={() => handleClick()}
        className={classNames(
          styles.toggle,
          {
            [styles.selected]: !value,
            [styles.disabled]: disabled,
          },
        )}
        style={{
          width: elementWidth,
        }}
      >
        {offLabel}
      </span>
      <span
        onClick={() => handleClick()}
        className={classNames(
          styles.toggle,
          {
            [styles.selected]: value,
            [styles.disabled]: disabled,
          },
        )}
        style={{
          width: elementWidth,
        }}
      >
        {onLabel}
      </span>
    </div>
  )
}

export default Toggle
