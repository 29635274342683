import React, { useState } from 'react'
import styles from './EditQuestionControls.module.scss'
import Button from '../../../../shared/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Translate } from 'react-localize-redux'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'

interface IProps {
  questionIndex: number
  removeQuestion: (indexOfQuestionToRemove: number) => void
  deletable: boolean
}

const EditQuestionControls = ({
  removeQuestion,
  questionIndex,
  deletable,
}: IProps) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleDeleteApprove = () => {
    removeQuestion(questionIndex)
    setTooltipVisible(false)
  }

  const handleDeleteReject = () => {
    setTooltipVisible(false)
  }

  if (!deletable) {
    return null
  }

  return (
    <Translate>
      {({ translate }) => (
        <div className={styles.editQuestionControls}>
          <Button
            type='primary'
            hardClassOverride={styles.deleteButton}
            onClick={() => setTooltipVisible(true)}
          >
            <FontAwesomeIcon icon={faTimes} className='fa' />
          </Button>
          {tooltipVisible && (
            <NotificationModal
              onAccept={handleDeleteApprove}
              onCancel={handleDeleteReject}
            >
              <Translate id='visaApplication.deleteQuestionTooltipDescription' />
            </NotificationModal>
          )}
        </div>
      )}
    </Translate>
  )
}

export default EditQuestionControls
