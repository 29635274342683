import React, { useEffect, useState } from 'react'
import Table from 'components/common/Table/Table'
import { GateListingColumns} from './Columns'
import styles from './GateListing.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { createStage, deleteStage } from '../../../actions/programOwnerActions'
import { addErrorToast, addSuccessToast } from '../actions/toastActions'

import { fetchChallengeStages } from 'components/common/actions/challengesActions'

// Confirm modal imports
import ConfirmActionModal from '../../Modal/ConfirmActionModal'
import { withLocalize } from 'react-localize-redux'

import { CellProps } from 'react-table'
import ActionIcon from 'components/common/Table/ActionIcon'
import { faCog, faTrash } from '@fortawesome/free-solid-svg-icons'
import { ICommonState } from 'components/common/utils/state'
import { IStage } from 'components/common/models/application/IStage'
import { IReview } from '../../../models/review/review'
import { GateListingProps, IGateRow } from "./types";

const GateListing = ({
  translate,
  challengeId,
  onSettingsClick
}: GateListingProps) => {
  const dispatch = useDispatch()

  /**
   * The ID of the gate (stage) about to be deleted.
   * When the delete button of a row of the listing is clicked,
   * this value will be set to the id of the gate of that row
   * and the modal will be shown.
   * If delete is confirmed, the delete request will be sent to server
   * and this field will be null again.
   */
  const [pendingDeleteStageId, setPendingDeleteStageId] = useState(
    null as string | null,
  )

  const stages = useSelector((state: ICommonState) => {
    if (!challengeId) return undefined
    return state.challenges.challengeStages[challengeId]
  })

  // callback when the add stage button is clicked
  const onAdd = () => {
    dispatch(
      createStage(challengeId, (res: IReview) => onSettingsClick(res.newStageId!)),
    )
  }

  // call back for the modal
  // get the id of the removing stage from the flag above
  const onDeleteStage = () => {
    if (pendingDeleteStageId) {
      dispatch(
        deleteStage(challengeId, pendingDeleteStageId, (res: any) => {
          if (res.id) {
            // delete stage okay
            dispatch(addSuccessToast('Stage deleted'))
            // reload the stage list
            dispatch(fetchChallengeStages(challengeId))
          } else {
            // delete stage failed
            dispatch(addErrorToast('Unable to delete stage'))
          }
        }),
      )
      // remove the id to clear the modal
      setPendingDeleteStageId(null)
    }
  }

  useEffect(() => {
    dispatch(fetchChallengeStages(challengeId))
  }, [challengeId, dispatch])

  const convertData = (gates: IStage[]): IGateRow[] => {
    return gates.map((gate, i) => ({
      id: gate.id,
      gateNumber: i + 1,
      gateName: gate.name,
      applications: gate.numberOfApplications,
      reviewers: gate.maxReviewers,
      goldenTicket: gate.goldenTicket,
    }))
  }

  const data = convertData(stages || [])
  const tableColumns = [
    {
      Header: GateListingColumns[0].Header,
      columns: [
        ...GateListingColumns[0].columns,
        {
          Header: '',
          accessor: 'id',
          id: 'settings',
          Cell: ({ cell }: CellProps<IStage>) => {
            return (
              <div className={styles.tableActionButtonList}>
                {/* Settings button */}
                <ActionIcon
                  id='settings-icon'
                  icon={faCog}
                  tooltip="challenge.gateSettings"
                  onClick={() => onSettingsClick(cell.value)}
                />
                {/* Delete button */}
                <ActionIcon
                  id='delete-icon'
                  icon={faTrash}
                  tooltip='delete'
                  onClick={() => setPendingDeleteStageId(cell.value as string)}
                />
              </div>
            )
          },
        },
      ],
    },
  ]
  return (
    <>
      <div className={styles.tableContainer}>
        <Table
          title='Gates'
          data={data}
          columns={tableColumns}
          onAdd={onAdd}
          addLabel={translate('review.addGate')}
        />
      </div>
      {pendingDeleteStageId && (
        <ConfirmActionModal
          close={() => setPendingDeleteStageId(null)}
          title={translate('admin.deleteStageConfirmation')}
          confirmText={translate('delete')}
          cancelText={translate('cancel')}
          confirmAction={() => onDeleteStage()}
        />
      )}
    </>
  )
}

export default withLocalize(GateListing)
