import React from 'react'
import style from './Form.module.scss'
import classNames from 'classnames'
import { FormProps } from "./types";

const Form = ({
  children,
  className,
  outerClassName,
  activeSectionIndex,
  borderless,
}: FormProps) => {
  const paddingStyle =
    activeSectionIndex === undefined
      ? {}
      : {
          position: 'sticky', // immune from scrolling
          width: 34, // extra width to cover differences between safari and chrome
          float: 'left', // prevent unwanted space at the content
          transform: 'translateX(-34px)',
          height: 'calc(6rem - 4px)', // same as section tab's height, minus the width of the border
          background: style.backgroundColor, // same color with background to create an illusion that the border has gone
          top: `calc(${activeSectionIndex * (6 + 2)}rem + 2px)`, // 6 rem for the tab's height; 2rem for the margin
        }
  return (
    <div
      className={classNames(style.formOuterContainer, outerClassName, {
        [style.border]: !borderless,
      })}
    >
      {activeSectionIndex !== undefined && (
        <div style={paddingStyle as object} />
      )}
      <form className={classNames(style.form, className)} autoComplete='on'>
        {children}
      </form>
    </div>
  )
}

export default Form
