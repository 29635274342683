import React from 'react';
import mainStyles from 'components/common/Main/Main.module.scss';
import styles from './../About.module.scss';
import { Translate } from 'react-localize-redux';

export default () => {
  return (
    <div
      className={[
        mainStyles.container,
        styles.container,
        'slideContainer',
        styles.areaContainer,
      ].join(' ')}
    >
      <div className={styles.areaLeft}>
        <div className={styles.imgContainer1} />
      </div>
      <div className={styles.areaRight}>
        <h1 className={styles.slideHeader}>
          <Translate id='about.slide1.title' />
        </h1>
        <p className={styles.paragraph}>
          <Translate id='about.slide1.p1' />
        </p>
      </div>
    </div>
  );
};
