import { IQuestion } from '../models/application/IQuestion'
import { IAnswer, isAnswered } from '../models/application/IAnswer'
import { getListOfQuestionsDisabled } from '../Application/ApplicationPanel'

/**
 *  computeCompletionPercent
 *  CAUTION: THIS METHOD IS SLOW
 */
export const computeCompletionPercent = (
  questions: IQuestion[],
  answers: IAnswer[],
) => {
  let questionsCount: number = 0
  let questionsCountCompleted: number = 0

  questions.forEach((question, questionIndex) => {
    const questionsDisabled = getListOfQuestionsDisabled(questions, answers)
    if (question.mandatory && !questionsDisabled.includes(questionIndex)) {
      questionsCount++
      if (isAnswered(answers[questionIndex])) {
        questionsCountCompleted++
      }
    }
  })

  return (100 * questionsCountCompleted) / questionsCount
}
