import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import styles from './SectionTab.module.scss'
import { SectionDeleteButtonProps } from "./types";

const SectionDeleteButton = ({ onClick }: SectionDeleteButtonProps) => (
  <label
    onClick={onClick}
    className={styles.sectionTitleLogoRemove}
  >
    <FontAwesomeIcon icon={faTimes} className={'fa'} />
  </label>
)

export default SectionDeleteButton
