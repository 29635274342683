import React, { FocusEvent, KeyboardEvent, useState } from 'react'
import CloseButton from '../CloseButton/CloseButton'
import styles from './InputTag.module.scss'
import { InputTagProps } from "./types";

const InputTag = ({ tags = [], onTagsChange = () => {} }: InputTagProps) => {
  const [value, setValue] = useState('')

  function handleRemoveTag(i: number) {
    const newTags = [ ...tags ];
    newTags.splice(i, 1);
    onTagsChange(newTags)
  }

  function handleInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const { value } = target
    if ((e.key === 'Enter' || e.key === ',') && value) {
      addTag(value)
    } else if (e.key === 'Backspace' && !value) {
      handleRemoveTag(tags.length - 1);
    }
  }

  function handleInputOnBlur(e: FocusEvent<HTMLInputElement>) {
    const {value} = e.target
    if (value) {
      addTag(value)
    }
  }

  function addTag(value: string) {
    if (tagExists(value)) {
      return;
    }
    onTagsChange([...tags, value])
    setValue('')
  }

  function tagExists(value: string) {
    return tags.find(tag => tag.toLowerCase() === value.toLowerCase())
  }

  return (
    <div className={styles.root}>
      <ul className={styles.tagList}>
        {tags.map((tag, i) => (
          <li className={styles.tagItem} key={tag}>
            {tag}
            <CloseButton
              className={styles.tagButton}
              onClick={() => handleRemoveTag(i)}
            />
          </li>
        ))}
        <li className={styles.tagItemInputContainer}>
          <input
            type="text"
            className={styles.input}
            onKeyDown={handleInputKeyDown}
            onBlur={handleInputOnBlur}
            value={value}
            onChange={event => {
              const { value } = event.target
              if (value.slice(-1) !== ',') {
                setValue(event.target.value)
              }
            }}
          />
        </li>
      </ul>
    </div>
  );
}

export default InputTag;
