import { Action } from "redux";
import { ACTIONS } from '../actions/adminActions'
import { LOGOUT } from '../components/common/actions/authActions'
import { createReducer} from "./createReducer";
import { AdminState, Reducer } from "./types";


const defaultState: AdminState = {
  loadingUsers: false,
  users: [],
  loadingRoles: false,
  roles: [],
  startups: [],
  loadingStartups: false,
}

const reducer: Reducer<AdminState, Action> = {
  [ACTIONS.LOADING_USERS]: (state, {payload}) => ({...state, loadingUsers: payload}),
  [ACTIONS.LOADING_USERS_SUCCESS]: (state, {payload}) => ({...state, users: payload}),
  [ACTIONS.LOADING_ROLES]: (state, {payload}) => ({...state, loadingRoles: payload}),
  [ACTIONS.LOADING_ROLES_SUCCESS]: (state, {payload}) => ({...state, roles: payload}),
  [ACTIONS.UPDATE_USER_SUCCESS]: (state, {payload}) => ({
    ...state,
    users: state.users.map((user: any) =>
      user.id === payload.id ? {...user, ...payload} : user,
    ),
  }),
  [ACTIONS.DELETE_USER_SUCCESS]: (state, {payload}) => ({
    ...state,
    users: state.users.filter((user: any) => user.id !== payload),
  }),
  [ACTIONS.LOADING_STARTUPS]: (state) => ({...state, loadingStartups: true}),
  [ACTIONS.GET_ALL_STARTUPS_SUCCESS]: (state, {payload}) => ({...state, startups: payload}),
  [ACTIONS.UPDATE_STARTUP_SUCCESS]: (state, {payload}) => ({
    ...state,
    startups: state.startups.map((startup: any) =>
      startup.id === payload.id ? payload : startup,
    ),
  }),
  [ACTIONS.DELETE_STARTUP_SUCCESS]: (state, {payload}) => ({
    ...state, startups: state.startups.filter(
      (startup: any) => startup.id !== payload,
    ),
  }),

  [LOGOUT]: () => defaultState,
}

export default createReducer<AdminState, Action>(reducer, defaultState);
