import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { ConnectedRouter, push } from 'connected-react-router'
import {
  loginSuccess,
  logout,
  persistantLoginCheck,
} from './components/common/actions/authActions'
import { getProtectedRoutes, getRoutes } from './routes'
import ToastsContainer from './components/containers/ToastsContainer'
import Footer from './components/Footer/Footer'
import AppHeader from 'components/common/AppHeader/AppHeader'
import { HeaderContext } from 'components/common/AppHeader/HeaderContext'
import { getActiveLanguage, getTranslate, setActiveLanguage, withLocalize } from 'react-localize-redux'

import TNCPopup from 'components/common/TNCPopup/TNCPopup'

import english from './translations/translations_en.json'
import arabic from './translations/translations_ar.json'
import { renderToStaticMarkup } from 'react-dom/server'
import { getDirection } from 'utils/translations'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import TagManager from 'react-gtm-module'
import styles from './App.module.scss'
import { Helmet } from 'react-helmet'
import { AppProps, AppState } from "./types";
import { RootState } from "./reducers";
import { defaultCookieOptions } from "./components/common/utils/cookies";
import Cookies from "js-cookie";
import { fidLogin } from "./service/fidLogin";
import { getUserFromLocalStorage, isLoggedInABrowser, tokenKey } from "./utils/localStorage";

config.autoAddCss = false

class App extends React.Component<AppProps, AppState> {
  constructor(props: any) {
    super(props)

    const {
      initialize,
      addTranslationForLanguage,
    } = props

    const loc = document.location
    if (loc.pathname.startsWith('/challenges')) {
      const substr = loc.pathname.substr('/challenges'.length)
      const host = `${loc.protocol}//${loc.hostname}:${loc.port}`
      const newUrl = new URL(
        `${host}/programs${substr}${loc.search}${loc.hash}`,
      ).toString()
      document.location.assign(newUrl)
    }

    initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'عربى', code: 'ar' },
      ],
      options: { renderToStaticMarkup },
    })
    addTranslationForLanguage(english, 'en')
    addTranslationForLanguage(arabic, 'ar')

    setActiveLanguage('en')

    if (process.env.REACT_APP_IS_PRODUCTION === 'true') {
      // initialize Google Tag Manager
      const tagManagerArgs = {
        gtmId: 'GTM-PFX38DR', // GTM code provided by DFF
      }
      TagManager.initialize(tagManagerArgs)
    }

    this.state = {
      yScroll: 0,
      isLoggedIn: null
    }
  }

  componentDidMount() {
    const handleScroll = () => {
      this.setState({
        yScroll: window.pageYOffset,
      })
    }
    window.addEventListener('scroll', handleScroll)
    this.checkLoginState()
  }

  updateLoginState (isLoggedIn: boolean) {
    this.setState((prevState) => ({...prevState, isLoggedIn}))
  }

  checkLoginState () {
    const {
      persistantLoginCheck,
      translate,
      loginSuccess,
    } = this.props;
    const {
      token,
      expires,
      email,
      userId
    } = getUserFromLocalStorage()
    const sessionToken = Cookies.get(tokenKey);
    const isUserDataAvailable = token && expires && userId && email;

    if(isUserDataAvailable) {
      persistantLoginCheck();
      this.updateLoginState(true)
    } else if (sessionToken?.length) {
      fidLogin(sessionToken)
        .then((response) => {
          return response.json()
        })
        .then(
          (loginData) => {
            const { expires, token, user } = loginData
            const { id, email } = user
            const data = { token, email, expires, userId: id }
            loginSuccess(data, translate, window.location.href);
            this.updateLoginState(true)
            Cookies.remove(tokenKey, defaultCookieOptions);
          },
          (err) => {
            console.log(err)
            this.updateLoginState(false);
          })
    } else {
      this.updateLoginState(false)
    }
  }

  getUrlsWithNoFooter() {
    return [
      /^\/programs\/\w+\/settings\/?\w*$/,
      // gate settings
      /^\/programs\/\w+\/gates\/\w+$/,
      // application template
      /^\/programs\/\w+\/application$/,
      /^\/programs\/\w+\/application\/.*$/,
      // appliction: select organization page
      /^\/programs\/\w+\/selectOrganisation$/,
      /^\/programs\/\w+\/application\/individual$/,
      /^\/facilitators\/\w+\/settings$/,
    ]
  }

  shouldHeaderWithWhiteBackground = () => {
    const pathname = this.props.location.pathname as string

    return 'terms,policy,programs'
      .split(',')
      .some((kw) => pathname.includes(kw))
  }

  shouldHaveActionBarMargin() {
    const pathname = this.props.location.pathname
    const matchPathRegexes = [
      /programs\/.+\/application\/validateEmail/,
      /programs\/.+\/application\//,
    ]

    if (matchPathRegexes.some((regex) => new RegExp(regex).test(pathname))) {
      return true
    }

    return false
  }

  render() {
    document.body.dir = this.props.isRtl ? 'rtl' : 'ltr'
    const { isLoggedIn } = this.state;
    /*
      Redirect user to Dubai programs if not logged in
    */

    return (
      <HeaderContext.Consumer>
        {({ collapsed, setCollapsed }) => {
          setCollapsed(this.state.yScroll !== 0)
          return (
            <>
              <Helmet>
                <title>
                  {this.props.translate('pageInfo.title').toString()}
                </title>
                <meta
                  property='og:title'
                  content={this.props.translate('pageInfo.title').toString()}
                />
                <meta
                  property='og:description'
                  content={this.props
                    .translate('pageInfo.description')
                    .toString()}
                />
              </Helmet>
              <ConnectedRouter history={this.props.history}>
                <a href='#maincontent' className={styles.skipNav}>Skip navigation</a>
                <AppHeader
                  links={[
                    {
                      translationKey: 'dashboard',
                      path: '/',
                      rank: 1,
                    },
                    {
                      translationKey: 'programOwners',
                      path: '/facilitators',
                      rank: 2,
                    },
                    {
                      translationKey: 'programs',
                      path: '/programs-list',
                      rank: 1,
                    },
                    {
                      translationKey: 'applications',
                      path: '/applications',
                      rank: 1,
                    },
                    {
                      translationKey: 'manage-rights',
                      path: '/manage-rights',
                      rank: 2,
                    },
                  ]}
                />
                <main
                  id='maincontent'
                  className={classNames(styles.content, {
                    [styles.collapse]: collapsed,
                    [styles.withHeaderMargin]: true,
                    [styles.withActionBarMargin]: this.shouldHaveActionBarMargin(),
                  })}
                >
                  {(isLoggedIn !== null) && (isLoggedInABrowser() ? getProtectedRoutes(this.props.userRank) : getRoutes())}
                </main>
                <div id="modal-root" />
                <div id="tooltip-root" />
                {!this.getUrlsWithNoFooter().some((pattern) =>
                  pattern.test(document.location.pathname),
                ) && <Footer />}
                <TNCPopup />
              </ConnectedRouter>

              <ToastsContainer />
            </>
          )
        }}
      </HeaderContext.Consumer>
    )
  }

  componentWillReceiveProps(nextProps: Readonly<AppProps>): void {
    this.logoutIfLocalStorageHasChanged()
  }

  logoutIfLocalStorageHasChanged = () => {
    setTimeout(() => {
      if (this.props.isLoggedIn && !isLoggedInABrowser()) this.props.logout()
    }, 500)
  }

  isDarkInvertedTheme = () => {
    const pathname = this.props.location.pathname
    return [
      '/about',
      '/privacy',
      '/terms',
      '/facilitators',
    ].some((darkInvertedPath) => pathname.startsWith(darkInvertedPath))
  }
}

const mapStateToProps = (state: RootState) => ({
  location: state.router.location,
  isLoggedIn: state.auth.loggedIn !== undefined && state.auth.loggedIn,
  isRtl: getDirection(getActiveLanguage(state.localize)) === 'rtl',
  user: state.user,
  languages: state.localize.languages,
  translate: getTranslate(state.localize),
  userRank: state.user && state.user.role ? state.user.role.rank : -1,
})

export default connect(mapStateToProps, {
  persistantLoginCheck,
  loginSuccess,
  setActiveLanguage,
  logout,
  push,
})(withLocalize(App))
