import axios from 'axios'
import { api, requestSuccess, ROUTES } from '../api'
import { handleError } from '../components/common/actions/errorHandlerActions'
import { IApplication } from 'models/application/application'
import { Dispatch } from "redux";
import { RootState } from "../reducers";
import { AppThunk } from "../index";
import ApiWrapperService from "../service/ApiWrapperService";

export const ACTIONS = {
  LOADING_REVIEWED_APPLICATIONS: '@dff_challenges_action/loading_reviewed_applications',
  ERROR_LOADING_REVIEWED_APPLICATIONS: '@dff_challenges_action/error_loading_reviewed_applications',
  LOADED_REVIEWED_APPLICATIONS: '@dff_challenges_action/loaded_reviewed_applications',
  CLEAR_REVIEWED_APPLICATION: '@dff_challenges/clear_reviewed_application',
  SET_ACTIVE_APPLICATION: '@dff_challenges/pm_set_active_application',
  CLEAR_ACTIVE_APPLICATION: '@dff_challenges/pm_clear_active_application',
  FETCH_APPLICATIONS_TO_REVIEW: '@dff_challanges_action/fetch_applications_to_review',
  SET_ALL_APPLICATIONS: '@dff_challenges_action/set_all_applications',
}

export const getReviewedApplications = (challengeId: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatch({ type: ACTIONS.LOADING_REVIEWED_APPLICATIONS, payload: source })

    const res = await ApiWrapperService.get({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/reviewedApplications`,
      state: getState(),
      optionalConfig: {cancelToken: source.token}
    })
    dispatch({ type: ACTIONS.LOADED_REVIEWED_APPLICATIONS, payload: res.data })
  } catch (error) {
    if (error.message !== 'request aborted') {
      // if we've canceled the request ourselves, don't treat it as an error
      dispatch({ type: ACTIONS.ERROR_LOADING_REVIEWED_APPLICATIONS })
      await handleError(error)(dispatch, getState, false)
    }
  }
}

export const scoreApplication = (
  challengeId: string,
  stageId: string,
  applicationId: string,
  review: any,
  onSuccess: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}/reviewedApplications/${applicationId}`,
      body: review,
      state: getState(),
    })
    await getReviewedApplications(challengeId)(dispatch, getState) // This is because backend does not return the updated object
    onSuccess()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const reassignApplication = (
  challengeId: string,
  stageId: string,
  applicationId: string,
  userId: string,
  onSuccess: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}/reviewedApplications/${applicationId}/programManagers`,
      body: {userId},
      state: getState(),
    })
    await getReviewedApplications(challengeId)(dispatch, getState) // This is because backend does not return the updated object
    onSuccess()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const moveApplication = (
  challengeId: string,
  srcStageId: string,
  applicationId: string,
  destStageId: string,
  onSuccess: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${srcStageId}/applications/${applicationId}/stages/${destStageId}`,
      body: null,
      state: getState(),
    })
    await getReviewedApplications(challengeId)(dispatch, getState) // This is because backend does not return the updated object
    onSuccess()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const rejectApplication = (
  challengeId: string,
  srcStageId: string,
  applicationId: string,
  onSuccess: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${srcStageId}/applications/${applicationId}/reject`,
      body: null,
      state: getState(),
    })
    await getReviewedApplications(challengeId)(dispatch, getState) // This is because backend does not return the updated object
    onSuccess()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const approveApplication = (
  challengeId: string,
  srcStageId: string,
  applicationId: string,
  onSuccess: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${srcStageId}/applications/${applicationId}/approve`,
      body: null,
      state: getState(),
    })
    await getReviewedApplications(challengeId)(dispatch, getState) // This is because backend does not return the updated object
    onSuccess()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

const setAllApplications = (applications: IApplication[]) => ({
  type: ACTIONS.SET_ALL_APPLICATIONS,
  payload: applications,
})

interface IFetchApplicationsProps {
  userId: string
  query?: string
}

export const fetchAllApplications = ({
  userId,
  query,
}: IFetchApplicationsProps): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const { data } = await api.get(
      `/v3/users/${userId}/applications/${query || ''}`,
    )

    dispatch(setAllApplications(data))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchApplicationsToReview = ({
  userId,
  query,
}: IFetchApplicationsProps): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const response = await api.get(
      `v3/users/${userId}/applicationsToReview/${query || ''}`,
    )

    if (!requestSuccess(response)) {
      throw new Error('Could not fetch assigned applications for user.')
    }

    dispatch({ type: ACTIONS.FETCH_APPLICATIONS_TO_REVIEW, payload: response.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}
