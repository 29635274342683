import { IFacilitatorMap } from 'components/common/reducers/facilitatorReducer'
import { FacilitatorRow } from "../../types";

export function convertDataFacilitators(profiles: IFacilitatorMap): FacilitatorRow[] {
  return Object.values(profiles).map(facilitator => ({
      facilitator: {
        name: facilitator.name,
        id: facilitator.id,
      },
      programs: facilitator.metaData.challengeCount,
      applications: facilitator.metaData.applicationCount,
    }))
}
