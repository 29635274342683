export interface IChallenge {
  id: string
  name: string
  rejectionMode?: RejectionMode
  autoCreateProfile: boolean
  challengeOwner: {
    name: string
  }
  programOwner: {
    name: string
  }
}

export enum RejectionMode {
  onChallenge = 'onChallenge',
  onApplication = 'onApplication',
}

export type ApplicationMode = 'FID_ORGANISATION' | 'FID_INDIVIDUAL' | 'E-MAIL'
