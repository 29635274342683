import { ACTIONS } from '../actions/sliderActions'
import { SliderState } from '../utils/state'
import { Reducer, SliderAction } from "./types";
import { createReducer } from "./createReducer";

const defaultState: SliderState = {
  draggable: false,
  scrollPop: false,
}

const reducer: Reducer<SliderState, SliderAction> = {
    [ACTIONS.SET_DRAGGABLE_TRUE]: () => ({ draggable: true }),
    [ACTIONS.SET_DRAGGABLE_FALSE]: () => ({ draggable: false }),
    [ACTIONS.SET_SCROLL_POP_TRUE]: () => ({ scrollPop: true }),
    [ACTIONS.SET_SCROLL_POP_FALSE]: () => ({ scrollPop: false }),
}

export default createReducer<SliderState, SliderAction>(reducer, defaultState)
