// functions being used to initialize the application form
import { IAnswer } from '../../../models/application/IAnswer'
import { IQuestionSection } from '../../../models/application/ISection'
import { IQuestion } from '../../../models/application/IQuestion'
import { UserState } from 'components/common/utils/state'
import { getTemplate } from '../utils/api'
import { fakeStartup } from '../constants'
import { TranslateFunction } from 'react-localize-redux'
import { ApplicationMode } from 'components/common/models/application/IChallenge'

type RetrievingQuestionsParams = {
  selectedOrganization: string
  selectedChallenge: string
  user: UserState
  handleError: (message: string) => void
}
export const retrieveQuestionsSectionsFromAPI = async ({
  selectedOrganization,
  selectedChallenge,
  user,
  handleError,
}: RetrievingQuestionsParams): Promise<{
  applicationId: string
  sections: IQuestionSection[]
  questions: IQuestion[]
  answers: IAnswer[]
  submitted: boolean
  applicationMode: ApplicationMode
}> => {
  const {
    applicationId,
    template,
    answers: apiAnswers,
    submitted,
    applicationMode,
  } = await getTemplate({
    selectedOrganization,
    selectedChallenge,
    user,
    handleError,
  })
  const { sections, questions } = template
  if (sections.length === 0) {
    sections.push({
      id: 'unsectioned-container',
      title: 'Questions',
      titleAlt: 'الأسئلة',
      description: '',
      questions: questions.length,
    })
  }

  const answers: IAnswer[] = apiAnswers.map((answer) => ({
    ...answer,
    section: -1,
  }))

  let globalIndex = 0
  sections.forEach((section, sectionIndex) => {
    for (
      let sectionQuestion = 0;
      sectionQuestion < section.questions;
      sectionQuestion += 1
    ) {
      questions[globalIndex].section = sectionIndex
      questions[globalIndex].options = questions[globalIndex]!.options!.map(
        (option) => ({
          ...option,
          // @ts-ignore - backend is breaking the rules here...
          jumpToQuestion: parseInt(option.jumpToQuestion, 10),
        }),
      )
      answers[globalIndex].section = sectionIndex
      globalIndex += 1
    }
  })

  return {
    applicationId: applicationId as string,
    sections,
    questions,
    answers,
    submitted,
    applicationMode,
  }
}

type InitializeApplicationParams = RetrievingQuestionsParams & {
  translate: TranslateFunction
  computeCompletionPercent: (
    questions: IQuestion[],
    answers: IAnswer[],
  ) => number
  setCompletionPercent: (percent: number) => void
}
export const initializeApplicationForm = async ({
  translate,
  selectedOrganization,
  selectedChallenge,
  user,
  handleError,
  computeCompletionPercent,
  setCompletionPercent,
}: InitializeApplicationParams) => {
  const {
    applicationId,
    sections: apiSections,
    questions: apiQuestions,
    answers: apiAnswers,
    submitted,
    applicationMode,
  } = await retrieveQuestionsSectionsFromAPI({
    selectedOrganization,
    selectedChallenge,
    user,
    handleError,
  })

  /**
   * Compute completed percentage
   */
  const percentCompleted = computeCompletionPercent(apiQuestions, apiAnswers)
  setCompletionPercent(percentCompleted)

  const getInitializationLabel = () => {
    switch (applicationMode) {
      case 'FID_ORGANISATION': {
        return translate('visaApplication.orgSection') as string
      }
      case 'E-MAIL': {
        return translate('visaApplication.validateEmail') as string
      }
      case 'FID_INDIVIDUAL': {
        return translate('visaApplication.goldenTicket') as string
      }
      default: {
        throw new Error('Invalid application mode')
      }
    }
  }

  const initializationSection = {
    id: 'initializationTab',
    title: getInitializationLabel(),
    titleAlt: getInitializationLabel(),
    description: '',
    descriptionAlt: '',
    questions: 0,
    disabled: applicationMode === 'E-MAIL',
  }

  const displaySections = fakeStartup
    ? apiSections
    : [initializationSection, ...apiSections]

  return {
    applicationId,
    applicationMode,
    sections: displaySections,
    answers: apiAnswers,
    questions: apiQuestions,
    readOnly: submitted,
    loading: false,
  }
}
