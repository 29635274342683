import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import mimeDb from 'mime-db'

import { QuestionType, IQuestion } from '../../../models/application/IQuestion'
import { IAnswer } from '../../../models/application/IAnswer'
import TextInput from '../../../FormInput/TextInput'
import MultiCheckbox from '../../../FormInput/MultiCheckbox'
import FileUpload from '../../../FormInput/FileUpload'
import Dropdown from '../../../FormInput/Dropdown'
import { RootState } from '../../../../../reducers'
import { addErrorToast } from '../../../actions/toastActions'
import {
  withLocalize,
  LocalizeContextProps,
  TranslateFunction,
} from 'react-localize-redux'
import autocompleteFields from '../utils/fidPrefill/autocompleteMapping.json'
import { TextType } from "../../../FormInput/types";

interface StoreProps {
  token?: string
}

export interface QuestionBoxProps extends StoreProps, LocalizeContextProps {
  question: IQuestion
  updateAnswerSingle : (answerIndex: number, value: string, type: QuestionType) => void
  updateAnswerMultiple: (answerIndex: number, value: number[], type: QuestionType) => void
  onFileChange?: (answerIndex: number, file?: File) => void
  downloadFile?: (answerIndex: number, onFinish: () => void) => void
  questionAnswer: IAnswer
  isDisabled: boolean
  index: number
  isAltLang?: boolean
  readOnly?: boolean
}

const getLabel = (question: IQuestion, translate: TranslateFunction, isAltLang?: boolean) => {
  const questionString = isAltLang ? question.questionAlt : question.question
  if (question.mandatory) {
    return questionString
  }
  return `${questionString} (${translate('visaApplication.optional')})`
}

const getDescription = (question: IQuestion, isAltLang?: boolean) =>
  isAltLang ? question.descriptionAlt : question.description

const QuestionBox = ({
  question,
  updateAnswerSingle,
  updateAnswerMultiple,
  onFileChange,
  downloadFile,
  questionAnswer,
  isDisabled,
  index,
  translate,
  isAltLang,
  readOnly,
}: QuestionBoxProps) => {
  const {
    fileUploadLimits,
  } = useSelector((state: RootState) => state.application)
  const dispatch = useDispatch()

  const autocompleteKey = question.apiKey
    // @ts-ignore
    ? autocompleteFields[question.apiKey.keyName]
    : 'off'


  const handleFileChange = (file?: File) => {
    if (onFileChange) {
      onFileChange(index, file)
    }
  }

  const handleFileDownload = (onFinish: () => void) => {
    if (downloadFile) {
      downloadFile(index, onFinish)
    } else {
      dispatch(addErrorToast(translate('utility.fileUpload.configError') as string))
    }
  }

  const renderTypeFileUpload = () => {
    return (
      <FileUpload
        id={`${question.id}`}
        value={questionAnswer.answer || ''}
        onChange={handleFileChange}
        uploadProgress={questionAnswer.uploadProgress}
        label={getLabel(question, translate, isAltLang)}
        description={getDescription(question, isAltLang)}
        downloadFile={handleFileDownload}
        readOnly={readOnly}
        accept={
          question.fileUploadCategory &&
          fileUploadLimits[question.fileUploadCategory].mimeTypes
            .filter(type => type && mimeDb[type] && mimeDb[type].extensions)
            .map(type => mimeDb[type].extensions)
            .flat()
            .map(extension => `.${extension}`)
        }
        required={question.mandatory}
      />
    )
  }

  const handleUpdateAnswer = (
    value: string,
    id: string,
  ) => {
    updateAnswerSingle(
      index,
      value,
      question.type
    )
  }

  const handleCheckBoxUpdate = (
    newSelections: number[]
  ) => {
    updateAnswerMultiple(
      index,
      newSelections,
      question.type
    )
  }

  const renderTypeCheckbox = () => {
    return (
      <MultiCheckbox
        id={question.id!}
        value={(questionAnswer.selectedOptions) || []}
        onChange={handleCheckBoxUpdate}
        options={question.options}
        label={getLabel(question, translate, isAltLang)}
        description={getDescription(question, isAltLang)}
        type='checkbox'
        disabled={isDisabled}
        readOnly={readOnly}
        isAltLang={isAltLang}
        required={question.mandatory}
      />
    )
  }

  const renderTypeMultipleChoice = () => {
    return (
      <MultiCheckbox
        id={question.id!}
        value={(questionAnswer.selectedOptions) || []}
        onChange={handleCheckBoxUpdate}
        options={question.options}
        label={getLabel(question, translate, isAltLang)}
        description={getDescription(question, isAltLang)}
        type='radio'
        disabled={isDisabled}
        readOnly={readOnly}
        isAltLang={isAltLang}
        required={question.mandatory}
      />
    )
  }

  const handleDropdownUpdate = (newSelection: number | number[]) => {
    if (Array.isArray(newSelection)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    const selectionArray = newSelection !== -1 ? [newSelection] : []
    updateAnswerMultiple(
      index,
      selectionArray,
      question.type,
    )
  }

  // This method returns a question box with a label and a dropdown menu
  const renderTypeDropdown = () => {
    return (
      <Dropdown
        id={question.id}
        value={questionAnswer!.selectedOptions![0]}
        onChange={handleDropdownUpdate}
        options={question.options!.map(option => (isAltLang ? option.optionAlt : option.option) || '') || []}
        label={getLabel(question, translate, isAltLang)}
        description={getDescription(question, isAltLang)}
        disabled={isDisabled}
        readOnly={readOnly}
        isClearable
        required={question.mandatory}
      />
    )
  }

  const renderTextInput = (type: TextType) => {
    return (
      <TextInput
        id={question.id}
        disabled={isDisabled}
        value={questionAnswer.answer}
        label={getLabel(question, translate, isAltLang)}
        description={getDescription(question, isAltLang)}
        type={type}
        maxLength={question.maxLength}
        onChange={(value: string) => handleUpdateAnswer(value, question.id!)}
        readOnly={readOnly}
        // @ts-ignore
        autocomplete={autocompleteKey}
        required={question.mandatory}
      />
    )
  }

  const renderQuestionBox = () => {
    const TypeMapping: {[key: string]: TextType} = {
      [QuestionType.SHORT_ANSWER]: 'text',
      [QuestionType.PARAGRAPH]: 'text-area',
      [QuestionType.DATE]: 'date',
      [QuestionType.TIME]: 'time',
    }
    switch (question.type) {
      case QuestionType.MULTIPLE_CHOICE:
        return renderTypeMultipleChoice()
      case QuestionType.CHECKBOX:
        return renderTypeCheckbox()
      case QuestionType.DROPDOWN:
        return renderTypeDropdown()
      case QuestionType.FILE_UPLOAD:
        return renderTypeFileUpload()
      default:
        return renderTextInput(TypeMapping[question.type])
    }
  }

  return (
    <Fragment>
      {renderQuestionBox()}
    </Fragment>
  )
}

export default withLocalize(React.memo(QuestionBox))
