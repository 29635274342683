import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MetricDisplay from '../../../MetricDisplay/MetricDisplay'
import ProgramsIcon from '../../../../assets/icons/Program.svg'
import ApplicationsIcon from '../../../../assets/icons/Application.svg'
import ReviewedApplicationsIcon from '../../../../assets/icons/ApplicationDone.svg'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { RootState } from 'reducers/index'
import { getUserMetrics } from 'components/common/actions/userActions'
import { defaultMetrics } from "./constants";

const AdminMetrics = ({ translate }: LocalizeContextProps) => {
  const { userId, metrics } = useSelector((state: RootState) => state.user)
  const {
    programCount,
    weeklyReviewedApplicationCount,
    monthlyReviewedApplicationCount,
    assignedPendingApplicationCount,
  } = metrics || defaultMetrics
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserMetrics(userId))
  }, [dispatch, userId])

  return (
    <>
      <MetricDisplay
        to='/programs-list'
        label={translate('internalDashboard.assignedPrograms').toString()}
        value={programCount}
        imageSource={ProgramsIcon}
      />
      <MetricDisplay
        to='/applications?orderBy=desc&sortBy=status'
        label={translate(
          'internalDashboard.reviewedApplicationsWeek',
        ).toString()}
        value={weeklyReviewedApplicationCount}
        imageSource={ReviewedApplicationsIcon}
      />
      <MetricDisplay
        to='/applications?orderBy=desc&sortBy=status'
        label={translate(
          'internalDashboard.reviewedApplicationsMonth',
        ).toString()}
        value={monthlyReviewedApplicationCount}
        imageSource={ReviewedApplicationsIcon}
      />
      <MetricDisplay
        to='/applications?orderBy=desc&sortBy=status'
        label={translate(
          'internalDashboard.assignedPendingApplications',
        ).toString()}
        value={assignedPendingApplicationCount}
        imageSource={ApplicationsIcon}
      />
    </>
  )
}

export default withLocalize(AdminMetrics)
