import React from 'react'
import { IAddTabProps } from "./types";
import classNames from 'classnames'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import styles from "./AppTabBar.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const AddTab = ({onAddClick, label}: IAddTabProps) => {
  return (
    <div
      className={classNames(styles.tab, styles.tabActive)}
      onClick={onAddClick}
    >
      <div className={styles.tabBlock}>
        <h3 className={styles.tabLabel}>
          <FontAwesomeIcon style={{marginRight: 5}} icon={faPlus}/>
          {label}
        </h3>
      </div>
      <div className={classNames(styles.connectionBlock)}/>
    </div>
  )
}
