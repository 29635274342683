import React from 'react'
import styles from './ProgramDetailsFooter.module.scss'
import { useDispatch } from 'react-redux'
import Button from '../shared/Button'
import { Translate, withLocalize } from 'react-localize-redux'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import ActionBar from '../ActionBar/ActionBar'
import Toggle from '../../Toggle'
import { updateChallengeWithImages } from '../actions/challengesActions'
import { ProgramDetailsFooterProps } from "./types";

const ProgramDetailsFooter = ({
  data,
  files,
  filesToDelete,
  onSave,
  isEditMode,
  toggleEditMode,
  review,
  preventUpdate,
}: ProgramDetailsFooterProps) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleSave = async() => {
    try {
      await dispatch(updateChallengeWithImages(data, files, filesToDelete))
      if (!isEditMode) toggleEditMode()
      onSave()
    } catch (e) {
      console.error(e)
    }
  }

  const onCancel = () => {
    history.push('/programs-list')
  }

  return (
    <>
      <ActionBar>
        <div>
          <Toggle
            value={isEditMode}
            onClick={toggleEditMode}
            onLabel={<Translate id='visaApplication.edit' />}
            offLabel={<Translate id='visaApplication.view' />}
            elementWidth='14rem'
          />
        </div>
        <div>
          <Button
            type='primary'
            onClick={onCancel}
            className={styles.actionButton}
          >
            <Translate id='cancel' />
          </Button>
          <Button
            type='primary'
            disabled={preventUpdate}
            onClick={handleSave}
            className={classNames(styles.actionButton, styles.updateButton)}
          >
            <Translate id='programSettings.update' />
          </Button>
        </div>
      </ActionBar>
    </>
  )
}

export default withLocalize(ProgramDetailsFooter)
