import React from 'react'
import styles from './StatusBar.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faForward, faSearch, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons'

import Tooltip from 'rc-tooltip'
import classnames from 'classnames'
import useTranslate from 'hooks/useTranslate'
import skipIcon from '../assets/icons/skipped.svg'
import { CellProps, StatusBarProps } from "./types";

const StatusCell = ({ status }: CellProps) => {
  const translate = useTranslate()

  const getCellConfigByStatus = (stat: string) => {
    switch (stat) {
      case 'accepted':
        return { icon: faCheck, style: styles.accepted }
      case 'rejected':
        return { icon: faTimes, style: styles.rejected }
      case 'pending':
        return { icon: faSearch, style: styles.pending }
      case 'skipped':
        return { icon: faForward, style: styles.accepted }
      default:
        return { icon: null, style: '' }
    }
  }
  const { icon, style } = getCellConfigByStatus(status.status)

  // reference: https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  return (
    <Tooltip
      placement='top'
      overlay={
        <span>
          {status.name}:{' '}
          {capitalize(
            status.status === 'blank'
              ? (translate('review.notReached') as string)
              : status.status,
          )}
        </span>
      }
    >
      <div id={status.id} className={classnames(styles.statusCell, style)}>
        {icon && status.status === 'skipped' ? (
          <img src={skipIcon} className={styles.icon} alt='skipped' />
        ) : (
          <FontAwesomeIcon icon={icon as IconDefinition} />
        )}
      </div>
    </Tooltip>
  )
}

const StatusBar = ({ status }: StatusBarProps) => {
  return (
    <div className={styles.container}>
      {status.map((stat, i) => {
        return (
          <>
            {i !== 0 && <div className={styles.connectLine} />}
            <StatusCell status={stat} />
          </>
        )
      })}
    </div>
  )
}

export default StatusBar
