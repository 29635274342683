import axios from 'axios'

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
})

export const auth = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_AUTH_URL,
})

export const requestSuccess = (request: any) =>
  request && (request.status >= 200 && request.status < 400)


export const ROUTES = {
  USERS: '/users',
  USER_ROLES: '/userRoles',
  PROGRAM_OWNERS: '/programOwners',
  PROGRAM_MANAGERS: '/programManagers',
  CHALLENGES: '/challenges',
  EXPORT_CHALLENGES_CSV: '/export/challenges/csv',
  STARTUPS: '/startups',
}
