import React from 'react'
import styles from './HeaderLogo.module.scss'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import LogoImage from 'assets/images/logos/dff_black.svg'
import { Translate, withLocalize } from 'react-localize-redux'
import classNames from 'classnames'
import useColorScheme from 'components/common/utils/useColorScheme'
import { HeaderLogoProps } from "./types";

const HeaderLogo = ({ isOpen, isNavbarScrolledDown, activeLanguage }: HeaderLogoProps) => {
  const isRtl = activeLanguage?.code === 'ar'

  const colorScheme = useColorScheme()

  return (
    <div
      className={classNames(styles.logoWrapper, {
        [styles.scrolledDown]: isNavbarScrolledDown,
        [styles.rtl]: isRtl,
        [styles.menuOpen]: isOpen,
      })}
    >
      <a href={process.env.REACT_APP_PROGRAMS_URL} aria-label="Dubai Future Foundation Website">
        <img
          alt=''
          className={classNames(styles.logo, {
            [styles.scrolledDown]: isNavbarScrolledDown,
            [styles.dark]: colorScheme === 'dark',
          })}
          src={LogoImage}
        />
      </a>
      <a href={process.env.REACT_APP_PROGRAMS_URL}>
        <ArrowRightAltIcon />
        <span
          className={classNames(styles.returnLabel, {
            [styles.scrolledDown]: isNavbarScrolledDown,
          })}
        >
          <Translate id='header.returnToSite' />
        </span>
      </a>
    </div>
  )
}

export default withLocalize(HeaderLogo)
