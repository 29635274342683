import React from 'react'
import style from './ModalSelect.module.scss'
import Dropdown from 'components/common/FormInput/Dropdown'
import { IModalSelectProps } from "./types";

const ModalSelect = ({
  label,
  value,
  options,
  onChange,
  allowBlank,
  blankLabel,
  disabled,
}: IModalSelectProps) => {
  const handleChange = (newSelection: number | number[]) => {
    if (Array.isArray(newSelection)) {
      throw new Error('multi value option in single value context')
    }
    onChange(newSelection !== -1 ? options[newSelection].value : '')
  }

  return (
    <Dropdown
      id='modal-dropdown'
      className={style.dropdown}
      label={label}
      value={options.findIndex((option) => option.value === value)}
      options={options.map((option) => option.label)}
      onChange={handleChange}
      readOnly={disabled}
      isClearable={allowBlank}
      showValidation={false}
      noPortal
    />
  )
}

export default ModalSelect
