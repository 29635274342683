import React from 'react'
import styles from './ViewChallengeSplashInfo.module.scss'
import { HeaderContext } from 'components/common/Header/HeaderContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
import { Translate } from 'react-localize-redux'
import { ViewChallengeSplashInfoProps } from "./types";

const ViewChallengeSplashInfo = ({ belowFoldRef }: ViewChallengeSplashInfoProps) => {
  const { isSmall } = React.useContext(HeaderContext)
  const children = (
    <div
      onClick={() => {
        if (belowFoldRef && belowFoldRef.current) {
          // @ts-ignore I just checked this.
          belowFoldRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }}
      className={styles.showMoreButton}
    >
      <button className={styles.showMoreCircle} aria-labelledby='show-more-label'>
        <FontAwesomeIcon icon={faLongArrowAltDown} className='fa' />
      </button>
      <span className={styles.showMoreText} id='show-more-label'>
        <Translate id='challenge.learnMore' />
      </span>
    </div>
  )

  return isSmall ? (
    children
  ) : (
    <div className={styles.splashFiller}>{children}</div>
  )
}

export default ViewChallengeSplashInfo
