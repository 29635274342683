import React from 'react'
import Toast from './Toast'
import styles from './Toasts.module.scss'

import warningIcon from '../assets/icons/warning.svg'
import checked from '../assets/icons/checked.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  ACTIONS,
} from '../actions/toastActions'
import { ICommonState } from '../utils/state'
import { IToastStored } from "../reducers/types";

const Toasts = (props: ToastProps) => {
  const dispatch = useDispatch()
  const { errorToasts, successToasts } = useSelector(
    (state: ICommonState) => state.toasts,
  )
  const removeToastById = ({ id }: IToastStored, errorType: 'success' | 'error') => {
    const actionName = errorType === 'success' ? ACTIONS.REMOVE_SUCCESS_TOAST : ACTIONS.REMOVE_ERROR_TOAST
    dispatch({
      type: actionName,
      payload: id,
    })
  }


  return (
    <div
      className={styles.ToastsContainer}
      aria-live='polite'
    >
      {errorToasts?.map((toast: IToastStored, index: number) => {
          !toast.buttonText && setTimeout(() => removeToastById(toast, 'error'), 7000)
          return (
            <Toast
              key={index}
              id={toast.id}
              message={toast.message}
              onToastClick={() =>
                dispatch({
                  type: ACTIONS.REMOVE_ERROR_TOAST,
                  payload: toast.id,
                })}
              buttonText={toast.buttonText}
              buttonHandler={toast.buttonHandler}
              button2Text={toast.button2Text}
              button2Handler={toast.button2Handler}
              icon={warningIcon}
              backgroundColor={props.backgroundColor || ''}
              textColor={props.textColor || ''}
              alert
            />
          )
        })}
      {successToasts.map((toast: IToastStored, index: number) => {
        !toast.buttonText && setTimeout(() => removeToastById(toast, 'success'), 5000)
        return (
          <Toast
            key={index}
            id={toast.id}
            message={toast.message}
            onToastClick={() => removeToastById(toast, 'success')}
            buttonText={toast.buttonText}
            buttonHandler={toast.buttonHandler}
            button2Text={toast.button2Text}
            button2Handler={toast.button2Handler}
            icon={checked}
            backgroundColor={props.backgroundColor || ''}
            textColor={props.textColor || ''}
          />
        )
      })}
    </div>
  )
}

interface ToastProps {
  backgroundColor?: string
  textColor?: string
}

export default Toasts
