import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import ImageUpload from '../../ImageUpload/ImageUpload'
import styles from './ImageUploadWithPreview.module.scss'
import DeleteIcon from '../assets/icons/close.svg'
import ActionIcon from '../Table/ActionIcon'
import CloseButton from '../CloseButton/CloseButton'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { ImageUploadWithPreviewProps } from "./types";

const ImageUploadWithPreview = ({
  id,
  links,
  isMulti,
  onLoad,
  onDelete,
  className,
  images,
  setImages,
}: ImageUploadWithPreviewProps) => {
  const [newImages, setNewImages] = useState<any>([])

  useEffect(() => {
    if (links) {
      setImages(isMulti ? links : [links])
      setNewImages([])
    }
  }, [links, isMulti, setImages])

  const getPreviewImage = (file: File) => {
    const reader = new FileReader() // eslint-disable-line
    reader.onload = (e) => {
      const res = e?.target?.result
      if (!isMulti) {
        setImages([res])
      } else {
        setImages((img: any) => (img || []).concat(res))
        setNewImages((img: any) => (img || []).concat(res))
      }
      onLoad(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDelete = (e: any, src: string) => {
    e.preventDefault()
    e.stopPropagation()
    setImages((oldImages: string[]) => oldImages.filter((img) => img !== src))
    setNewImages((oldImages: string[]) =>
      oldImages.filter((img) => img !== src),
    )
    onDelete && onDelete(
      src,
      src?.includes('data:image'),
      newImages.findIndex((i: any) => i === src),
    )
  }

  return (
    <ImageUpload
      id={id}
      className={classnames(styles.root, className)}
      onUpdateImage={getPreviewImage}
      accept='image/*'
    >
      <div
        className={classnames(styles.imagesContainer, {
          [styles.scrollable]: isMulti && images.length > 1,
        })}
      >
        {!images.length && (
          <ActionIcon
            id='settings-icon'
            icon={faCloudUploadAlt}
            tooltip='challenge.gateSettings'
            onClick={() => {}}
          />
        )}
        {images.map((src: string) => (
          <div
            className={styles.imageContainer}
            key={src}
            style={{ backgroundImage: `url(${src})` }}
          >
            {!!onDelete && (
              <CloseButton
                className={styles.deleteButton}
                onClick={(e) => handleDelete(e, src)}
              >
                <img src={DeleteIcon} alt='delete' />
              </CloseButton>
            )}
          </div>
        ))}
      </div>
    </ImageUpload>
  )
}

export default ImageUploadWithPreview
