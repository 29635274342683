import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IChallenge } from '../../models/challenge/challenge';
import Slide from '../common/Slider/Slide';
import Slider from '../common/Slider/Slider';
import styles from './About.module.scss';
import AboutSection1 from './Section1/AboutSection1';
import AboutSection2 from './Section2/AboutSection2';
import AboutSection3 from './Section3/AboutSection3';

const About = () => {
  const recomputeSize = () => {
    const isMobileAfterResize = window.innerWidth <= 768;
    if (isMobile !== isMobileAfterResize) {
      setIsMobile(isMobileAfterResize);
    }
  };
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', recomputeSize);
    recomputeSize();
    return () => {
      window.removeEventListener('resize', recomputeSize);
    };
  });
  return (
    <>
      {isMobile ? (
        <div className={styles.content}>
          <AboutSection1 />
          <AboutSection2 />
          <AboutSection3 />
        </div>
      ) : (
        <Slider
          initialSlide={0}
          vertical
          speed={800}
          dots
          arrows={false}
          inPage
          swipe
        >
          <Slide>
            <AboutSection1 />
          </Slide>
          <Slide>
            <AboutSection2 />
          </Slide>
          <Slide>
            <AboutSection3 />
          </Slide>
        </Slider>
      )}
    </>
  );
};

interface Props {
  initialSlide: number;
  rightSlideComponent: any;
  rightSlidePath: string;
  push: Function;
  challenges: IChallenge[];
}

const mapStateToProps = (state: any) => ({
  challenges: state.challenges.challenges,
});

export default connect(
  mapStateToProps,
  { push },
)(About);
