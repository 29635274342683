import React from 'react'
import styles from './MultiCheckbox.module.scss'
import Fieldset from './Fieldset'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import { MultiCheckboxProps } from "./types";

const MultiCheckbox = (props: MultiCheckboxProps) => {
  const {
    options,
    disabled,
    type,
    id,
    value,
    isAltLang,
    readOnly,
    required,
  } = props

  const handleCheckboxUpdate = (changedIndex: number) => () => {
    if (value.includes(changedIndex)) {
      props.onChange(value.filter(selection => selection !== changedIndex))
    } else {
      props.onChange([
        ...value,
        changedIndex,
      ])
    }
  }

  const handleRadioUpdate = (changedIndex: number) => () => {
    props.onChange([changedIndex])
  }

  if (options) {
    return (
      <Fieldset isValid={value.length > 0} {...props}>
        <>
          {options.map((option, optionIndex) => {
            const optionId = `${id}-${optionIndex}`

            return (
              <React.Fragment key={optionId}>
                <div className={styles.wrapper}>
                  <input
                    onChange={type === 'checkbox' ? handleCheckboxUpdate(optionIndex) : handleRadioUpdate(optionIndex)}
                    type={type}
                    id={optionId}
                    checked={value.includes(optionIndex)}
                    disabled={disabled || readOnly}
                    className={styles.input}
                  />
                  <label
                    className={classNames(styles.label, {
                      [styles.error]: required && value.length === 0,
                    })}
                    htmlFor={optionId}
                  >
                    {isAltLang ? option.optionAlt : option.option}
                  </label>
                </div>
              </React.Fragment>
            )
          })}
          {required && value.length === 0 ? (
            <div className={styles.errorMessage}>
              <Translate id='register.form.error.reqField' />
            </div>
          ) : (
            <div className={styles.errorPlaceholder} />
          )}
        </>
      </Fieldset>
    )
  }
  return <div />
}

export default MultiCheckbox
