import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'components/common/FormInput/Dropdown'
import Button from 'components/common/shared/Button'
import { Translate, withLocalize } from 'react-localize-redux'
import style from './ScoringTool.module.scss'
import {
  forceApprove,
  forceReject,
  forceStage,
  reassign,
} from 'components/common/actions/programManagerActions'
import { fetchChallengeStages } from 'components/common/actions/challengesActions'
import { ICommonState } from 'components/common/utils/state'
import DisplayValue from 'components/common/DisplayValue/DisplayValue'
import { addErrorToast } from 'components/common/actions/toastActions'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { cancelApplication } from 'components/common/actions/userActions'
import { AdminTabProps } from "./types";

const AdminTab = ({ stage, translate }: AdminTabProps) => {
  const [reassignIndex, setReassignIndex] = useState(-1)
  const [forceTooltipVisible, setForceTooltipVisible] = useState(false)
  const [reassignTooltipVisible, setReassignTooltipVisible] = useState(false)
  const [confirmCancelVisible, setConfirmCancelVisible] = useState(false)
  const [
    rejectOrApproveTooltipVisible,
    setRejectOrApproveTooltipVisible,
  ] = useState(false)
  const [selectedStageIndex, setSelectedStageIndex] = useState(-1)
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(-1)

  const { programManagers } = stage

  const {
    v2ReviewApplication,
    reassignPending,
    forceStagePending,
    forceApprovePending,
    forceRejectPending,
  } = useSelector((state: ICommonState) => state.review)
  const {
    challenge,
    id: applicationId,
    metaData,
    // @ts-ignore
  } = v2ReviewApplication.application

  const getStageId = () => {
    if (!v2ReviewApplication || !v2ReviewApplication.reviewedApplications[0]) {
      return undefined
    }
    const firstReviewedApplication = v2ReviewApplication.reviewedApplications[0]
    if (!firstReviewedApplication.stage) {
      return undefined
    }
    const { id } = firstReviewedApplication.stage
    return id
  }

  const stageId = getStageId()

  const stages = useSelector(
    (state: ICommonState) => state.challenges.challengeStages[challenge.id],
  )
  const currentStageIndex = stages
    ? stages.findIndex((stage) => stage.id === stageId)
    : -1
  const subsequentStages = stages
    ? stages.filter((stage, index) => index > currentStageIndex)
    : []

  const isRejected = metaData.status === 'rejected'
  const isFinal = metaData.status === 'final'

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchChallengeStages(challenge.id))
  }, [dispatch, challenge.id])

  const reassignOptions = programManagers.map((manager) => manager.displayName)

  const handleForceApprove = () => {
    if (stageId) {
      dispatch(
        forceStage(
          challenge.id,
          applicationId,
          stageId,
          subsequentStages[selectedStageIndex].id,
        ),
      )
      setSelectedStageIndex(-1)
      setForceTooltipVisible(false)
    } else {
      dispatch(addErrorToast(translate('utility.genericError').toString()))
    }
  }

  const handleForceReject = () => {
    setSelectedStageIndex(-1)
    setForceTooltipVisible(false)
  }

  const handleReassignApprove = () => {
    const selectedReview = v2ReviewApplication && v2ReviewApplication.reviewedApplications[selectedReviewIndex]
    if (stageId && selectedReview) {
      dispatch(
        reassign(
          challenge.id,
          stageId,
          selectedReview.id,
          applicationId,
          programManagers[reassignIndex].id,
        ),
      )
      setReassignTooltipVisible(false)
    } else {
      dispatch(addErrorToast(translate('utility.genericError').toString()))
    }
  }

  const handleReassignReject = () => {
    setReassignIndex(-1)
    setReassignTooltipVisible(false)
  }

  const handleRejectOrApprove = () => {
    if (stageId) {
      if (isRejected) {
        dispatch(forceApprove(challenge.id, stageId, applicationId))
      } else {
        dispatch(forceReject(challenge.id, stageId, applicationId))
      }
      setRejectOrApproveTooltipVisible(false)
    } else {
      dispatch(addErrorToast(translate('utility.genericError').toString()))
    }
  }

  const handleRejectOrApproveReject = () => {
    setRejectOrApproveTooltipVisible(false)
  }

  const handleReassignChange = (optionIndex: number | number[]) => {
    if (Array.isArray(optionIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    setReassignIndex(optionIndex)
    setReassignTooltipVisible(true)
  }

  const toggleRejectOrApproveTooltip = () => {
    setRejectOrApproveTooltipVisible(!rejectOrApproveTooltipVisible)
  }

  const showForceTooltip = (index: number | number[]) => {
    if (Array.isArray(index)) {
      throw new Error('Multi-value used in single value context')
    }
    setSelectedStageIndex(index)
    setForceTooltipVisible(true)
  }

  const handleReviewChange = (optionIndex: number | number[]) => {
    if (Array.isArray(optionIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    if (v2ReviewApplication!.reviewedApplications![optionIndex]!.scoring === undefined) {
      setSelectedReviewIndex(optionIndex)
    }
  }

  return (
    <>
      <DisplayValue
        label='Reviewers'
        value={
          v2ReviewApplication &&
          v2ReviewApplication.application.metaData.reviewerNames.join(', ')
        }
      />
      <Dropdown
        id='force-stage'
        showValidation={false}
        label={translate('review.forceStage') as string}
        value={selectedStageIndex}
        options={
          (subsequentStages && subsequentStages.map((stage) => stage.name)) ||
          []
        }
        onChange={showForceTooltip}
        readOnly={forceStagePending || isFinal}
      />
      {forceTooltipVisible && (
        <NotificationModal
          header={translate('review.forceStage')}
          onCancel={handleForceReject}
          onAccept={handleForceApprove}
        >
          <Translate id='review.forceQuestion' />
        </NotificationModal>
      )}
      <div>
        {reassignOptions.length > 1 && (
          <>
            <Dropdown
              id='reassign'
              showValidation={false}
              label={translate('review.selectReassignReview') as string}
              value={selectedReviewIndex}
              options={v2ReviewApplication
                ? v2ReviewApplication.reviewedApplications.map((ra => {
                  const name = ra.programManager && ra.programManager.displayName
                    ? ra.programManager.displayName
                    : translate('review.unassigned').toString()
                  if (ra.scoring != null) {
                    return `${name} (${translate('review.alreadyReviewed')})`
                  }
                  return name
                }))
                : []}
              onChange={handleReviewChange}
            />
            <Dropdown
              id='reassign-target'
              className={style.reassignTarget}
              showValidation={false}
              label={translate('review.reassign') as string}
              value={reassignIndex}
              onChange={handleReassignChange}
              options={reassignOptions}
              readOnly={reassignPending || isFinal || selectedReviewIndex < 0}
            />
            {reassignTooltipVisible && (
              <NotificationModal
                onAccept={handleReassignApprove}
                onCancel={handleReassignReject}
                header={translate('review.reassign')}
              >
                <Translate id='review.reassignQuestion' />
              </NotificationModal>
            )}
          </>
        )}
      </div>
      <div>
        <Button
          type='solid'
          onClick={toggleRejectOrApproveTooltip}
          className={style.button}
          disabled={
            isFinal || (isRejected ? forceApprovePending : forceRejectPending)
          }
        >
          <Translate id={isRejected ? 'utility.approve' : 'utility.reject'} />
        </Button>
        <Button
          type='solid'
          onClick={() => {
            setConfirmCancelVisible(true)
          }}
          className={style.button}
          disabled={isFinal}
        >
          <Translate id='review.archive.button' />
        </Button>
      </div>
      {rejectOrApproveTooltipVisible && (
        <NotificationModal
          onAccept={handleRejectOrApprove}
          onCancel={handleRejectOrApproveReject}
          header={translate(isRejected
            ? 'utility.approve'
            : 'utility.reject',
          )}
        >
          <Translate
            id={isRejected
              ? 'review.forceApproveQuestion'
              : 'review.forceRejectQuestion'}
          />
        </NotificationModal>
      )}
      {confirmCancelVisible && (
        <NotificationModal
          header={<Translate id='dashboard.cancel.confirmHeader' />}
          onCancel={() => setConfirmCancelVisible(false)}
          onAccept={() => {
            dispatch(cancelApplication(applicationId, () => setConfirmCancelVisible(false)))
          }}
        >
          <Translate id='dashboard.cancel.confirmContent' />
        </NotificationModal>
      )}
    </>
  )
}

export default withLocalize(AdminTab)
