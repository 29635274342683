import { Action } from 'redux';
import { ACTIONS } from '../actions/challengesActions'
import { IChallenge } from 'models/challenge/challenge'
import { AUTH_ACTIONS } from "../actions/authActions";
import { ChallengesState, IChallengeMap, Reducer } from "./types";
import { createReducer } from "./createReducer";

const defaultState: ChallengesState = {
  loading: false,
  challengesDeprecated: null,
  challengeDeprecated: null,
  challenges: {},
  relatedChallenges: {},
  challengeStages: {},
}

const reducer: Reducer<ChallengesState, Action> = {
  [ACTIONS.LOADING_ALL_CHALLENGES]: (state, {payload}) => ({...state, loading: payload}),
  [ACTIONS.FETCH_ALL_CHALLENGES_SUCCESS_DEPRECATED]: (state, {payload}) => ({
    ...state,
    challengesDeprecated: payload
  }),
  [ACTIONS.FETCH_ALL_CHALLENGES_SUCCESS]: (state, {payload}) => {
    const challenges = payload.reduce(
      (challengesMap: IChallengeMap, challenge: IChallenge) => ({
        ...challengesMap,
        [challenge.id]: challenge,
      }), {} as IChallengeMap,
    )
    return {...state, challenges}
  },
  [ACTIONS.FETCH_CHALLENGE_SUCCESS_DEPRECATED]: (state, {payload}) => ({...state, challengeDeprecated: payload}),
  [ACTIONS.FETCH_CHALLENGE_SUCCESS]: (state, {payload}) => ({
    ...state,
    challenges: {
      ...state.challenges,
      [payload.id]: payload,
    },
  }),
  [ACTIONS.FETCH_RELATED_CHALLENGES_SUCCESS]: (state, {payload}) => ({
    ...state,
    relatedChallenges: {
      ...state.relatedChallenges,
      [payload.id]: payload.challenges,
    },
  }),
  [ACTIONS.SET_CHALLENGE_STAGES]: (state, {payload: {challengeId, stages}}) => ({
    ...state,
    challengeStages: {
      ...state.challengeStages,
      [challengeId]: stages,
    },
  }),
  [AUTH_ACTIONS.LOGOUT]: () => defaultState
}

export default createReducer<ChallengesState, Action>(reducer, defaultState);
