import React from 'react'
import mainStyles from '../Main/Main.module.scss'
import { Translate } from 'react-localize-redux'
import { LandingProps } from "./types";

const LandingSimple = ({ headingKey, descriptionKey }: LandingProps) => {
  return (
    <div className={mainStyles.container}>
      <div className={mainStyles.headingContainer}>
        <h1 className={mainStyles.heading}>
          <Translate
            id={headingKey}
            options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
          />
        </h1>
      </div>
      {descriptionKey && (
        <div className={mainStyles.descriptionContainer}>
          <p className={mainStyles.description}>
            <Translate
              id={descriptionKey}
              options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
            />
          </p>
        </div>
      )}
    </div>
  )
}

export default LandingSimple
