import styles from './EditFormPanel.module.scss'
import React from 'react'
import { IQuestion } from '../../models/application/IQuestion'
import EditSectionDetails from './Section/EditSectionDetails'
import EditQuestion from './Section/Question/EditQuestion'
import Button from '../../shared/Button'
import { Translate } from 'react-localize-redux'

interface Props {
  questions: IQuestion[]
  sectionNumber: number
  sectionDescription?: string
  sectionTitle?: string
  possibleSectionScore: number
  setSectionField: (field: string, newValue: string) => void
  onQuestionChange: (updatedQuestions: IQuestion[]) => void
  addQuestion: (sectionNumber: number) => void
  removeQuestion: (indexOfQuestionToRemove: number) => void
  isAltLang?: boolean
}

const EditFormPanel = (props: Props) => {
  const handleAddQuestion = (event: React.MouseEvent<HTMLElement>) => {
    props.addQuestion(props.sectionNumber)
  }

  const sectionQuestions = props.questions
    .filter(question => question.section === props.sectionNumber)

  const handleQuestionChange = (questionIndex: number) => (updatedQuestion: IQuestion) => {
    const updatedQuestions = props.questions.map((question, index) => {
      if (index === questionIndex) {
        return updatedQuestion
      }
      return question
    })

    props.onQuestionChange(updatedQuestions)
  }

  return (
    <>
      <EditSectionDetails
        sectionDescription={props.sectionDescription}
        sectionTitle={props.sectionTitle}
        possibleScore={props.possibleSectionScore}
        updateSectionField={props.setSectionField}
        numberOfQuestions={sectionQuestions.length}
        isAltLang={props.isAltLang}
      />
      {props.questions.map((question, questionIndex) => {
        if (question.section === props.sectionNumber) {
          return (
            <EditQuestion
              key={question.id}
              question={question}
              updatedQuestions={props.questions}
              setUpdatedQuestion={handleQuestionChange(questionIndex)}
              questionIndex={questionIndex}
              removeQuestion={props.removeQuestion}
              isAltLang={props.isAltLang}
            />
          )
        }
        return null
      })}
      <div className={styles.buttonContainer}>
        <Button type='primary'
          className={styles.addQuestionButton}
          onClick={handleAddQuestion}
        >
          <Translate id='visaApplication.newQuestion' />
        </Button>
      </div>
    </>
  )
}

export default EditFormPanel
