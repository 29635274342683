/* global FormData */
import {
  IFacilitator,
  IFacilitatorResponse,
} from 'models/facilitator/facilitator'
import { api, requestSuccess } from 'api'
import { handleError } from 'components/common/actions/errorHandlerActions'
import { addSuccessToast } from './toastActions'
import { AppThunk } from "./types";

export const ACTIONS = {
  FETCH_FACILITATOR_START: '@dff_common_action/fetch_facilitator_start',
  FETCH_FACILITATOR_SUCCESS: '@dff_common_action/fetch_facilitator_success',
  FETCH_FACILITATORS_QUERY_START: '@dff_common_action/fetch_facilitator_query_start',
  FETCH_FACILITATORS_QUERY_SUCCESS: '@dff_common_action/fetch_facilitator_query_success',
  SET_FACILITATOR_LOGO_SUCCESS: '@dff_common_action/set_facilitator_logo_success',
  UPDATE_FACILITATOR_START: '@dff_common_action/update_facilitator_start',
  UPDATE_FACILITATOR_SUCCESS: '@dff_common_action/update_facilitator_success',
}


type IFetchFacilitatorStartAction = {
  type: typeof ACTIONS.FETCH_FACILITATOR_START
  payload: string
}

const fetchFacilitatorStart = (id: string) => ({
  type: ACTIONS.FETCH_FACILITATOR_START,
  payload: id,
})

type IFetchFacilitatorSuccessAction = {
  type: typeof ACTIONS.FETCH_FACILITATOR_SUCCESS
  payload: IFacilitator
}

const fetchFacilitatorSuccess = (facilitator: IFacilitator) => ({
  type: ACTIONS.FETCH_FACILITATOR_SUCCESS,
  payload: facilitator,
})

type IFetchFacilitatorQueryStartAction = {
  type: typeof ACTIONS.FETCH_FACILITATORS_QUERY_START
  payload: string
}

const fetchFacilitatorsQueryStart = (query?: string) => ({
  type: ACTIONS.FETCH_FACILITATORS_QUERY_START,
  payload: query,
})

type IFetchFacilitatorQuerySuccessAction = {
  type: typeof ACTIONS.FETCH_FACILITATORS_QUERY_SUCCESS
  payload: IFacilitatorResponse
}

const fetchFacilitatorsQuerySuccess = (response: IFacilitatorResponse) => ({
  type: ACTIONS.FETCH_FACILITATORS_QUERY_SUCCESS,
  payload: response,
})

type ISetFacilitatorLogoSuccessAction = {
  type: typeof ACTIONS.SET_FACILITATOR_LOGO_SUCCESS
  payload: {
    id: string
    url: string
  }
}

const setFacilitatorLogoSuccess = (id: string, url: string) => ({
  type: ACTIONS.SET_FACILITATOR_LOGO_SUCCESS,
  payload: {
    id,
    url,
  },
})

type IUpdateFacilitatorStartAction = {
  type: typeof ACTIONS.UPDATE_FACILITATOR_START
  payload: string
}

const updateFacilitatorStart = (id: string) => ({
  type: ACTIONS.UPDATE_FACILITATOR_START,
  payload: id,
})

type IUpdateFacilitatorSuccessAction = {
  type: typeof ACTIONS.UPDATE_FACILITATOR_SUCCESS
  payload: IFacilitator
}

const updateFacilitatorSuccess = (facilitator: IFacilitator) => ({
  type: ACTIONS.UPDATE_FACILITATOR_SUCCESS,
  payload: facilitator,
})

export type IFacilitatorAction =
  | IFetchFacilitatorStartAction
  | IFetchFacilitatorSuccessAction
  | IFetchFacilitatorQueryStartAction
  | IFetchFacilitatorQuerySuccessAction
  | ISetFacilitatorLogoSuccessAction
  | IUpdateFacilitatorStartAction
  | IUpdateFacilitatorSuccessAction

export const fetchFacilitators = (query?: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchFacilitatorsQueryStart(query))
    const response = await api.get(`v3/programOwners/${query || ''}`)

    if (!requestSuccess(response)) {
      throw new Error('Could not fetch facilitators')
    }

    dispatch(fetchFacilitatorsQuerySuccess(response.data))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchFacilitator = (id: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch(fetchFacilitatorStart(id))
    const response = await api.get(`programOwners/${id}`)

    if (!requestSuccess(response)) {
      throw new Error('Could not fetch facilitators')
    }

    dispatch(fetchFacilitatorSuccess(response.data))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

interface ISetFacilitatorLogo {
  id: string
  image?: File
  onUploadProgress: (progressEvent: ProgressEvent) => void
}

export const setFacilitatorLogo = ({
  id,
  image,
  onUploadProgress,
}: ISetFacilitatorLogo): AppThunk => async(dispatch, getState) => {
  try {
    if (image) {
      const formData = new FormData()
      formData.append('logo', image)

      const response = await api.post(`/programOwners/${id}/logo`, formData, {
        onUploadProgress,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (!requestSuccess(response)) {
        throw new Error('Failed to set logo of facilitator.')
      }

      dispatch(setFacilitatorLogoSuccess(id, response.data.logo))
    } else {
      throw new Error('No image')
    }
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateFacilitator = (facilitator: IFacilitator): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch(updateFacilitatorStart(facilitator.id))
    const response = await api.patch(
      `/programOwners/${facilitator.id}`,
      facilitator,
    )

    if (!requestSuccess(response)) {
      throw new Error('Error while saving facilitator')
    }

    dispatch(updateFacilitatorSuccess(response.data))
    dispatch(
      addSuccessToast({
        message: 'Changes Saved',
      }),
    )
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}
