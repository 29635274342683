/* global FormData */
import { IUser, markTNCAccepted } from './authActions'
import { IMetrics } from '../utils/state'
import { api, requestSuccess } from '../../../api'
import { handleError } from './errorHandlerActions'
import { addSuccessToast } from './toastActions'
import { AppThunk } from "./types";

export const ACTIONS = {
  SET_USER: '@dff_common_action/set_user',
  SET_USER_ID: '@dff_common_action/set_user_id',
  SHOW_TNC_POPUP: '@dff_common_action/show_tnc_popup',
  RESET_USER: '@dff_common_action/reset_user',
  LOADED_USER_REVIEWS: '@dff_common_action/loaded_user_reviews',
  CANCELLED_APPLICATION: '@dff_common_action/cancelled_application',
  SET_USER_CHALLENGES: '@dff_common_action/set_user_challenges',
  SET_USER_METRICS: '@dff_common_action/set_user_metrics',
}

export const USER_UPDATED = '@dff_common_action/user_updated'
export const CLEAR_USER_OWNER_STARTUP =
  '@dff_common_action/clear_user_owner_startup'
export const FETCH_USER_OWNER_STARTUP_SUCCESS =
  '@dff_common_action/fetch_user_owner_startup_success'
export const FETCH_USER_MEMBER_STARTUPS_SUCCESS =
  '@dff_common_action/fetch_user_member_startups_success'
export const LOADING_MEMBER_STARTUPS =
  '@dff_common_action/loading_member_startups'
export const LOADING_PERSONAL_PROFILE =
  '@dff_common_action/loading_personal_profile'
export const FETCH_PERSONAL_PROFILE_SUCCESS =
  '@dff_common_action/fetch_personal_profile_success'

export const setUserMetrics = (metrics: IMetrics) => ({
  type: ACTIONS.SET_USER_METRICS,
  payload: metrics,
})

export const getUserMetrics = (userId: string): AppThunk => async(dispatch) => {
  try {
    const { data } = await api.get(`/v3/users/${userId}/metrics`)
    dispatch(setUserMetrics(data))
  } catch (e) {
    handleError(e)
  }
}

/*
  Action just for accepting TNC
*/
export const acceptTNC = (userId: string): AppThunk => async(
  dispatch,
) => {
  try {
    await api.post(`/users/${userId}/acceptTnC`)
    dispatch({ type: ACTIONS.SHOW_TNC_POPUP, payload: false })
    markTNCAccepted(true)
  } catch (e) {
    handleError(e)
  }
}

interface IFetchUserChallengesProps {
  userId: string
  query?: string
}

export const fetchUserChallenges = ({
  userId,
  query,
}: IFetchUserChallengesProps): AppThunk => async(
  dispatch,
  getState,
) => {
  // preapre the query params
  // default behaviour is to sort by application deadline in descending order
  // so if "sortBy" key is not found, set to this defeault behaviour
  const queryObject = new URLSearchParams(query)
  if (!queryObject.get('sortBy')) {
    queryObject.set('sortBy', 'applicationDeadline')
    queryObject.set('orderBy', 'desc')
  }

  try {
    const { data } = await api.get(
      // sort of
      `/v3/users/${userId}/programs/?${queryObject.toString()}`,
      {
        headers: {
          'Accept-Language': getState().localize.languages.filter(
            (v: any) => v.active,
          )[0].code,
        },
      },
    )
    dispatch(setUserChallenges(data))
  } catch (e) {
    handleError(e)
  }
}

interface IUserChallengesResponse {
  data: any[]
  count: number
}

export interface SetUserChallengesAction {
  payload: IUserChallengesResponse
  type: typeof ACTIONS.SET_USER_CHALLENGES
}

export const setUserChallenges = (
  data: IUserChallengesResponse,
): SetUserChallengesAction => {
  return {
    payload: data,
    type: ACTIONS.SET_USER_CHALLENGES,
  }
}

export const setNewUser = (user: IUser): AppThunk => async(
  dispatch,
) => dispatch({ type: ACTIONS.SET_USER, payload: user })

export const fetchUserReviews = (userId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const { data } = await api.get(`/v3/users/${userId}/reviews`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    dispatch({ type: ACTIONS.LOADED_USER_REVIEWS, payload:  data })
  } catch (e) {
    handleError(e)
  }
}

export const cancelApplication = (applicationId: string, onSuccess: () => void): AppThunk => async(
  dispatch,
) => {
  try {
    await api.post(`/v3/applications/${applicationId}/cancel`, {})
    console.log(`removing application ${applicationId}`)
    dispatch({
      type: ACTIONS.CANCELLED_APPLICATION,
      payload: applicationId,
    })
    onSuccess()
    dispatch(addSuccessToast('Application Cancelled'))
  } catch (e) {
    handleError(e)
  }
}


export const updateUser = (id: string, body: object): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const res = await api.patch(`/users/${id}`, body, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to update user.')
    }
    dispatch({ type: USER_UPDATED, payload: res.data })
    // dispatch(addSuccessToast('Successfully updated user.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const uploadUserImage = (userId: string, image: File): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const formData = new FormData()
    formData.append('profilePicture', image)
    const { data } = await api.post(`/users/${userId}/picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    dispatch({ type: USER_UPDATED, payload: data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deactivateUser = ({ id }: any): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const res = await api.patch(
      `/users/${id}/deactivate`,
      {},
      {
        headers: {
          'Accept-Language': getState().localize.languages.filter(
            (v: any) => v.active,
          )[0].code,
        },
      },
    )
    if (!requestSuccess(res)) {
      throw new Error('Failed to deactivate user.')
    }
    dispatch({ type: USER_UPDATED, payload: res.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchUsersOwnedStartups = (userId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const { data } = await api.get(`/users/${userId}/startups/owner`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    dispatch({ type: FETCH_USER_OWNER_STARTUP_SUCCESS, payload: data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchUsersMemberStartups = (userId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: true })
    const { data } = await api.get(`/users/${userId}/startups/member`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: false })
    dispatch({ type: FETCH_USER_MEMBER_STARTUPS_SUCCESS, payload: data })
  } catch (e) {
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchPersonalProfile = (id: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOADING_PERSONAL_PROFILE, payload: true })
    const res = await api.get(`/users/${id}`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch Personal/User profile')
    }
    dispatch({ type: LOADING_PERSONAL_PROFILE, payload: false })
    dispatch({ type: FETCH_PERSONAL_PROFILE_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: LOADING_PERSONAL_PROFILE, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchUsersStartups = (userId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: true })
    const resMember = await api.get(`/users/${userId}/startups/member`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    const resOwner = await api.get(`/users/${userId}/startups/owner`, {
      headers: {
        'Accept-Language': getState().localize.languages.filter(
          (v: any) => v.active,
        )[0].code,
      },
    })
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: false })
    dispatch({ type: FETCH_USER_MEMBER_STARTUPS_SUCCESS, payload: resMember.data })
    dispatch({ type: FETCH_USER_OWNER_STARTUP_SUCCESS, payload: resOwner.data })
    return resOwner.data.concat(resMember.data)
  } catch (e) {
    dispatch({ type: LOADING_MEMBER_STARTUPS, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}
