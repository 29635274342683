import React from 'react'
import styles from './EditSectionDetails.module.scss'
import TextInput from '../../../FormInput/TextInput'
import { Translate } from 'react-localize-redux'
import FormSection from '../../../Form/FormSection'

interface IProps {
  sectionDescription?: string
  sectionTitle?: string
  updateSectionField: (field: string, newValue: string) => void
  numberOfQuestions: number
  possibleScore: number
  isAltLang?: boolean
}

const EditSectionDetails = (props: IProps) => {
  const handleChangeSectionField = (field: string) => (value: string) => {
    props.updateSectionField(field, value)
  }

  return (
    <FormSection>
      <TextInput
        id='section-title'
        className={styles.sectionDescription}
        value={props.sectionTitle || ''}
        label={<Translate id='visaApplication.sectionTitle' />}
        type='text'
        showValidation={false}
        onChange={handleChangeSectionField(
          props.isAltLang ? 'titleAlt' : 'title',
        )}
        required
      />
      <TextInput
        id='section-description'
        className={styles.sectionDescription}
        value={props.sectionDescription || ''}
        label={<Translate id='visaApplication.sectionDescription' />}
        type={'text-area'}
        showValidation={false}
        onChange={handleChangeSectionField(
          props.isAltLang ? 'descriptionAlt' : 'description',
        )}
      />
      <div className={styles.sectionInformation}>
        <div className={styles.sectionHeading}>
          <Translate id='visaApplication.sectionInformation' />
        </div>
        <span className={styles.infoRow}>
          <Translate id='visaApplication.questions' />
          :&nbsp;{props.numberOfQuestions}
          <div className={styles.sectionPossibleScore}>
            <Translate id='visaApplication.possibleScore' />
            :&nbsp;{props.possibleScore}
          </div>
        </span>
      </div>
    </FormSection>
  )
}

export default EditSectionDetails
