import React from 'react'
import styles from './Spinner.module.scss'
import classNames from 'classnames'
import { ISpinnerProps } from "./types";

export default ({ small }: ISpinnerProps) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.spinner, {
          [styles.small]: small,
        })}
      />
    </div>
  )
}
