import React from 'react'

export interface RoundCheckProps {
  width?: number
  height?: number
  className?: any
  onClick?: () => void
  color: string
  backgroundColor?: string
}

const RoundCheck = ({ color, backgroundColor, ...props }: RoundCheckProps) => (
  <svg
    id='round_blue_check_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
    color='white'
  >
    <style>{`.round_blue_check_svg__st1{fill: ${color}}`}</style>
    <path
      color='white'
      d='M25 3.6C13.2 3.6 3.6 13.2 3.6 25S13.2 46.4 25 46.4 46.4 36.8 46.4 25 36.8 3.6 25 3.6zM35.2 20L20.4 32.2l-6.7-6.5c.1-.2.3-.8.8-1.2.6-.5 1.2-.6 1.4-.6l4.5 4.5c.2 0 11-8.8 13-10.5l.3-.3s.7.1 1.2.6c.6.6.6 1.4.6 1.4l-.3.4z'
      fill={backgroundColor || '#000'}
    />
    <path
      className='round_blue_check_svg__st1'
      color='white'
      d='M33.8 17.6l-.3.3c-2 1.7-12.9 10.5-13 10.5L16 23.9c-.2 0-.8.1-1.4.6-.5.5-.7 1-.8 1.2l6.7 6.5L35.2 20l.4-.3s0-.9-.6-1.4c-.4-.6-1.2-.7-1.2-.7z'
    />
  </svg>
)

export default RoundCheck
