import React, { useState } from 'react'
import styles from './Tabs.module.scss'
import Tab from './Tab'
import classNames from 'classnames'
import { TabsProps } from "./types";

const Tabs = ({ tabs, initialTab, hasBorderlessTab }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(initialTab || 0)

  const handleClick = (index: number) => () => {
    setActiveTab(index)
  }

  const activeThing = tabs.find((_, index) => index === activeTab)
  const activeContent = activeThing && activeThing.content

  return (
    <>
      <div className={styles.tabs}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            active={activeTab === index}
            onClick={handleClick(index)}
            className={tab.className}
          >
            {tab.heading}
          </Tab>
        ))}
        <div
          className={classNames(styles.spaceFiller, {
            [styles.leftBorder]: !hasBorderlessTab,
          })}
        />
      </div>
      <div className={styles.tabPanel}>{activeContent}</div>
    </>
  )
}

export default Tabs
