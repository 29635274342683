import React from 'react'
import { useSelector } from 'react-redux'
import styles from './ApplicationSuccess.module.scss'
import useTranslate from '../../../hooks/useTranslate'
import { RootState } from 'reducers'
import FitScreen from 'components/common/Layout/FitScreen'

const { REACT_APP_PROGRAMS_URL } = process.env

const ApplicationSuccess = () => {
  const isOnboarding = document.location.hash === '#onboarding'
  const translate = useTranslate()

  const { userId } = useSelector((state: RootState) => state.user)

  return (
    <FitScreen>
      <div className={styles.container}>
        <p>
          {!isOnboarding && translate('challenge.applicationSuccess.description')}
          {isOnboarding && translate('challenge.onboardingSuccess.description')}
        </p>
        {userId && (
          <p>
            <a href={`${REACT_APP_PROGRAMS_URL}/my-applications`}>{translate('challenge.applicationSuccess.back')}</a>
          </p>
        )}
      </div>
    </FitScreen>
  )
}

export default ApplicationSuccess
