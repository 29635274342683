import { api } from '../../../../../api'
import {
  IQuestion,
  stripQuestion,
  getQuestionError,
} from '../../../models/application/IQuestion'
import { IQuestionSection } from '../../../models/application/ISection'
import { IOption } from '../../../models/application/IOption'

interface ISaveTemplateProps {
  questions: IQuestion[]
  sections: IQuestionSection[]
  challengeId?: string
  onError: (message: string) => void
  onSuccess: (questions: IQuestion[], sections: IQuestionSection[]) => void
  translate: Function
}

export const saveTemplate = async ({
  questions,
  sections,
  challengeId,
  onError,
  onSuccess,
  translate,
}: ISaveTemplateProps) => {
  // @ts-ignore TS doesn't seem to accept that my filter will work
  const errors: string[] = questions
    .map((question, index) => {
      const error = getQuestionError(question)
      return error ? translate(error, { index: index + 1 }) : undefined
    })
    .filter((error) => error !== undefined)
  if (errors.length > 0) {
    errors.forEach(onError)
  } else {
    const strippedQuestions = questions.map(stripQuestion)
    const strippedSections = sections.map((section) => ({
      ...section,
      id: section.id.startsWith('new-section') ? undefined : section.id,
    }))
    const updatedTemplate = {
      challenge: challengeId,
      questions: strippedQuestions,
      sections: strippedSections,
    }
    try {
      const { data } = await api.patch(
        `/challenges/${challengeId}/applicationTemplate`,
        updatedTemplate,
      )
      const { sections: newSections, questions: freshQuestions } = data
      const newQuestions = linkQuestionsSections(freshQuestions, newSections)
      onSuccess(newQuestions, newSections)
    } catch (error) {
      console.error(error)
      onError(error.message)
    }
  }
}

const linkQuestionsSections = (
  questions: IQuestion[],
  sections: IQuestionSection[],
): IQuestion[] => {
  let globalIndex = 0
  const linkedQuestions = [...questions]
  sections.forEach((section: IQuestionSection, sectionIndex: number) => {
    sections[sectionIndex].titleAlt = sections[sectionIndex].titleAlt || ''

    for (
      let sectionQuestion = 0;
      sectionQuestion < section.questions;
      sectionQuestion += 1
    ) {
      linkedQuestions[globalIndex].section = sectionIndex
      linkedQuestions[globalIndex].descriptionAlt =
        linkedQuestions[globalIndex].descriptionAlt || ''
      linkedQuestions[globalIndex].questionAlt =
        linkedQuestions[globalIndex].questionAlt || ''
      linkedQuestions[globalIndex].options = linkedQuestions[
        globalIndex
      ]!.options!.map((option: IOption) => ({
        ...option,
        optionAlt: option.optionAlt || '',
        // @ts-ignore - backend is breaking the rules here...
        jumpToQuestion: option.jumpToQuestion
          ? parseInt(option.jumpToQuestion.toString(), 10)
          : undefined,
        optionId:
          option.optionId !== undefined
            ? option.optionId.toString()
            : undefined,
      }))
      globalIndex += 1
    }
  })

  return linkedQuestions
}

export const retrieveQuestionsSectionsFromAPI = async (
  challengeId?: string,
): Promise<{
  questions: IQuestion[]
  sections: IQuestionSection[]
  readonly: boolean
  createdAt: string
  updatedAt: string
}> => {
  const { data } = await api.get(
    `/challenges/${challengeId}/applicationTemplate`,
  )
  const { sections, questions, readonly, createdAt, updatedAt } = data
  if (sections.length === 0) {
    sections.push({
      id: 'new-section-was-unsectioned',
      title: 'Questions',
      titleAlt: 'الأسئلة',
      description: '',
      questions: questions.length,
    })
  }

  const linkedQuestions = linkQuestionsSections(questions, sections)

  return {
    createdAt,
    updatedAt,
    sections,
    readonly,
    questions: linkedQuestions,
  }
}

interface IPublishProps {
  questions: IQuestion[]
  challengeId?: string
  onSuccess: () => void
  onError: (message: string) => void
}

export const publishTemplate = async ({
  questions,
  challengeId,
  onSuccess,
  onError,
}: IPublishProps) => {
  try {
    await api.post(
      `/challenges/${challengeId}/applicationTemplate/publishDraft`,
    )
    onSuccess()
  } catch (error) {
    console.error(error)
    onError(error.message)
  }
}
