import React from 'react'
import { CellProps } from 'react-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { Translate } from 'react-localize-redux'
import CopyableLink from '../../../../CopyableLink/CopyableLink'
import styles from './Columns.module.scss'
import { IAssignedApplicationsRow } from "../../types";

export const ColumnsAssignedApplicationsTable = [
  {
    Header: ' ',
    columns: [
      {
        Header: <Translate id='dashboard.tables.applicant' />,
        accessor: 'applicant',
        id: 'assignedApplicationsName',
        disableSortBy: true,
        Cell: ({ cell, row }: CellProps<IAssignedApplicationsRow>) => {
          const { applicationId } = row.original
          return (
            <CopyableLink to={`/review/${applicationId}`}>
              {cell.value}
            </CopyableLink>
          )
        },
      },
      {
        Header: <Translate id='dashboard.tables.program' />,
        accessor: 'program',
        id: 'assignedApplicationsProgram',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<IAssignedApplicationsRow>) => {
          const { name } = cell.value
          return name
        },
      },
      {
        Header: <Translate id='dashboard.tables.gate' />,
        accessor: 'gate',
        disableSortBy: true,
      },
      {
        Header: <Translate id='dashboard.tables.recieved' />,
        accessor: 'received',
        id: 'assignedApplicationsRecieved',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<IAssignedApplicationsRow>) => {
          if (cell.value <= 14) {
            return <p>{cell.value} days ago</p>
          }
          return (
            <div className={styles.criticalContainer}>
              <p style={{ color: 'red' }}>
                <Translate
                  id='dashboard.tables.utilities.daysAgo'
                  data={{ days: cell.value }}
                />
              </p>
              <div
                style={{
                  backgroundColor: 'red',
                  borderRadius: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '20px',
                  width: '20px',
                }}
              >
                <FontAwesomeIcon style={{ color: 'white' }} icon={faBell} />
              </div>
            </div>
          )
        },
      },
    ],
  },
]
