import React from 'react'
import Cookies from 'js-cookie'
import { useLocation } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { performLogout } from '../actions/authActions'
import { defaultCookieOptions } from "./cookies";
import { tokenKey } from "../../../utils/localStorage";
export type UseFIDAuthType = {
  isLoggedInWithFID: 'true' | 'false' | undefined
  requestLogin: () => void
  logout: () => void
}

const {
  REACT_APP_AUTH_URL,
  REACT_APP_PROGRAMS_URL
} = process.env;

const useFIDAuth = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector((state: RootState) => state.auth.loggedIn)
  const isLoggedInWithFID = Cookies.get('fid-is-loggedin') as
    | 'true'
    | 'false'
    | undefined
  const sessionToken = Cookies.get(tokenKey) as string | undefined
  const requestLogin = React.useCallback(() => {
    window.location.replace(
      `${REACT_APP_AUTH_URL}?app_id=programs&next=${window.location.href}`,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])

  const logout = React.useCallback(() => {
    // remove cookie
    Cookies.remove('fid-is-loggedin', defaultCookieOptions)
    Cookies.remove (tokenKey, defaultCookieOptions)
    // perform logout
    dispatch(performLogout())
  }, [dispatch])
  /*
        If user has been logged in somewhere in other FID sites but not here,
        simply help him to request login for him to get an access token   
    */
  React.useEffect(() => {
    if (
      isLoggedInWithFID === 'true' &&
      !isLoggedIn &&
      !window.location.pathname.includes('fid-callback') &&
      window.location.hostname !== 'localhost'
    ) {
      // if(sessionToken?.length) {
      //   window.location.replace(`${window.location.origin}/fid-callback?next=${window.location.href}`)
      // }
      // else {
        requestLogin()
      // }
    } else if (
      isLoggedIn &&
      isLoggedInWithFID !== 'true' &&
      window.location.hostname !== 'localhost'
    ) {
      logout()
    } else if (location.pathname === '/' && !isLoggedIn && !isLoggedInWithFID) {
      window.location.replace(REACT_APP_PROGRAMS_URL as string)
    }
  }, [isLoggedInWithFID, isLoggedIn, requestLogin, logout, location.pathname, sessionToken])
  return { isLoggedInWithFID, requestLogin, logout }
}

export const FIDLoginChecker = () => {
  useFIDAuth()
  return null
}

export default useFIDAuth
