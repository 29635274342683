import { RootState } from "../reducers";
import { api } from "../api";
import { AxiosRequestConfig } from "axios";

type BodyType = Record<string, string | string[] | number | number[] | boolean | boolean[]> | FormData
type OptionsType = BodyType

type GetOptions = {
  url: string,
  state: RootState,
  optionalHeaders?: OptionsType,
  optionalConfig?: AxiosRequestConfig
}

type PostPatchOptions = {
  url: string,
  body: BodyType | null,
  state: RootState
}


class ApiWrapperService {
  static getDefaultHeaders(state: RootState) {
    return {
      Authorization: state.auth.token,
      'Accept-Language': state.localize.languages.filter(
        (v: any) => v.active,
      )[0].code,
    }
  }
  static async get(options: GetOptions) {
    const {url, optionalHeaders, state, optionalConfig } = options;
    const headers = optionalHeaders ? {...optionalHeaders, ...this.getDefaultHeaders(state)} : this.getDefaultHeaders(state)
    return api.get(url, { headers, ...(optionalConfig ?? {}) })
  }

  static async post(options: PostPatchOptions) {
    const {url, state, body} = options;
    return api.post(url, body,{
      headers: this.getDefaultHeaders(state),
    })
  }

  static async patch(options: PostPatchOptions) {
    const {url, state, body} = options;
      return api.patch(url, body,{
        headers: this.getDefaultHeaders(state),
      })
    }

  static async delete(url: string, state: RootState) {
    return api.delete(url,{
      headers: this.getDefaultHeaders(state),
    })
  }

}

export default ApiWrapperService;
