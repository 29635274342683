import React, { Component } from 'react'

import { connect } from 'react-redux'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import styles from './ImageUpload.module.scss'
import { ImageUploadProps } from "./types";

class ImageUpload extends Component<ImageUploadProps, any> {
  render() {
    return (
      <Dropzone
        onDrop={this.onDropImage}
        multiple={false}
        accept={this.props.accept}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={classNames(
                'dropzone',
                styles.dropzone,
                this.props.className,
              )}
            >
              <input id={this.props.id} {...getInputProps()} />
              {this.props.children}
            </div>
          )
        }}
      </Dropzone>
    )
  }

  onDropImage = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      this.props.onUpdateImage(acceptedFiles[0])
    }
  }
}

const mapStateToProps = (state: any) => ({})

export default connect(mapStateToProps, {})(ImageUpload)
