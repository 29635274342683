import React, { useState } from 'react'
import classes from './Accordion.module.scss'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { IProps } from "./types";

const Accordion = ({ children, label }: IProps) => {
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.container}>
      <div
        className={classes.bar}
        onClick={() => setOpen(!open)}
      >
        <h3>{label}</h3>
        <div
          className={classNames(classes.expandButton, {
            [classes.collapse]: open,
          })}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
      </div>
      <div
        className={classNames(classes.content, {
          [classes.open]: open,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
