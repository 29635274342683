import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classes from './AutoReview.module.scss'
import { Translate } from 'react-localize-redux'
import TemplateList from './TemplateList'
import TemplateForm from './TemplateForm'
import { IAutoReviewTemplate } from 'components/common/GateSettings/types'
import Button from 'components/common/shared/Button'
import { createAutoReviewCriteria, updateAutoReviewCriteria } from 'components/common/actions/gateSettingsActions'
import { AutoReviewProps } from "./types";

const AutoReview = ({ programOwnerId }: AutoReviewProps) => {
  const [createNew, setCreateNew] = useState(false)
  const [editing, setEditing] = useState('')

  const dispatch = useDispatch()

  if (createNew) {
    return (
      <TemplateForm
        backAction={() => setCreateNew(false)}
        programOwnerId={programOwnerId}
        onSave={(template: IAutoReviewTemplate) => {
          dispatch(
            createAutoReviewCriteria(
              programOwnerId,
              template,
              () => {setCreateNew(false)},
            ),
          )
        }}
      />
    )
  }

  if (editing) {
    return (
      <TemplateForm
        selectedId={editing}
        backAction={() => setEditing('')}
        programOwnerId={programOwnerId}
        onSave={(template: IAutoReviewTemplate) => {
          dispatch(
            updateAutoReviewCriteria(
              programOwnerId,
              template,
              () => {setEditing('')},
            ),
          )
        }}
      />
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerBar}>
        <h2><Translate id='autoReview.allAutoReviews' /></h2>
        <Button
          className={classes.addButton}
          type='solid'
          onClick={() => setCreateNew(true)}
        >
          <Translate id='autoReview.createNew' />
        </Button>
      </div>
      <TemplateList
        onEdit={setEditing}
        programOwnerId={programOwnerId}
      />
    </div>
  )
}

export default AutoReview
