import React from 'react'
import Form from '../../Form/Form'
import FormSection from '../../Form/FormSection'
import TextInput from '../../FormInput/TextInput'
import ReferenceList from '../../FormInput/ReferenceList'
import { withLocalize, } from 'react-localize-redux'
import gateStyles from '../GateSettings.module.scss'
import { ICommonState } from '../../utils/state'
import { useSelector } from 'react-redux'
import { EditStageAssignmentProps } from "./types";

const EditStageAssignment = ({
  translate,
  onFieldChange,
  addReviewer,
  removeReviewer,
  availableReviewers,
}: EditStageAssignmentProps) => {
  const { changedStages, stageIndex, unchangedStages } = useSelector(
    (state: ICommonState) => state.gate,
  )
  const currentStage = changedStages[stageIndex]
  const { maxReviewers = '', programManagers = [] } = currentStage
  const blockUnsafe = unchangedStages[stageIndex].numberOfApplications !== 0

  return (
    <Form>
      <FormSection>
        <div className={gateStyles.field}>
          <TextInput
            id='num-reviewers'
            showValidation={false}
            label={translate('gate.numReviewers') as string}
            value={maxReviewers}
            min={0}
            onChange={value =>
              onFieldChange({
                newValue: parseInt(value, 10),
                field: 'maxReviewers',
              })}
            type='number'
            readOnly={blockUnsafe}
          />
        </div>
        <div className={gateStyles.field}>
          <ReferenceList
            id='reviewers'
            selected={programManagers.map(pm => ({
              id: pm.id,
              label: pm.email,
            }))}
            options={availableReviewers.map(pm => ({
              id: pm.id,
              label: pm.email,
            }))}
            newInputLabel={translate('gate.newProgramManager') as string}
            onAdd={addReviewer}
            onDelete={removeReviewer}
            label={translate('gate.assignedProgramManagers') as string}
          />
        </div>
      </FormSection>
    </Form>
  )
}

export default withLocalize(EditStageAssignment)
