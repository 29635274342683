import React, { useState } from 'react'
import styles from './FileUpload.module.scss'
import FileDrop from '../FileDrop/FileDrop'
import WrappedInput from './WrappedInput'
import FileDownload from '../FileDownload'
import DragAndDropBox from './DragAndDropBox'
import { Translate } from 'react-localize-redux'
import { IFileUploadProps } from "./types";

const FileUpload = (props: IFileUploadProps) => {
  const [ downloading, setDownloading ] = useState(false)
  const handleUpload = (file?: File) => {
    if (props.onChange) {
      props.onChange(file)
    }
  }

  const isUnset = () => {
    if (props.uploadProgress === undefined) {
      return props.value === ''
    }
    return props.uploadProgress === 0
  }

  const handleDownload = () => {
    setDownloading(true)
    props.downloadFile(() => setDownloading(false))
  }

  return (
    <WrappedInput isValid={props.value !== ''} {...props}>
      <>
        {isUnset() && (
          <>
            <div className={styles.inputBoxOuter}>
              <FileDrop
                id={props.id}
                onUpdateFile={handleUpload}
                accept={props.accept}
                required={props.required}
              >
                <DragAndDropBox error={props.required} accept={props.accept || []} />
              </FileDrop>
            </div>
            {props.required && (
              <div className={styles.errorMessage}>
                <Translate id='register.form.error.reqField' />
              </div>
            )}
          </>
        )}
        {!isUnset() && (
          <>
            <FileDownload
              downloadFile={handleDownload}
              downloading={downloading}
              deleteFile={() => handleUpload()}
              progress={props.uploadProgress}
            />
            <div className={styles.errorPlaceholder} />
          </>
        )}
      </>
    </WrappedInput>
  )
}

export default FileUpload
