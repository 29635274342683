import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Dropzone, { FileRejection } from 'react-dropzone'
import { addErrorToast } from '../actions/toastActions'
import styles from './FileDrop.module.scss'
import { withLocalize } from 'react-localize-redux'
import { FileDropProps } from "./types";

const FileDrop = ({ id, children, className, onUpdateFile, accept, translate, required }: FileDropProps) => {
  const [currentStyling, setCurrentStyling] = useState(styles.baseStyle)
  const dispatch = useDispatch()

  const onDropFile = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length === 1) {
      onUpdateFile(acceptedFiles[0])
    }
    changeLeaveDragStyle()
  }

  const changeEnterDragStyle = () => {
    setCurrentStyling(styles.activeStyle)
  }

  const changeLeaveDragStyle = () => {
    setCurrentStyling(styles.baseStyle)
  }

  const handleError = (fileRejections: FileRejection[]) => {
    const { file } = fileRejections[0]
    const fileExtension = file.name.split('.').slice(-1)[0]
    dispatch(addErrorToast(translate(
      'utility.fileUpload.unsupportedFile',
      {
        extension: fileExtension,
      },
    ) as string))
  }

  return (
    <Dropzone
      onDrop={onDropFile}
      onDragEnter={changeEnterDragStyle}
      onDragLeave={changeLeaveDragStyle}
      onDropRejected={handleError}
      multiple={false}
      accept={accept}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps()}
            className={currentStyling}
          >
            <input id={id} required={required} {...getInputProps()} />
            {children}
          </div>
        )
      }}
    </Dropzone>
  )
}

export default withLocalize(FileDrop)
