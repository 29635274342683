export interface IApiAnswer {
  answer?: string
  selectedOptions?: number[]
  uploadProgress?: number
}

export interface IAnswer extends IApiAnswer {
  section: number
}

/**
* Checks whether this answer is completed
*/
export function isAnswered(answer: IAnswer | IApiAnswer): boolean {
  if (answer.answer && answer.answer.trim()) {
    return true
  }
  if (answer.selectedOptions && answer.selectedOptions.length > 0) {
    return true
  }
  return false
}
