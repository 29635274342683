import ApiWrapperService from "../service/ApiWrapperService";
import { addSuccessToast } from '../components/common/actions/toastActions'
import { requestSuccess, ROUTES } from '../api'
import { push } from 'connected-react-router'
import { handleError } from '../components/common/actions/errorHandlerActions'
import { translate } from 'utils/translations'
import { Dispatch } from "redux";
import { RootState } from "../reducers";

// *** ACTIONS ***
export const ACTIONS = {
  LOADING_STARTUP_PROFILES: '@dff_challenges_action/loading_startup_profiles',
  LOADING_OWNED_STARTUP_PROFILES: '@dff_challenges_action/loading_owner_startup_profiles',
  LOADING_FULL_STARTUP_PROFILE: '@dff_challenges_action/loading_full_startup_profile',
  FETCH_STARTUP_PROFILES_SUCCESS: '@dff_challenges_action/fetch_startup_profiles_success',
  FETCH_OWNED_STARTUPS_PROFILES_SUCCESS: '@dff_challenges_action/fetch_owned_startups_success',
  FETCHED_FULL_STARTUP_SUCCESS: '@dff_challenges_action/fetch_full_startups_success',
  STARTUP_UPDATED: '@dff_challenges_action/startup_updated',
  CREATE_STARTUP: '@dff_challenges/action/create_startup',
  DROP_CHALLENGE: '@dff_challenges/action/drop_challenge',
  DROP_STARTUP: '@dff_challenges/action/drop_startup',
  CLEAR_DROPPED_ITEMS: '@dff_challenges/action/clear_dropped_items',
  CLEAR_DROPPED_STARTUP: '@dff_challenges/action/clear_dropped_startup',
}

export const fetchFullStartupProfile = (startupId: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({ type: ACTIONS.LOADING_FULL_STARTUP_PROFILE, payload: true })
    const res = await fetchFullStartup(startupId, getState)
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch startup profiles')
    }
    dispatch({ type: ACTIONS.FETCHED_FULL_STARTUP_SUCCESS, payload: res.data })
    dispatch({ type: ACTIONS.LOADING_FULL_STARTUP_PROFILE, payload: false })
    if (res.data.suggestedMembers) {
      for (const member of res.data.suggestedMembers) {
        dispatch(
          addSuccessToast({
            message: translate('actions.justRegistered', getState(), {
              email: member.email,
              name: res.data.name,
            }) as string,
            buttonText: translate('accept', getState()) as string,
            buttonHandler: () =>
              dispatch(addStartupMember(startupId, member.email) as any),
            button2Text: translate('decline', getState()) as string,
            button2Handler: () =>
              dispatch(removeSuggestedStartupMember(startupId, member.id) as any),
          }),
        )
      }
    }
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_FULL_STARTUP_PROFILE, payload: true })
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchFullStartupAndShowProfile = (
  startupId: string,
  pushToProfile: boolean = true,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    dispatch({ type: ACTIONS.LOADING_FULL_STARTUP_PROFILE, payload: true })
    const res = await fetchFullStartup(startupId, getState)
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch startup profiles')
    }
    dispatch({ type: ACTIONS.FETCHED_FULL_STARTUP_SUCCESS, payload: res.data })
    pushToProfile && dispatch(push('/startup-profile'))
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_FULL_STARTUP_PROFILE, payload: true })
    await handleError(e)(dispatch, getState, false)
  }
}

const fetchFullStartup = (startupId: string, getState: Function) =>
  ApiWrapperService.get({ url: `/startups/${startupId}`, state: getState() })

export const fetchUserStartups = (userId: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const startupMemberPromise = await ApiWrapperService.get({
      url: `${ROUTES.USERS}/${userId}/startups/member`,
      state: getState(),
    })
    const startupOwnerPromise = await ApiWrapperService.get({
      url: `${ROUTES.USERS}/${userId}/startups/owner`,
      state: getState(),
    })
    dispatch({ type: ACTIONS.FETCH_STARTUP_PROFILES_SUCCESS, payload: startupMemberPromise.data })
    dispatch({ type: ACTIONS.FETCH_OWNED_STARTUPS_PROFILES_SUCCESS, payload: startupOwnerPromise.data })
  } catch (e) {
    console.error(e)
  }
}

export const addStartupMember = (startupId: string, email: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.post({
      url: `${ROUTES.STARTUPS}/${startupId}/members`,
      body: { email },
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to add member to the startup.')
    }
    if (res.data) {
      dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    }
    // console.log(res);
    dispatch(
      addSuccessToast('Successfully invited the user to join your startup.'),
    )
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const removeStartupMember = (
  startupId: string,
  userId: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.delete(`${ROUTES.STARTUPS}/${startupId}/members/${userId}`, getState())
    if (!requestSuccess(res)) {
      throw new Error('Failed to remove the user from your startup.')
    }
    dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    // dispatch(addSuccessToast('Successfully removed the user from your startup.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const removeSuggestedStartupMember = (
  startupId: string,
  userId: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.delete(
      `${ROUTES.STARTUPS}/${startupId}/suggestedMembers/${userId}`,
      getState(),
    )
    if (!requestSuccess(res)) {
      throw new Error('Failed to remove the suggested user from your startup.')
    }
    dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const promoteMemberToOwner = (
  startupId: string,
  userId: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.post({
      url: `${ROUTES.STARTUPS}/${startupId}/owners`,
      body: {userId},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to promote user to owner.')
    }
    dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    // dispatch(addSuccessToast('Successfully promoted the user to owner.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const demoteOwnerToUse = (startupId: string, userId: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.delete(`${ROUTES.STARTUPS}/${startupId}/owners/${userId}`, getState())
    if (!requestSuccess(res)) {
      throw new Error('Failed to demote owner to user.')
    }
    dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    // dispatch(addSuccessToast('Successfully demoted the owner to user.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateStartup = (startupId: string, body: Record<string, string>) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.patch({
      body,
      url: `${ROUTES.STARTUPS}/${startupId}`,
      state: getState()
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to update startup.')
    }
    if (res.data && res.data !== 'OK') {
      // TODO: Remove this OK check when we get the object from backend. Already synced with Thomas
      dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    }
    // dispatch(addSuccessToast('Successfully updated your startup.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const uploadStartupLogo = (startupId: string, logo: File) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const formData = new window.FormData()
    formData.append('logo', logo)
    const res = await ApiWrapperService.post({
      url: `${ROUTES.STARTUPS}/${startupId}/logo`,
      body: formData,
      state: getState()
    })
    dispatch({ type: ACTIONS.STARTUP_UPDATED, payload: res.data })
    // Hotfix. After chaning the startup logo it won't render the updated logo, probably because of the Async loading. Ater a reload it's there
    // ToDo: find better solution for this
    window.location.reload()
    // dispatch(addSuccessToast('Successfully changed image.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}
