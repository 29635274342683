export const mapValueForReactSelect = (
  options: string[],
  value: number | number[],
) => {
  if (Array.isArray(value)) {
    return value.map((index) => {
      return mapOptionForReactSelect(options[index], index)
    })
  }
  return mapOptionForReactSelect(options[value], value)
}

export const mapOptionForReactSelect = (option: string, index: number) => ({
  value: index,
  label: option,
})

// @ts-ignore
export function insertToken(token: string) {
  // @ts-ignore
  const cursorPosition = this.quill.getSelection().index
  // @ts-ignore
  this.quill.insertText(cursorPosition, token)
  // @ts-ignore
  this.quill.setSelection(cursorPosition + token.length)
}
