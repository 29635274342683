import React from 'react'
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import classNames from 'classnames'
import styles from './StageListItem.module.scss'
import { StageListItemProps } from "./types";

const StageListItem = ({
  stageName,
  count,
  selected,
  onSelect,
  isShowingAllApplications = false,
}: StageListItemProps) => {
  return (
    <div
      className={classNames(
        styles.container,
        isShowingAllApplications && styles.containerAllApplications,
      )}
    >
      <FontAwesomeIcon
        onClick={() => onSelect()}
        icon={selected ? faCheckCircle : faCircle}
        className={classNames(
          styles.checkButton,
          selected && styles.checkButtonSelected,
        )}
        style={{ fontSize: 22, borderRadius: 20 }}
      />
      <div className={styles.stageName}>{stageName}</div>
      <div className={styles.count}>{count}</div>
    </div>
  )
}

export default StageListItem
