import React from 'react'
import styles from './Loader.module.scss'
import { Translate } from 'react-localize-redux'

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader} />
      <div className={styles.loaderLabel}>
        <Translate id='utility.loading' />
      </div>
    </div>
  )
}

export default Loader
