import React, { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { insertToken } from "./utils";
import CustomToolbar from "./CustomToolbar";
import { TextInputProps } from "./types";

const RichTextInput = ({
  id,
  value,
  onChange,
  rtl,
  translate,
  withPlaceholderTag,
}: TextInputProps) => {
  const quillRef: any = useRef(null)

  useEffect(() => {
    if (typeof quillRef.current.getEditor === 'function') {
      const editor = quillRef.current.getEditor()
      if (rtl && editor) {
        editor.format('direction', 'rtl')
        editor.format('align', 'right')
      }

      const placeholderPickerItems = Array.prototype.slice.call(
        document.querySelectorAll('.ql-insertToken .ql-picker-item'),
      )
      placeholderPickerItems.forEach((item) => {
        item.textContent = item.dataset.value
      })

      document
        .querySelectorAll('.ql-insertToken .ql-picker-label')
        .forEach((buttonElement) => {
          buttonElement.innerHTML = translate(
            'input.selectEmailToken',
          ).toString()
        })
    }
  }, [quillRef, rtl, translate])

  const modules = {
    toolbar: {
      container: `#toolbar-${id}`,
      handlers: {
        insertToken: insertToken,
      },
    },
  }
  return (
    <>
      <CustomToolbar
        id={`toolbar-${id}`}
        withPlaceholder={withPlaceholderTag}
      />
      <ReactQuill
        id={id}
        ref={quillRef}
        value={value as string}
        onChange={onChange}
        modules={modules}
      />
    </>
  )
}

export default RichTextInput

