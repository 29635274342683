import { ACTIONS, ReviewAction } from '../actions/programManagerActions'
import { getNewApplicationData } from "../ProgramManager/utils";
import { ReviewState } from "../ProgramManager/types";
import { Reducer } from "./types";
import { createReducer } from "./createReducer";

const defaultState: ReviewState = {
  stageIndex: 0,
  searchTerm: '',
  applicationIndex: 0,
  showPrimaryLanguage: true,
  showSecondaryLanguage: true,
  selectedApplication: null,
  selectedReviewedApplication: null,
  showRejected: false,
  rejectedSelected: false,
  sectionIndex: 0,
  stages: [],
  applicationsData: null,
  template: null,
  applicants: [],
  rejectedApplicants: [],
  filesDownloading: [],
  v2ReviewApplicationError: false,
  scoringPending: false,
  reassignPending: false,
  forceStagePending: false,
  forceApprovePending: false,
  forceRejectPending: false,
}

const reducer: Reducer<ReviewState, ReviewAction> = {
  [ACTIONS.SET_STAGE_INDEX]: (state, {payload: {stageIndex}}) => ({...state, stageIndex}),
  [ACTIONS.SET_STAGES]: (state, {payload: {stages}}) => ({...state, stages}),
  [ACTIONS.SET_APPLICATIONS_DATA]: (state, {payload: {applicationsData, languages}}) => {
    const newApplicationData = getNewApplicationData(
      state,
      {applicationsData},
      languages,
    )
    return {
      ...state,
      ...newApplicationData,
      applicationsData,
    }
  },
  [ACTIONS.SET_APPLICATION_INDEX]: (state, {payload: {applicationIndex, languages}}) => {
    const newApplicationData = getNewApplicationData(
      state,
      {applicationIndex},
      languages,
    )

    return {
      ...state,
      ...newApplicationData,
      applicationIndex,
    }
  },
  [ACTIONS.SET_SHOW_PRIMARY_LANGUAGE]: (state, {payload: {languages, showPrimaryLanguage}}) => {
    const newApplicationData = getNewApplicationData(
      state,
      {showPrimaryLanguage},
      languages,
    )

    return {
      ...state,
      ...newApplicationData,
      showPrimaryLanguage,
    }
  },
  [ACTIONS.SET_SHOW_SECONDARY_LANGUAGE]: (state, {payload: {languages, showSecondaryLanguage}}) => {
    const newApplicationData = getNewApplicationData(
      state,
      {showSecondaryLanguage},
      languages,
    )

    return {
      ...state,
      ...newApplicationData,
      showSecondaryLanguage,
    }
  },
  [ACTIONS.TOGGLE_REJECTED]: (state, {payload: {languages}}) => {
    const showRejected = !state.showRejected
    const applicationIndex = 0
    const rejectedSelected = showRejected

    const newApplicationData = getNewApplicationData(
      state,
      {
        showRejected,
        applicationIndex,
        rejectedSelected,
      },
      languages,
    )

    return {
      ...state,
      ...newApplicationData,
      showRejected,
      applicationIndex,
      rejectedSelected,
    }
  },
  [ACTIONS.SET_SEARCH_TERM]: (state, {payload: {languages, searchTerm}}) => {
    const newApplicationData = getNewApplicationData(
      state,
      {searchTerm},
      languages,
    )

    return {
      ...state,
      ...newApplicationData,
      searchTerm,
    }
  },
  [ACTIONS.SET_REJECTED_SELECTED]: (state, {payload}) => ({...state, rejectedSelected: payload}),
  [ACTIONS.SET_V2_REVIEW_APPLICATION]: (state, {payload}) => ({
    ...state,
    v2ReviewApplication: payload,
    v2ReviewApplicationError: false,
  }),
  [ACTIONS.V2_REVIEW_APPLICATION_ERROR]: (state, {payload}) => ({
    ...state,
    v2ReviewApplication: undefined,
    v2ReviewApplicationError: true,
  }),
  [ACTIONS.START_FILE_DOWNLOAD]: (state, {payload}) => ({
    ...state,
    filesDownloading: [...state.filesDownloading, payload],
  }),
  [ACTIONS.END_FILE_DOWNLOAD]: (state, {payload}) => ({
    ...state,
    filesDownloading: state.filesDownloading.filter(
      (slug) => slug !== payload,
    ),
  }),
  [ACTIONS.START_SCORING]: (state) => ({...state, scoringPending: true}),
  [ACTIONS.END_SCORING]: (state) => ({...state, scoringPending: false}),
  [ACTIONS.START_REASSIGN]: (state) => ({...state, reassignPending: true}),
  [ACTIONS.END_REASSIGN]: (state) => ({...state, reassignPending: false}),
  [ACTIONS.START_FORCE_STAGE]: (state) => ({...state, forceStagePending: true}),
  [ACTIONS.END_FORCE_STAGE]: (state) => ({...state, forceStagePending: false}),
  [ACTIONS.START_APPROVE]: (state) => ({...state, forceApprovePending: true}),
  [ACTIONS.END_APPROVE]: (state) => ({...state, forceApprovePending: false}),
  [ACTIONS.START_REJECT]: (state) => ({...state, forceRejectPending: true}),
  [ACTIONS.END_REJECT]: (state) => ({...state, forceRejectPending: false}),
}

export default createReducer<ReviewState, ReviewAction>(reducer, defaultState);
