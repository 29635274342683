import React, { useEffect } from 'react'
import styles from './RelatedChallengesList.module.scss'
import splashInfoStyles from './ViewChallengeSplashInfo.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import { tagTechnologies } from "./utils";
import { RelatedChallengesListProps } from "./types";

const RelatedChallengesList = ({ relatedChallenges, isAltLang }: RelatedChallengesListProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = React.useState('Top')

  useEffect(() => {
    getScrollPosition()
  }, [relatedChallenges, ref])

  const getScrollPosition = () => {
    if (ref.current) {
      const {
        scrollTop,
        scrollHeight,
        offsetHeight,
      } = ref.current

      if (offsetHeight >= scrollHeight) {
        setScrollPosition('Small')
      } else if (scrollTop === 0) {
        setScrollPosition('Top')
      } else if (scrollTop >= scrollHeight - offsetHeight) {
        setScrollPosition('Bottom')
      } else {
        setScrollPosition('Middle')
      }
    }
  }

  return (
    <div className={styles.otherChallengesList} onScroll={getScrollPosition} ref={ref}>
      {relatedChallenges.map((otherChallenge) => {
        const {
          id,
          technologies,
          coverImage,
          name,
          nameAlt,
          detailImages,
        } = otherChallenge
        const displayName = isAltLang ? nameAlt : name
        const hasTechList = technologies && technologies.length > 0

        return (
          <a
            key={id}
            className={styles.otherChallenge}
            href={`/programs/${id}/`}
          >
            {hasTechList && (
              <span className={styles.technologies}>
                {tagTechnologies(technologies)}
              </span>
            )}
            <div
              className={classNames(styles.imageContainer, {
                [styles.topMargin]: hasTechList,
              })}
              style={{
                backgroundImage: `url(${coverImage || detailImages?.[0]})`,
              }}
            />
            <span className={styles.heading}>{displayName}</span>
          </a>
        )
      })}
      {scrollPosition !== 'Top' && scrollPosition !== 'Small' && (
        <div className={classNames(styles.scrollIndicator, styles.top)}>
          <div
            className={classNames(
              splashInfoStyles.showMoreCircle,
              styles.showMoreCircle,
            )}
          >
            <FontAwesomeIcon icon={faLongArrowAltDown} />
          </div>
        </div>
      )}
      {scrollPosition !== 'Bottom' && scrollPosition !== 'Small' && (
        <div className={classNames(styles.scrollIndicator, styles.bottom)}>
          <div
            className={classNames(
              splashInfoStyles.showMoreCircle,
              styles.showMoreCircle,
            )}
          >
            <FontAwesomeIcon icon={faLongArrowAltDown} />
          </div>
        </div>
      )}
    </div>
  )
}

export default RelatedChallengesList
