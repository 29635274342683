import React from 'react'
import styles from './FormEditorFooter.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import ActionBar from '../../ActionBar/ActionBar'
import Toggle from '../../../Toggle'
import Button from '../../shared/Button'
import {
  isApplicationFormPublishable,
  QuestionError,
  ErrorSummary,
} from './utils'
import { saveTemplate, publishTemplate } from './utils/api'
import { addErrorToast, addSuccessToast } from '../../actions/toastActions'
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux'
import { RootState } from '../../../../reducers'
import { IQuestion } from '../../models/application/IQuestion'
import { IQuestionSection } from '../../models/application/ISection'
import classnames from 'classnames'

interface IFormEditorFooterProps extends LocalizeContextProps {
  unchangedQuestions: IQuestion[]
  updatedQuestions: IQuestion[]
  updatedSections: IQuestionSection[]
  unsavedChanges: boolean
  onSave: (newQuestions: IQuestion[], newSections: IQuestionSection[]) => void
  editMode: boolean
  toggleEditMode: () => void
  readonly: boolean
}

const FormEditorFooter = ({
  translate,
  unchangedQuestions,
  updatedQuestions,
  updatedSections,
  unsavedChanges,
  editMode,
  toggleEditMode,
  readonly,
  onSave,
}: IFormEditorFooterProps) => {
  const dispatch = useDispatch()
  const { selectedChallenge } = useSelector(
    (state: RootState) => state.application,
  )

  const handleSave = async () => {
    saveTemplate({
      translate,
      questions: updatedQuestions,
      sections: updatedSections,
      challengeId: selectedChallenge,
      onError: (message: string) => {
        dispatch(addErrorToast(message))
      },
      onSuccess: onSave,
    })
  }

  const handlePublish = () => {
    const [isPublishable, publishErrors] = isApplicationFormPublishable(
      unchangedQuestions,
    )

    if (isPublishable) {
      publishTemplate({
        questions: unchangedQuestions,
        challengeId: selectedChallenge,
        onSuccess: () => {
          dispatch(
            addSuccessToast(
              translate('visaApplication.templatePublished') as string,
            ),
          )
        },
        onError: (message: string) => {
          dispatch(addErrorToast(message))
        },
      })
    } else {
      displayPublishErrors(publishErrors)
    }
  }

  const displayPublishErrors = (publishErrors: QuestionError[]) => {
    const uniqueErrors = publishErrors.reduce((uniqueErrors, newError) => {
      const matchingError = uniqueErrors.find(
        (candidateError) => candidateError.error === newError.error,
      )
      if (matchingError) {
        return [
          ...uniqueErrors.filter((error) => error.error !== newError.error),
          {
            ...newError,
            indicies: [...matchingError.indicies, newError.index],
          },
        ]
      }
      return [
        ...uniqueErrors,
        {
          ...newError,
          indicies: [newError.index],
        },
      ]
    }, [] as ErrorSummary[])

    uniqueErrors.forEach((error) => {
      const errorComponent = translate(error.error, {
        indicies: error.indicies.join(', '),
      })
      dispatch(addErrorToast(errorComponent as string))
    })
  }

  return (
    <ActionBar>
      <div className={styles.container}>
        <Toggle
          value={editMode}
          onClick={toggleEditMode}
          onLabel={<Translate id='visaApplication.edit' />}
          offLabel={<Translate id='visaApplication.view' />}
          elementWidth='14rem'
        />
        <span className={styles.footerSpan}>
          {readonly && <Translate id='visaApplication.readonlyWarning' />}
        </span>
        <Button
          type='primary'
          onClick={handleSave}
          className={styles.actionButton}
        >
          <Translate id='form.saveAsDraft' />
        </Button>
        <Button
          type='primary'
          onClick={handlePublish}
          className={classnames(styles.actionButton, styles.publishButton)}
          disabled={unsavedChanges}
        >
          <Translate id='visaApplication.publish' />
        </Button>
      </div>
    </ActionBar>
  )
}

export default withLocalize(FormEditorFooter)
