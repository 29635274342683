import React from 'react'
import style from './Breadcrumbs.module.scss'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { BreadcrumbsProps } from "./types";

const Breadcrumbs = ({ crumbs, separator, className }: BreadcrumbsProps) => {
  const output = crumbs.map((crumb, index) => {
    if (crumb.link && crumb.link.startsWith('http')) {
      return (
        <a key={index} href={crumb.link} aria-label={crumb.ariaLabel}>
          <span className={crumb.bold ? style.bold : ''}>{crumb.label}</span>
        </a>
      )
    }
    if (crumb.link) {
      return (
        <Link key={index} to={crumb.link} aria-label={crumb.ariaLabel}>
          <span className={crumb.bold ? style.bold : ''}>{crumb.label}</span>
        </Link>
      )
    }
    return (
      <span key={index} className={crumb.bold ? style.bold : ''} aria-label={crumb.ariaLabel}>
        {crumb.label}
      </span>
    )
  })
  return (
    <span className={classnames(style.breadcrumbs, className)}>{output}</span>
  )
}

export default Breadcrumbs
