import React from 'react'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import './style-overrides.css'
import { InfoTooltipProps } from "./types";

const InfoTooltip = ({
     overlay,
     children,
     disabled,
   }: InfoTooltipProps) => disabled ? (
    <>
      {children}
    </>
  )
  :
  (
    <Tooltip
      placement='top'
      trigger={['hover', 'focus']}
      overlay={overlay}
    >
      {children}
    </Tooltip>
  )

export default InfoTooltip
