import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ApplicationListingSidebar from './ApplicationListingSidebar'
import { loadStages } from '../common/actions/programManagerActions'
import { RootState } from '../../reducers'
import ApplicationListingMain from './ApplicationListMain'

import styles from './ApplicationListing.module.scss'
import { api } from 'api'
import { IChallenge } from 'models/challenge/challenge'
import FitScreen from 'components/common/Layout/FitScreen'

const ApplicationListing = () => {
  // route params
  const { id: programId } = useParams()
  // list of stages to be populated fro API
  const [programInfo, setProgramInfo] = React.useState(
    null as IChallenge | null,
  )
  // retrieving data from the state
  const { stages } = useSelector((state: RootState) => state.review)
  const dispatch = useDispatch()

  /**
   * Function for fetching program info (program name & facilitator name)
   */
  const fetchProgramInfo = React.useCallback(async() => {
    const { data } = await api.get(`/challenges/${programId}`)
    setProgramInfo(data)
  }, [programId])

  /**
   * Function for fetching list of stages and their application counts
   */
  const fetchStageListInfo = React.useCallback(() => {
    dispatch(
      loadStages(programId, {
        onError: (error: any) => {
          console.error(error)
        },
      }),
    )
  }, [dispatch, programId])

  // selected application listings
  // if this is null, then it is assumed that all stages are selected
  const [selectedStageId, setSelectedStageId] = React.useState(
    null as string | null,
  )

  // trigger the initial fetching
  React.useEffect(() => {
    fetchProgramInfo()
    fetchStageListInfo()
  }, [fetchStageListInfo, fetchProgramInfo])

  return (
    <FitScreen>
      <div className={styles.container}>
        <ApplicationListingSidebar
          stages={stages}
          selectedStageId={selectedStageId}
          onSelectedStageIdChange={setSelectedStageId}
        />
        <ApplicationListingMain
          facilitatorName={programInfo?.programOwner?.name || ''}
          programName={programInfo?.name || ''}
          programId={programId}
          stageId={selectedStageId}
        />
      </div>
    </FitScreen>
  )
}

export default ApplicationListing
