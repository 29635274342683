import React from 'react'
import styles from './Row.module.scss'
import classnames from 'classnames'
import { IRowProps } from "./types";

const Row = ({ children, className }: IRowProps) => {
  return <div className={classnames(styles.row, className)}>{children}</div>
}

export default Row
