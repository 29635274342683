import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Translate, withLocalize, LocalizeContextProps } from 'react-localize-redux'
import {
  IQuestion,
  QuestionType,
  typesWithOptions,
  typesWithCharacterLimitation,
  getPossibleScoreForQuestion,
} from '../../../../models/application/IQuestion'
import { IOption } from '../../../../models/application/IOption'
import styles from './EditQuestion.module.scss'
import EditQuestionControls from './EditQuestionControls'
import TextInput from '../../../../FormInput/TextInput'
import Dropdown from '../../../../FormInput/Dropdown'
import Checkbox from '../../../../FormInput/Checkbox'
import AllowedAnswers from './AllowedAnswers'
import { RootState } from '../../../../../../reducers'
import FormSection from '../../../../Form/FormSection'
import { getApiKeys } from 'components/common/actions/applicationActions'

interface IProps extends LocalizeContextProps {
  question: IQuestion
  updatedQuestions: IQuestion[]
  setUpdatedQuestion: (updatedQuestion: IQuestion) => void
  questionIndex: number
  removeQuestion: (indexOfQuestionToRemove: number) => void
  isAltLang?: boolean
}

const EditQuestion = (props: IProps) => {
  const {
    question,
    updatedQuestions,
    setUpdatedQuestion,
    questionIndex,
    translate,
  } = props

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getApiKeys())
  }, [dispatch])

  const { apiKeys } = useSelector((state: RootState) => state.application)

  const apiKeyOptions = apiKeys
    .map(apiKey => apiKey.keyName)

  const handleUpdateQuestionField = (fieldId: string) =>
    (value: string) => {
      updateQuestionField(fieldId, value)
    }

  const questionTypeOptions = [
    { option: translate('visaApplication.shortAnswer') as string },
    { option: translate('visaApplication.paragraph') as string },
    { option: translate('visaApplication.multipleChoice') as string },
    { option: translate('visaApplication.checkbox') as string },
    { option: translate('visaApplication.dropdown') as string },
    { option: translate('visaApplication.fileUpload') as string },
    { option: translate('visaApplication.date') as string },
    { option: translate('visaApplication.time') as string },
  ]

  const handleQuestionTypeChange = (question: IQuestion) =>
    (newSelection: number | number[]) => {
      if (Array.isArray(newSelection)) {
        throw new Error('Multi valued selection when expecting single value')
      }
      const selectedType: string | undefined = Object.keys(questionTypeMapping)
        .find((type: string) => questionTypeMapping[type] === newSelection)
      if (selectedType) {
        updateQuestionField('type', selectedType)
      }
    }

  const updateQuestionField = (field: string, value?: boolean | string | IOption[]) => {
    setUpdatedQuestion({
      ...props.question,
      [field]: value,
    })
  }

  const possibleScore = getPossibleScoreForQuestion(props.question)

  const handleApiKeyChange = (question: IQuestion) =>
    (newSelection: number | number[]) => {
      if (Array.isArray(newSelection)) {
        throw new Error('Multi valued selection when expecting single value')
      }
      updateQuestionField('apiKey', newSelection >= 0 ? apiKeys[newSelection].id : undefined)
    }

  const handleFileCategoryChange = (question: IQuestion) =>
    (newSelection: number | number[]) => {
      if (Array.isArray(newSelection)) {
        throw new Error('Multi valued selection when expecting single value')
      }
      updateQuestionField(
        'fileUploadCategory', newSelection >= 0
          ? fileCategories[newSelection]
          : undefined
      )
    }

  const questionTypeMapping: { [key: string]: number } = {
    [QuestionType.SHORT_ANSWER]: 0,
    [QuestionType.PARAGRAPH]: 1,
    [QuestionType.MULTIPLE_CHOICE]: 2,
    [QuestionType.CHECKBOX]: 3,
    [QuestionType.DROPDOWN]: 4,
    [QuestionType.FILE_UPLOAD]: 5,
    [QuestionType.DATE]: 6,
    [QuestionType.TIME]: 7,
  }

  const fileCategories = [
    'document',
    'image',
    'video',
  ]

  const lastQuestionInSection = updatedQuestions
    .filter(updatedQuestion => updatedQuestion.section === question.section).length === 1

  return (
    <React.Fragment key={question.id}>
      <FormSection>
        <EditQuestionControls
          questionIndex={props.questionIndex}
          removeQuestion={props.removeQuestion}
          deletable={!lastQuestionInSection}
        />
        <span className={styles.questionIndex}>
          {`${questionIndex + 1}. `}
          <Translate id='visaApplication.question' />
        </span>
        <span className={styles.questionPossibleScore}>
          <Translate id={'visaApplication.possibleScore'} />:&nbsp;{possibleScore}
        </span>
        <TextInput
          id={`${question.id}-title`}
          label={<Translate id='visaApplication.questionTitle' />}
          className={styles.questionTitle}
          showValidation={false}
          value={props.isAltLang ? question.questionAlt : question.question}
          type='text'
          onChange={handleUpdateQuestionField(props.isAltLang ? 'questionAlt' : 'question')}
        />
        <Checkbox
          value={question.mandatory}
          label={<Translate id='visaApplication.required' />}
          onChange={(newValue: boolean) => updateQuestionField('mandatory', newValue)}
          id={`${question.id}-mandatory`}
        />
        <div className={styles.questionMainFields}>
          <Dropdown
            id={`${question.id}-type`}
            label={<Translate id='visaApplication.type' />}
            className={styles.typeSelect}
            showValidation={false}
            value={questionTypeMapping[question.type]}
            options={questionTypeOptions.map(option => option.option)}
            onChange={handleQuestionTypeChange(question)}
          />
          <Dropdown
            id={`${question.id}-apiKey`}
            label={<Translate id='visaApplication.apiKey' />}
            className={styles.apiKeySelect}
            showValidation={false}
            value={
              question.apiKey
                ? apiKeys.findIndex(apiKey => apiKey.id === question.apiKey)
                : -1
            }
            options={apiKeyOptions}
            onChange={handleApiKeyChange(question)}
            isClearable
          />
        </div>
        <TextInput
          id={`${question.id}-description`}
          className={styles.questionDescription}
          showValidation={false}
          value={props.isAltLang ? question.descriptionAlt : question.description}
          type='text-area'
          label={<Translate id='visaApplication.questionDescription' />}
          onChange={handleUpdateQuestionField(props.isAltLang ? 'descriptionAlt' : 'description')}
        />
        {typesWithOptions.includes(question.type) && (
          <div className={styles.questionOptions}>
            <AllowedAnswers
              question={question}
              updatedQuestions={updatedQuestions}
              questionIndex={questionIndex}
              updateQuestionField={updateQuestionField}
              isAltLang={props.isAltLang}
            />
          </div>
        )}
        {typesWithCharacterLimitation.includes(question.type) && (
          <div className={styles.questionOptions}>
            <TextInput
              id={`${question.id}-maxLength`}
              className={styles.characterLimitations}
              value={question.maxLength}
              onChange={handleUpdateQuestionField('maxLength')}
              type='number'
              label={<Translate id='visaApplication.maxCharactersLength' />}
              showValidation={false}
            />
          </div>
        )}
        {question.type === QuestionType.FILE_UPLOAD && (
          <div className={styles.questionOptions}>
            <Dropdown
              id={`${question.id}-fileCategory`}
              label={<Translate id='visaApplication.fileCategory' />}
              className={styles.typeSelect}
              showValidation={false}
              value={question.fileUploadCategory !== undefined
                ? fileCategories.indexOf(question.fileUploadCategory)
                : -1
              }
              options={['Document', 'Image', 'Video']}
              onChange={handleFileCategoryChange(question)}
            />
          </div>
        )}
      </FormSection>
    </React.Fragment >
  )
}

export default withLocalize(EditQuestion)
