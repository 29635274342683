/* global FormData */
import { addSuccessToast } from 'components/common/actions/toastActions'
import { api, requestSuccess } from 'api'
import { IChallenge } from 'models/challenge/challenge'
import axios, { AxiosPromise } from 'axios'
import { handleError } from 'components/common/actions/errorHandlerActions'
import { translate } from 'utils/translations'
import { IStage } from 'components/common/models/application/IStage'
import { RootState } from "../../../reducers";
import { ACTIONS as PROGRAM_OWNER_ACTIONS } from "../../../actions/programOwnerActions";
import get from 'lodash/get'
import set from 'lodash/set'
import { AppThunk } from "./types";

// *** ACTIONS ***
export const ACTIONS = {
  LOADING_ALL_CHALLENGES: '@dff_challenges_action/loading_all_challenges',
  FETCH_ALL_CHALLENGES_SUCCESS: '@dff_challenges_action/fetch_all_challenges_success',
  FETCH_CHALLENGE_SUCCESS: '@dff_challenges_action/fetch_challenge_success',
  SET_CHALLENGE_STAGES: '@dff_challenges_action/set_challenge_stages',
  FETCH_ALL_CHALLENGES_SUCCESS_DEPRECATED: '@dff_challenges_action/fetch_all_challenges_success_deprecated',
  FETCH_CHALLENGE_SUCCESS_DEPRECATED: '@dff_challenges_action/fetch_challenge_success_deprecated',
  FETCH_RELATED_CHALLENGES_SUCCESS: '@dff_challenges_action/fetch_related_challenges_success',
}

const loadingAllChallenges = (isLoading: boolean) => ({
  type: ACTIONS.LOADING_ALL_CHALLENGES,
  payload: isLoading,
})

const fetchChallengeSuccess = (challenge: IChallenge) => ({
  type: ACTIONS.FETCH_CHALLENGE_SUCCESS,
  payload: challenge,
})

const fetchAllChallengesSuccess = (challenges: IChallenge[]) => ({
  type: ACTIONS.FETCH_ALL_CHALLENGES_SUCCESS,
  payload: challenges,
})

const fetchRelatedChallengesSuccess = (id: string, challenges: IChallenge[]) => ({
  type: ACTIONS.FETCH_RELATED_CHALLENGES_SUCCESS,
  payload: {
    id,
    challenges,
  },
})

export const fetchAllChallengesDeprecated = (): AppThunk => async(dispatch, getState) => {
  try {
    dispatch(loadingAllChallenges(true))

    const response = await api.get('/challenges', { headers: { 'Accept-Language': getState().localize.languages.filter((v: any) => v.active)[0].code } })

    if (!requestSuccess(response)) {
      throw new Error('Failed to fetch challenges.')
    }

    const challenges: IChallenge[] = response.data

    challenges.sort((a, b) => +new Date(a.applicationDeadline || '') - +new Date(b.applicationDeadline || ''))

    dispatch({ type: ACTIONS.FETCH_ALL_CHALLENGES_SUCCESS_DEPRECATED, payload: challenges })
    dispatch(loadingAllChallenges(false))
  } catch (e) {
    dispatch(loadingAllChallenges(true))
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchChallengeDeprecated = (id: string): AppThunk => async(dispatch, getState) => {
  try {
    dispatch(loadingAllChallenges(true))

    const response = await api.get(`/challenges/${id}`, { headers: { 'Accept-Language': getState().localize.languages.filter((v: any) => v.active)[0].code } })

    if (!requestSuccess(response)) {
      throw new Error('Failed to fetch challenges.')
    }

    dispatch({ type: ACTIONS.FETCH_CHALLENGE_SUCCESS_DEPRECATED, payload: response.data })
    dispatch(loadingAllChallenges(false))
  } catch (e) {
    dispatch(loadingAllChallenges(true))
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchAllChallenges = (): AppThunk => async(dispatch, getState) => {
  try {
    dispatch(loadingAllChallenges(true))

    const response = await api.get('/challenges', { headers: { 'Accept-Language': getState().localize.languages.filter((v: any) => v.active)[0].code } })

    if (!requestSuccess(response)) {
      throw new Error('Failed to fetch challenges.')
    }

    const challenges: IChallenge[] = response.data

    dispatch(fetchAllChallengesSuccess(challenges))
    dispatch(loadingAllChallenges(false))
  } catch (e) {
    dispatch(loadingAllChallenges(true))
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchChallenge = (id: string): AppThunk => async(dispatch, getState) => {
  try {
    dispatch(loadingAllChallenges(true))

    const response = await api.get(`/challenges/${id}`, { headers: { 'Accept-Language': getState().localize.languages.filter((v: any) => v.active)[0].code } })

    if (!requestSuccess(response)) {
      throw new Error('Failed to fetch challenges.')
    }

    dispatch(fetchChallengeSuccess(response.data))
    dispatch(loadingAllChallenges(false))
  } catch (e) {
    dispatch(loadingAllChallenges(true))
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchRelatedChallenges = (id: string): AppThunk => async(dispatch, getState) => {
  try {
    const response = await api.get(`/v3/programs/${id}/related`)

    if (!requestSuccess(response)) {
      throw new Error('Failed to fetch challenges.')
    }

    const challenges: IChallenge[] = response.data

    dispatch(fetchRelatedChallengesSuccess(id, challenges))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const addDetailImagesRequest = (challenge: IChallenge, images: File[], state: RootState) => {
  const promises: AxiosPromise[] = []

  images.forEach(async(image: File) => {
    const formData = new FormData() // eslint-disable-line
    formData.append('image', image)

    promises.push(api.post(`/challenges/${challenge.id}/images`, formData, {
      headers: {
        'Authorization': state.auth.token,
        'Content-Type': 'multipart/form-data',
        'Accept-Language': state.localize.languages.filter((v: any) => v.active)[0].code,
      },
    }))
  })

  return promises
}
export const addDetailImagesToChallenge = (challenge: IChallenge, images: File[]): AppThunk =>
  async(dispatch, getState) => {
  try {
    const promises = addDetailImagesRequest(challenge, images, getState())
    axios.all(promises).then(() => {
      dispatch(fetchChallenge(challenge.id))
    })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteDetailImageFromChallengeRequest = (challenge: IChallenge, index: number, state: RootState) => {
  return api.delete(`/challenges/${challenge.id}/images/${index}`, {
    headers: {
      Authorization: state.auth.token,
      'Accept-Language': state.localize.languages.filter((v: any) => v.active)[0].code,
    },
  })
}
export const deleteDetailImageFromChallenge = (challenge: IChallenge, index: number): AppThunk => async(dispatch, getState) => {
  try {
    const response = await deleteDetailImageFromChallengeRequest(challenge, index, getState())

    if (!requestSuccess(response)) {
      throw new Error('Could not delete challenge detail images.')
    }

    dispatch(fetchChallenge(challenge.id))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const setCoverImageOfChallengeRequest = (challenge: IChallenge, image: File, state: RootState) => {
  const formData = new FormData() // eslint-disable-line
  formData.append('coverImage', image)
  return api.post(`/challenges/${challenge.id}/cover`, formData, {
    headers: {
      'Authorization': state.auth.token,
      'Content-Type': 'multipart/form-data',
      'Accept-Language': state.localize.languages.filter((v: any) => v.active)[0].code,
    },
  })
}
export const setCoverImageOfChallenge = (challenge: IChallenge, image: File): AppThunk => async(dispatch, getState) => {
  try {
    const formData = new FormData() // eslint-disable-line
    formData.append('coverImage', image)
    const response = await setCoverImageOfChallengeRequest(challenge, image, getState())

    if (!requestSuccess(response)) {
      throw new Error('Failed to set cover image for challenge.')
    }

    dispatch(fetchChallenge(challenge.id))
    dispatch(addSuccessToast(translate('actions.coverImageUpdated', getState()) as string))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const setLogoOfChallengeOwnerRequest = (challenge: IChallenge, image: File, state: RootState) => {
  const formData = new FormData() // eslint-disable-line
  formData.append('logo', image)
  return api.post(`/challenges/${challenge.id}/challengeOwner/logo`, formData, {
    headers: {
      'Authorization': state.auth.token,
      'Content-Type': 'multipart/form-data',
      'Accept-Language': state.localize.languages.filter((v: any) => v.active)[0].code,
    },
  })
}
export const setLogoOfChallengeOwner = (challenge: IChallenge, image: File): AppThunk =>
  async(dispatch, getState) => {
  try {
    const response = setLogoOfChallengeOwnerRequest(challenge, image, getState())

    if (!requestSuccess(response)) {
      throw new Error('Failed to set logo of challenge owner.')
    }

    dispatch(fetchChallenge(challenge.id))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateChallenge = (
  challenge: IChallenge,
  field: string,
  value: string,
  args: { patchField?: string } = { patchField: undefined }): AppThunk => async(dispatch, getState) => {
  try {
    const { patchField } = args

    let data

    if (patchField) {
      const newChallenge = set(challenge, field, value)
      const patchData = get(newChallenge, patchField)
      data = set({}, patchField, patchData)
    } else {
      data = set({}, field, value)
    }

    const response = await api.patch(`/challenges/${challenge.id}`, data, {
      headers: { Authorization: getState().auth.token, 'Accept-Language': getState().localize.languages.filter((v: any) => v.active)[0].code },
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not update challenge.')
    }

    dispatch(fetchChallenge(challenge.id))
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateChallengeWithImages = (challenge: IChallenge, files: any, fileIndexesToDelete: number[]): AppThunk =>
  async(dispatch, getState) => {
  const requests = [];
  const state = getState();

  try {
    if (fileIndexesToDelete?.length) {
      const promises: AxiosPromise[] = [];
      fileIndexesToDelete.forEach((index: number) => {
        promises.push(deleteDetailImageFromChallengeRequest(challenge, index, state))
      })
      await axios.all(promises)
    }

    const update = api.patch(`/challenges/${challenge.id}`, challenge);
    requests.push(update)

    if (files.coverImage) {
      requests.push(setCoverImageOfChallengeRequest(challenge, files.coverImage, state))
    }
    if (files.detailImages?.length) {
      requests.push(...addDetailImagesRequest(challenge, files.detailImages, state))
    }

    const response = await axios.all(requests)
    dispatch({ type: PROGRAM_OWNER_ACTIONS.CHALLENGE_UPDATED, payload: response[0].data })
    dispatch(
      addSuccessToast(
        translate('actions.programUpdated', getState()) as string,
      ),
    )
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

interface ISetChallengeStages {
  type: typeof ACTIONS.SET_CHALLENGE_STAGES
  payload: {
    challengeId: string
    stages: IStage[]
  }
}

const setChallengeStages = (challengeId: string, stages: IStage[]) => ({
  type: ACTIONS.SET_CHALLENGE_STAGES,
  payload: {
    challengeId,
    stages,
  },
})

export const fetchChallengeStages = (challengeId: string): AppThunk => async(
  dispatch,
) => {
  const { data } = await api.get(`/v2/challenges/${challengeId}/review/stages`)

  dispatch(setChallengeStages(challengeId, data))
}
