import { IStage } from '../models/application/IStage'
import {
  IReviewedApplication,
  IApplication,
} from '../models/application/IApplication'
import { Language } from 'react-localize-redux'
import { api } from 'api'
import { handleError } from './errorHandlerActions'
import FileDownload from 'js-file-download'
import { addSuccessToast } from 'components/common/actions/toastActions'
import { AppThunk } from "./types";


export const ACTIONS = {
  SET_STAGE_INDEX: '@dff_common_action/SET_REVIEW_STAGE_INDEX',
  SET_STAGES: '@dff_common_action/SET_REVIEW_STAGES',
  SET_APPLICATIONS_DATA: '@dff_common_action/SET_REVIEW_APPLICATIONS_DATA',
  SET_APPLICATION_INDEX: '@dff_common_action/SET_REVIEW_APPLICATION_INDEX',
  SET_SHOW_PRIMARY_LANGUAGE: '@dff_common_action/SET_REVIEW_SHOW_PRIMARY_LANGUAGE',
  SET_SHOW_SECONDARY_LANGUAGE: '@dff_common_action/SET_REVIEW_SHOW_SECONDARY_LANGUAGE',
  TOGGLE_REJECTED: '@dff_common_action/TOGGLE_REVIEW_SHOW_REJECTED',
  SET_REJECTED_SELECTED: '@dff_visa_action/SET_REVIEW_REJECTED_SELECTED',
  SET_SEARCH_TERM: '@dff_common_action/SET_REVIEW_SEARCH_TERM',
  SET_V2_REVIEW_APPLICATION: '@dff_common_action/SET_V2_REVIEW_APPLICATION',
  V2_REVIEW_APPLICATION_ERROR: '@dff_common_action/V2_REVIEW_APPLICATION_ERROR',
  START_FILE_DOWNLOAD: '@dff_common_action/START_FILE_DOWNLOAD',
  END_FILE_DOWNLOAD: '@dff_common_action/END_FILE_DOWNLOAD',
  START_SCORING: '@dff_common_action/START_SCORING',
  END_SCORING: '@dff_common_action/END_SCORING',
  START_REASSIGN: '@dff_common_action/START_REASSIGN',
  END_REASSIGN: '@dff_common_action/END_REASSIGN',
  START_FORCE_STAGE: '@dff_common_action/START_FORCE_STAGE',
  END_FORCE_STAGE: '@dff_common_action/END_FORCE_STAGE',
  START_APPROVE: '@dff_common_action/START_APPROVE',
  END_APPROVE: '@dff_common_action/END_APPROVE',
  START_REJECT: '@dff_common_action/START_REJECT',
  END_REJECT: '@dff_common_action/END_REJECT',
}

export interface StageIndexAction {
  payload: {
    stageIndex: number
  }
  type: typeof ACTIONS.SET_STAGE_INDEX
}

export interface StagesAction {
  payload: {
    stages: IStage[]
  }
  type: typeof ACTIONS.SET_STAGES
}

export interface IApplicationsData {
  applications: IApplication[]
  rejectedApplications: IApplication[]
  reviewedApplications: IReviewedApplication[]
}

export interface ApplicationsDataAction {
  payload: {
    applicationsData: IApplicationsData
    languages: Language[]
  }
  type: typeof ACTIONS.SET_APPLICATIONS_DATA
}

export interface ApplicationIndexAction {
  payload: {
    applicationIndex: number
    languages: Language[]
  }
  type: typeof ACTIONS.SET_APPLICATION_INDEX
}

export interface ShowPrimaryLanguageAction {
  payload: {
    showPrimaryLanguage: boolean
    languages: Language[]
  }
  type: typeof ACTIONS.SET_SHOW_PRIMARY_LANGUAGE
}

export interface ShowSecondaryLanguageAction {
  payload: {
    showSecondaryLanguage: boolean
    languages: Language[]
  }
  type: typeof ACTIONS.SET_SHOW_SECONDARY_LANGUAGE
}

export interface ToggleRejectedSelectedAction {
  payload: {
    languages: Language[]
  }
  type: typeof ACTIONS.TOGGLE_REJECTED
}

export interface RejectedSelectedAction {
  payload: boolean
  type: typeof ACTIONS.SET_REJECTED_SELECTED
}

export interface SearchTermAction {
  payload: {
    searchTerm: string
    languages: Language[]
  }
  type: typeof ACTIONS.SET_SEARCH_TERM
}

export interface SetV2ReviewApplication {
  payload: IApplication
  type: typeof ACTIONS.SET_V2_REVIEW_APPLICATION
}

export const setV2ReviewApplication = (data: IApplication) => ({
  payload: data,
  type: ACTIONS.SET_V2_REVIEW_APPLICATION,
})

export const loadStages = (
  challengeId: string,
  {
    onSuccess,
    onError,
  }: {
    onSuccess?: Function
    onError?: Function
  },
): AppThunk => async (dispatch) => {
  try {
    const { data: stages } = await api.get(
      `/v2/challenges/${challengeId}/review/stages`,
    )
    dispatch({ type: ACTIONS.SET_STAGES, payload: { stages } })
    onSuccess && onSuccess({ stages })
  } catch (error) {
    onError && onError(error)
  }
}
export const getReviewedApplication = (applicationId: string): AppThunk => async (
  dispatch
) => {
  try {
    const { data } = await api.get(`/v3/applications/${applicationId}/review`)
    dispatch(setV2ReviewApplication(data))
  } catch (e) {
    dispatch(v2ReviewApplicationError())
    handleError(e)
  }
}

export interface V2ReviewApplicationError {
  type: typeof ACTIONS.V2_REVIEW_APPLICATION_ERROR
}

export const v2ReviewApplicationError = () => ({
  type: ACTIONS.V2_REVIEW_APPLICATION_ERROR,
})

export interface StartDownload {
  payload: string
  type: typeof ACTIONS.START_FILE_DOWNLOAD
}

const startFileDownload = (slug: string) => ({
  payload: slug,
  type: ACTIONS.START_FILE_DOWNLOAD,
})

export interface FinishDownload {
  payload: string
  type: typeof ACTIONS.END_FILE_DOWNLOAD
}

const finishFileDownload = (slug: string) => ({
  payload: slug,
  type: ACTIONS.END_FILE_DOWNLOAD,
})

export const downloadAnswerFile = (
  applicationId: string,
  answerIndex: number,
): AppThunk => async (dispatch) => {
  const idSlug = `${applicationId}-${answerIndex}`
  try {
    dispatch(startFileDownload(idSlug))
    const { data, request } = await api.get(
      `/v3/applications/${applicationId}/answers/${answerIndex}/files`,
      {
        responseType: 'blob',
      },
    )
    FileDownload(
      data,
      request.getResponseHeader('Content-Disposition').split('filename=')[1],
    )
  } catch (error) {
    handleError('utility.fileUpload.downloadError')
  } finally {
    dispatch(finishFileDownload(idSlug))
  }
}

interface StartScoring {
  type: typeof ACTIONS.START_SCORING
}

const startScoring = () => ({
  type: ACTIONS.START_SCORING,
})

interface EndScoring {
  type: typeof ACTIONS.END_SCORING
}

const endScoring = () => ({
  type: ACTIONS.END_SCORING,
})

interface ScoreProps {
  score: number
  comment: string
  challengeId: string
  stageId: string
  applicationId: string
  reviewedApplicationId: string
  fastTrack: boolean
  useOptionalStage: boolean
  onSuccess?: () => void
}

export const scoreApplication = ({
  score,
  comment,
  challengeId,
  stageId,
  reviewedApplicationId,
  applicationId,
  fastTrack,
  useOptionalStage,
  onSuccess,
}: ScoreProps): AppThunk => async (dispatch) => {
  try {
    dispatch(startScoring())
    await api.patch(
      `/v2/challenges/${challengeId}/review/stages/${stageId}/reviewedApplications/${reviewedApplicationId}`,
      {
        comment,
        fastTrack,
        useOptionalStage,
        scoring: score,
      },
    )
    dispatch(getReviewedApplication(applicationId))
    dispatch(endScoring())
    dispatch(addSuccessToast('Review submitted'))
    if (onSuccess) {
      onSuccess()
    }
  } catch (response) {
    dispatch(endScoring())
    handleError(response)
  }
}

interface StartReassign {
  type: typeof ACTIONS.START_REASSIGN
}

const startReassign = () => ({
  type: ACTIONS.START_REASSIGN,
})

interface EndReassign {
  type: typeof ACTIONS.END_REASSIGN
}

const endReassign = () => ({
  type: ACTIONS.END_REASSIGN,
})

export const reassign = (
  challengeId: string,
  stageId: string,
  reviewedApplicationId: string,
  applicationId: string,
  userId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(startReassign())
    await api.patch(
      `/v2/challenges/${challengeId}/review/stages/${stageId}/reviewedApplications/${reviewedApplicationId}/programManager`,
      { userId },
    )
    dispatch(getReviewedApplication(applicationId))
    dispatch(endReassign())
  } catch (response) {
    dispatch(endReassign())
    handleError(response)
  }
}

interface StartForceStage {
  type: typeof ACTIONS.START_FORCE_STAGE
}

const startForceStage = () => ({
  type: ACTIONS.START_FORCE_STAGE,
})

interface EndForceStage {
  type: typeof ACTIONS.END_FORCE_STAGE
}

const endForceStage = () => ({
  type: ACTIONS.END_FORCE_STAGE,
})

export const forceStage = (
  challengeId: string,
  applicationId: string,
  srcStageId: string,
  destStageId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(startForceStage())
    await api.post(
      `/v2/challenges/${challengeId}/review/stages/${srcStageId}/applications/${applicationId}/stages/${destStageId}`,
      {},
    )
    dispatch(getReviewedApplication(applicationId))
    dispatch(endForceStage())
  } catch (response) {
    handleError(response)
  }
}

interface StartReject {
  type: typeof ACTIONS.START_REJECT
}

const startReject = () => ({
  type: ACTIONS.START_REJECT,
})

interface EndReject {
  type: typeof ACTIONS.END_REJECT
}

const endReject = () => ({
  type: ACTIONS.END_REJECT,
})

export const forceReject = (
  challengeId: string,
  stageId: string,
  applicationId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(startReject())
    await api.post(
      `/v2/challenges/${challengeId}/review/stages/${stageId}/applications/${applicationId}/reject`,
      {},
    )
    dispatch(getReviewedApplication(applicationId))
    dispatch(endReject())
  } catch (response) {
    handleError(response)
  }
}

interface StartApprove {
  type: typeof ACTIONS.START_APPROVE
}

const startApprove = () => ({
  type: ACTIONS.START_APPROVE,
})

interface EndApprove {
  type: typeof ACTIONS.END_APPROVE
}

const endApprove = () => ({
  type: ACTIONS.END_APPROVE,
})

export const forceApprove = (
  challengeId: string,
  stageId: string,
  applicationId: string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(startApprove())
    await api.post(
      `/v2/challenges/${challengeId}/review/stages/${stageId}/applications/${applicationId}/approve`,
      {},
    )
    dispatch(getReviewedApplication(applicationId))
    dispatch(endApprove())
  } catch (response) {
    handleError(response)
  }
}

export type ReviewAction =
  | StageIndexAction
  | StagesAction
  | ShowPrimaryLanguageAction
  | ShowSecondaryLanguageAction
  | ToggleRejectedSelectedAction
  | RejectedSelectedAction
  | SearchTermAction
  | ApplicationIndexAction
  | ApplicationsDataAction
  | SetV2ReviewApplication
  | V2ReviewApplicationError
  | StartDownload
  | FinishDownload
  | StartScoring
  | EndScoring
  | StartReassign
  | EndReassign
  | StartForceStage
  | EndForceStage
  | StartReject
  | EndReject
  | StartApprove
  | EndApprove
