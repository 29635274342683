import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../reducers'
import { convertDataPrograms } from '../Admin/ProgramsTable/DataConverter'
import { ColumnsProgramsTable } from '../Admin/ProgramsTable/Columns'
import { fetchUserChallenges } from 'components/common/actions/userActions'
import { convertDataAssignedApplications } from './AssignedApplicationsTable/DataConverter'
import Table from 'components/common/Table/Table'
import { ColumnsAssignedApplicationsTable } from './AssignedApplicationsTable/Columns'
import { Translate } from 'react-localize-redux'
import { fetchApplicationsToReview } from 'actions/programManagerActions'

const ManageProgramsReviewer = () => {
  const dispatch = useDispatch()
  const { applicationsToReview } = useSelector((state: RootState) => state.programManager)
  const { userId, challenges } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(fetchUserChallenges({
      userId,
      query: '?sortBy=applicationDeadline&orderBy=desc',
    }))
  }, [dispatch, userId])

  useEffect(() => {
    dispatch(fetchApplicationsToReview({
      userId,
      query: '?sortBy=dateApplication&orderBy=asc&status=pending',
    }))
  }, [dispatch, userId])

  const programsData = convertDataPrograms(challenges)
  const assignedApplicationsData = convertDataAssignedApplications(applicationsToReview)

  return (
    <>
      <Table
        data={programsData}
        columns={ColumnsProgramsTable({ goToSettings: false })}
        moreLink='/programs-list'
        title={
          <Translate id="dashboard.tables.headers.myPrograms" />
        }
      />
      <Table
        data={assignedApplicationsData}
        columns={ColumnsAssignedApplicationsTable}
        moreLink='/applications'
        title={
          <Translate id="dashboard.tables.headers.assignedApplications" />
        }
      />
    </>
  )
}

export default ManageProgramsReviewer
