import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDataPrograms } from './ProgramsTable/DataConverter'
import { convertDataFacilitators } from './FacilitatorsTable/DataConverter'
import { convertDataApplications } from './ApplicationsTable/DataConverter'
import { ColumnsProgramsTable } from './ProgramsTable/Columns'
import { ColumnsFacilitatorsTable } from './FacilitatorsTable/Columns'
import { ColumnsApplicationsTable } from './ApplicationsTable/Columns'
import { Translate } from 'react-localize-redux'
import { RootState } from '../../../../reducers'
import Table from 'components/common/Table/Table'
import { fetchApplicationsToReview } from 'actions/programManagerActions'
import { fetchFacilitators } from 'components/common/actions/facilitatorActions'
import { fetchUserChallenges } from 'components/common/actions/userActions'
import styles from './AdminTables.module.scss'

const ManageProgramsAdmin = () => {
  const dispatch = useDispatch()
  const { applicationsToReview } = useSelector(
    (state: RootState) => state.programManager,
  )
  const { facilitators } = useSelector((state: RootState) => state.facilitators)
  const { userId, challenges } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(
      fetchUserChallenges({
        userId,
        query: '?sortBy=applicationDeadline&orderBy=desc',
      }),
    )
  }, [dispatch, userId])

  useEffect(() => {
    dispatch(
      fetchApplicationsToReview({
        userId,
        query: '?sortBy=dateApplication&orderBy=asc&status=pending',
      }),
    )
  }, [dispatch, userId])

  useEffect(() => {
    dispatch(fetchFacilitators('?sortBy=applicationCount&orderBy=desc'))
  }, [dispatch])

  const programsData = convertDataPrograms(challenges)
  const facilitatorsData = convertDataFacilitators(facilitators)
  const applicationsData = convertDataApplications(applicationsToReview)

  return (
    <>
      <Table
        className={styles.table}
        data={facilitatorsData}
        columns={ColumnsFacilitatorsTable}
        title={<Translate id='dashboard.tables.headers.facilitators' />}
        moreLink='/facilitators'
      />
      <Table
        className={styles.table}
        data={programsData}
        columns={ColumnsProgramsTable({ goToSettings: true })}
        title={<Translate id='dashboard.tables.headers.programs' />}
        moreLink='programs-list'
      />
      <Table
        className={styles.table}
        data={applicationsData}
        columns={ColumnsApplicationsTable}
        title={<Translate id='dashboard.tables.headers.assignedApplications' />}
        moreLink='/applications?__showAllApplications=0'
      />
    </>
  )
}

export default ManageProgramsAdmin
