import React, { useContext } from 'react'
import styles from './FitScreen.module.scss'
import { HeaderContext } from 'components/common/AppHeader/HeaderContext'
import classNames from 'classnames'
import { FitScreenProps } from "./types";

const FitScreen = ({ children }: FitScreenProps) => {
  const { collapsed } = useContext(HeaderContext);
  return (
    <div
      className={classNames(styles.container,{
        [styles.collapse]: collapsed,
      })}
    >
      {children}
    </div>
  )
}

export default FitScreen
