import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './DragAndDropBox.module.scss'
import { debounce } from 'throttle-debounce'
import classNames from 'classnames'
import { IDragAndDropProps } from "./types";

const DragAndDropBox = ({ accept, error }: IDragAndDropProps) => {
  const [isSmall, setSmall] = useState(false)

  useEffect(() => {
    const handleResize = debounce(300, () => {
      setSmall(window.innerWidth <= 500)
    })
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const acceptArray = Array.isArray(accept)
    ? accept
    : [accept]

  const supported = acceptArray.map(extension => extension.replace(/\./, '')).join(', ')
  return (
    <div className={classNames(styles.dragAndDropBox, {
      [styles.error]: error,
    })}>
      <div className={styles.dragAndDropLabelWrapper}>
        {!isSmall && (
          <label className={styles.dragAndDropLabel} >
            <Translate id='utility.fileUpload.dragAndDrop' />
          </label>
        )}
        <label className={`${styles.dragAndDropLabel} ${styles.supportedFileList}`} >
          <Translate
            id='utility.fileUpload.supportedFiles'
            data={{ supported }}
          />
        </label>
      </div>
      <div className={styles.dragAndDropIconWrapper}>
        <FontAwesomeIcon icon={faCloudUploadAlt} className={'fa-2x'} />
      </div>
    </div>
  )
}

export default DragAndDropBox
