import React from "react";
import styles from "../Terms/Terms.module.scss";
import layout from "scss/modules/layout.module.scss";
import classnames from "classnames";

export default () => (
  <>
    <div className={classnames(layout.container, styles.container)}>
      <div className={[layout.contentFullContainer].join(" ")}>
        <p><strong>DUBAI FUTURE PROGRAMS PRIVACY POLICY</strong></p>
        <p><strong>Our Commitment and Terminology</strong></p>
        <p>Dubai Future Foundation (“<strong>DFF</strong>”) is committed to protecting the rights and privacy of all users of this Dubai Future Programs website (the “<strong>Website</strong>”). The Website is managed and operated by or on behalf of DFF. This Website is a platform for providing information in connection with various innovative programs, applications and submission request areas for private and public sector organisations both within and outside Dubai and the United Arab Emirates (the “<strong>Programs</strong>”, and each a “<strong>Program</strong>”).</p>
        <p>The terms “<strong>we</strong>”, “<strong>us</strong>” or “<strong>our(s)</strong>” hereunder refer to DFF and its affiliates and the terms “<strong>you</strong>” and “<strong>your</strong>” hereunder refer to the users of this Website.</p>
        <p><strong>Scope of Policy</strong></p>
        <p>This privacy policy (the “<strong>Privacy Policy</strong>”) (together with any other privacy policies/notices made available to you when we collect your data) the <a href="/terms">Terms of Use</a> apply to your use of this Website.</p>
        <p>This Privacy Policy explains how we, as reasonably necessary for our own purposes, collect, process and use your personal information. It also describes the choices available to you regarding our use of your personal information and how you can access and update this information. Please read the following carefully to understand our views and practices regarding your personal information and how we will treat it.</p>
        <p>In the event of any inconsistency or conflict between the above mentioned Terms of Use and this Privacy Policy, the terms of this Privacy Policy shall prevail to the extent of such conflict or inconsistency.</p>
        <p><strong>Third Party Links</strong></p>
        <p>This Website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. <strong>When you leave our Website, we encourage you to read the privacy policy and terms and conditions of use of every website you visit</strong>.</p>
        <p><strong>Collection and Use</strong></p>
        <p>We use different methods to collect data from and about you including through:</p>
        <ul>
          <li><p>direct interactions with you;</p></li>
          <li><p>automated technologies or interactions; and</p></li>
          <li><p>third parties or publicly available sources.</p></li>
        </ul>
        <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
        <ul>
          <li><p><strong>Identity Data</strong> includes your name, username or similar identifier, title, date of birth, gender and role/job title;</p></li>
          <li><p><strong>Passport Data</strong> includes the information contained within your passport and information in relation to your current and expired visa(s);</p></li>
          <li><p><strong>Contact Data</strong> includes delivery addresses, email addresses, social networking profile(s), telephone numbers and contact information;</p></li>
          <li><p><strong>Financial Data</strong> includes bank account details;</p></li>
          <li><p><strong>Employer/Company Data</strong> includes information in relation to your employer and company, including the name and role of people who are key decision makers and their contact details (including LinkedIn profile links);</p></li>
          <li><p><strong>Technical and Location Data</strong> includes internet protocol (IP) address, device type, your login data, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this Website, unique device identification numbers, broad geographic location (e.g. country or city-level location) and other technical information from your device. Collecting this information enables us to better understand the visitors who come to this Website, where they come from, and what content on this Website interests them. This information may be used for internal analytics purposes and to improve the quality and relevance of content on this Website. Some services or functions on this Website may make use of location-based information pursuant to which we may collect information about the Wi-Fi routers closest to you and the cell IDs of the towers closest to you, we may use this information for statistical purposes, not to identify you;</p></li>
          <li><p><strong>Profile Data</strong> includes your username and password, your preferences, feedback and survey responses;</p></li>
          <li><p><strong>Usage Data</strong> includes information about how you use our Website, products and services;</p></li>
          <li><p><strong>Clothing Data</strong> includes your clothing sizes; and</p></li>
          <li><p><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p></li>
        </ul>
        <p>Some of this information may be collected using cookies and similar tracking technology, as explained further under the heading “Cookies” below.</p>
        <p>When you download, install or uninstall a service containing a unique application number or when such a service searches for automatic updates, that number and information about your downloading or installation may be sent to us.</p>
        <p><strong>We use information collected:</strong></p>
        <ul>
          <li><blockquote>
            <p>to fulfil your requests and application(s), including in relation to any of the Programs on this Website;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to contact you (including providing you with communication in relation to your application(s) and the Program(s) that you have applied for and to inform you of any relevant upcoming Program(s), relevant opportunities, business opportunities and offers);</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to send related updates, if applicable;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to compile relevant statistics and to monitor the performance of the Website;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to provide access to the Website and the relevant Program(s);</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to provide you with clothing;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to respond to your questions and/or concerns (as submitted to us via the Website or e-mail); and/or</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>for such other uses you authorise and/or consent to from time to time.</p>
          </blockquote>
          </li>
        </ul>
        <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to allow you to access sections of our Website, participate in any Program(s) or to provide any of our services to you.</p>
        <p>If you share any personal data of a third party with us, please ensure that you have the relevant consent from that third party which permits you to share that personal data with us (and we will assume that you have such consent prior to sharing that data with us). Where required by any local laws, we advise you to keep a record of their consent and provide them with a copy of, or link to, this Privacy Policy. You acknowledge and agree that you shall remain fully responsible and liable for obtaining the relevant consent from that third party and for sharing such data with us.</p>
        <p><strong>Cookies</strong></p>
        <p>We use cookies to distinguish you from other users of this Website. This helps us to provide you with a good experience when you use this Website and also allows us to improve this Website.</p>
        <p><strong>Sharing Your Information</strong></p>
        <p><strong>We may disclose your personal information:</strong></p>
        <ul>
          <li><blockquote>
            <p>to the relevant public and private entities who are the owners or sponsors of the applied for Program(s) or who collect information to enable you to apply or work on a Program;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to any competent law enforcement body, regulatory or government agency, court or other third party where we believe disclosure is necessary (i) as required by applicable law or regulation or a compliance requirement (such as to comply with a court order or any other legal process), (ii) to exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any other person;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud or respond to a government request;</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to third parties in the ways that are permitted under this Privacy Policy; or</p>
          </blockquote>
          </li>
          <li><blockquote>
            <p>to third parties with your prior consent.</p>
          </blockquote>
          </li>
        </ul>
        <p><strong>Security</strong></p>
        <p>The security of your personal information is important to us. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. This Website uses appropriate technical and organisational security measures to protect your personal information. The measures used are designed to provide a level of security appropriate to the risk of unauthorised or unlawful processing of your personal information.</p>
        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to or through this Website. Any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. Please note that no website or online service can ever be guaranteed as 100% secure and you should take reasonable precautions to protect your personal information (for example, by sharing personal information only with websites that you trust, by keeping any login details secure and confidential and by using antivirus software on your devices).</p>
        <p>We will retain your information for as long as we deem necessary or appropriate, including to comply with our legal obligations, resolve disputes and enforce our agreements. If you wish to request that we no longer use your information, please contact us at the email address set out at the end of this Privacy Policy.</p>
        <p><strong>International data transfers</strong></p>
        <p>Personal information submitted through this Website may be hosted, transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different to the laws of your country. However, we have taken appropriate safeguards to require that your personal information will remain protected in accordance with this Privacy Policy.</p>
        <p><strong>Correcting and Updating Your Personal Information</strong></p>
        <p>To review and update your personal information to ensure it is accurate, please contact us at the email address set out at the end of this Privacy Policy.</p>
        <p><strong>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</strong></p>
        <p><strong>Notification of Privacy Policy Changes</strong></p>
        <p>We may update this Privacy Policy to reflect changes to our information practices or any relevant statute, regulations and/or policy. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
        <p><strong>Contact</strong></p>
        <p>You can contact us about this Privacy Policy by emailing us at <a href="mailto:info@dubaifuture.gov.ae">info@dubaifuture.gov.ae</a>.</p>
        <p>You can also contact us in order to exercise your data protection rights by emailing us at <a href="mailto:info@dubaifuture.gov.ae">info@dubaifuture.gov.ae</a>. <strong>We will respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws.</strong></p>
      </div>
    </div>
  </>
);
