import React from 'react'
import { RoundBlueResetIconProps } from "./types";

const RoundBlueReset = (props: RoundBlueResetIconProps) => (
  <svg
    id='round_blue_reset_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 50 50'
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>
      {
        '.round_blue_reset_svg__st1{fill:#317fbd}.round_blue_reset_svg__st2{fill:none}'
      }
    </style>
    <circle cx={25.5} cy={25.5} r={21.5} fill='#fff' />
    <path
      className='round_blue_reset_svg__st1'
      d='M25.5 2C12.5 2 2 12.5 2 25.5S12.5 49 25.5 49 49 38.5 49 25.5 38.5 2 25.5 2zm0 44.4C13.9 46.4 4.6 37 4.6 25.5S13.9 4.6 25.5 4.6 46.4 14 46.4 25.5s-9.3 20.9-20.9 20.9z'
    />
    <path
      className='round_blue_reset_svg__st1'
      d='M25 36.4l3.5 3.1 1.4-1.5-2-1.8c-.7.1-1.4.2-2.1.2H25z'
    />
    <path className='round_blue_reset_svg__st2' d='M28.8 16.7zM28.8 16.7z' />
    <path
      className='round_blue_reset_svg__st1'
      d='M28.8 16.7zM25.7 14.2l-3.5-2.8-1.3 1.5 2.2 1.7c.8-.3 1.7-.4 2.6-.4z'
    />
    <path
      className='round_blue_reset_svg__st1'
      d='M23.7 16.4c.6-.1 1.3-.2 2-.2h.6l.8-1-1.4-1.1c-.9 0-1.8.1-2.6.3l1.4 1-.8 1zM27.6 34.2c-.6.1-1.3.2-1.9.2h-.8l-.9 1.1 1 .9h.7c.7 0 1.4-.1 2.1-.2l-1.1-.9.9-1.1z'
    />
    <path
      className='round_blue_reset_svg__st2'
      d='M23.3 16.8l.3-.4c-.1 0-.2.1-.3.1v.3zM26 25.6v-1.9l-1 1.9zM34.8 25.6v-.3-.4-.1-.3-.1-.3-.1-.3-.1c0-.1-.1-.3-.1-.4v-.1c0-.1-.1-.2-.1-.3v-.1c0-.1-.1-.2-.1-.3v-.1c0-.1-.1-.2-.1-.2V22c0-.1-.1-.2-.1-.3v-.1c-.1-.1-.1-.2-.2-.3 0 0 0-.1-.1-.1 0-.1-.1-.2-.1-.2s0-.1-.1-.1c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1 0-.1-.1-.1-.2-.2 0 0 0-.1-.1-.1-.1-.1-.2-.2-.2-.3-.1-.1-.2-.2-.2-.3l-.1-.1-.2-.2-.1-.1-.2-.2-.1-.1-.2-.2s-.1 0-.1-.1c-.1-.1-.2-.2-.3-.2h-.1c-.1-.1-.2-.1-.2-.2l-.1-.1c-.1-.1-.1-.1-.2-.1l-.1-.1c-.1 0-.1-.1-.2-.1 0 0-.1 0-.1-.1-.1-.1-.2-.1-.3-.2h-.1c-.1-.1-.2-.1-.3-.2L26 23.7v1.9h8.8zM23.3 19.9l-.2.2-1.6-1.3 1.8-2.1v-.3c-3.9 1-6.7 4.6-6.7 8.8v.3h6.7v-5.6z'
    />
    <path
      className='round_blue_reset_svg__st2'
      d='M23.3 25.6H25l1-1.9v-7.1l-2.7 3.3zM23.3 28.6l1.7-3h-1.7zM20.9 33c.4.3.9.5 1.3.7l.4-.9-.4.9c.4.1.7.3 1.1.4v-5.5L20.9 33zM34.8 25.6H26V33l2-2.4 1.5 1.3-1.9 2.3c4-.9 7-4.4 7.2-8.6z'
    />
    <path
      className='round_blue_reset_svg__st2'
      d='M16.6 25.6c.1 3.1 1.8 5.8 4.3 7.4l2.4-4.4v-3h-6.7zM26 25.6h-1l-1.6 3v5.5c.5.1 1 .2 1.6.3l1-1.4v-7.4z'
    />
    <path
      className='round_blue_reset_svg__st1'
      d='M30.7 15.4l-1 1.8c.1.1.2.1.3.2h.1c.1.1.2.1.3.2 0 0 .1 0 .1.1.1 0 .1.1.2.1l.1.1c.1 0 .1.1.2.1l.1.1c.1.1.2.1.2.2h.1c.1.1.2.2.3.2 0 0 .1 0 .1.1l.2.2.1.1.2.2.1.1.2.2.1.1c.1.1.2.2.2.3.1.1.2.2.2.3 0 0 0 .1.1.1l.2.2.1.1c0 .1.1.1.1.2 0 0 0 .1.1.1 0 .1.1.2.1.2s0 .1.1.1c.1.1.1.2.2.3v.1c0 .1.1.2.1.3v.1c0 .1.1.2.1.2v.1c0 .1.1.2.1.3v.1c0 .1.1.2.1.3v.1c0 .1.1.3.1.4v2h2v-.3c.2-4.2-2.3-7.9-5.9-9.7zM23.7 16.4l.8-.9-1.1-.8v1.8c.1 0 .2 0 .3-.1zM16.6 25.3c0-4.2 2.9-7.7 6.7-8.8v-1.8l-.3-.2c-4.9 1.2-8.5 5.6-8.5 10.8v.3h2c.1-.1.1-.2.1-.3zM27.6 34.2l-.9 1.1 1.1.9c5-1 8.9-5.3 9-10.6h-2c-.2 4.2-3.2 7.7-7.2 8.6zM16.6 25.6h-2c.1 3.9 2.2 7.2 5.3 9.1l1-1.8c-2.5-1.5-4.2-4.2-4.3-7.3zM26.3 16.2H26v.4l.3-.4zM23.2 20.2l.1-.3v-3.1l-1.7 2.1z'
    />
    <path
      className='round_blue_reset_svg__st1'
      d='M23.3 19.9l2.7-3.4v-.4h-.3c-.7 0-1.4.1-2 .2l-.3.4v3.2zM24.9 34.3H26V33l-1.1 1.3zM28 30.6L26 33v1.4c.5 0 1.1-.1 1.6-.2l1.9-2.3-1.5-1.3z'
    />
  </svg>
)

export default RoundBlueReset
