import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styles from './SocialMediaShareButton.module.scss'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share'
import CopyToClipboard from 'react-copy-to-clipboard'
import { addSuccessToast } from 'components/common/actions/toastActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt, faLink } from '@fortawesome/free-solid-svg-icons'
import { withLocalize, LocalizeContextProps } from 'react-localize-redux'
import classNames from 'classnames'

const SocialMediaShareButton = ({ translate }: LocalizeContextProps) => {
  const [ shareExpanded, setShareExpanded ] = useState(false)
  const dispatch = useDispatch()

  const shareUrl = window.location.toString()
  return (
    <div
      className={classNames(styles.container, styles.button, {
        [styles.contractedContainer]: !shareExpanded,
      })}
      onClick={!shareExpanded
        ? () => { setShareExpanded(true)}
        : () => {}}
    >
      <span
        className={classNames(styles.shareIcons, {
          [styles.expandedShareIcons]: shareExpanded,
          [styles.contractedShareIcons]: !shareExpanded,
        })}
      >
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon
            size='3rem'
            round
            bgStyle={{ fill: 'transparent' }}
            iconFillColor={styles.white}
          />
        </FacebookShareButton>
        <EmailShareButton url={shareUrl}>
          <EmailIcon
            size='3rem'
            round
            bgStyle={{ fill: 'transparent' }}
            iconFillColor={styles.white}
          />
        </EmailShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon
            size='3rem'
            round
            bgStyle={{ fill: 'transparent' }}
            iconFillColor={styles.white}
          />
        </LinkedinShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon
            size='3rem'
            round
            bgStyle={{ fill: 'transparent' }}
            iconFillColor={styles.white}
          />
        </TwitterShareButton>
        <CopyToClipboard
          text={shareUrl}
          onCopy={() => {dispatch(addSuccessToast(translate('utility.copied').toString()))}}
        >
          <button
            className={styles.shareLinkButton}
            aria-label={translate('utility.copyUrl') as string}
          >
            <FontAwesomeIcon
              icon={faLink}
              className='fa'
            />
          </button>
        </CopyToClipboard>
      </span>
      <div className={styles.showShareButton}>
        <button
          onClick={() => setShareExpanded(current => !current)}
          aria-label={translate('utility.share') as string}
        >
          <FontAwesomeIcon
            icon={faShareAlt}
            className='fa'
          />
        </button>
      </div>
    </div>
  )
}

export default withLocalize(SocialMediaShareButton)
