import React from 'react'
import style from './DisplayValue.module.scss'
import classNames from 'classnames'
import { DisplayValueProps } from "./types";

const DisplayValue = ({
  isRtl,
  label,
  value,
}: DisplayValueProps) => {
  return (
    <div className={classNames(style.valueBox, isRtl ? style.rtl : '')}>
      <div className={style.label}>{label}</div>
      <div className={style.value}>{value}</div>
    </div>
  )
}

export default DisplayValue
