import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import styles from './ViewChallenge.module.scss'
import { RootState } from 'reducers'
import {
  fetchAllChallenges,
  fetchChallenge,
  fetchRelatedChallenges,
} from 'components/common/actions/challengesActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import Button from 'components/common/shared/Button'
import SocialMediaShareButton from 'components/common/SocialMediaShareButton/SocialMediaShareButton'
import classNames from 'classnames'
import { Translate, withLocalize, } from 'react-localize-redux'
import { getShortDate, tagTechnologies } from './utils'
import { ApplicatioMode, IChallenge } from 'models/challenge/challenge'
import { HeaderContext } from '../Header/HeaderContext'
import ViewChallengeSplashInfo from './ViewChallengeSplashInfo'
import RelatedChallengesList from './RelatedChallengesList'
import { fetchUserReviews } from '../actions/userActions'
import { DateTime } from 'luxon'
import { renderToString } from 'react-dom/server'
import FitScreen from 'components/common/Layout/FitScreen'
import { ViewChallengeProps } from "./types";

const YOUTUBE_URL_REGEX = /(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^& \n<]+)(?:[^ \n<]+)?/g

const replaceYoutubeUrls = (html?: string) => {
  if (!html) return ''

  return html.replace(YOUTUBE_URL_REGEX, (url: string) => {
    const idMatch = url.match(/[a-zA-Z0-9_-]{11}/)
    const id = idMatch && idMatch.length ? idMatch[0] : ''
    if (id) {
      return renderToString(
        <div className={styles.videoWrapper}>
          <iframe
            title='Programme Video'
            src={`https://www.youtube.com/embed/${id}`}
            frameBorder='0'
            allowFullScreen
          />
        </div>,
      ).replace('allowfullscreen=""', 'allowFullScreen={true}')
    }
    return url
  })
}

const ViewChallenge = ({
  activeLanguage,
  challengeProp,
  supportImages,
  paddingBottom,
  previewCoverImage,
}: ViewChallengeProps) => {
  const { collapsed, isSmall } = React.useContext(HeaderContext)

  const [backgroundImageIndex, setBackgroundImageIndex] = useState(0)

  const {
    userId,
    reviews,
    role: { rank },
  } = useSelector((state: RootState) => state.user)
  const loggedIn = rank >= 0

  const backgroundImageIndexTimer = useRef<any>()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id = '' } = useParams()

  const isAltLang = activeLanguage ? activeLanguage.code === 'ar' : false

  const belowFoldRef = useRef(null)

  const { challenges } = useSelector((state: RootState) => state.challenges)
  const challenge: IChallenge | undefined = challengeProp ?? challenges[id]

  /**
   * Fetch user reviews to see if the current challenge is applied by this user
   */
  useEffect(() => {
    if (userId) {
      dispatch(fetchUserReviews(userId))
    }
  }, [userId, dispatch])

  useEffect(() => {
    if (!challengeProp) dispatch(fetchChallenge(id))
    dispatch(fetchRelatedChallenges(id))
  }, [id, dispatch, challengeProp])

  const getSupportImages = React.useCallback(() => {
    const useCoverImage = previewCoverImage || challenge?.coverImage
    return [
      ...(useCoverImage ? [useCoverImage] : []),
      ...(supportImages ?? challenge?.detailImages ?? []),
    ]
  }, [previewCoverImage, challenge, supportImages])

  useEffect(() => {
    if (getSupportImages().length > 1) {
      backgroundImageIndexTimer.current = window.setInterval(() => {
        setBackgroundImageIndex(backgroundImageIndex + 1)
      }, 4000)
      return () => {
        window.clearInterval(backgroundImageIndexTimer.current)
      }
    }
  }, [backgroundImageIndex, getSupportImages])

  const hasUserAppliedThisChallenge =
    challenge?.id &&
    !!reviews?.find((review) => review.challenge?.id === challenge?.id)

  const allRelatedChallenges = useSelector(
    (state: RootState) => state.challenges.relatedChallenges[id],
  )

  useEffect(() => {
    if (allRelatedChallenges && allRelatedChallenges.length === 1) {
      // a challenge is always related to itself
      dispatch(fetchAllChallenges())
    }
  }, [allRelatedChallenges, dispatch])

  const relatedChallenges =
    allRelatedChallenges && allRelatedChallenges.length > 1
      ? Object.values(allRelatedChallenges.filter((c) => c.id !== id))
      : Object.values(challenges).filter((c) => c.id !== id)

  if (challenge) {
    const images = getSupportImages()
    const index = backgroundImageIndex % images.length
    const backgroundImage = images[index]

    const name = isAltLang ? challenge.nameAlt : challenge.name
    const description = isAltLang
      ? challenge.descriptionAlt
      : challenge.description

    const { oneliner } = challenge
    const technologies = tagTechnologies(challenge.technologies)
    const deadlineString = challenge.applicationDeadline ? (
      // @ts-ignore
      <Translate
        id='challenge.deadline'
        data={{
          date: getShortDate(challenge.applicationDeadline?.toString()),
        }}
      />
    ) : (
      ''
    )
    const shouldRenderApplyButton =
      !(
        // if user is an applicant...
        (
          rank < 1 &&
          // and there's a deadline
          challenge.applicationDeadline &&
          // which is earlier than now
          DateTime.fromISO(challenge.applicationDeadline.toString()) <
            DateTime.local()
        )
        // and if the user is not reviewer
      ) && rank !== 1

    const onApply = () => {
      if (rank >= 3) {
        return history.push(`/programs/${id}/settings`)
      }
      if (shouldLoginToApply) {
        window.location.replace(
          `${process.env.REACT_APP_AUTH_URL}?app_id=programs&next=/programs/${id}`,
        )
      } else if (challenge.applicationMode === ApplicatioMode.ORGANISATION) {
        history.push(`/programs/${id}/selectOrganisation`)
      } else if (hasUserAppliedThisChallenge) {
        history.push(`/programs/${id}/application`)
      } else if (loggedIn) {
        history.push(`/programs/${id}/application/individual`)
      } else {
        // user is not logged in, and they don't need to. Must be an email application
        history.push(`/programs/${id}/application/validateEmail`)
      }
    }
    const shouldLoginToApply =
      !loggedIn && challenge.applicationMode !== ApplicatioMode.EMAIL

    return (
      <div className={styles.container} style={{ paddingBottom }}>
        <FitScreen>
          <div
            className={classNames(styles.splash, {
              [styles.collapsed]: collapsed,
            })}
            style={{
              backgroundImage: `linear-gradient(to left, transparent, black), url(${backgroundImage})`,
            }}
          >
            <div className={styles.splashContents}>
              <div className={styles.summary}>
                {challenge.applicationDeadline && (
                  <span className={styles.deadline}>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className={classNames('fa', styles.deadlineIcon)}
                    />
                    {deadlineString}
                  </span>
                )}
                <h1>{name}</h1>
                <span className={styles.technologies}>
                  {technologies.join(' ')}
                </span>
                <div className={styles.oneliner}>{oneliner}</div>
                <span className={styles.buttons}>
                  <SocialMediaShareButton />
                  {shouldRenderApplyButton && (
                    <Button
                      type='border'
                      onClick={onApply}
                      className={styles.applyButton}
                    >
                      <Translate
                        id={
                          shouldLoginToApply
                            ? 'challenge.loginToApply'
                            : rank >= 2
                            ? 'challenge.edit'
                            : 'challenge.applyNow'
                        }
                      />
                    </Button>
                  )}
                  {isSmall && (
                    <ViewChallengeSplashInfo belowFoldRef={belowFoldRef} />
                  )}
                </span>
              </div>
              {!isSmall && (
                <ViewChallengeSplashInfo belowFoldRef={belowFoldRef} />
              )}
            </div>
          </div>
        </FitScreen>
        <div ref={belowFoldRef} className={styles.belowFold}>
          <div className={styles.details}>
            <div className={styles.description}>
              <span
                dangerouslySetInnerHTML={{
                  __html: replaceYoutubeUrls(description) || '',
                }}
              />
            </div>
          </div>
          <div className={styles.otherChallenges}>
            <h3 className={styles.otherChallengesTitle}>
              <Translate
                id={
                  allRelatedChallenges && allRelatedChallenges.length > 1
                    ? 'challenge.relatedChallenges'
                    : 'challenge.otherChallenges'
                }
              />
            </h3>
            <RelatedChallengesList
              relatedChallenges={relatedChallenges}
              isAltLang={isAltLang}
            />
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default withLocalize(ViewChallenge)
