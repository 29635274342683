import { createReducer } from "./createReducer";
import { Action } from "redux";

import { ACTIONS } from '../actions/programManagerActions'
import { LOGOUT } from '../components/common/actions/authActions'
import { IProgramManagerState, Reducer } from "./types";

const defaultState: IProgramManagerState = {
  loadingApplications: false,
  reviewedApplication: null,
  activeApplication: null,
  cancelLoadApplicationsToken: undefined,
  applicationsToReview: [],
  applicationsToReviewCount: 0,
  allApplications: [],
  allApplicationsCount: 0,
}

const cancelPreviousRequest = (state: IProgramManagerState) => {
  if (state.loadingApplications && state.cancelLoadApplicationsToken) {
    state.cancelLoadApplicationsToken.cancel('request aborted')
  }
}

const reducer: Reducer<IProgramManagerState, Action> = {
  [ACTIONS.ERROR_LOADING_REVIEWED_APPLICATIONS]: (state) => {
    cancelPreviousRequest(state)
    return {
      ...state,
      loadingApplications: false,
      cancelLoadApplicationsToken: undefined,
    }
  },
  [ACTIONS.LOADING_REVIEWED_APPLICATIONS]: (state, {payload}) => {
    cancelPreviousRequest(state)
    return {
      ...state,
      loadingApplications: true,
      cancelLoadApplicationsToken: payload,
    }
  },
  [ACTIONS.LOADED_REVIEWED_APPLICATIONS]: (state, {payload}) => {
    return {
      ...state,
      loadingApplications: false,
      reviewedApplication: payload,
      cancelLoadApplicationsToken: undefined,
    }
  },
  [ACTIONS.CLEAR_REVIEWED_APPLICATION]: (state) => ({...state, reviewedApplication: undefined}),
  [ACTIONS.FETCH_APPLICATIONS_TO_REVIEW]: (state, {payload}) => ({
    ...state,
    applicationsToReview: payload.data,
    applicationsToReviewCount: payload.count,
  }),
  [ACTIONS.SET_ACTIVE_APPLICATION]: (state, {payload}) => ({...state, activeApplication: payload}),
  [ACTIONS.CLEAR_ACTIVE_APPLICATION]: (state) => ({...state, activeApplication: undefined}),
  [ACTIONS.SET_ALL_APPLICATIONS]: (state, {payload}) => ({
    ...state,
    allApplications: payload.data,
    allApplicationsCount: payload.count,
  }),
  [LOGOUT]: () => defaultState,
}

export default createReducer<IProgramManagerState, Action>(reducer, defaultState);