import styles from '../ApplicationPanel.module.scss'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter, RouteComponentProps, useParams } from 'react-router'
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux'

import { RootState } from '../../../../reducers'

import FormPanel from './FormPanel/FormPanel'
import { IAnswer } from '../../models/application/IAnswer'
import { IQuestion } from '../../models/application/IQuestion'
import { addErrorToast } from '../../actions/toastActions'
import Form from '../../Form/Form'

import SectionList from '../../SectionList/SectionList'

import { downloadFileFromBackend } from '../ApplicationPanel'
import { isRtl } from '../../utils/translations'
import Loader from '../../Loader/Loader'
import { debounce } from 'throttle-debounce'
import {
  setCompletionPercentDistinctUntilChanged,
  setActiveSection,
} from '../../actions/applicationActions'
import { SectionDescription } from './SectionDescription'
import FormSection from '../../Form/FormSection'
import { Row, Column } from '../../Layout'
import { fakeStartup, singleChallenge } from './constants'
import { applicationStateContext } from './ApplicationFormState'
import { ApplicatioMode } from 'models/challenge/challenge'
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs'
import ApplicationFooter from '../ApplicationFooter'
import HomeIcon from 'components/common/ProgramSettings/HomeIcon'
import { currentProgramUrl } from "../utils";

export interface ApplicationFormProps
  extends LocalizeContextProps,
    RouteComponentProps {}

const ApplicationForm = ({ translate, history }: ApplicationFormProps) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { localize } = useSelector((state: RootState) => state)
  const isAltLang = isRtl(localize)
  const {
    // decompose form data
    applicationId,
    applicationMode,

    // challenge
    challenges,
    // form data
    sections,
    answers,
    questions,
    loading,
    readOnly,

    initialize,

    // mutations
    updateSingleAnswer,
    updateMultipleChoiceAnswer,
    updateFile,

    // computed properties
    disabledQuestions,
    isSectionCompleted,
  } = React.useContext(applicationStateContext)

  // figure out the selected challenge
  const challenge = challenges?.[id]

  const {
    selectedOrganization,
    selectedChallenge,
    activeSection,
  } = useSelector((state: RootState) => state.application)

  React.useEffect(() => {
    initialize && initialize()
    // `initialize` would not change, so no need to add it to dependency
    // eslint-disable-next-line
  }, [])

  const handleFileDownload = (answerIndex: number, onFinish: () => void) => {
    if (
      (applicationMode === 'FID_ORGANISATION' && !selectedOrganization) ||
      !selectedChallenge
    ) {
      console.error('Startup and challenge must be defined')
      addErrorToast(translate('errorGeneric') as string)
    } else {
      downloadFileFromBackend({
        answerIndex,
        onFinish,
        applicationMode,
        applicationId: applicationId as string,
      })
    }
  }

  const getCompletionPercent = debounce(
    500,
    (questions: IQuestion[], answers: IAnswer[]): void => {
      dispatch(
        setCompletionPercentDistinctUntilChanged({
          questions,
          answers,
        }),
      )
    },
  )

  /**
   * This method is used to change sections when user clicks on section title on the left
   * bar
   */
  const handleSectionTabClicked = (sectionIndex: number) => {
    if (sectionIndex === 0 && !fakeStartup) {
      if (singleChallenge) {
        history.push('/selectOrganisation')
      } else if (applicationMode === ApplicatioMode.INDIVIDUAL) {
        history.push(`/programs/${selectedChallenge}/application/individual`)
      } else {
        history.push(`/programs/${selectedChallenge}/selectOrganisation`)
      }
    } else {
      const useIndex = fakeStartup ? sectionIndex : sectionIndex - 1 // compensate for fake organisation tab
      window.scrollTo(0, 0)
      dispatch(setActiveSection(useIndex))
    }
  }

  /**
   * When the user clicks on the complete section tick button,
   * the next section is selected.
   */
  const onHandleCompleteClick = () => {
    const totalSectionCount = fakeStartup
      ? sections.length
      : sections.length - 1
    const nextSection = activeSection + 1

    if (nextSection < totalSectionCount) {
      dispatch(setActiveSection(nextSection))
    }
  }

  getCompletionPercent(questions, answers)

  if (loading) {
    return <Loader />
  }

  const thisSectionComplete = isSectionCompleted!(activeSection)

  const isSectionTabComplete = (index: number) => {
    return isSectionCompleted!(fakeStartup ? index : index - 1)
  }

  const correctedSectionIndex = fakeStartup ? activeSection : activeSection + 1

  return (
    <div className={styles.container}>
      <Row className={styles.breadcrumbsContainer}>
        <Breadcrumbs
          crumbs={[
            {
              link: '/',
              label: <HomeIcon />,
            },
            {
              link: process.env.REACT_APP_PROGRAMS_URL,
              label: translate('applications.programs'),
            },
            {
              link: currentProgramUrl(challenge?.name),
              label: challenge?.name || translate('applications.program'),
            },
            {
              label: translate('applications.apply'),
            },
          ]}
        />
      </Row>
      <Row className={styles.contentContainer}>
        <Column nav className={styles.sectionTabColumn}>
          <SectionList
            sections={sections}
            displayStatus
            onSelected={handleSectionTabClicked}
            selectedSection={correctedSectionIndex}
            isSectionComplete={isSectionTabComplete}
            isAltLang={isAltLang}
          />
        </Column>
        <Column>
          <Form activeSectionIndex={correctedSectionIndex}>
            {sections[correctedSectionIndex].description && (
              <FormSection>
                <SectionDescription
                  sections={sections}
                  index={correctedSectionIndex}
                />
              </FormSection>
            )}
            <FormPanel
              questions={questions}
              answers={answers}
              updateAnswerMultiple={updateMultipleChoiceAnswer!}
              updateAnswerSingle={updateSingleAnswer!}
              sectionCompleteness={thisSectionComplete}
              listOfJumpToQuestionsDisabled={disabledQuestions!}
              sectionNumber={activeSection}
              onFileChange={updateFile!}
              downloadFile={handleFileDownload}
              handleCompleteClick={onHandleCompleteClick}
              isAltLang={isAltLang}
              readOnly={readOnly}
            />
            <div className={styles.getToNextSectionNote}>
              {!thisSectionComplete && (
                <Translate id='visaApplication.nextSectionNote' />
              )}
            </div>
          </Form>
        </Column>
      </Row>
      <ApplicationFooter
        readOnly={readOnly}
        submitLabel='visaApplication.submitButton.label'
        hasPercentage
      />
    </div>
  )
}

const ApplicationFormWithRouter = withRouter(withLocalize(ApplicationForm))

export default ApplicationFormWithRouter
