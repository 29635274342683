import { DateTime } from 'luxon'
import { IChallenge } from '../../../../../models/challenge/challenge'
import { IProgramsTableRow } from "../../types";

const getRemainingDays = (deadline: string | undefined) => {
  if (deadline) {
    const deadlineDate = DateTime.fromISO(deadline)
    const todayDate = DateTime.local()
    return Math.round(deadlineDate.diff(todayDate, 'days').as('days'))
  }
  return undefined
}

export const convertDataPrograms = (challenges: IChallenge[]): IProgramsTableRow[] => {
  return challenges.map((challenge: IChallenge) => ({
      // @ts-ignore - applicationDeadline is a string
      remainingDays: getRemainingDays(challenge.applicationDeadline),
      name: challenge.name,
      applicationCount: challenge.metaData && challenge.metaData.applicationCount,
      // @ts-ignore
      id: challenge._id,
    }))
}
