import React from 'react'
import { IStage } from '../models/application/IStage'
import EditStageGeneral from './Stages/EditStageGeneral'
import EditStageAssignment from './Stages/EditStageAssignment'
import EditStageEmail from './Stages/EditStageEmail'
import EditStageScoring from './Stages/EditStageScoring'
import { handleFieldChangeType } from './GateSettingsTypes'
import { useSelector, useDispatch } from 'react-redux'
import { ICommonState } from '../utils/state'
import { ACTIONS } from '../actions/gateSettingsActions'
import { IGateSectionsProps } from "./types";

const GateSections = ({ sections, hideGoldenTicket, programOwnerId }: IGateSectionsProps) => {
  const {
    changedStages,
    unchangedStages,
    stageIndex,
    sectionIndex,
    availableReviewers,
  } = useSelector((state: ICommonState) => state.gate)
  const sectionId = sections[sectionIndex].id
  const dispatch = useDispatch()
  const currentStage = changedStages[stageIndex]
  const getAvailableReviewersForStage = (stageIndex: number) => {
    return availableReviewers.filter(reviewer => {
      return !userWithEmailIsAssignedToStage(reviewer.email, stageIndex)
    })
  }

  const userWithEmailIsAssignedToStage = (
    email: string,
    stageIndex: number,
  ) => {
    return changedStages[stageIndex].programManagers.some(assignedReviewer => {
      return assignedReviewer.email === email
    })
  }

  const handleFieldChange = (stageIndex: number) => ({
    field,
    newValue,
  }: handleFieldChangeType) => {
    dispatch({
      type: ACTIONS.CHANGE_STAGE,
      payload: { index: stageIndex, value: newValue, field },
    })
  }

  const addReviewer = (reviewerIndex: number | number[]) => {
    if (Array.isArray(reviewerIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    const updatedProgramManagers = [
      ...currentStage.programManagers,
      getAvailableReviewersForStage(stageIndex)[reviewerIndex],
    ]
    handleFieldChange(stageIndex)({
      newValue: updatedProgramManagers,
      field: 'programManagers',
    })
  }

  const removeReviewer = (reviewerId: string) => {
    const updatedProgramManagers = currentStage.programManagers.filter(
      manager => manager.id !== reviewerId,
    )
    handleFieldChange(stageIndex)({
      newValue: updatedProgramManagers,
      field: 'programManagers',
    })
  }

  const handleFastTrackChange = (stageIndex: number) => (newValue: string) => {
    handleFieldChange(stageIndex)({
      newValue: newValue,
      field: 'fastTrack',
    })
    handleFieldChange(stageIndex)({
      newValue:
        newValue !== undefined &&
        newValue !== null &&
        newValue.toString() !== '',
      field: 'canBeFastTracked',
    })
  }

  const handleScoringChange = (stageIndex: number) => (
    newValue: string | number | boolean,
    field: string,
  ) => {
    const updatedScoringTool = {
      ...currentStage.scoringTool,
      [field]: newValue,
    }
    handleFieldChange(stageIndex)({
      newValue: updatedScoringTool,
      field: 'scoringTool',
    })
  }

  const handlePublicPrivateChange = (stageIndex: number) => (
    newValue: number | number[],
  ) => {
    if (Array.isArray(stageIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    handleFieldChange(stageIndex)({
      newValue: newValue === 1,
      field: 'private',
    })
  }

  switch (sectionId) {
    case 'general':
      return (
        <EditStageGeneral
          onFieldChange={handleFieldChange(stageIndex)}
          onPublicPrivateChange={handlePublicPrivateChange(stageIndex)}
          hideGoldenTicket={hideGoldenTicket}
          programOwnerId={programOwnerId}
        />
      )
    case 'assignment':
      return (
        <EditStageAssignment
          onFieldChange={handleFieldChange(stageIndex)}
          availableReviewers={getAvailableReviewersForStage(stageIndex)}
          addReviewer={addReviewer}
          removeReviewer={removeReviewer}
        />
      )
    case 'email':
      return (
        <EditStageEmail
          onFieldChange={handleFieldChange(stageIndex)}
          isFirstGate={stageIndex === 0}
        />
      )
    case 'scoring':
      const stages: IStage[] = []
      let isNextIndex = -1
      unchangedStages.forEach((stage, index) => {
        if (stage.id === currentStage.id) {
          isNextIndex = unchangedStages.length > index + 2 ? index + 2 : -1
        }
        if (index >= isNextIndex && isNextIndex !== -1) {
          stages.push(stage)
        }
      })
      return (
        <EditStageScoring
          stages={stages}
          onChangeFastTrack={handleFastTrackChange(stageIndex)}
          onChange={handleScoringChange(stageIndex)}
          onGeneralFieldChange={handleFieldChange(stageIndex)}
        />
      )
    default:
      throw new Error('Unexpected section type')
  }
}

export default GateSections
