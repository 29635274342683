import React, { useEffect, useState, useCallback } from 'react'
import styles from './ProgramSettings.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { RootState } from 'reducers'
import GateListing from 'components/common/GateListing/GateListing'
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs'

import { fetchChallenge } from 'components/common/actions/challengesActions'
import Tabs from '../Tabs/Tabs'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import ApplicationPanel from '../Application/ApplicationPanel'
import { ApplicationStateProvider } from '../Application/ApplicationForm/ApplicationFormState'
import ProgramDetailsForm from './ProgramDetailsForm'
import { selectChallenge } from '../actions/applicationActions'
import { api } from '../../../api'
import GateSettings from '../GateSettings/GateSettings'
import { fetchReview } from '../../../actions/programOwnerActions'
import GoBackButton from '../GoBackButton/GoBackButton'
import HomeIcon from './HomeIcon'
import AutoReview from 'components/common/AutoReview/AutoReview'

const ProgramSettings = ({ translate }: LocalizeContextProps) => {
  const [selectedGate, selectGate] = useState('')
  const dispatch = useDispatch()
  const [isEditMode, setIsEditMode] = useState(true)

  const { id = '' } = useParams()
  const { challenges } = useSelector((state: RootState) => state.challenges)
  const challenge = challenges[id]
  const user = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(selectChallenge(id))
    dispatch(fetchChallenge(id))
    dispatch(fetchReview(id))
  }, [id, dispatch])

  const goToGatesList = () => selectGate('')
  const onSaveForm = useCallback(() => {
    dispatch(fetchChallenge(id))
  }, [dispatch, id])

  if (!challenge) {
    return null
  }

  return (
    <>
      <div id='previewChallengePortal' />
      <div
        className={styles.container}
        style={{ height: isEditMode ? 'auto' : '1px' }}
      >
        <div className={styles.navContainer}>
          <GoBackButton />
          <Breadcrumbs
            crumbs={[
              {
                label: <HomeIcon />,
                link: '/',
              },
              {
                link: `/programs-list/?managedBy=${challenge?.programOwner?.name}`,
                label: challenge?.programOwner?.name || '',
              },
              {
                label: challenge?.name || '',
              },
            ]}
          />
        </div>
        <div className={styles.bottomContainer}>
          <Tabs
            tabs={[
              {
                heading: translate('programSettings.details').toString(),
                content: (
                  <ProgramDetailsForm
                    challenge={challenge}
                    user={user}
                    isEditMode={isEditMode}
                    setIsEditMode={setIsEditMode}
                    onSaveForm={onSaveForm}
                  />
                ),
              },
              {
                heading: translate('programSettings.gates').toString(),
                content: selectedGate ? (
                  <GateSettings
                    api={api}
                    challengeId={id}
                    programOwnerId={challenge.programOwner!.id}
                    stageId={selectedGate}
                    goBack={goToGatesList}
                  />
                ) : (
                  <GateListing challengeId={id} onSettingsClick={selectGate} />
                ),
              },
              {
                heading: translate('admin.applicationForm'),
                content: (
                  <ApplicationStateProvider>
                    <ApplicationPanel adminMode={user && user.role.rank >= 2} />
                  </ApplicationStateProvider>
                ),
              },
              {
                heading: translate('admin.autoReview'),
                content: (
                  <AutoReview programOwnerId={challenge.programOwner!.id} />
                ),
              },
              {
                heading: (
                  <Link to={`/programs/${challenge?.id}/applications`}>
                    {translate('programSettings.viewApplications')}
                  </Link>
                ),
                content: '',
                className: styles.applicationsLink,
              },
            ]}
            hasBorderlessTab
            initialTab={0}
          />
        </div>
      </div>
    </>
  )
}

export default withLocalize(ProgramSettings)
