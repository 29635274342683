import React from 'react'
import styles from './UsersRoleList.module.scss'
import Spinner from '../Spinner/Spinner'

import UserDropdown from './UserDropdown'
import { Email, Name, UserImage } from './SearchHighlight'
import dropdownArrow from '../../assets/icons/dropdownArrow.svg'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useLocation } from 'react-router'
import escapeRegExp from 'lodash.escaperegexp'
import { updateRole as rawUpdateRole } from 'actions/adminActions'
import Table from 'components/common/Table/Table'
import { Translate } from 'react-localize-redux'

// typings
import { CellProps } from 'react-table'
import { IUser } from 'models/user/user'
import { useDispatch } from 'react-redux'
import { UserRoleListProps } from "./types";

const UsersRoleList = ({
  users,
  roles,
  loggedInUser,
  loading,
}: UserRoleListProps) => {
  /**
   * Functions from hooks
   */
  const dispatch = useDispatch()
  const { search } = useLocation()
  const searchParams = React.useMemo(() => new URLSearchParams(search), [
    search,
  ])
  const updateRole = (userId: string, role: any) =>
    dispatch(rawUpdateRole(userId, role))

  const emailRegExp = new RegExp(searchParams.get('email') || 'undefined', 'i')

  // regex having a form /(firstName|lastName)/i
  // unless theres a user with his / her name containing the word "undefined", everything should work properly here.....
  const nameRegExp = new RegExp(
    `(${escapeRegExp(
      searchParams.get('firstName') || 'undefined',
    )}|${escapeRegExp(searchParams.get('lastName') || 'undefined')})`,
    'i',
  )

  /**
   * Component state
   */
  const [openRoleDropdownForUser, setOpenRoleDropdownForUser] = React.useState(
    null as IUser | null,
  )

  // constant derived state functions
  const getUserFullName = React.useMemo(
    () => (user: IUser) => {
      return `${user.firstName ? user.firstName : ''} ${
        user.lastName ? user.lastName : ''
      }`.trim()
    },
    [],
  )

  const tableColumns = [
    {
      Header: ' ',
      columns: [
        {
          Header: <Translate id='admin.name' />,
          accessor: 'userId',
          id: 'info',
          Cell: ({ row }: CellProps<IUser>) => {
            const user = row.original
            return (
              <>
                <UserImage user={user} />
                <Name name={getUserFullName(user)} regex={nameRegExp} />
              </>
            )
          },
        },
        {
          Header: <Translate id='utility.email' />,
          accessor: 'email',
          id: 'email',
          Cell: ({ row }: CellProps<IUser>) => {
            const { email } = row.original
            return <Email email={email} regex={emailRegExp} />
          },
        },
        {
          Header: <Translate id='admin.role' />,
          accessor: 'userId',
          id: 'role',
          Cell: ({ row }: CellProps<IUser>) => {
            const user = row.original
            return (
              <div
                className={styles.wrapper}
                onClick={() => setOpenRoleDropdownForUser(user)}
              >
                <div className={styles.menu}>
                  <p className={styles.existingRole}>
                    <Translate id={`admin.roles.${user.role?.rank}`} />
                  </p>
                  <img
                    className={styles.arrow}
                    src={dropdownArrow}
                    alt='Roles'
                  />
                </div>
                {openRoleDropdownForUser?.email === user.email && (
                  <UserDropdown
                    user={user}
                    roles={roles}
                    close={() => setOpenRoleDropdownForUser(null)}
                    updateRole={updateRole}
                    loggedInUser={loggedInUser}
                  />
                )}
              </div>
            )
          },
        },
      ],
    },
  ]

  return (
    <div className={styles.searchBarRow}>
      {loading ? (
        <Spinner />
      ) : (
        <Table
          disableSorting
          title={<Translate id='admin.manageUserRights' />}
          data={users}
          columns={tableColumns}
        />
      )}
    </div>
  )
}

export default UsersRoleList
