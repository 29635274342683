import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import classes from './Summary.module.scss'
import { Translate, withLocalize } from 'react-localize-redux'
import { DateTime } from 'luxon'
import DisplayRow from './DisplayRow'
import { IComparisonValue } from 'components/common/FormInput/types'
import { getCountryList, getSectorList } from 'components/common/actions/gateSettingsActions'
import { SummaryProps } from "./types";

const Summary = ({ translate, template }: SummaryProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCountryList())
    dispatch(getSectorList())
  }, [dispatch])

  const { countryList, sectorList } = useSelector((state: RootState) => state.gate)

  const selectedCountries = template.applicationEligibility.location?.map(selectedCode => {
    const country = countryList.find(({ code }) => code === selectedCode)
    if (country) return country.en
    return 'Unknown'
  })

  const selectedSectors = template.applicationEligibility.sector?.map(selectedCode => {
    const sector = sectorList.find(({ code }) => code === selectedCode)
    if (sector) return sector.name
    return 'Unknown'
  })

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <table>
          <tbody>
            {/*
              // @ts-ignore */}
            <tr colSpan={2}>
              <td className={classes.sectionHeading}>
                <Translate id='autoReview.applicationEligibility.heading' />
              </td>
            </tr>
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.company') as string}
              value={template.applicationEligibility.company}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.location') as string}
              value={selectedCountries}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.sector') as string}
              value={selectedSectors}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.employees') as string}
              value={template.applicationEligibility.employees as IComparisonValue}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.minimumScore') as string}
              value={template.applicationEligibility.minimumScore}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.minDate') as string}
              value={template.applicationEligibility.minDate && DateTime.fromISO(template.applicationEligibility.minDate).toLocaleString(DateTime.DATE_SHORT)}
            />
            <DisplayRow
              heading={translate('autoReview.applicationEligibility.maxDate') as string}
              value={template.applicationEligibility.maxDate && DateTime.fromISO(template.applicationEligibility.maxDate).toLocaleString(DateTime.DATE_SHORT)}
            />
            {/*
              // @ts-ignore */}
            <tr colSpan={2}>
              <td className={classes.sectionHeading}>
                <Translate id='autoReview.accountEligibility.heading' />
              </td>
            </tr>
            <DisplayRow
              heading={translate('autoReview.accountEligibility.futureIdCompletion') as string}
              value={template.accountEligibility.futureIdCompletion
              ? `${template.accountEligibility.futureIdCompletion}%`
              : ''}
            />
            <DisplayRow
              heading={translate('autoReview.accountEligibility.futureId') as string}
              value={template.accountEligibility.futureId}
            />
          </tbody>
        </table>
      </div>
      <div className={classes.column}>
        <table>
          <tbody>
            {/*
              // @ts-ignore */}
            <tr colSpan={2}>
              <td className={classes.sectionHeading}>
                <Translate id='autoReview.previousApplications.heading' />
              </td>
            </tr>
            <DisplayRow
              heading={translate('autoReview.previousApplications.goldenTicket') as string}
              value={template.previousApplications.goldenTicket}
            />
            <DisplayRow
              heading={translate('autoReview.previousApplications.gateApprovals') as string}
              value={template.previousApplications.gateApprovals as IComparisonValue}
            />
            <DisplayRow
              heading={translate('autoReview.previousApplications.pendingApplications') as string}
              value={template.previousApplications.pendingApplications as IComparisonValue}
            />
            {template.onStages.length > 0 && (
              <>
                {/*
                  // @ts-ignore */}
                <tr colSpan={2}>
                  <td className={classes.sectionHeading}>
                    <Translate id='autoReview.onStages' />
                  </td>
                </tr>
                {template.onStages.map(name => (
                  <>
                    {/*
                      // @ts-ignore */}
                    <tr colSpan={2}>
                      <td key={name}>{name}</td>
                    </tr>
                  </>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default withLocalize(Summary)
