import React, { useState } from 'react'
import Form from '../../Form/Form'
import FormSection from '../../Form/FormSection'
import TextInput from '../../FormInput/TextInput'
import Checkbox from '../../FormInput/Checkbox'
import Button from '../../shared/Button'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { Translate, withLocalize } from 'react-localize-redux'
import styles from './EditStageEmail.module.scss'
import gateStyles from '../GateSettings.module.scss'
import classNames from 'classnames'
import { ICommonState } from '../../utils/state'
import { useSelector, useDispatch } from 'react-redux'
import { resendStageEmail, sendPendingRejections } from '../../actions/gateSettingsActions'
import { RejectionMode } from '../../models/application/IChallenge'
import { EditStageEmailProps } from "./types";

const isReglab = process.env.REACT_APP_PROJECT_NAME === 'reglab'
const isMultilingual = process.env.REACT_APP_MONOLINGUAL !== 'true'

const EditStageEmail = ({ translate, onFieldChange, isFirstGate }: EditStageEmailProps) => {
  const { changedStages, stageIndex, selectedChallenge } = useSelector(
    (state: ICommonState) => state.gate,
  )
  const currentStage = changedStages[stageIndex]
  const {
    emailSubject,
    emailContent,
    emailSubjectAlt,
    emailContentAlt,
    sendEmail,
    sendEmailToPMs,
    RejectionEmailContent,
    RejectionEmailContentAlt,
    RejectionEmailSubject,
    RejectionEmailSubjectAlt,
  } = currentStage

  const dispatch = useDispatch()

  const [confirmEmailTooltipVisible, setConfirmEmailTooltipVisible] = useState(
    false,
  )

  const handleResendEmail = () => {
    setConfirmEmailTooltipVisible(false)
    if (selectedChallenge === undefined) {
      throw new Error('Gate settings page incorrectly configured')
    }
    dispatch(
      resendStageEmail(selectedChallenge.id, changedStages[stageIndex].id),
    )
  }

  const handleSendPendingRejections = () => {
    if (selectedChallenge === undefined) {
      throw new Error('Gate settings page incorrectly configured')
    }
    dispatch(
      sendPendingRejections(selectedChallenge.id, changedStages[stageIndex].id),
    )
  }

  const emailCheckboxes = [
    {
      id: 'reviewer',
      label: 'gate.sendReviewer',
      value: sendEmail.reviewer,
    },
    {
      id: 'approved',
      label: isFirstGate ? 'gate.sendSubmission' : 'gate.sendApproved',
      value: sendEmail.approved,
    },
    {
      id: 'rejected',
      label: 'gate.sendRejected',
      value: sendEmail.rejected,
    },
  ]
  !isReglab &&
    emailCheckboxes.concat({
      id: 'ica',
      label: 'gate.sendIca',
      value: sendEmail.ica,
    })

  return (
    <Form>
      <FormSection>
        <h3 className={styles.sectionHeading}>
          <Translate id='gate.optionsHeading' />
        </h3>
        {emailCheckboxes.map((email) => (
          <div
            key={email.id}
            className={classNames(gateStyles.field, gateStyles.checkbox)}
          >
            <Checkbox
              label={translate(email.label) as string}
              id={`send-${email.id}`}
              value={email.value}
              onChange={(value) =>
                onFieldChange({
                  newValue: {
                    ...sendEmail,
                    [email.id]: value,
                  },
                  field: 'sendEmail',
                })
              }
            />
          </div>
        ))}
      </FormSection>
      <FormSection>
        <h3 className={styles.sectionHeading}>
          {isFirstGate ? (
            <Translate id='gate.submissionEmailHeading' />
          ) : (
            <Translate id='gate.nextGateEmailHeading' />
          )}
        </h3>
        <div className={gateStyles.field}>
          <TextInput
            id='email-subject'
            required={sendEmail.approved}
            showValidation={false}
            label={translate('gate.emailSubject') as string}
            value={emailSubject || ''}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'emailSubject',
              })
            }
            type='text'
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='email-subject-alt'
              required={sendEmail.approved}
              rtl
              showValidation={false}
              label={translate('gate.emailSubjectAlt') as string}
              value={emailSubjectAlt || ''}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'emailSubjectAlt',
                })
              }
              type='text'
            />
          </div>
        )}
        <div className={gateStyles.field}>
          <TextInput
            id='email-content'
            required={sendEmail.approved}
            showValidation={false}
            label={translate('gate.emailContent') as string}
            value={emailContent || ''}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'emailContent',
              })
            }
            type='rich-text-area'
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='email-alt'
              required={sendEmail.approved}
              rtl
              showValidation={false}
              label={translate('gate.emailContentAlt') as string}
              value={emailContentAlt || ''}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'emailContentAlt',
                })
              }
              type='rich-text-area'
            />
          </div>
        )}
        <div className={classNames(gateStyles.field, gateStyles.checkbox)}>
          <Checkbox
            label={translate('gate.includePms') as string}
            id='include-pm'
            value={sendEmailToPMs}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'sendEmailToPMs',
              })
            }
          />
        </div>
        <div className={styles.sendEmailContainer}>
          <Button
            type='primary'
            onClick={() => setConfirmEmailTooltipVisible(true)}
          >
            <Translate id='admin.resend' />
          </Button>
          {confirmEmailTooltipVisible && (
            <NotificationModal
              onAccept={handleResendEmail}
              onCancel={() => setConfirmEmailTooltipVisible(false)}
            >
              <Translate id='admin.confirmResend' />
            </NotificationModal>
          )}
        </div>
      </FormSection>
      <FormSection>
        <h3 className={styles.sectionHeading}>
          <Translate id='gate.rejectEmailHeading' />
        </h3>
        <div className={gateStyles.field}>
          <TextInput
            id='reject-email-subject'
            required={sendEmail.rejected}
            showValidation={false}
            label={translate('gate.rejectEmailSubject') as string}
            value={RejectionEmailSubject || ''}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'RejectionEmailSubject',
              })
            }
            type='text'
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='reject-email-subject-alt'
              required={sendEmail.rejected}
              rtl
              showValidation={false}
              label={translate('gate.rejectEmailSubjectAlt') as string}
              value={RejectionEmailSubjectAlt || ''}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'RejectionEmailSubjectAlt',
                })
              }
              type='text'
            />
          </div>
        )}
        <div className={gateStyles.field}>
          <TextInput
            id='reject-email'
            required={sendEmail.rejected}
            showValidation={false}
            label={translate('gate.rejectEmailContent') as string}
            value={RejectionEmailContent || ''}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'RejectionEmailContent',
              })
            }
            type='rich-text-area'
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='reject-email-alt'
              required={sendEmail.rejected}
              rtl
              showValidation={false}
              label={translate('gate.rejectEmailContentAlt') as string}
              value={RejectionEmailContentAlt || ''}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'RejectionEmailContentAlt',
                })
              }
              type='rich-text-area'
            />
          </div>
        )}
        {!!(
          selectedChallenge &&
          selectedChallenge.rejectionMode === RejectionMode.onChallenge
        ) && (
          <Button type='primary' onClick={handleSendPendingRejections}>
            <Translate id='gate.sendPendingRejections' />
          </Button>
        )}
      </FormSection>
    </Form>
  )
}

export default withLocalize(EditStageEmail)
