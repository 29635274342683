import {
  IQuestion,
  IApiQuestion,
  getPossibleScoreForQuestion,
} from '../../models/application/IQuestion'

export interface ISection {
  id: string
  title: string
  titleAlt?: string
  disabled?: boolean
}

export interface IQuestionSection extends ISection {
  description: string
  descriptionAlt?: string
  questions: number
}

export const getSectionBounds = (sections: IQuestionSection[], selectedIndex: number) => {
  const lower = sections.reduce(
    (questionsSoFar, section, index) => {
      if (index < selectedIndex) {
        return questionsSoFar + section.questions
      }
      return questionsSoFar
    },
    0
  )
  const upper = lower + sections[selectedIndex].questions

  return [lower, upper]
}

export const getPossibleScoresForSections = (
  sections: IQuestionSection[],
  questions: (IQuestion | IApiQuestion)[]
) =>
  sections.map((section, sectionIndex) => {
  const [lowerBound, upperBound] = getSectionBounds(sections, sectionIndex)

  return questions
    .filter((_, questionIndex) => questionIndex >= lowerBound && questionIndex < upperBound)
    .reduce(
      (sectionScore, question) => sectionScore + getPossibleScoreForQuestion(question),
      0
    )
})
