import React from 'react'
import styles from './ApplicationSummarySection.module.scss'
import classnames from 'classnames'
import { ApplicationSummarySectionProps } from "./types";

const ApplicationSummarySection = ({ title, children }: ApplicationSummarySectionProps) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
  </div>
)

export const ApplicationSummarySubSection = ({ title, children }: ApplicationSummarySectionProps) => (
  <div className={classnames(styles.container, styles.subsectionContainer)}>
    <div className={styles.subsectionTitle}>{title}</div>
    <div className={styles.subsectionContent}>{children}</div>
  </div>
)

export default ApplicationSummarySection
