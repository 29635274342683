import { IOption } from './IOption'
import { IAnswer } from './IAnswer'

interface IBaseQuestion {
  id?: string
  question: string
  questionAlt?: string,
  description: string
  descriptionAlt?: string
  maxLength?: number
  type: QuestionType
  options?: IOption[]
  mandatory?: boolean
  fileUploadCategory?: 'document' | 'image' | 'video'
}

export interface IApiQuestion extends IBaseQuestion {
  apiKey?: string
}

export interface IFullKeyQuestion extends IBaseQuestion {
  apiKey: IApiKey
}

export interface IQuestion extends IApiQuestion {
  id: string
  section: number
}

export interface IApiKey {
  id: string
  keyName: string
  keyType: string
}

export enum QuestionType {
  SHORT_ANSWER = 'ShortAnswer',
  PARAGRAPH = 'Paragraph',
  MULTIPLE_CHOICE = 'MultipleChoice',
  CHECKBOX = 'Checkbox',
  DROPDOWN = 'Dropdown',
  FILE_UPLOAD = 'FileUpload',
  DATE = 'Date',
  TIME = 'Time',
}

export const typesWithOptions = [
  QuestionType.MULTIPLE_CHOICE,
  QuestionType.CHECKBOX,
  QuestionType.DROPDOWN,
]

export const typesWithCharacterLimitation = [
  QuestionType.SHORT_ANSWER,
  QuestionType.PARAGRAPH,
]

export function getQuestionTypes (): string[] {
  return Object.values(QuestionType)
}

export const stripQuestion = (localQuestion: IQuestion): IApiQuestion => {
  const options = typesWithOptions.includes(localQuestion.type)
    ? (localQuestion.options!.filter(option => option.option !== ''))
    : undefined
  return {
    id: localQuestion.id.startsWith('new-question') ? undefined : localQuestion.id,
    question: localQuestion.question,
    questionAlt: localQuestion.questionAlt,
    description: localQuestion.description,
    descriptionAlt: localQuestion.descriptionAlt,
    maxLength: localQuestion.maxLength,
    type: localQuestion.type,
    options: options,
    mandatory: localQuestion.mandatory,
    apiKey: localQuestion.apiKey,
    fileUploadCategory: localQuestion.fileUploadCategory,
  }
}

export const getQuestionError = (question: IQuestion): (string | undefined) => {
  const optionIds: string[] = []
  question.options!.forEach(option => {
    if (option.optionId !== undefined) {
      optionIds.push(option.optionId)
    }
  })
  const options: string[] = []
  question.options!.forEach(option => {
    options.push(option.option)
  })

  if ((optionIds.length !== 0 && optionIds.length !== options.length) || optionIds.some(optionId => optionId === '')) {
    return 'visaApplication.cannotSave.apiLength'
  }
  const validId = /^\d+$/
  if (optionIds.some(optionId => {
    return !validId.test(optionId)
  })) {
    return 'visaApplication.cannotSave.nonNumerical'
  }
}

export const getPossibleScoreForQuestion = (question: IQuestion | IApiQuestion): number => {
  if (question.options!.length === 0) {
    return 0
  }
  if (question.type === QuestionType.CHECKBOX) {
    return question.options!.reduce((totalScore, option) => {
      return totalScore + (option.score || 0)
    }, 0)
  }
  return Math.max(...question.options!.map(option => option.score || 0))
}

export const getLastPossibleJump = (question: IQuestion, index: number) => {
  return Math.max(...question.options!.map(option => option.jumpToQuestion || index + 1))
}

export const getQuestionJumpTarget = (
  question: IQuestion,
  answer: IAnswer,
  index: number
): number => {
  const trueOptionIndex: number | null = answer.selectedOptions
    && answer.selectedOptions.length >= 1
      ? answer.selectedOptions[0]
      : null

  if (trueOptionIndex === null && question.options!.length > 0) {
    return getLastPossibleJump(question, index)
  }

  const jumpTarget = question.options!.length > 0 &&
    trueOptionIndex !== null &&
    question.options![trueOptionIndex].jumpToQuestion

  return jumpTarget || index + 1
}

export const questionJumpsOver = (index: number) => (question: IQuestion) => {
  return question.options!.some(option => option.jumpToQuestion! > index)
}

export const couldBeSkipped = (
  lastActiveAnswer: IAnswer,
  intervalQuestions: IQuestion[],
  index: number
) => {
  const lastActiveAnswerIsBlank = lastActiveAnswer.selectedOptions
    ? lastActiveAnswer.selectedOptions.length === 0
    : true
  return lastActiveAnswerIsBlank && intervalQuestions.some(questionJumpsOver(index))
}
