import React from 'react'

const availableTokens = [
  {
    value: '{{DISPLAY_NAME}}',
    label: 'Display Name',
  },
  {
    value: '{{PROGRAM_NAME}}',
    label: 'Program Name',
  },
  {
    value: '{{COMPANY_NAME}}',
    label: 'Company Name',
  },
]

interface CustomToolbarProps {
  id: string
  withPlaceholder?: boolean
}

const CustomToolbar = ({
    id,
    withPlaceholder = true,
  }: CustomToolbarProps) => (
  <div id={id} style={{direction: 'ltr'}}>
    <select
      className='ql-header'
      defaultValue={''}
      onChange={(e) => e.persist()}
    >
      <option value='1'/>
      <option value='2'/>
      <option value='3'/>
      <option value=''/>
    </select>
    <button className='ql-bold'/>
    <button className='ql-italic'/>
    <button className='ql-underline'/>
    <button className='ql-list' value='ordered'/>
    <button className='ql-list' value='bullet'/>
    <button className='ql-link'/>
    <button className='ql-clean'/>
    <button className='ql-direction'/>
    {withPlaceholder && (
      <select className='ql-insertToken' defaultValue=''>
        <option value=''>Choose one</option>
        {availableTokens.map((token) => (
          <option value={token.value}>{token.label}</option>
        ))}
      </select>
    )}
  </div>
)

export default CustomToolbar;
