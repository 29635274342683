import React from 'react'
import { Translate } from 'react-localize-redux'
import { IRemainingDaysProps } from "./types";

export const HumanReadableRemainingDays = ({
  daysRemaining,
}: IRemainingDaysProps) => {
  if (typeof daysRemaining !== 'number') {
    return <Translate id='utility.remainingTime.n/a' />
  }
  if (daysRemaining < -1) {
    return <Translate id='utility.remainingTime.daysAgo' data={{days: (daysRemaining * (-1))}} />
  }
  if (daysRemaining === -1) {
    return <Translate id='utility.remainingTime.yesterday' />
  }
  if (daysRemaining === 0) {
    return <Translate id='utility.remainingTime.today' />
  }
  if (daysRemaining === 1) {
    return <Translate id='utility.remainingTime.tomorrow' />
  }
  return <Translate id='utility.remainingTime.days' data={{ days: daysRemaining }} />
}
