import React from 'react'
import Modal from './Modal'
import styles from './ConfirmActionModal.module.scss'
import Button from 'components/common/shared/Button'
import { ConfirmActionModalProps } from "./types";

const ConfirmActionModal = ({ close, style, title, confirmAction, confirmText, cancelText } : ConfirmActionModalProps) => (
  <Modal close={close} requireAction={false} style={style}>
    <div className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.buttons}>
        <Button
          type='solid'
          className={styles.button}
          onClick={confirmAction}
        >
          {confirmText}
        </Button>
        <Button
          type='solid'
          className={styles.button}
          onClick={close}
        >
          {cancelText}
        </Button>
      </div>
    </div>
  </Modal>
)

export default ConfirmActionModal
