import React from 'react'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaste } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { addSuccessToast } from '../common/actions/toastActions'
import { Translate, withLocalize } from 'react-localize-redux'
import styles from './CopyableLink.module.scss'
import classNames from 'classnames'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import { ICopyableLinkProps } from "./types";

const CopyableLink = ({
  to,
  translate,
  children,
  className,
  reverse = false,
}: ICopyableLinkProps) => {
  const dispatch = useDispatch()
  const copyText = `${window.location.origin}${to}`

  const notifyCopied = () =>
    dispatch(addSuccessToast(translate('utility.copied').toString()))

  return (
    <span
      className={classNames(
        className,
        styles.container,
        reverse && styles.reverse,
      )}
    >
      <Link to={to}>{children}</Link>
      <InfoTooltip
        overlay={
          <Translate id='copy' />
        }
      >
        <CopyToClipboard
          text={copyText}
          onCopy={notifyCopied}
        >
          <FontAwesomeIcon icon={faPaste} className={styles.clickableIcon} />
        </CopyToClipboard>
      </InfoTooltip>
    </span>
  )
}

export default withLocalize(CopyableLink)
