import React from 'react'
import StageListItem from './StageListItem'
import { withLocalize } from 'react-localize-redux'
import styles from './ApplicationListingSidebar.module.scss'
import { ApplicationListingSidebarProps } from "./types";

const ApplicationListingSidebar = ({
  stages = [],
  selectedStageId,
  onSelectedStageIdChange,
  translate,
}: ApplicationListingSidebarProps) => {
  /**
   * Aggregate application numbers here...
   */
  const applicationDetailsList: {
    [id: string]: { count: number; name: string }
  } = stages.reduce(
    (counts, stage) => ({
      ...counts,
      [stage.id]: {
        count: stage.numberOfApplications,
        name: stage.name,
      },
    }),
    {},
  )

  const totalApplicationCounts = Object.values(applicationDetailsList).reduce(
    (sum, { count }) => sum + count,
    0,
  )

  return (
    <div className={styles.container}>
      <div className={styles.headerRow}>
        <h3>{translate('review.gate')} </h3>
      </div>
      <div className={styles.stageListingContainer}>
        {/* All applications */}
        <StageListItem
          isShowingAllApplications
          stageName={translate('review.allApplications') as string}
          count={totalApplicationCounts}
          selected={selectedStageId === null}
          onSelect={() => onSelectedStageIdChange(null)}
        />
        {Object.entries(applicationDetailsList).map(
          ([stageId, { name, count }]) => (
            <StageListItem
              key={stageId}
              stageName={name}
              count={count}
              selected={selectedStageId === stageId}
              onSelect={() => onSelectedStageIdChange(stageId)}
            />
          ),
        )}
      </div>
    </div>
  )
}

export default withLocalize(ApplicationListingSidebar)
