import React, { useMemo } from 'react'
import { DisplayRowProps } from "./types";

const DisplayRow = ({ heading, value }: DisplayRowProps) => {
  const displayValue = useMemo(() => {
    if (Array.isArray(value)) {
      return value.join(', ')
    }
    if (value === true) {
      return 'Yes'
    }
    if (value === false) {
      return 'No'
    }
    if (typeof value === 'object') {
      if (value.comparitor) {
        const modeChar = value.comparitor === '===' ? '=' : value.comparitor
        return `${modeChar} ${value.value}`
      }
      return ''
    }
    return value
  }, [value])

  if (value == null || value === '' || displayValue === '') {
    return null
  }

  return (
    <tr>
      <td>{heading}:</td>
      <td>{displayValue}</td>
    </tr>
  )
}

export default DisplayRow
