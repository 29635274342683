import { Action } from 'redux';
import { ACTIONS } from '../actions/userActions'
import { UserState } from '../utils/state'
import { Reducer } from "./types";
import { createReducer } from "./createReducer";

export interface IRole {
  rank: number
  id: string
  name: string
}

const defaultState: UserState = {
  email: '',
  userId: '',
  firstName: '',
  lastName: '',
  profilePicture: '',
  displayName: '',
  role: {
    rank: -1,
    name: '',
    id: '',
  },
  reviews: null,
  skills: [],
  jobTitles: [],
  challenges: [],
  challengesCount: 0,
  showTncPopup: false,
}

const reducer: Reducer<UserState, Action> = {
  [ACTIONS.SET_USER]: (state, { payload }) => {
    const { id, ...data } = payload;

    return {
      ...state,
      ...data,
      userId: id,
    }
  },
  [ACTIONS.SET_USER_ID]: (state, {payload}) => ({
    ...state,
    userId: payload,
  }),
  [ACTIONS.RESET_USER]: () => defaultState,
  [ACTIONS.LOADED_USER_REVIEWS]: (state, {payload}) => ({...state, reviews: payload}),
  [ACTIONS.CANCELLED_APPLICATION]: (state, {payload}) => ({
    ...state,
    reviews: state.reviews ? state.reviews.filter(review => !review.applicationId || review.applicationId !== payload) : [],
  }),
  [ACTIONS.SET_USER_CHALLENGES]: (state, {payload}) => ({
    ...state,
    challenges: payload.data,
    challengesCount: payload.count,
  }),
  [ACTIONS.SHOW_TNC_POPUP]: (state, {payload}) => ({
    ...state,
    showTncPopup: payload,
  }),
  [ACTIONS.SET_USER_METRICS]: (state, {payload}) => ({
    ...state,
    metrics: payload,

  }),
}

export default createReducer<UserState, Action>(reducer, defaultState);
