import React from 'react'
import ReactDOM from 'react-dom'
import styles from './Modal.module.scss'
import closeIcon from '../../assets/icons/close.svg'
import { ModalProps } from "./types";

class Modal extends React.Component<ModalProps> {
  modalRoot = document.getElementById('modal-root');
  el = document.createElement('div');

  componentDidMount() {
    if (this.modalRoot) {
      this.modalRoot.appendChild(this.el)
    }
  }

  componentWillUnmount() {
    if (this.modalRoot) {
      this.modalRoot.removeChild(this.el)
    }
  }

  render() {
    const { requireAction, close, modalContainerStyle, className, style, children } = this.props;
    return ReactDOM.createPortal(
      <div
        className={styles.container}
        onClick={() => requireAction && close()}
        style={modalContainerStyle}
      >
        <div
          className={[styles.modal, className].join(' ')}
          onClick={e => e.stopPropagation()}
          style={style}
        >
          <img
            className={styles.close}
            src={closeIcon}
            alt='Close'
            onClick={close}
          />
          <div
            className={styles.content}
          >
            {children}
          </div>
        </div>
      </div>,
      this.el,
    )
  }
}

export default Modal
