import styles from "./SearchHighlight.module.scss";
import React from "react";
import { highLightSearch } from "./utils";
import { EmailProps } from "./types";

const Email = ({className, email, regex}: EmailProps) => {
  return (
    <div className={`${styles.email} ${className}`}>
      {email && regex && highLightSearch(email, regex)}
    </div>
  )
}

export default Email;
