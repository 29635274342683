import { QuestionType, IQuestion, typesWithOptions } from '../../../models/application/IQuestion'
import { IAnswer } from '../../../models/application/IAnswer'

const monolingual = process.env.REACT_APP_MONOLINGUAL === 'true'

export interface QuestionError {
  error: string
  index: number
}

export interface ErrorSummary {
  error: string
  indicies: number[]
}


export const isQuestionPublishable = (question: IQuestion, index: number) => {
  if (!monolingual && question.options) {
    const bothLanguagesDefined = question.options.every(option => option.option !== '' && option.optionAlt !== '')
    return [
      bothLanguagesDefined,
      bothLanguagesDefined
        ? undefined
        : {
          error: 'visaApplication.cannotPublish.undefinedLanguage',
          index: index + 1, // start at 1 vs start at 0
        },
    ]
  }
  if (typesWithOptions.includes(question.type) && question.options && question.options.length === 0) {
    return [
      false,
      {
        error: 'visaApplication.cannotPublish.missingAnswers',
        index: index + 1,
      },
    ]
  }
  return [true, undefined]
}

type publishableErrorObject = [boolean, QuestionError[]]

export const isApplicationFormPublishable = (questions: IQuestion[]) => {
  return questions.reduce((allErrorsObject, question, questionIndex) => {
    const [isPublishable, errorObject] = isQuestionPublishable(question, questionIndex)
    return isPublishable
      ? allErrorsObject
      : [
        false,
        [
          ...allErrorsObject[1],
          errorObject,
        ],
      ] as publishableErrorObject
  }, [true, []] as publishableErrorObject)
}

export const getNextIndexOfQuestionBySection = (
  questions: IQuestion[],
  sectionNumber: number
): number => {
  for (let i = questions.length; i-- > 0;) {
    if (questions[i].section === sectionNumber) {
      return i + 1
    }
  }
  return -1
}

export const spliceNewQuestionInCurrentSection = (
  questions: IQuestion[],
  index: number,
  question: IQuestion
): IQuestion[] => {
  return [
    ...questions.slice(0, index),
    question,
    ...questions.slice(index),
  ].map((question, newIndex) => {
    return {
      ...question,
      options: question.options!.map(option => ({
        ...option,
        jumpToQuestion:
          option.jumpToQuestion && option.jumpToQuestion > index
            ? option.jumpToQuestion + 1
            : option.jumpToQuestion,
      })),
    }
  })
}

export const questionCanBeDeleted = (
  questions: IQuestion[],
  questionIndex: number
): boolean => {
  const candidate = questions[questionIndex]
  return (
    questions.filter(question => question.section === candidate.section)
      .length > 1
  )
}

export const updateIndexFollowingDeletion = (
  indexReferenced: number,
  indiciesDeleted: number[]
): number | undefined => {
  if (indiciesDeleted.includes(indexReferenced)) {
    return undefined
  }
  if (indexReferenced > indiciesDeleted[indiciesDeleted.length - 1]) {
    return indexReferenced - indiciesDeleted.length
  }
  return indexReferenced
}

export const deleteQuestionAtIndex = (
  questions: IQuestion[],
  indexToRemove: number
): IQuestion[] => {
  return questions
    .map((question, questionIndex) => {
      // update references to later questions
      return {
        ...question,
        options: question.options!.map(option => {
          const jumpToQuestion =
            option.jumpToQuestion &&
            updateIndexFollowingDeletion(option.jumpToQuestion, [indexToRemove])
          const newOption = {
            ...option,
            jumpToQuestion,
          }
          return newOption
        }),
      }
    })
    .filter((question, index) => index !== indexToRemove)
}

export const prepareAnswersObject = (questions: IQuestion[]): IAnswer[] => {
  const selectTypes = [
    QuestionType.DROPDOWN,
    QuestionType.CHECKBOX,
    QuestionType.MULTIPLE_CHOICE,
  ]
  return questions.map(question => {
    const baseAnswer = {
      selectedOptions: [],
      section: question.section,
    }

    if (selectTypes.includes(question.type)) {
      return {
        ...baseAnswer,
        selectedOptions: [],
      }
    }
    return {
      ...baseAnswer,
      answer: '',
    }
  })
}
