import { Action } from 'redux';
import { Reducer } from "./types";

export const createReducer = <S, A extends Action>(
  reducer: Reducer<S, A>,
  initialState: S,
) => (state: S = initialState, action: A): S => {
  if (reducer[action.type]) {
    return {...state, ...reducer[action.type](state, action)};
  }

  return state
}
