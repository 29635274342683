import styles from '../ApplicationPanel.module.scss'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Translate,
  LocalizeContextProps,
  withLocalize,
} from 'react-localize-redux'
import { QuestionType, IQuestion } from '../../models/application/IQuestion'

import { RootState } from '../../../../reducers'

import EditFormPanel from './EditFormPanel'
import FormPanel from '../ApplicationForm/FormPanel/FormPanel'
import { IAnswer } from '../../models/application/IAnswer'
import {
  IQuestionSection,
  getPossibleScoresForSections,
} from '../../models/application/ISection'
import { addErrorToast, addSuccessToast } from '../../actions/toastActions'
import { setActiveSection } from '../../actions/applicationActions'
import Form from '../../Form/Form'

import SectionList from '../../SectionList/SectionList'
import Button from '../../shared/Button'

import {
  getListOfQuestionsDisabled,
  isSectionCompleted,
} from '../ApplicationPanel'
import { isRtl } from '../../utils/translations'
import Loader from '../../Loader/Loader'
import { Row, Column } from '../../Layout'
import { retrieveQuestionsSectionsFromAPI } from './utils/api'
import FormEditorFooter from './FormEditorFooter'
import {
  getNextIndexOfQuestionBySection,
  spliceNewQuestionInCurrentSection,
  questionCanBeDeleted,
  updateIndexFollowingDeletion,
  deleteQuestionAtIndex,
  prepareAnswersObject,
} from './utils'

const nullQuestion = {
  question: '',
  questionAlt: '',
  description: '',
  descriptionAlt: '',
  type: QuestionType.SHORT_ANSWER,
  options: [],
}

interface ITemplate {
  questions: IQuestion[]
}

interface IUpdatedTemplate extends ITemplate {
  unsavedChanges: boolean
  sections: IQuestionSection[]
  answers: IAnswer[]
  readOnly: boolean
}

const nullTemplate = {
  questions: [],
  answers: [],
  sections: [],
  unsavedChanges: false,
  readOnly: false,
}

const FormEditor = ({ translate }: LocalizeContextProps) => {
  const [editMode, setEditMode] = useState(true)
  const [updatedTemplate, setUpdatedTemplate] = useState<IUpdatedTemplate>(
    nullTemplate,
  )
  const [unchangedQuestions, setUnchangedQuestions] = useState<IQuestion[]>([])
  const [loading, setLoading] = useState(true)
  const [lastAddedQuestionId, setLastAddedQuestionId] = useState(0)
  const [lastAddedSectionId, setLastAddedSectionId] = useState(0)

  const { localize } = useSelector((state: RootState) => state)
  const isAltLang = isRtl(localize)
  const { activeSection, selectedChallenge } = useSelector(
    (state: RootState) => state.application,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    const populateQuestions = async () => {
      setLoading(true)
      const {
        questions: returnedQuestions,
        sections: returnedSections,
        readonly: returnedReadonly,
      } = await retrieveQuestionsSectionsFromAPI(selectedChallenge)
      const preparedAnswers = prepareAnswersObject(returnedQuestions)

      setUnchangedQuestions(returnedQuestions)
      setUpdatedTemplate({
        answers: preparedAnswers,
        questions: returnedQuestions,
        sections: returnedSections,
        readOnly: returnedReadonly,
        unsavedChanges: false,
      })
      setLoading(false)
    }

    populateQuestions()
  }, [dispatch, selectedChallenge])

  const handleQuestionChange = (changedQuestions: IQuestion[]) => {
    setUpdatedTemplate((prevTemplate) => ({
      ...prevTemplate,
      questions: changedQuestions,
      unsavedChanges: true,
      answers: prepareAnswersObject(changedQuestions),
    }))
  }

  const handleUpdateMultipleChoiceAnswer = (
    answerIndex: number,
    value: number[],
  ): void => {
    setUpdatedTemplate((prevTemplate) => ({
      ...prevTemplate,
      unsavedChanges: true,
      answers: prevTemplate.answers.map((answer, index) => {
        if (index === answerIndex) {
          return {
            ...answer,
            selectedOptions: value,
          }
        }
        return answer
      }),
    }))
  }

  const handleUpdateSingleAnswer = (
    answerIndex: number,
    value: string,
  ): void => {
    setUpdatedTemplate((prevTemplate) => ({
      ...prevTemplate,
      unsavedChanges: true,
      answers: prevTemplate.answers.map((answer, index) => {
        if (index === answerIndex) {
          return {
            ...answer,
            answer: value,
          }
        }
        return answer
      }),
    }))
  }

  const handleSectionFieldChanged = (sectionNumber: number) => (
    field: string,
    newValue: string,
  ) => {
    setUpdatedTemplate((prevTemplate) => ({
      ...prevTemplate,
      unsavedChanges: true,
      sections: prevTemplate.sections.map((section, index) => {
        if (index === sectionNumber) {
          return {
            ...section,
            [field]: newValue,
          }
        }
        return section
      }),
    }))
  }

  const handleAddNewQuestion = (sectionNumber: number) => {
    if (
      sectionNumber >= 0 &&
      sectionNumber <= updatedTemplate.sections.length - 1
    ) {
      const newQuestionId = lastAddedQuestionId + 1
      setUpdatedTemplate((prevTemplate) => {
        const updatedQuestions = spliceNewQuestionInCurrentSection(
          prevTemplate.questions,
          getNextIndexOfQuestionBySection(
            prevTemplate.questions,
            sectionNumber,
          ),
          {
            ...nullQuestion,
            id: `new-question-${newQuestionId}`,
            section: sectionNumber,
          },
        )

        return {
          ...prevTemplate,
          unsavedChanges: true,
          questions: updatedQuestions,
          answers: prepareAnswersObject(updatedQuestions),
          sections: prevTemplate.sections.map((section, index) => {
            if (index === sectionNumber) {
              return {
                ...section,
                questions: section.questions + 1,
              }
            }
            return section
          }),
        }
      })
      setLastAddedQuestionId(newQuestionId)
    }
  }

  const handleRemoveQuestion = (indexOfQuestionToRemove: number) => {
    if (
      questionCanBeDeleted(updatedTemplate.questions, indexOfQuestionToRemove)
    ) {
      setUpdatedTemplate((prevTemplate) => {
        const newQuestions = deleteQuestionAtIndex(
          prevTemplate.questions,
          indexOfQuestionToRemove,
        )
        return {
          ...prevTemplate,
          unsavedChanges: true,
          sections: prevTemplate.sections.map((section, index) => {
            if (
              index === prevTemplate.questions[indexOfQuestionToRemove].section
            ) {
              return {
                ...section,
                questions: section.questions - 1,
              }
            }
            return section
          }),
          questions: newQuestions,
          answers: prepareAnswersObject(newQuestions),
        }
      })
    }
  }

  /**
   * This method is used to change sections when user clicks on section title on the left
   * bar
   */
  const handleSectionTabClicked = (sectionIndex: number) => {
    window.scrollTo(0, 0)
    dispatch(setActiveSection(sectionIndex))
  }

  const addSection = () => {
    const newQuestionId = lastAddedQuestionId + 1
    const newSectionId = lastAddedSectionId + 1

    setUpdatedTemplate((prevTemplate) => {
      const newQuestions = [
        ...prevTemplate.questions,
        {
          ...nullQuestion,
          id: `new-question-${newQuestionId}`,
          section: prevTemplate.sections.length,
        },
      ]

      return {
        ...prevTemplate,
        unsavedChanges: true,
        sections: [
          ...prevTemplate.sections,
          {
            id: `new-section-${newSectionId}`,
            description: '',
            descriptionAlt: '',
            title: '',
            titleAlt: '',
            questions: 1,
          },
        ],
        questions: newQuestions,
        answers: prepareAnswersObject(newQuestions),
      }
    })
    setLastAddedQuestionId(newQuestionId)
    setLastAddedSectionId(newSectionId)
  }

  const handleSectionDeleted = (deletedSectionIndex: number) => () => {
    if (
      // there are at least 1 section
      updatedTemplate.sections.length === 1 ||
      // check that you can't have templates with all sections without titles
      updatedTemplate.sections
        .filter((_, ind) => ind !== deletedSectionIndex)
        .every((section) => section.title === '')
    ) {
      dispatch(
        addErrorToast(
          translate('visaApplication.finalSectionDelete') as string,
        ),
      )
    } else {
      // avoid out of bounds errors on selected section
      const newSelectedSection =
        activeSection >= deletedSectionIndex ? activeSection - 1 : activeSection
      dispatch(setActiveSection(newSelectedSection))

      const remainingQuestions = updatedTemplate.questions.filter(
        (question) => question.section !== deletedSectionIndex,
      )

      const deletedIndicies = updatedTemplate.questions.reduce(
        (indices, question, index) =>
          question.section === deletedSectionIndex
            ? [...indices, index]
            : indices,
        [] as number[],
      )

      // indexes are now different - update any references
      const newQuestions = remainingQuestions.map((question) => ({
        ...question,
        section:
          question.section > deletedSectionIndex
            ? question.section - 1
            : question.section,
        options: question.options!.map((option) => ({
          ...option,
          jumpToQuestion:
            option.jumpToQuestion &&
            updateIndexFollowingDeletion(
              option.jumpToQuestion,
              deletedIndicies,
            ),
        })),
      }))

      setUpdatedTemplate((prevTemplate) => ({
        ...prevTemplate,
        unsavedChanges: true,
        sections: prevTemplate.sections.filter(
          (section, index) => index !== deletedSectionIndex,
        ),
        questions: newQuestions,
        answers: prepareAnswersObject(newQuestions),
      }))
    }
  }

  const currentSection = updatedTemplate.sections[activeSection]
  const listOfQuestionsDisabled = getListOfQuestionsDisabled(
    updatedTemplate.questions,
    updatedTemplate.answers,
  )
  const possibleScores = getPossibleScoresForSections(
    updatedTemplate.sections,
    updatedTemplate.questions,
  )
  const currentSectionScore = possibleScores[activeSection]

  if (loading) {
    return <Loader />
  }

  const thisSectionComplete = isSectionCompleted(
    updatedTemplate.answers,
    updatedTemplate.questions,
  )(activeSection)

  const toggleEditMode = () => setEditMode((prev) => !prev)

  const handleSave = (
    newQuestions: IQuestion[],
    newSections: IQuestionSection[],
  ) => {
    setUpdatedTemplate((prevTemplate) => ({
      ...prevTemplate,
      unsavedChanges: false,
      questions: newQuestions,
      sections: newSections,
    }))
    setUnchangedQuestions(newQuestions)
    dispatch(
      addSuccessToast(translate('visaApplication.templateSaved') as string),
    )
  }

  return (
    <Row className={styles.formEditorRow}>
      <Column nav>
        <SectionList
          sections={updatedTemplate.sections}
          displayStatus
          possibleScores={possibleScores}
          onSelected={handleSectionTabClicked}
          selectedSection={activeSection}
          isSectionComplete={isSectionCompleted(
            updatedTemplate.answers,
            updatedTemplate.questions,
          )}
          onDelete={handleSectionDeleted}
          editMode={editMode}
          isAltLang={isAltLang}
        />
        {editMode && (
          <Button
            type='primary'
            className={styles.addSectionButton}
            onClick={addSection}
          >
            <Translate id='visaApplication.newSection' />
          </Button>
        )}
      </Column>
      <Column>
        <Form>
          {editMode ? (
            <EditFormPanel
              questions={updatedTemplate.questions}
              sectionTitle={
                isAltLang ? currentSection.titleAlt : currentSection.title
              }
              sectionDescription={
                isAltLang
                  ? currentSection.descriptionAlt
                  : currentSection.description
              }
              setSectionField={handleSectionFieldChanged(activeSection)}
              sectionNumber={activeSection}
              possibleSectionScore={currentSectionScore}
              onQuestionChange={handleQuestionChange}
              addQuestion={handleAddNewQuestion}
              removeQuestion={handleRemoveQuestion}
              isAltLang={isAltLang}
            />
          ) : (
            <FormPanel
              questions={updatedTemplate.questions}
              answers={updatedTemplate.answers}
              updateAnswerMultiple={handleUpdateMultipleChoiceAnswer}
              updateAnswerSingle={handleUpdateSingleAnswer}
              sectionCompleteness={thisSectionComplete}
              listOfJumpToQuestionsDisabled={listOfQuestionsDisabled}
              sectionNumber={activeSection}
              isAltLang={isAltLang}
            />
          )}
          {!editMode && (
            <div className={styles.getToNextSectionNote}>
              {!thisSectionComplete && (
                <Translate id='visaApplication.nextSectionNote' />
              )}
            </div>
          )}
        </Form>
      </Column>
      <FormEditorFooter
        unchangedQuestions={unchangedQuestions}
        updatedQuestions={updatedTemplate.questions}
        updatedSections={updatedTemplate.sections}
        unsavedChanges={updatedTemplate.unsavedChanges}
        editMode={editMode}
        readonly={updatedTemplate.readOnly}
        toggleEditMode={toggleEditMode}
        onSave={handleSave}
      />
    </Row>
  )
}

export default withLocalize(FormEditor)
