import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../AdminTables.module.scss'
import applicationPageStyles from './Applications.module.scss'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import Table from 'components/common/Table/Table'
import { fetchAllApplications, fetchApplicationsToReview } from 'actions/programManagerActions'
import FilterSearch from 'components/common/TableFilter/FilterSearch'
import Pagination from 'components/common/TableFilter/Pagination'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import FitScreen from 'components/common/Layout/FitScreen'
import { RootState } from "../../../reducers";
import { dataConverter, dataConverterAll, getAllColumns, getBaseColumns } from "./utils";

const Applications = ({translate}: LocalizeContextProps) => {
  /**
   * Check from the query params to see if there are any preferences in the inital active mode
   * the query param is: __showAllApplications
   */
  const {search} = useLocation()
  const initialActiveMode = parseInt(
    new URLSearchParams(search).get('__showAllApplications') || '1',
  )
  const [activeMode, setMode] = useState(initialActiveMode)

  const dispatch = useDispatch()
  const {
    applicationsToReview,
    applicationsToReviewCount,
    allApplications,
    allApplicationsCount,
  } = useSelector((state: RootState) => state.programManager)
  const {userId, role} = useSelector((state: RootState) => state.user)
  const {rank} = role
  const location = useLocation()
  const assignedData = dataConverter(applicationsToReview)
  const allData = dataConverterAll(allApplications)

  const baseColumns = getBaseColumns(translate)

  const allColumns = getAllColumns(translate)
  const columns = [
    {
      Header: ' ',
      columns: activeMode === 0 || rank === 1 ? baseColumns : allColumns,
    },
  ]

  useEffect(() => {
    if (rank >= 2) {
      if (activeMode === 0) {
        dispatch(
          fetchApplicationsToReview({
            userId,
            query: location.search,
          }),
        )
      } else if (activeMode === 1) {
        dispatch(
          fetchAllApplications({
            userId,
            query: location.search,
          }),
        )
      }
    } else {
      dispatch(
        fetchApplicationsToReview({
          userId,
          query: location.search,
        }),
      )
    }
  }, [dispatch, userId, activeMode, location.search, rank])

  // check whether assigned data is used or all data is used
  const isAssignedDataInUse = rank === 1 || activeMode === 0

  // the data source used in the table
  const tableDataSource = isAssignedDataInUse ? assignedData : allData

  const dataSourceCount = isAssignedDataInUse
    ? applicationsToReviewCount
    : allApplicationsCount

  return (
    <FitScreen>
      <div
        className={classNames(styles.container, applicationPageStyles.container)}
      >
        <FilterSearch
          dark
          supportedFilters={{
            company: 'Company',
            applicant: 'Applicant',
            program: 'Programme',
            ...(isAssignedDataInUse
              ? {}
              : {
                reviewer: 'Reviewer',
              }),
          }}
          defaultFilter='company'
        />
        <div
          className={classNames(styles.tableContainer, styles.tableWithSearch)}
        >
          <Table
            title={translate('applications.applications').toString()}
            data={tableDataSource}
            modes={rank >= 2 ? ['Assigned', 'All'] : undefined}
            onModeChange={rank >= 2 ? setMode : undefined}
            columns={columns}
            activeModeIndex={activeMode}
          />
        </div>
        <Pagination resultCount={dataSourceCount} />
      </div>
    </FitScreen>
  )
}

export default withLocalize(Applications)
