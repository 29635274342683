import { SliderState } from '../utils/state'
import { AppThunk } from "./types";

// *** ACTIONS ***
export const ACTIONS = {
  SET_DRAGGABLE_TRUE: '@dff_common_action/set_draggable_true',
  SET_DRAGGABLE_FALSE: '@dff_common_action/set_draggable_false',
  SET_SCROLL_POP_TRUE: '@dff_common_action/set_scroll_pop_true',
  SET_SCROLL_POP_FALSE: '@dff_common_action/set_scroll_pop_false',
}

export const toggleScrollPop = (scrollTop: number): AppThunk => (
  dispatch,
  getState,
) => {
  const state: SliderState = getState().slider
  const { scrollPop } = state
  const isPassedThreshold = scrollTop > (window.innerHeight - 130)
  if (isPassedThreshold && !scrollPop) {
    dispatch({ type: ACTIONS.SET_SCROLL_POP_TRUE })
  } else if (!isPassedThreshold && scrollPop) {
    dispatch({ type: ACTIONS.SET_SCROLL_POP_FALSE })
  }
}
