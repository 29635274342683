import { DateTime } from 'luxon'

const getDayNumberSuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th'
  }
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const getShortDate = (date: string): string => {
  const day = +DateTime.fromISO(date).toFormat(`dd`)
  return DateTime.fromISO(date).toFormat(
    `LLL dd'${getDayNumberSuffix(day)}' yyyy, HH:mm`,
  )
}
export const tagTechnologies = (technologies?: string[]) =>
  technologies ? technologies.map((t) => `#${t} `) : []
