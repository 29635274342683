import styles from "./SearchHighlight.module.scss";
import React from "react";

export const highLightSearch = (s: string, regex: RegExp): JSX.Element | string => {
  if (!regex) {
    return s
  }

  const match = s.match(regex)
  if (match && match[0] === s) {
    return (
      <p><span className={styles.highlight}>{s}</span></p>
    )
  }
  const splitted = s.split(regex)
  return (
    <p>
      {splitted.map((part, i) => {
        const pass = regex.test(part)
        return (
          <span key={i} className={pass ? styles.highlight : ''}>
            {part}
          </span>
        )
      })}
    </p>
  )
}

export const getFirstLetter = (word: string): string => word?.substring(0, 1) || '';
