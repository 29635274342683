import React from 'react'
import styles from './FileDownload.module.scss'
import { Line } from 'rc-progress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSpinner, } from '@fortawesome/free-solid-svg-icons'
import DeleteFile from '../../../assets/icons/close.svg'
import { Translate } from 'react-localize-redux'
import { FileDownloadProps } from "./types";

const FileDownload = ({
  downloadFile,
  downloading,
  deleteFile,
  progress,
}: FileDownloadProps) => {
  return (
    <div className={styles.uploadedFileBar}>
      <div>
        <div className={styles.progressFileUploadLine}>
          {progress !== undefined && (
            <Line
              percent={progress ? progress * 100 : 100}
              strokeWidth={1}
              strokeColor={styles.lineColor}
              trailWidth={1}
              trailColor={'#f2f2f2'}
            />
          )}
        </div>
      </div>
      <div className={styles.uploadedFileWrapper}>
        <div className={styles.fileNameBox}>
          <label className={styles.fileNameLabel}>
            <Translate id={progress === 1 || progress === undefined
              ? 'utility.fileUpload.uploaded'
              : 'utility.fileUpload.uploading'
            } />
          </label>
        </div>
        <div className={styles.fileActions}>
          {downloading ? (
            <button
              className={styles.fileActionBtn}
              disabled
            >
              <FontAwesomeIcon icon={faSpinner} className={'fa fa-spinner fa-spin'} />
            </button>
          ) : (
            <>
              <button
                className={styles.fileActionBtn}
                onClick={downloadFile}
                disabled={progress !== undefined}
              >
                <FontAwesomeIcon icon={faDownload} className={'fa'} />
              </button>
              {deleteFile && (
                <button
                  className={styles.fileActionBtn}
                  onClick={deleteFile}
                  disabled={progress !== undefined}
                >
                  <img src={DeleteFile} alt='delete file icon' />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FileDownload
