import React from 'react'
import { useSelector } from 'react-redux'
import styles from './InternalDashboard.module.scss'
import AdminMetrics from './Admin/AdminMetrics'
import ReviewerMetrics from './Reviewer/ReviewerMetrics'
import { RootState } from '../../../reducers'
import AdminTables from './Admin/AdminTables'
import ReviewerTables from './Reviewer/ReviewerTables'

const InternalDashboard = () => {
  const { user } = useSelector((state: RootState) => state)
  const { rank } = user.role

  return (
    <div className={styles.page}>
      <div className={styles.metricContainer}>
        {rank >= 2 ? <AdminMetrics /> : <ReviewerMetrics />}
      </div>
      <div className={styles.tableContainer}>
        {rank >= 2 ? <AdminTables /> : <ReviewerTables />}
      </div>
    </div>
  )
}

export default InternalDashboard
