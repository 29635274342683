import React from 'react'
import styles from './Footer.module.scss'
import { Translate } from 'react-localize-redux'
import logo from 'assets/images/logos/dff_black.svg'
import { GovLinks } from "./GovLinks";

const rootUrl = process.env.REACT_APP_DFF_URL

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.links}>
      <div className={styles.logo}>
        <img alt='Dubai future programs' src={logo} />
      </div>
      <div className={styles.copyright}>
        <Translate
          id='footer.dff'
          data={{year: new Date().getFullYear()}}
        />
      </div>
      <div className={styles.govLinkContainer}>
        <GovLinks />
      </div>
      <div className={styles.govLinkContainer}>
        <a href={`${rootUrl}terms-of-service`}>
          <Translate id='footer.terms_of_use' />
        </a>
        <a href={`${rootUrl}privacy-policy`}>
          <Translate id='footer.privacy_policy' />
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
