import { ICriteria } from 'models/review/criteria'
import { IStage } from 'components/common/models/application/IStage'

export const convertCriteriaToTemplate = ({filters, name, id, stages}: ICriteria) => {
  const getFilter = (name: string) => {
    return filters.find(({filterName}) => filterName === name)
  }
  const getSimpleField = (name: string) => {
    const filter = getFilter(name)
    return filter?.target.targetValue
  }
  const getComparisonField = (name: string) => {
    const filter = getFilter(name)
    return filter && {
      comparitor: filter.operator,
      value: filter.target.targetValue,
    }
  }
  const getArrayField = (name: string) => {
    const filter = getFilter(name)
    return filter && filter.target.targetValues
  }

  const dates = filters.filter(({filterName}) => filterName === 'date')
  const minDate = dates?.find(({operator}) => operator === '>')
  const maxDate = dates?.find(({operator}) => operator === '<')
  const onStages = stages?.map(({challengeName, stageName}) => `${challengeName} / ${stageName}`) || []
  return {
    name,
    id,
    onStages,
    applicationEligibility: {
      company: getSimpleField('company'),
      minimumScore: getSimpleField('score'),
      location: getArrayField('location'),
      sector: getArrayField('sector'),
      employees: getComparisonField('employees'),
      minDate: minDate && minDate.target.targetValue,
      maxDate: maxDate && maxDate.target.targetValue,
    },
    accountEligibility: {
      futureIdCompletion: getSimpleField('futureIdCompletion'),
      futureId: getSimpleField('futureId'),
    },
    previousApplications: {
      goldenTicket: getSimpleField('goldenTicket'),
      gateApprovals: getComparisonField('gateApprovals'),
      pendingApplications: getComparisonField('pendingApplications'),
    },
  }
}

export const cleanStageForSave = (stage: Partial<IStage>, isScoringToolSame = false) => {
  const scoringTool = stage.scoringTool
  const cleanedScoringTool = {
    ...scoringTool,
    threshold:
      scoringTool?.name === 'YesNo'
        ? stage?.maxReviewers && Math.floor(stage?.maxReviewers / 2) + 1
        : scoringTool?.threshold,
  }

  return {
    ...stage,
  ...(isScoringToolSame ? {} : { scoringTool: cleanedScoringTool }),
    completenessPercent: undefined,
    numberOfApplications: 0,
    numberOfRejectedApplications: undefined,
  }
}


export const compareStages = (oldStage: IStage, stage: IStage): Partial<IStage> => {
  const diff: Partial<IStage> = {};

  for (const key in stage) {
    if (
      stage.hasOwnProperty(key) &&
      (!oldStage.hasOwnProperty(key) || oldStage[key as keyof IStage] !== stage[key as keyof IStage])
    ) {
      // @ts-ignore
      diff[key as keyof IStage] = stage[key as keyof IStage];
    }
  }

  return diff;
}
