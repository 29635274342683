// *** FacilitatorActions ***
export const ACTIONS = {
  ADD_SUCCESS_TOAST: '@dff_common_action/add_success_toast',
  ADD_ERROR_TOAST: '@dff_common_action/add_error_toast',
  REMOVE_SUCCESS_TOAST: '@dff_common_action/remove_success_toast',
  REMOVE_ERROR_TOAST: '@dff_common_action/remove_error_toast',
  CLEAR_SUCCESS_TOASTS: '@dff_common_action/clear_success_toasts',
  CLEAR_ERROR_TOASTS: '@dff_common_action/clear_error_toasts',
}

export interface IToast {
  message: string
  buttonText?: string
  buttonHandler?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  button2Text?: string
  button2Handler?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

export type IToastPayload = IToast | string

export interface AddToastAction {
  type: typeof ACTIONS.ADD_SUCCESS_TOAST | typeof ACTIONS.ADD_ERROR_TOAST
  payload: IToastPayload
}

export interface RemoveToastAction {
  type: typeof ACTIONS.REMOVE_SUCCESS_TOAST | typeof ACTIONS.REMOVE_ERROR_TOAST
  payload: number
}

export interface ClearToastAction {
  type: typeof ACTIONS.CLEAR_SUCCESS_TOASTS | typeof ACTIONS.CLEAR_ERROR_TOASTS
}

export type ToastAction = AddToastAction
  | RemoveToastAction
  | ClearToastAction

export const addSuccessToast = (payload: IToastPayload | string): AddToastAction => {
  return {
    payload,
    type: ACTIONS.ADD_SUCCESS_TOAST,
  }
}

export const addErrorToast = (payload: IToastPayload | string): AddToastAction => {
  return {
    payload,
    type: ACTIONS.ADD_ERROR_TOAST,
  }
}

export const removeSuccessToast = (id: number): RemoveToastAction => {
  return {
    type: ACTIONS.REMOVE_SUCCESS_TOAST,
    payload: id,
  }
}

export const removeErrorToast = (id: number): RemoveToastAction => {
  return {
    type: ACTIONS.REMOVE_ERROR_TOAST,
    payload: id,
  }
}

export const clearSuccessToasts = (): ClearToastAction => {
  return {
    type: ACTIONS.CLEAR_SUCCESS_TOASTS,
  }
}

export const clearErrorToasts = (): ClearToastAction => {
  return {
    type: ACTIONS.CLEAR_ERROR_TOASTS,
  }
}
