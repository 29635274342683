import React from 'react'
import { QuestionType, typesWithOptions, } from 'components/common/models/application/IQuestion'
import { isAnswered } from 'components/common/models/application/IAnswer'
import FileDownload from 'components/common/FileDownload'
import { Translate } from 'react-localize-redux'
import DisplayValue from 'components/common/DisplayValue/DisplayValue'
import { IReadOnlyQuestionProps } from "./types";

const ReadOnlyQuestion = ({
  question,
  answer,
  downloadFile,
  downloading,
  isAltLang,
  isRTL,
  reducedDataSet,
}: IReadOnlyQuestionProps) => {
  if (question.mandatory && !isAnswered(answer)) {
    // a mandatory question which hasn't been answered is implicitly one which has been skipped
    return null
  }

  const getAnswerValue = () => {
    if (typesWithOptions.includes(question.type)
      ? answer.selectedOptions!.length === 0
      : !answer.answer
    ){
      return (
        <Translate id='review.unanswered' />
      )
    }
    if (question.type === QuestionType.FILE_UPLOAD) {
      return <FileDownload downloadFile={downloadFile} downloading={downloading} />
    }
    if (typesWithOptions.includes(question.type)) {
      const selectedOptionStrings = answer.selectedOptions!.map((fullDataIndex, reducedDataIndex) => {
        const usedIndex = reducedDataSet ? reducedDataIndex : fullDataIndex
        return isAltLang
          ? question.options![usedIndex].optionAlt
          : question.options![usedIndex].option
      })

      return selectedOptionStrings.join(', ')
    }
    return answer.answer
  }

  return (
    <DisplayValue
      isRtl={isRTL}
      label={isAltLang ? question.questionAlt : question.question}
      value={getAnswerValue()}
    />
  )
}

export default ReadOnlyQuestion
