import { IProgramOwner } from "../programOwner/program-owner";
import { IChallengeOwner } from "./challenge-owner";

const moment = require("moment-timezone");

export interface IChallenge {
  id?: any;
  coverImage?: string;
  detailImages?: string[];
  name?: string;
  nameAlt?: string;
  technologies?: string[];
  technologiesAlt?: string[];
  location?: string;
  description?: string;
  descriptionAlt?: string;
  oneliner?: string;
  onelinerAlt?: string;
  programOwner?: IProgramOwner;
  challengeOwner?: IChallengeOwner;
  process?: string;
  startDate?: Date;
  applicationDeadline?: Date;
  applicationDeadlineTZ?: string;
  profileCompletenessPercent?: number;
  reviewStageTemplateId?: any;
  applicationTemplateId?: any;
  onboardingTemplateId?: any;
  isPublic?: boolean;
  language?: string;
  availableLanguages?: string[];
  hasOnboarding?: boolean;
  rejectionMode?: RejectionMode;
  autoCreateprofiles?: boolean;
  metaData?: {
    applicationCount?: number;
    rejectedApplicationCount?: number;
  };
  publishedAt?: Date;
  applicationMode?: ApplicatioMode;
  updatedAt?: Date;
  createdAt?: Date;
}

export function isExpired(challenge: IChallenge) {
  const now = moment();

  if (!challenge.applicationDeadline) {
    return false;
  }

  const deadlineInDubai = moment.tz(challenge.applicationDeadline.toISOString(), "Asia/Dubai");

  return deadlineInDubai.isBefore(now);
}

export enum RejectionMode {
  onChallenge = "onChallenge",
  onApplication = "onApplication",
}

export const rejectionModeOptions = [
  {
    value: 'onApplication',
    label: 'Automatic',
  },
  {
    value: 'onChallenge',
    label: 'Manual',
  },
]

export enum ApplicatioMode {
  INDIVIDUAL = "FID_INDIVIDUAL",
  ORGANISATION = "FID_ORGANISATION",
  EMAIL = "E-MAIL",
}
