import { IApplicationToReview } from 'models/application/application-to-review'
import { DataApplicantRow } from "../../types";

export function convertDataApplications(
  reviewedApplications: IApplicationToReview[] | null,
): DataApplicantRow[] {
  if (reviewedApplications == null) return []
  return reviewedApplications.map((reviewedApplication) => ({
      applicationId: reviewedApplication.application._id,
      applicant: reviewedApplication.application.applicant,
      program: reviewedApplication.application.program,
      status: reviewedApplication.stage.name,
    }))
}
