import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllRoles, getAllUsers } from '../../actions/adminActions'
import UsersRoleList from './UsersRoleList'
import styles from './ManageRights.module.scss'
import { withLocalize } from 'react-localize-redux'
import { useLocation } from 'react-router'
import FilterSearch from 'components/common/TableFilter/FilterSearch'
import FitScreen from 'components/common/Layout/FitScreen'
import { ManageRightsProps } from "./types";
import { RootState } from "../../reducers";

const ManageRights = ({ translate }: ManageRightsProps) => {
  const { search } = useLocation()

  /********************************************************
   * Results from redux store
   *******************************************************/
  const dispatch = useDispatch()
  const { user: loggedInUser } = useSelector((state: RootState) => state)
  const { loadingUsers, roles: rawRoles, users: userResults } = useSelector(
    (state: RootState) => state.admin,
  )

  // hide the "Admin" role to fulfill requirements
  const roles = rawRoles.filter((role) => role.rank !== 3)

  /********************************************************
   * Side effects
   *******************************************************/
  React.useEffect(() => {
    // get roles if the component just loaded
    dispatch(getAllRoles())
  }, [dispatch])

  // when search params updated, update the user list (filtered) accordingly
  React.useEffect(() => {
    const fetchUsers = async() => {
      dispatch(getAllUsers(search))
    }

    fetchUsers()
  }, [dispatch, search])

  return (
    <FitScreen>
      <div className={styles.container}>
        <div className={styles.searchBarRow}>
          <FilterSearch
            dark
            supportedFilters={{
              email: 'Email',
              firstName: 'First Name',
              lastName: 'Last Name',
            }}
            defaultFilter='lastName'
          />
        </div>
        <UsersRoleList
          loading={loadingUsers}
          users={userResults}
          roles={roles}
          loggedInUser={loggedInUser}
        />
      </div>
    </FitScreen>
  )
}

export default withLocalize(ManageRights)
