import React, { useState } from 'react'
import styles from './SectionTab.module.scss'
import classNames from 'classnames'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { Translate } from 'react-localize-redux'
import { getScoreString } from './utils'
import SectionDeleteButton from './SectionDeleteButton'
import SectionStatusIndicator from './SectionStatusIndicator'
import { ISectionTabProps } from "./types";

const SectionTab = ({
  section,
  sectionScore,
  possibleScore,
  complete,
  active,
  onClick,
  editMode,
  onDelete,
  displayStatus,
  isAltLang,
  isRelative,
  disabled,
}: ISectionTabProps) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleDeleteApprove = () => {
    onDelete && onDelete()
    setTooltipVisible(false)
  }

  const handleDeleteReject = () => {
    setTooltipVisible(false)
  }

  return (
    <Translate>
      {({ translate }) => (
        <div className={styles.sectionTabWrapper}>
          <React.Fragment key={`section-tab-${section.id}`}>
            <span className={styles.sectionIndicator}>
              {editMode ? (
                <SectionDeleteButton onClick={() => setTooltipVisible(true)} />
              ) : (
                <SectionStatusIndicator
                  isComplete={complete}
                  isVisible={displayStatus}
                />
              )}
              {tooltipVisible && (
                <NotificationModal
                  onAccept={handleDeleteApprove}
                  onCancel={handleDeleteReject}
                >
                  <Translate id='visaApplication.deleteSectionTooltipDescription' />
                </NotificationModal>
              )}
            </span>
            <button
              className={classNames(
                styles.sectionTitleTab,
                active
                  ? styles.sectionTitleTabActive
                  : styles.sectionTitleTabInactive,
              )}
              onClick={!disabled ? onClick : () => {}}
            >
              {possibleScore ? (
                <div className={styles.sectionScore}>
                  {getScoreString(possibleScore, sectionScore, isRelative)}
                </div>
              ) : null}
              <label
                className={classNames(styles.sectionTitleTabLabel, {
                  [styles.disabled]: disabled,
                })}
              >
                {isAltLang ? section.titleAlt : section.title}
              </label>
              {active && <div className={styles.backgroundPadding} />}
            </button>
          </React.Fragment>
        </div>
      )}
    </Translate>
  )
}

export default SectionTab
