import { ACTIONS } from '../actions/programOwnerActions'
import { LOGOUT } from '../components/common/actions/authActions'
import { IProgramOwner } from '../models/programOwner/program-owner'
import { IReview } from '../models/review/review'
import { IReviewStage } from '../models/review/review-stage'
import { IChallenge } from '../models/challenge/challenge'
import { createReducer } from "./createReducer";
import { Action } from "redux";
import { ProgramOwnerState, Reducer } from "./types";

;

const defaultState: ProgramOwnerState = {
  loadingProfiles: null,
  programOwnerProfiles: null,
  review: null,
  loadingReview: null,
  activeStage: null,
  activePoProfile: null,
}

const reducer: Reducer<ProgramOwnerState, Action> = {
  [ACTIONS.LOADING_PROGRAM_OWNER_PROFILES]: (state, {payload}) => ({
    ...state,
    loadingProfiles: payload,
  }),
  [ACTIONS.LOADING_PROGRAM_OWNER_PROFILES_SUCCESS]: (state, {payload}) => ({
    ...state,
    programOwnerProfiles: payload,
  }),
  [ACTIONS.PROGRAM_OWNER_PROFILE_DELETED]: (state, {payload}) => {
    if (state.programOwnerProfiles !== null) {
      return {
        ...state,
        programOwnerProfiles: state.programOwnerProfiles.filter(
          (profile: IProgramOwner) => profile.id !== payload,
        ),
      }
    }
    return state
  },
  [ACTIONS.PROGRAM_CREATED]: (state, {payload}) => {
    if (state.programOwnerProfiles !== null) {
      return {
        ...state,
        programOwnerProfiles: [...state.programOwnerProfiles, payload],
      }
    }
    return state
  },
  [ACTIONS.LOADING_REVIEW]: (state, {payload}) => ({
    ...state,
    loadingReview: payload,
  }),
  [ACTIONS.LOADING_REVIEW_SUCCESS]: (state, {payload}) => ({
    ...state,
    review: payload,
  }),
  [ACTIONS.CLEAR_REVIEW]: (state, {payload}) => ({
    ...state,
    review: {},
  }),
  [ACTIONS.STAGE_CREATED]: (state, {payload}) => ({
    ...state,
    review: payload,
  }),
  [ACTIONS.STAGE_UPDATED]: (state, {payload}) => {
    if (state.review?.reviewStages) {
      const review: IReview = {...state.review}
      review.reviewStages = state.review.reviewStages.map((review: IReviewStage) =>
        review.id === payload.id ? payload : review,
      )
      return {
        ...state,
        review,
        activeStage: payload,
      }
    }
    return state
  },
  [ACTIONS.STAGE_DELETED]: (state, {payload}) => ({
    ...state,
    review: payload,
  }),
  [ACTIONS.CHALLENGE_CREATED]: (state, {payload}) => {
    if (state.programOwnerProfiles !== null && state.activePoProfile !== null) {
      const newProgramOwnerProfiles = state.programOwnerProfiles.map(programOwnerProfile => {
        if (programOwnerProfile.id === state.activePoProfile!.id) {
          if (programOwnerProfile.challenges) {
            return {
              ...programOwnerProfile,
              challenges: [
                payload,
                ...programOwnerProfile.challenges,
              ],
            }
          }
          return {
            ...programOwnerProfile,
            challenges: [
              payload,
            ],
          }
        }
        return programOwnerProfile
      })
      return {...state, programOwnerProfiles: newProgramOwnerProfiles}
    }
    return state
  },
  [ACTIONS.CHALLENGE_DELETED]: (state, {payload}) => {
    if (state.programOwnerProfiles !== null) {
      const newProgramOwnerProfiles = state.programOwnerProfiles.map(programOwnerProfile => {
        if (
          programOwnerProfile &&
          programOwnerProfile.challenges &&
          programOwnerProfile.challenges.length
        ) {
          return {
            ...programOwnerProfile,
            challenges: programOwnerProfile.challenges.filter(
              (challenge: IChallenge) => challenge.id !== payload,
            ),
          }
        }
        return programOwnerProfile
      })

      return {...state, programOwnerProfiles: newProgramOwnerProfiles}
    }
    return state
  },
  [ACTIONS.LOADING_PROGRAM_MANAGERS]: (state, {payload}) => ({...state, loadingAllProgramManagers: payload}),
  [ACTIONS.LOADED_PROGRAM_MANAGERS]: (state, {payload}) => ({...state, allProgramManagers: payload}),
  [ACTIONS.SET_ACTIVE_STAGE]: (state, {payload}) => ({...state, activeStage: payload}),
  [ACTIONS.CLEAR_ACTIVE_STAGE]: (state) => ({...state, activeStage: undefined}),
  [ACTIONS.SET_ACTIVE_PO_PROFILE]: (state, {payload}) => ({...state, activePoProfile: payload}),
  [ACTIONS.CHALLENGE_UPDATED]: (state, {payload}) => {
    if (state.activePoProfile?.challenges && state.programOwnerProfiles) {
      const newActivePoProfile: IProgramOwner = {...state.activePoProfile}
      if (newActivePoProfile.challenges) {
        newActivePoProfile.challenges = newActivePoProfile.challenges.map((challenge: IChallenge) =>
          challenge.id === payload.id ? payload : challenge,
        )
      }
      const newProgramOwnerProfiles = [...state.programOwnerProfiles]
      for (const profile of newProgramOwnerProfiles) {
        const anyProfile = profile as any
        anyProfile.challenges = anyProfile.challenges.map((challenge: IChallenge) =>
          challenge.id === payload.id ? payload : challenge,
        )
      }
      return {
        ...state,
        activePoProfile: newActivePoProfile,
        programOwnerProfiles: newProgramOwnerProfiles,
      }
    }
    return state
  },
  [LOGOUT]: () => defaultState,
}

export default createReducer<ProgramOwnerState, Action>(reducer, defaultState);