import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import MainContainer from '../components/containers/MainContainer'
import Login from '../components/Login/Login'
import Challenge from '../pages/Challenge'
import ManageRights from '../components/Admin/ManageRights'
import About from 'components/About/About'
import Terms from 'components/Terms/Terms'
import Privacy from 'components/Privacy/Privacy'
import background from '../assets/images/backgrounds/main.jpg'
import ApplicationSuccess from '../components/Application/ApplicationSuccess/ApplicationSuccess'
import OrganizationSelector from '../components/common/Application/OrganizationSelector'
import ValidateEmailApplication from 'components/common/Application/ValidateEmailApplication'
import ApplicationPage from '../pages/ApplicationPage'
import ProgramOwners from 'pages/AdminTables/ProgramOwners/ProgramOwners'
import Programs from 'pages/AdminTables/Programs/Programs'
import InternalDashboard from '../components/containers/InternalDashboard/InternalDashboard'
import ProgramSettings from '../components/common/ProgramSettings/ProgramSettings'
import ReviewApplication from 'components/common/ReviewApplication/ReviewApplication'
import Applications from 'pages/AdminTables/Applications/Applications'
import ApplicationListing from '../components/ApplicationListing/ApplicationListing'
import IndividualApplicant from 'components/common/Application/IndividualApplicant'
import ValidateEmailToken from '../components/common/Application/ValidateEmailToken'
import EditFacilitator from 'components/common/Facilitator/EditFacilitator'
import useFIDAuth from 'components/common/utils/useFIDLogin'

const renderChallenges = () => (<Redirect to={process.env.REACT_APP_PROGRAMS_URL || '/'} />)

const mainRoutes = [
  {
    loggedIn: '/start',
    loggedOut: '/',
    type: 'simple',
    headingKey: 'intro1.hline1',
    descriptionKey: 'intro1.description',
  },
  {
    loggedOut: '/meet',
    type: 'simple',
    headingKey: 'intro2.hline1',
    descriptionKey: 'intro2.description',
  },
  {
    loggedOut: '/landing',
    type: 'two-button',
    headingKey: 'landing_page.hline1',
    descriptionKey: 'landing_page.description',
    button2Text: 'landing_page.register',
    button1Text: 'landing_page.view_challenges',
    button2Target: '/login',
    button1Target: process.env.REACT_APP_PROGRAMS_URL,
  },
]

const createMainRoute = (
  path: string,
  rightSlideComponent: any,
  rightSlidePath: string | null = null,
  initialSlide: number = 3,
) => {
  return (
    <Route
      exact
      path={path}
      render={() => (
        <MainContainer
          initialSlide={initialSlide}
          rightSlideComponent={rightSlideComponent}
          rightSlidePath={rightSlidePath || path}
          routes={mainRoutes}
          background={background}
        />
      )}
    />
  )
}

const getHomePage = (userRank: number) => () => {
  if (userRank >= 1) {
    return <InternalDashboard />
  }
  window.location.assign(process.env.REACT_APP_PROGRAMS_URL as string)
  return <div>Loading...</div>
}

export const getProtectedRoutes = (userRank: number) => {
  if (userRank === -1) {
    return <div>Loading...</div>
  }
  return (
    <Switch>
      <Route exact path='/' render={getHomePage(userRank)} />
      {createMainRoute('/start', null, '/login', 0)}
      {createMainRoute('/meet', null, '/login', 1)}
      {createMainRoute('/landing', null, '/login', 2)}
      <Route exact path='/about' component={About} />
      <Route exact path='/programs' render={renderChallenges} />
      <Route
        exact
        path='/programs/:id/success'
        component={ApplicationSuccess}
      />
      <Route
        exact
        path='/programs/:id/application'
        component={ApplicationPage}
      />
      <Route
        exact
        path='/programs/:id/selectOrganisation'
        component={OrganizationSelector}
      />
      <Route
        exact
        path='/programs/application/individual'
        component={IndividualApplicant}
      />
      <Route
        exact
        path='/programs/:id/application/individual'
        component={IndividualApplicant}
      />

      <Route
        exact
        path='/programs/:id/onboarding/:step'
        component={Challenge}
      />
      <Route
        exact
        path='/programs/:id/onboarding/:startup/:step'
        component={Challenge}
      />
      <Route exact path='/programs/:id/onboardingForm' component={Challenge} />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/privacy' component={Privacy} />
      <Route exact path='/logout' component={Logout} />
      {userRank > 0 && (
        <>
          <Route exact path='/applications' component={Applications} />
          <Route exact path='/facilitators' component={ProgramOwners} />
          <Route
            exact
            path='/facilitators/:id/settings'
            component={EditFacilitator}
          />
          <Route exact path='/programs/:id/settings' component={ProgramSettings} />
          <Route
            exact
            path='/programs/:id/applications'
            component={ApplicationListing}
          />
          <Route exact path='/manage-rights' component={ManageRights} />
          <Route
            exact
            path='/review/:applicationId'
            component={ReviewApplication}
          />
          <Route exact path='/programs-list' component={Programs} />
        </>
      )}
      <Redirect to='/' />
    </Switch>
  )
}

export const getRoutes = () => {
  return (
    <Switch>
      {createMainRoute('/', null, null, 0)}
      {createMainRoute('/meet', null, null, 1)}
      {createMainRoute('/landing', null, '/login', 2)}
      {createMainRoute('/login', <Login />, null, 3)}
      <Route exact path='/about' component={About} />
      <Route exact path='/programs' render={renderChallenges} />
      <Route exact path='/programs/:id' component={Challenge} />
      <Route
        exact
        path='/programs/:id/application'
        component={ApplicationPage}
      />
      <Route
        exact
        path='/programs/:id/success'
        component={ApplicationSuccess}
      />
      <Route
        exact
        path='/programs/:id/application/validateEmail'
        component={ValidateEmailApplication}
      />
      <Route
        exact
        path='/programs/:programId/application/:email/verifyToken'
        component={ValidateEmailToken}
      />
      <Route exact path='/programs/:id/apply/:step' component={Challenge} />
      <Route
        exact
        path='/programs/:id/apply/:startup/:step'
        component={Challenge}
      />
      <Route
        exact
        path='/programs/:id/onboarding/:step'
        component={Challenge}
      />
      <Route
        exact
        path='/programs/:id/onboarding/:startup/:step'
        component={Challenge}
      />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/privacy' component={Privacy} />
      <Redirect to='/' />
    </Switch>
  )
}

const Logout = () => {
  const { logout } = useFIDAuth()
  logout()
  return null
}

// export default routes;
