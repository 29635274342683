import React from 'react'
import styles from './Column.module.scss'
import classnames from 'classnames'
import { IColumnProps } from "./types";

const Column = React.forwardRef(
  (
    {nav, children, className}: IColumnProps,
    ref: React.Ref<HTMLDivElement>,
  ) => nav ? (
    <div className={classnames(styles.navColumn, className)} ref={ref}>
      <div className={styles.innerNavContainer}>{children}</div>
    </div>
  ) : (
    <div className={classnames(styles.mainColumn, className)} ref={ref}>
      {children}
    </div>
  )
)

export default Column
