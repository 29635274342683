// Components for grouping a list of icon
import React from 'react'
import styles from './Table.module.scss'
import ActionIcon from './ActionIcon'
import classNames from 'classnames'
import { ActionIconGroupProps } from "./types";

const ActionIconGroup = ({ actions, className }: ActionIconGroupProps) => (
  <div className={classNames(styles.actionIconGroupContainer, className)}>
    {actions.map((props, i) => (
      <ActionIcon {...props} key={i} />
    ))}
  </div>
)

export default ActionIconGroup
