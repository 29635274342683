import { RootState } from "../../reducers";
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Main from '../common/Main/Main'

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.auth.loggedIn || false,
  slider: state.slider,
})

export default connect(
  mapStateToProps,
  { push },
)(Main)
