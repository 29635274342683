export enum ScoringMethod {
  SUM = 'Sum',
  AVG = 'Avg',
}

export enum ScoringToolName {
  YES_NO = 'YesNo',
  RATING = 'Rating',
}

export interface IScoringTool {
  name?: string
  threshold?: number
  max?: number
  min?: number
  method?: ScoringMethod
  description?: string
}

export const DefaultScoringTool: { [key in ScoringToolName]: IScoringTool } = {
  [ScoringToolName.YES_NO]: {
    name: ScoringToolName.YES_NO,
    threshold: 1,
    max: 1,
    min: 0,
    method: ScoringMethod.SUM,
  },
  [ScoringToolName.RATING]: {
    name: ScoringToolName.RATING,
    threshold: 4,
    max: 5,
    min: 0,
    method: ScoringMethod.AVG,
  }
};

export const scoringTools: IScoringTool[] = [
  DefaultScoringTool[ScoringToolName.YES_NO],
  DefaultScoringTool[ScoringToolName.RATING],
]

export function getScoringMethods(): string[] {
  return Object.values(ScoringMethod)
}

export function getScoringToolNames(): string[] {
  const toolNames = []
  for (const tool of scoringTools) {
    toolNames.push(tool.name || '')
  }
  return toolNames
}
