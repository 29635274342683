import React from 'react'
import SectionTab from './SectionTab'
import styles from './SectionList.module.scss'
import { Translate } from 'react-localize-redux'
import { getScoreString } from './utils'
import { SectionListProps } from "./types";

const SectionList = (props: SectionListProps) => {
  const {
    sections,
    sectionScores,
    possibleScores,
    displayStatus,
    onSelected,
    selectedSection,
    isSectionComplete,
    editMode,
    onDelete,
    isAltLang,
    isRelative,
  } = props

  const sumScores = (scoreArray: number[]) => {
    return scoreArray.reduce(
      (totalScore: number, sectionScore) => totalScore + sectionScore,
      0,
    )
  }

  const totalScorePossible = possibleScores && sumScores(possibleScores)
  const totalScoreAchieved = sectionScores && sumScores(sectionScores)

  return (
    <div className={styles.list}>
      {sections.map((section, index) => {
        return (
          <SectionTab
            isRelative={isRelative}
            key={`section ${index}`}
            section={section}
            sectionScore={sectionScores && sectionScores[index]}
            possibleScore={possibleScores && possibleScores[index]}
            complete={
              displayStatus && isSectionComplete
                ? isSectionComplete(index)
                : false
            }
            active={index === selectedSection}
            onClick={() => onSelected(index)}
            displayStatus={displayStatus}
            editMode={editMode}
            onDelete={onDelete && onDelete(index)}
            isAltLang={isAltLang}
            disabled={section.disabled}
          />
        )
      })}
      {possibleScores && possibleScores.length > 0 && (
        <div className={styles.totalScoreContainer}>
          <div className={styles.totalScoreBox}>
            <Translate
              id='review.totalScore'
              data={{
                score: getScoreString(
                  totalScorePossible,
                  totalScoreAchieved,
                  isRelative,
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default SectionList
