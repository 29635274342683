import React from 'react'
import { IQuestionSection } from '../../models/application/ISection'
import styles from '../ApplicationPanel.module.scss'

interface ISectionDescriptionProps {
  sections: IQuestionSection[]
  index: number
}

export const SectionDescription = ({ sections, index }: ISectionDescriptionProps) => {
  const sectionDescription = sections[index] && sections[index].description
  return sectionDescription ? (
    <div className={styles.description}>{sectionDescription}</div>
  ) : null
}
