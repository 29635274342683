import React, { ReactNode } from 'react'
import { debounce } from 'throttle-debounce'
import styles from './Header.module.scss'

export const HeaderContext = React.createContext<HeaderContextProps>({
  isSmall: false,
  isMedium: false,
  collapsed: false,
  setCollapsed: () => {},
})

export const HeaderContextProvider = ({
  render,
}: {
  render: (props: HeaderContextProps) => JSX.Element
}) => {
  const [isSmall, setSmall] = React.useState(false)
  const [isMedium, setMedium] = React.useState(false)
  const [collapsed, setCollapsed] = React.useState(false)

  /*
    Registering scroll handler
  */
  React.useEffect(() => {
    const handleResize = debounce(200, () => {
      setSmall(window.innerWidth <= parseInt(styles.smallBreakpoint, 10))
      setMedium(window.innerWidth <= parseInt(styles.largeBreakpoint, 10))
    })
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <HeaderContext.Provider
      value={{
        isSmall,
        isMedium,
        collapsed,
        setCollapsed,
      }}
    >
      {render({ isSmall, isMedium, collapsed, setCollapsed })}
    </HeaderContext.Provider>
  )
}

export interface HeaderContextProps {
  isSmall: boolean
  isMedium: boolean
  collapsed: boolean
  setCollapsed: (flag: boolean) => void
}

export interface IProps {
  context: HeaderContextProps
  children: ReactNode
}
