import { IFacilitator, IFacilitatorResponse } from 'models/facilitator/facilitator'
import {
  IFacilitatorAction,
  ACTIONS,
} from '../actions/facilitatorActions'
import { Reducer } from "./types";
import { createReducer } from "./createReducer";

export interface IFacilitatorMap {
  [id: string]: IFacilitator
}

export interface IProgressMap {
  [id: string]: number
}

export interface IFacilitatorsState {
  facilitators: IFacilitatorMap
  loading: string[]
  loadingQuery?: string
  queryFacilitators: string[]
  totalCount?: number
  imageUploadProgress: IProgressMap
  updatingFacilitators: string[]
}

const defaultState: IFacilitatorsState = {
  facilitators: {},
  loading: [],
  loadingQuery: undefined,
  queryFacilitators: [],
  totalCount: undefined,
  imageUploadProgress: {},
  updatingFacilitators: [],
}

const reducer: Reducer<IFacilitatorsState, IFacilitatorAction> = {
  [ACTIONS.FETCH_FACILITATOR_START]: (state, {payload}) => ({
    ...state,
    loading: [
      ...state.loading,
      payload,
    ],
  }),
  [ACTIONS.FETCH_FACILITATOR_SUCCESS]: (state, {payload}) => ({
    ...state,
    loading: state.loading.filter(f => f !== payload.id),
    facilitators: {
      ...state.facilitators,
      [payload.id]: payload,
    },
  }),
  [ACTIONS.FETCH_FACILITATORS_QUERY_START]: (state, {payload}) => ({
    ...state,
    loadingQuery: payload,
  }),
  [ACTIONS.FETCH_FACILITATORS_QUERY_SUCCESS]: (state, {payload}) => {
    const newFacilitators = (payload as IFacilitatorResponse).data.reduce((newMap, facilitator) => ({
      ...newMap,
      [facilitator.id]: facilitator,
    }), {})
    return {
      ...state,
      loadingQuery: undefined,
      facilitators: {
        ...state.facilitators,
        ...newFacilitators,
      },
      totalCount: payload.count,
    }
  },
  [ACTIONS.SET_FACILITATOR_LOGO_SUCCESS]: (state, {payload}) => ({
    ...state,
    facilitators: {
      ...state.facilitators,
      [payload.id]: {
        // @ts-ignore
        ...state.facilitators[payload.id],
        logo: payload.url,
      },
    },
  }),
  [ACTIONS.UPDATE_FACILITATOR_START]: (state, {payload}) => ({
    ...state,
    updatingFacilitators: [
      ...state.updatingFacilitators,
      payload,
    ],
  }),
  [ACTIONS.UPDATE_FACILITATOR_SUCCESS]: (state, {payload}) => ({
    ...state,
    updatingFacilitators: state.updatingFacilitators.filter(id => id !== payload.id),
    facilitators: {
      ...state.facilitators,
      [payload.id]: payload,
    },
  }),
}

export default createReducer<IFacilitatorsState, IFacilitatorAction>(reducer, defaultState);
