import React, { useMemo } from 'react'
import styles from './MenuLinks.module.scss'
import { useSelector } from 'react-redux'

import { Translate } from 'react-localize-redux'
import { RootState } from 'reducers'
import { MenuLinksProps } from "./types";

const MenuLinks = ({ onLogout }: MenuLinksProps) => {
  const { user } = useSelector((state: RootState) => state)
  const isLoggedIn = user.role.rank >= 0
  const isUserAdmin = user.role.rank >= 1
  const myDashboardLink = useMemo(() =>
      isUserAdmin ? '/' : `${process.env.REACT_APP_DFF_URL}/programs/my-applications`,
    [isUserAdmin]);

  return (
    <>
      {isLoggedIn && !isUserAdmin && (
        <>
          <li className={styles.linkContainer}>
            <a
              className={styles.link}
              href={process.env.REACT_APP_FID_URL}
            >
              <Translate id='header.profile' />
            </a>
          </li>
          <li className={styles.linkContainer}>
            <a
              className={styles.link}
              href={myDashboardLink}
            >
              <Translate id='header.userDashboard'/>
            </a>
          </li>
        </>
      )}
      {!isUserAdmin &&
        (<li className={styles.linkContainer}>
          <a
            className={styles.link}
            href={`${process.env.REACT_APP_DFF_URL}/future-id`}
          >
            <Translate id='header.about'/>
          </a>
        </li>)}
      {!isLoggedIn ? (
        <li className={styles.linkContainer}>
          <a
            className={styles.link}
            href={`${process.env.REACT_APP_AUTH_URL}?app_id=programs`}
          >
            <Translate id='header.connectWithFID' />
          </a>
        </li>
      ) : (
        <li className={styles.linkContainer}>
          <button className={styles.link} onClick={onLogout}>
            <Translate id='header.logout' />
          </button>
        </li>
      )}
    </>
  )
}

export default MenuLinks
