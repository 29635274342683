import React from 'react'
import ReactDOM from 'react-dom'
import { Action, applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { LocalizeProvider } from 'react-localize-redux'
import thunk, { ThunkAction } from 'redux-thunk'
import App from './App'
import rootReducer, { RootState } from './reducers'
import './scss/main.scss'
import * as serviceWorker from './serviceWorker'
import HTML5Backend from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import history from 'components/common/history'
import './index.css'
import { detectColorScheme } from 'components/common/utils/detectColorScheme'
import { HeaderContextProvider } from 'components/common/AppHeader/HeaderContext'

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

const middleware = () => applyMiddleware(thunk, routerMiddleware(history))
const store = createStore(
  rootReducer(history),
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools({ trace: true })(middleware())
    : middleware(),
)

const theme = detectColorScheme()
if (theme === 'dark') {
  window.localStorage.setItem('theme', 'dark')
  document.documentElement.setAttribute('data-theme', 'dark')
} else {
  window.localStorage.setItem('theme', 'light')
  document.documentElement.setAttribute('data-theme', 'light')
}

if (process.env.NODE_ENV !== 'production') {
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000, {});
}

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <HeaderContextProvider>
          <App history={history} />
        </HeaderContextProvider>
      </LocalizeProvider>
    </Provider>
  </DndProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
