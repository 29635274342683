import { ACTIONS, GateAction } from '../actions/gateSettingsActions'
import { Reducer } from "./types";
import { convertCriteriaToTemplate } from "../GateSettings/utils";
import { GateState, IAutoReviewTemplate } from "../GateSettings/types";
import { createReducer } from "./createReducer";
import { ICriteria } from "../../../models/review/criteria";

const defaultState: GateState = {
  stageIndex: 0,
  sectionIndex: 0,
  unchangedStages: [],
  changedStages: [],
  availableReviewers: [],
  selectedChallenge: undefined,
  syncingStages: [],
  gettingAutoReviewCriteria: false,
  autoReviewCriteria: {},
  countryList: [],
  sectorList: [],
}

const reducer: Reducer<GateState, GateAction> = {
  [ACTIONS.SET_STAGE_INDEX]: (state, {payload: {stageIndex}}) => ({...state, stageIndex}),
  [ACTIONS.SET_SECTION_INDEX]: (state, {payload: {sectionIndex}}) => ({...state, sectionIndex}),
  [ACTIONS.SET_CHANGED_STAGES]: (state, {payload: {changedStages}}) => ({...state, changedStages}),
  [ACTIONS.SET_UNCHANGED_STAGES]: (state, {payload: {unchangedStages}}) => ({...state, unchangedStages}),
  [ACTIONS.SET_AVAILABLE_REVIEWERS]: (state, {payload: {availableReviewers}}) => ({...state, availableReviewers}),
  [ACTIONS.CHANGE_STAGE]: (state, {payload: {index, value, field}}) => ({
    ...state,
    changedStages: state.changedStages.map((stage, i) =>
      index === i ? {...stage, [field]: value} : stage,
    ),
  }),
  [ACTIONS.SELECT_CHALLENGE]: (state, {payload}) => ({...state, selectedChallenge: payload}),
  [ACTIONS.SYNC_STAGE_START]: (state, {payload}) => ({
    ...state,
    syncingStages: [
      ...state.syncingStages,
      payload,
    ],
  }),
  [ACTIONS.SYNC_STAGE]: (state, {payload}) => ({
    ...state,
    unchangedStages: state.unchangedStages.map(stage =>
      stage.id === payload.id ? payload : stage,
    ),
    syncingStages: state.syncingStages.filter(id => id !== payload.id),
  }),
  [ACTIONS.GET_AUTO_REVIEW_CRITERIA_START]: (state) => ({...state, gettingAutoReviewCriteria: true}),
  [ACTIONS.GET_AUTO_REVIEW_CRITERIA_END]: (state, {payload}) => {
    const templates = payload
      .map(convertCriteriaToTemplate)
      .reduce((allTemplates: { [key: string]: IAutoReviewTemplate }, template: ICriteria) => ({
          ...allTemplates,
          [template.id]: template,
        }), {} as { [key: string]: IAutoReviewTemplate },
      )
    return {
      ...state,
      gettingAutoReviewCriteria: false,
      autoReviewCriteria: templates,
    }
  },
  [ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_START]: (state, {payload}) => ({
    ...state,
    gettingSingleAutoReviewCriterial: payload,
  }),
  [ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_END]: (state, {payload}) => {
    const template = convertCriteriaToTemplate(payload)
    return {
      ...state,
      gettingSingleAutoReviewCriterial: '',
      autoReviewCriteria: {
        ...state.autoReviewCriteria,
        [template.id]: template,
      },
    }
  },
  [ACTIONS.DELETE_AUTO_REVIEW_CRITERIA]: (state, {payload}) => ({
    ...state,
    gettingAutoReviewCriteria: false,
    autoReviewCriteria: Object.values(state.autoReviewCriteria)
      .filter(({id}) => id !== payload)
      .reduce((allCriteria, criteria) => {
        return {
          ...allCriteria,
          [criteria.id]: criteria,
        }
      }, {}),
  }),
  [ACTIONS.GET_COUNTRY_LIST]: (state, {payload}) => ({...state, countryList: payload}),
  [ACTIONS.GET_SECTOR_LIST]: (state, {payload}) => ({...state, sectorList: payload}),

}

export default createReducer<GateState, GateAction>(reducer, defaultState);
