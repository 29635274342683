import { IFileLimits } from '../models/application/IFileLimits'
import { IApiKey } from '../models/application/IApiKey'
import {
  IAnswer,
} from '../models/application/IAnswer'
import { IQuestion } from '../models/application/IQuestion'
import { computeCompletionPercent } from '../utils/computeCompletionPercent'
import { api } from 'api'
import { AppThunk } from "./types";

export const ACTIONS = {
  SET_LAST_STAGE_TAB_INDEX: '@dff_application_action/set_last_stage',
  SET_FILE_LIMITS: '@dff_application_action/set_file_limits',
  SET_COMPLETION_PERCENT:
    '@dff_application_action/set_completion_percent',
  SET_API_KEYS: '@dff_application_action/set_api_keys',
  SET_API_KEYS_LOADING: '@dff_application_action/set_api_keys_loading',
  SET_ACTIVE_SECTION: '@dff_application_action/set_active_section',
  SELECT_ORGANIZATION: '@dff_application_action/select_organization',
  SELECT_CHALLENGE: '@dff_application_action/select_challenge',
  SET_GOLDEN_TICKET: '@dff_application_action/set_golden_ticket',
}


export interface SetFileLimitsAction {
  type: typeof ACTIONS.SET_FILE_LIMITS
  payload: IFileLimits
}

export interface SetLastStageTabIndexAction {
  type: typeof ACTIONS.SET_LAST_STAGE_TAB_INDEX
  payload: number
}

export interface SetApiKeysAction {
  type: typeof ACTIONS.SET_API_KEYS
  payload: IApiKey[]
}

export interface SetApiKeysLoadingAction {
  type: typeof ACTIONS.SET_API_KEYS_LOADING
}

export interface SetCompletionPercentAction {
  type: typeof ACTIONS.SET_COMPLETION_PERCENT
  payload: number
}

export interface SetLastStageTabIndex {
  type: typeof ACTIONS.SET_LAST_STAGE_TAB_INDEX
  payload: number
}

export interface SetActiveSectionAction {
  type: typeof ACTIONS.SET_ACTIVE_SECTION
  payload: number
}

export interface SelectOrganization {
  type: typeof ACTIONS.SELECT_ORGANIZATION
  payload: string | undefined
}

export interface SelectChallenge {
  type: typeof ACTIONS.SELECT_CHALLENGE
  payload: string | undefined
}

export interface SetGoldenTicket {
  type: typeof ACTIONS.SET_GOLDEN_TICKET
  payload: string | undefined
}

export type ApplicationAction = SetFileLimitsAction
  | SetApiKeysAction
  | SetApiKeysLoadingAction
  | SetCompletionPercentAction
  | SetLastStageTabIndex
  | SetActiveSectionAction
  | SelectOrganization
  | SelectChallenge
  | SetGoldenTicket

export const setLastStageTabIndex = (stage: number): SetLastStageTabIndexAction => {
  return {
    type: ACTIONS.SET_LAST_STAGE_TAB_INDEX,
    payload: stage,
  }
}

export const setFileLimits = (payload: IFileLimits): SetFileLimitsAction => {
  return {
    type: ACTIONS.SET_FILE_LIMITS,
    payload,
  }
}

export const getApiKeys = (): AppThunk => async (dispatch, getState) => {
  if (getState().application.apiKeys.length === 0 && getState().application.apiKeysLoading === false) {
    dispatch({
      type: ACTIONS.SET_API_KEYS_LOADING,
    })
    const { data } = await api.get(`ica/apiKey`)
    dispatch({
      type: ACTIONS.SET_API_KEYS,
      payload: data,
    })
  }
}

export const setCompletionPercent = (
  payload: number,
): SetCompletionPercentAction => {
  return {
    type: ACTIONS.SET_COMPLETION_PERCENT,
    payload,
  }
}

export const setActiveSection = (
  sectionIndex: number,
): SetActiveSectionAction => {
  return {
    type: ACTIONS.SET_ACTIVE_SECTION,
    payload: sectionIndex,
  }
}

export const selectOrganization = (
  organizationId?: string
): SelectOrganization => {
  return {
    type: ACTIONS.SELECT_ORGANIZATION,
    payload: organizationId,
  }
}

export const selectChallenge = (
  challengeId?: string
): SelectChallenge => {
  return {
    type: ACTIONS.SELECT_CHALLENGE,
    payload: challengeId,
  }
}

export const setGoldenTicket = (
  goldenTicket?: string
): SetGoldenTicket => {
  return {
    type: ACTIONS.SET_GOLDEN_TICKET,
    payload: goldenTicket,
  }
}

/*
* Sets completion percent.
* Only update the value if there is an actual change
* */
export const setCompletionPercentDistinctUntilChanged = (
  params: {questions: IQuestion[], answers: IAnswer[], debounced?: number}
): AppThunk => (dispatch, getState) => {
  const { questions, answers } = params
  const percent = computeCompletionPercent(questions, answers)
  if (percent !== getState().application.completionPercent) {
    dispatch(setCompletionPercent(percent))
  }
}
