import React, { useState } from 'react'
import { withRouter, RouteComponentProps, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Column } from '../Layout'
import Form from '../Form/Form'
import FormSection from '../Form/FormSection'
import TextInput from '../FormInput/TextInput'
import SectionList from '../SectionList/SectionList'
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux'
import { RootState } from '../../../reducers'
import { selectChallenge } from '../actions/applicationActions'
import {
  createEmailApplication,
  verifyApplicationEmail,
} from './ApplicationForm/utils/api'
import styles from './ValidateEmailApplication.module.scss'
import Button from '../shared/Button'
import { handleError } from '../actions/errorHandlerActions'
// @ts-ignore
import ReactCodeInput from 'react-code-input'
import ActionBar from 'components/common/ActionBar/ActionBar'

const usesGoldenTicket = process.env.REACT_APP_USES_GOLDEN_TICKET === 'true'

interface IValidateEmailApplicationProps
  extends LocalizeContextProps,
    RouteComponentProps {}

const ValidateEmailApplication = ({
  translate,
  history,
}: IValidateEmailApplicationProps) => {
  const [email, setEmail] = useState('')
  const [goldenTicket, setGoldenTicket] = useState('')
  const [applicationId, setApplicationId] = useState('')
  const [verificationCode, setVerificationCode] = useState('')

  const { selectedChallenge } = useSelector(
    (state: RootState) => state.application,
  )

  const dispatch = useDispatch()

  const { id: challengeId } = useParams()

  if (challengeId && challengeId !== selectedChallenge) {
    dispatch(selectChallenge(challengeId))
  }

  const sections = [
    {
      id: 'validateEmail',
      title: translate('visaApplication.validateEmail') as string,
    },
    {
      id: 'application',
      title: translate('visaApplication.applicationForm') as string,
    },
  ]

  const handleSectionSelected = (index: number) => {
    if (index === 1 && email) {
      createApplication()
    }
  }

  const createApplication = async () => {
    try {
      const { data } = await createEmailApplication({
        selectedChallenge,
        email,
        goldenTicket,
      })
      setApplicationId(data.applicationId)
    } catch (err) {
      dispatch(handleError(err))
    }
  }

  const startApplication = async () => {
    try {
      const { data } = await verifyApplicationEmail({
        email,
        verificationCode,
        applicationId,
      })
      history.push(
        `/programs/${selectedChallenge}/application/?token=${data.token}`,
      )
    } catch (e) {
      dispatch(handleError(e))
    }
  }

  return (
    <>
      <Row className={styles.container}>
        <Column nav>
          <SectionList
            sections={sections}
            onSelected={handleSectionSelected}
            selectedSection={0}
            displayStatus={false}
          />
        </Column>
        <Column>
          <Form>
            <FormSection>
              {applicationId ? (
                <>
                  <h4>
                    <Translate id='visaApplication.validationCode' />
                  </h4>
                  <ReactCodeInput
                    type='number'
                    fields={4}
                    value={verificationCode}
                    onChange={setVerificationCode}
                    inputStyle={{}}
                    className={styles.codeInput}
                    autoFocus
                  />
                  <p>
                    <Translate id='visaApplication.resendEmail' />
                    &nbsp;
                    <span
                      onClick={createApplication}
                      className={styles.resendLink}
                    >
                      <Translate id='utility.here' />
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <TextInput
                    id='email'
                    type='text'
                    label={translate('utility.email') as string}
                    value={email}
                    onChange={setEmail}
                    required
                  />
                  {usesGoldenTicket && (
                    <TextInput
                      id='golden-ticket'
                      type='text'
                      value={goldenTicket || ''}
                      label={
                        translate(
                          'visaApplication.goldenTicketQuestionTitle',
                        ) as string
                      }
                      description={
                        translate(
                          'visaApplication.goldenTicketQuestionDescription',
                        ) as string
                      }
                      onChange={setGoldenTicket}
                    />
                  )}
                </>
              )}
            </FormSection>
          </Form>
        </Column>
        <ActionBar flexEnd>
          <div className={styles.footerButtonWrapper}>
            <div>
              {!applicationId ? (
                <Button
                  type='solid'
                  disabled={!email}
                  className={styles.btn}
                  onClick={createApplication}
                >
                  <Translate id='visaApplication.sendValidationEmail' />
                </Button>
              ) : (
                <Button
                  type='solid'
                  disabled={
                    !verificationCode || verificationCode.length !== 4
                  }
                  onClick={startApplication}
                  className={styles.btn}
                >
                  <Translate id='visaApplication.startApplication' />
                </Button>
              )}
            </div>
          </div>
        </ActionBar>
      </Row>
    </>
  )
}

export default withRouter(withLocalize(ValidateEmailApplication))
