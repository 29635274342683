import React from 'react'
import styles from './Hamburger.module.scss'
import classNames from 'classnames'
import { HamburgerProps } from "./types";

const Hamburger = ({ isOpen }: HamburgerProps) => {
  return (
    <button
      className={styles.container}
    >
      <span
        className={classNames(styles.icon, {
          [styles.open]: isOpen,
        })}
      />
    </button>
  )
}

export default Hamburger
