import React from 'react'
import styles from './ApplicationSummary.module.scss'

import facebook from 'assets/images/logos/facebook_black.svg'
import linkedin from 'assets/images/logos/linkedin_black.svg'
import crunchbase from 'assets/images/logos/crunchbase.png'
import twitter from 'assets/images/logos/twitter.svg'
import instagram from 'assets/images/logos/instagram.svg'
import ApplicationSummarySection, { ApplicationSummarySubSection } from './ApplicationSummarySection'
import moment from 'moment'
import StatusBar from './StatusBar'
import { fetchFullStartupAndShowProfile } from 'actions/startupActions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'reducers'
import useTranslate from 'hooks/useTranslate'
import { ApplicationSummaryProps } from "./types";

const ApplicationSummary = ({ application, status }: ApplicationSummaryProps) => {
  const dispatch = useDispatch()
  const { selectedStartup: fullStartupProfile } = useSelector(
    (state: RootState) => state.startup,
  )

  // function for mapping social media keys to actual icon
  const smToIconMap = (sm: string) => {
    switch (sm) {
      case 'facebook':
        return facebook
      case 'linkedIn':
        return linkedin
      case 'crunchbase':
        return crunchbase
      case 'twitter':
        return twitter
      case 'instagram':
        return instagram
    }
  }

  const applicantName = application.metaData.applicantName || application.email
  const organizationName = application.metaData.startupName
  const participants = application.participants
  const translate = useTranslate()
  React.useEffect(() => {
    if (application.startup?.id)
      dispatch(fetchFullStartupAndShowProfile(application.startup.id, false))
  }, [dispatch, application])

  const convertToExternalLink = (link: string) => {
    if (link.startsWith('http')) return link
    return `http://${link}`
  }
  return (
    <div className={styles.columnR}>
      <ApplicationSummarySection title='Application Details'>
        <>
          <ApplicationSummarySubSection title='Status'>
            <>
              <StatusBar status={status} />
              {`Review: ${application.metaData.currentStage.name}`}
            </>
          </ApplicationSummarySubSection>
          <div className={styles.dateAndGoldenTicketContainer}>
            <ApplicationSummarySubSection title='Submission Date'>
              {application.submittedAt
                ? moment(application.submittedAt).format('DD/MM/YYYY')
                : (translate('review.unknown') as string)}
            </ApplicationSummarySubSection>
            <ApplicationSummarySubSection title='Golden Ticket'>
              {application.goldenTicket || '-'}
            </ApplicationSummarySubSection>
          </div>
        </>
      </ApplicationSummarySection>
      <ApplicationSummarySection title='Applicant Details'>
        <>
          {/* Orgnaization section, if it exists */}
          {organizationName && (
            <ApplicationSummarySubSection title='Organization'>
              <>
                <div>{organizationName}</div>
                <div>
                  {application.startup.website && (
                    <a
                      href={convertToExternalLink(application.startup.website)}
                    >
                      Company website
                    </a>
                  )}
                </div>
                <div className={styles.socialMediaContainer}>
                  {Object.entries(fullStartupProfile?.socialMedia || {}).map(
                    ([media, link]) => (
                      <a key={media} href={convertToExternalLink(link)}>
                        <img
                          className={styles.socialMedia}
                          src={smToIconMap(media)}
                          alt={media}
                        />
                      </a>
                    ),
                  )}
                </div>
              </>
            </ApplicationSummarySubSection>
          )}
          <div className={styles.column}>
            <ApplicationSummarySubSection title='Applicant Name'>
              <div>{applicantName}</div>
            </ApplicationSummarySubSection>
            {participants?.length ? (
              <ApplicationSummarySubSection title='Participants'>
                <>
                  {participants.map((participant) => (
                    <div key={participant.id}>
                      {`${participant.firstName} ${participant.lastName}`}
                    </div>
                  ))}
                </>
              </ApplicationSummarySubSection>
            ) : null}
          </div>
        </>
      </ApplicationSummarySection>
    </div>
  )
}

export default ApplicationSummary
