import React, { useState } from 'react'
import Button from '../shared/Button'
import { isEqual } from 'lodash'
import { Translate, withLocalize } from 'react-localize-redux'
import { IStage } from '../models/application/IStage'
import { useSelector, useDispatch } from 'react-redux'
import { addErrorToast, addSuccessToast } from '../actions/toastActions'
import { IParticipant } from '../models/application/IParticipant'
import { ICommonState } from '../utils/state'
import { ACTIONS, syncStage } from '../actions/gateSettingsActions'
import styles from './GateSettings.module.scss'
import ActionBar from 'components/common/ActionBar/ActionBar'
import { htmlEmpty } from 'components/common/utils/htmlUtils'
import { IFooterProps } from "./types";
import { compareStages } from "./utils";

const GateFooter = ({ challengeId, translate, api, stageId }: IFooterProps) => {
  const [saving, setSaving] = useState(false)
  const dispatch = useDispatch()

  const { changedStages, unchangedStages, syncingStages } = useSelector(
    (state: ICommonState) => state.gate,
  )
  const { token } = useSelector((state: ICommonState) => state.auth)

  const removeReviewerFromStage = (stage: IStage) => async(
    reviewer: IParticipant,
  ) => {
    try {
      await api.delete(
        `/v2/challenges/${challengeId}/review/stages/${stage.id}/programManagers/${reviewer.id}`,
        {
          headers: {
            Authorization: token,
          },
        },
      )
    } catch (error) {
      dispatch(
        addErrorToast(translate('stages.error.removingReviewer', {
          stage: stage.name,
          email: reviewer.email,
        }) as string),
      )
      throw error
    }
  }

  const addReviewerToStage = (stage: IStage) => async(
    reviewer: IParticipant,
  ) => {
    try {
      await api.post(
        `/v2/challenges/${challengeId}/review/stages/${stage.id}/programManagers`,
        {
          userId: reviewer.id,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      )
    } catch (error) {
      dispatch(
        addErrorToast(translate('stages.error.addingReviewer', {
          stage: stage.name,
          email: reviewer.email,
        }) as string),
      )
      throw error
    }
  }
  const getAssignmentDifference = (old: IStage, later: IStage) => {
    const inANotB = (a: IStage, b: IStage) => {
      return a.programManagers.filter(aReviewer => {
        return !b.programManagers
          .map(bReviewer => bReviewer.email)
          .includes(aReviewer.email)
      })
    }
    return {
      deletedReviewers: inANotB(old, later),
      newReviewers: inANotB(later, old),
    }
  }

  const syncReviewers = async(originalStage: IStage, changedStage: IStage) => {
    const { newReviewers, deletedReviewers } = getAssignmentDifference(
      originalStage!,
      changedStage,
    )
    const postNewReviewers = newReviewers.map(addReviewerToStage(changedStage))
    const deleteReviewers = deletedReviewers.map(
      removeReviewerFromStage(changedStage),
    )

    await Promise.all([...deleteReviewers, ...postNewReviewers])
  }

  const saveStage = async(stage: IStage) => {
    const originalStage = unchangedStages.find(
      oldStage => oldStage.id === stage.id,
    )

    const invalidReviewerCount = stage.programManagers.length < stage.maxReviewers
    const countChanged = originalStage && stage.programManagers.length !== originalStage.programManagers.length
    const assignmentCountChanged = originalStage && stage.maxReviewers !== originalStage.maxReviewers

    const invalidEmailFields = (stage.sendEmail.approved && !stage.emailSubject)
      || (stage.sendEmail.approved && htmlEmpty(stage.emailContent))
      || (stage.sendEmail.rejected && !stage.RejectionEmailSubject)
      || (stage.sendEmail.rejected && htmlEmpty(stage.RejectionEmailContent))

    if (invalidReviewerCount && (countChanged || assignmentCountChanged)) {
      throw new Error(translate('stages.error.invalidReviewerCount') as string)
    } else if (invalidEmailFields) {
      throw new Error(translate('stages.error.invalidEmailSettings') as string)
    } else {
      await syncReviewers(originalStage!, stage, )
      const isScoringToolSame = !!originalStage && isEqual(stage.scoringTool, originalStage.scoringTool)
      const diffOfStage = originalStage && compareStages(originalStage, stage)
      diffOfStage && dispatch(syncStage(challengeId, stage.id, diffOfStage, isScoringToolSame))
    }
  }

  const getStagesToSave = () => {
    if (stageId) {
      return changedStages.filter(stage => stage.id === stageId)
    }
    return changedStages
  }

  const handleSave = async() => {
    const stageUpdates = getStagesToSave().map(saveStage)

    setSaving(true)
    try {
      await Promise.all(stageUpdates)
      dispatch(addSuccessToast(translate('stages.saveSuccess') as string))
    } catch (error) {
      dispatch(addErrorToast(error))
    }
    setSaving(false)
  }

  const handleDiscard = () => {
    dispatch({
      type: ACTIONS.SET_STAGE_INDEX,
      payload: {
        stageIndex: 0,
      },
    })
    dispatch({
      type: ACTIONS.SET_CHANGED_STAGES,
      payload: {
        changedStages: unchangedStages,
      },
    })
  }

  return (
    <ActionBar>
      <div className={styles.btnContainer}>
        <Button type='default' onClick={handleDiscard}>
          <Translate id='utility.discard' />
        </Button>
        <Button type='primary' onClick={handleSave} disabled={saving || syncingStages.length > 0}>
          <Translate id='utility.save' />
        </Button>
      </div>
    </ActionBar>
  )
}

export default withLocalize(GateFooter)
