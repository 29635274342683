import React from 'react'
import styles from './MetricDisplay.module.scss'
import { useHistory } from 'react-router'
import { IMetricDisplayProps } from "./types";

const MetricDisplay = ({
  label,
  value,
  imageSource,
  to,
}: IMetricDisplayProps) => {
  const history = useHistory()
  return (
    <div
      className={styles.container}
      onClick={to ? () => history.push(to) : undefined}
    >
      <img className={styles.image} src={imageSource} alt='' />
      <div className={styles.dataContainer}>
        <div className={styles.number}>{value}</div>
        <div className={styles.label}>{label}</div>
      </div>
    </div>
  )
}

export default MetricDisplay
