import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './GateSettings.module.scss'
import AppTabBar from '../shared/TabBars/AppTabBar'
import SectionList from '../SectionList/SectionList'
import { ISection } from '../models/application/ISection'
import { IStage } from '../models/application/IStage'
import { withLocalize } from 'react-localize-redux'
import GateSections from './GateSections'
import Loader from '../Loader/Loader'
import { addErrorToast } from '../actions/toastActions'
import { ICommonState } from '../utils/state'
import {
  ACTIONS,
  getAndSelectChallenge,
} from '../actions/gateSettingsActions'

import Footer from './Footer'
import { GateSettingsProps } from "./types";
import { cleanStageForSave } from "./utils";

const GateSettings = ({
  api,
  challengeId,
  programOwnerId,
  stageId,
  setActiveLanguage,
  translate,
  goBack,
}: GateSettingsProps) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const {
    changedStages,
    unchangedStages,
    sectionIndex,
    stageIndex,
  } = useSelector((state: ICommonState) => state.gate)
  const { token } = useSelector((state: ICommonState) => state.auth)

  useEffect(() => {
    const getStages = async () => {
      setActiveLanguage('en')
      try {
        setLoading(true)
        const { data } = await api.get(
          `/v2/challenges/${challengeId}/review/stages`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        dispatch({
          type: ACTIONS.SET_UNCHANGED_STAGES,
          payload: { unchangedStages: data },
        })

        // change stage index of stage Id is present
        if (stageId) {
          const stageIndex = data.findIndex(
            (stage: IStage) => stage.id === stageId,
          )
          dispatch({
            type: ACTIONS.SET_STAGE_INDEX,
            payload: {
              stageIndex: stageIndex,
            },
          })
        }

        dispatch({ type: ACTIONS.SET_CHANGED_STAGES, payload: { changedStages: data } })

        const { data: availableReviewers } = await api.get(`/programManagers`, {
          headers: {
            Authorization: token,
          },
        })
        dispatch({
          type: ACTIONS.SET_AVAILABLE_REVIEWERS,
          payload: { availableReviewers },
        })

        setLoading(false)
      } catch (error) {
        setLoading(false)
        dispatch(addErrorToast(translate('stages.error.retrieve') as string))
        console.error(error)
      }

      dispatch(getAndSelectChallenge(challengeId))
    }

    getStages()
  }, [stageId, token, translate, setActiveLanguage, api, challengeId, dispatch])

  const sections: ISection[] = [
    {
      id: 'general',
      title: translate('gate.general') as string,
    },
    {
      id: 'assignment',
      title: translate('gate.assignment') as string,
    },
    {
      id: 'email',
      title: translate('gate.email') as string,
    },
    {
      id: 'scoring',
      title: translate('gate.scoring') as string,
    },
  ]

  if (loading || changedStages.length === 0) {
    return <Loader />
  }

  const handleAddStage = async () => {
    const { data } = await api.post(
      `/v2/challenges/${challengeId}/review/stages`,
      {},
      {
        headers: {
          Authorization: token,
        },
      },
    )
    const newStage: IStage = data.reviewStages.filter((stage: IStage) => {
      return !changedStages.some((localStage) => localStage.id === stage.id)
    })[0]

    // Weird indexing to reflect backend behaviour
    dispatch({
      type: ACTIONS.SET_UNCHANGED_STAGES,
      payload: {
        unchangedStages: [
          ...unchangedStages.slice(0, -1),
          newStage,
          unchangedStages.slice(-1)[0],
        ],
      },
    })
    dispatch({
      type: ACTIONS.SET_CHANGED_STAGES,
      payload: {
        changedStages: [
          ...changedStages.slice(0, -1),
          newStage,
          changedStages.slice(-1)[0],
        ],
      },
    })
  }

  const cleanFastTracksTo = (targetId: string) => {
    changedStages.forEach(async(stage) => {
      const strippedStage = cleanStageForSave(stage)
      if (stage.fastTrack === targetId) {
        await api.patch(
          `/v2/challenges/${challengeId}/review/stages/${stage.id}`,
          {
            ...strippedStage,
            fastTrack: '',
          },
          {
            headers: {
              Authorization: token,
            },
          },
        )
      }
    })
  }

  const getDeleteFuncForStage = (deletedIndex: number) => {
    if (
      unchangedStages[deletedIndex] &&
      unchangedStages[deletedIndex].numberOfApplications === 0
    ) {
      const deletedId = changedStages[deletedIndex].id
      return async() => {
        cleanFastTracksTo(deletedId)
        const { data } = await api.delete(
          `/v2/challenges/${challengeId}/review/stages/${deletedId}`,
          {
            headers: {
              Authorization: token,
            },
          },
        )
        const remainingStagesIds: string[] = data.reviewStages.map(
          (stage: IStage) => {
            return stage.id
          },
        )
        if (deletedIndex <= stageIndex) {
          dispatch({
            type: ACTIONS.SET_STAGE_INDEX,
            payload: {
              stageIndex: stageIndex - 1,
            },
          })
        }

        dispatch({
          type: ACTIONS.SET_UNCHANGED_STAGES,
          payload: {
            unchangedStages: unchangedStages.filter((stage) =>
              remainingStagesIds.includes(stage.id),
            ),
          },
        })
        dispatch({
          type: ACTIONS.SET_CHANGED_STAGES,
          payload: {
            changedStages: changedStages.filter((stage) =>
              remainingStagesIds.includes(stage.id),
            ),
          },
        })
      }
    } else {
      return undefined
    }
  }

  const setSectionIndex = (sectionIndex: number) => {
    dispatch({ type: ACTIONS.SET_SECTION_INDEX, payload: { sectionIndex } })
  }

  const setStageIndex = (stageIndex: number) => {
    dispatch({ type: ACTIONS.SET_STAGE_INDEX, payload: { stageIndex } })
  }

  return (
    <div className={styles.rootContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.innerContentContainer}>
          {stageId && (
            <>
              {goBack ? (
                <button className={styles.backButton} onClick={goBack as any}>
                  {`< ${translate('stages.backToGateSettings')}`}
                </button>
              ) : (
                <a
                  className={styles.backButton}
                  href={`/programs/${challengeId}/settings`}
                >
                  {`< ${translate('stages.backToGateSettings')}`}
                </a>
              )}
            </>
          )}
          {!stageId && (
            <AppTabBar
              activeIndex={stageIndex}
              setIndex={setStageIndex}
              addTab
              addTabLabel={translate('gate.addTab') as string}
              onAddClick={handleAddStage}
              tabs={changedStages.map((stage) => ({
                label: stage.name,
              }))}
              getTabDelete={getDeleteFuncForStage}
              showActiveTabIndicator
            />
          )}
          <div className={styles.formContainer}>
            <div className={styles.column}>
              <SectionList
                onSelected={setSectionIndex}
                selectedSection={sectionIndex}
                sections={sections}
                displayStatus={false}
              />
            </div>
            <div className={styles.columnR}>
              <GateSections
                sections={sections}
                hideGoldenTicket={stageIndex === 0}
                programOwnerId={programOwnerId}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer api={api} challengeId={challengeId} stageId={stageId} />
    </div>
  )
}

export default withLocalize(GateSettings)
