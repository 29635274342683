import React from 'react'
import { Translate } from 'react-localize-redux'
import { CellProps } from 'react-table'
import { IGateRow } from "./types";

const renderGoldenTicket = ({ row, value }: CellProps<IGateRow>) => {
  if (!value || row.original.gateNumber === 1) {
    return '-'
  }
  return value
}

export const GateListingColumns = [
  {
    Header: ' ',
    columns: [
      {
        Header: <Translate id='gate.heading' />,
        accessor: 'gateNumber',
        id: 'gateListingGateNumber',
      },
      {
        Header: <Translate id='gate.name' />,
        accessor: 'gateName',
        id: 'gateListingGateName',
      },
      {
        Header: <Translate id='gate.applications' />,
        accessor: 'applications',
        id: 'gateListingApplications',
      },
      {
        Header: <Translate id='gate.assigments' />,
        accessor: 'reviewers',
        id: 'gateListingReviewers',
      },
      {
        Header: <Translate id='gate.goldenTicket' />,
        accessor: 'goldenTicket',
        id: 'gateListingGoldenTickets',
        Cell: renderGoldenTicket,
      },
    ],
  },
]
