import React from 'react'
import classNames from 'classnames'
import styles from './MultiCheckbox.module.scss'
import { CheckboxProps } from "./types";

const Checkbox = ({
  readOnly,
  value,
  id,
  label,
  onChange,
  className,
  dark,
  disabled,
}: CheckboxProps) => {

  const handleUpdate = ({ target }: React.ChangeEvent<HTMLInputElement>) => onChange(target.checked)

  return (
    <div className={classNames(styles.wrapper, className, {
      [styles.disabled]: disabled,
    })}>
      <input
        onChange={handleUpdate}
        id={id}
        checked={value}
        disabled={disabled || readOnly}
        className={styles.input}
        type='checkbox'
      />
      <label
        className={classNames(styles.label, {
          [styles.dark]: dark,
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

export default Checkbox
