import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import {
  IQuestion,
  getQuestionJumpTarget,
  couldBeSkipped,
} from '../models/application/IQuestion'
import Loader from 'components/common/Loader/Loader'

import { IAnswer, isAnswered } from '../models/application/IAnswer'
import { UserState } from '../utils/state'
import { api } from '../../../api'

import FormEditor from './FormEditor/FormEditor'
import ApplicationForm from './ApplicationForm/ApplicationForm'
import { RootState } from '../../../reducers'
import { fetchChallenge } from 'components/common/actions/challengesActions'
import { authorizeEmail } from 'components/common/actions/authActions'
import queryString from 'query-string'
import { getBasePath } from './ApplicationForm/utils/api'
import { ApplicationMode } from 'components/common/models/application/IChallenge'
import { applicationStateContext } from './ApplicationForm/ApplicationFormState'

const FileDownload = require('js-file-download')

interface StoreProps {
  user?: UserState
}

const singleChallenge = !!process.env.REACT_APP_CHALLENGE_ID
const fakeStartup = process.env.REACT_APP_FAKE_STARTUP === 'true'
const usesGoldenTicket = process.env.REACT_APP_USES_GOLDEN_TICKET === 'true'

export interface ApplicationPanelProps extends StoreProps {
  adminMode?: boolean
}

export const getListOfQuestionsDisabled = (
  questions: IQuestion[],
  answers: IAnswer[],
): number[] => {
  const questionIndicies = questions.map((_, index) => index)
  return questionIndicies.reduce(
    (disabledQuestions, questionIndex, thisQuestionIndex) => {
      const priorQuestions = questions.slice(0, thisQuestionIndex)
      const priorQuestionAnswers = priorQuestions.map((question, index) => ({
        question,
        answer: answers[index],
        originalIndex: index,
      }))
      const activePriorQuestionAnswers = priorQuestionAnswers.filter(
        (_, questionIndex) => !disabledQuestions.includes(questionIndex),
      )
      if (activePriorQuestionAnswers.length > 0) {
        const {
          question: lastActiveQuestion,
          answer: lastActiveAnswer,
          originalIndex: lastActiveIndex,
        } = activePriorQuestionAnswers[activePriorQuestionAnswers.length - 1]
        const lastActiveJump = getQuestionJumpTarget(
          lastActiveQuestion,
          lastActiveAnswer,
          lastActiveIndex,
        )
        const priorSkippedQuestions = priorQuestions.filter(
          (_, questionIndex) =>
            disabledQuestions.includes(questionIndex) &&
            questionIndex > lastActiveIndex,
        )

        if (
          (lastActiveJump && lastActiveJump > thisQuestionIndex) ||
          (lastActiveJump === thisQuestionIndex &&
            couldBeSkipped(
              lastActiveAnswer,
              priorSkippedQuestions,
              thisQuestionIndex,
            ))
        ) {
          return [...disabledQuestions, thisQuestionIndex]
        }
      }
      return disabledQuestions
    },
    [] as number[],
  )
}

export const isSectionCompleted = (
  answers: IAnswer[],
  questions: IQuestion[],
) => (section: number): boolean => {
  const disabledQuestionsList = getListOfQuestionsDisabled(questions, answers)
  return answers
    .filter(
      (answer, index) =>
        answer.section === section &&
        !disabledQuestionsList.includes(index) &&
        questions[index].mandatory,
    )
    .every(isAnswered)
}

interface IDownloadFileProps {
  applicationId: string
  answerIndex: number
  applicationMode?: ApplicationMode
  onFinish?: () => void
}

export const downloadFileFromBackend = async ({
  applicationId,
  answerIndex,
  applicationMode,
  onFinish,
}: IDownloadFileProps) => {
  try {
    const basePath = getBasePath(applicationMode)
    const { data, request } = await api.get(
      `${basePath}${applicationId}/answers/${answerIndex}/files`,
      {
        responseType: 'blob',
      },
    )
    FileDownload(
      data,
      request.getResponseHeader('Content-Disposition').split('filename=')[1],
    )
    if (onFinish) onFinish()
  } catch (error) {
    console.error(error)
  }
}

const ApplicationPanel = ({ adminMode }: ApplicationPanelProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const paramToken = queryString.parse(location.search).token as string
  const {
    applicationId,
    loading: applicationLoading,
    readOnly,
    initialize,
  } = useContext(applicationStateContext)

  const { selectedOrganization, selectedChallenge, goldenTicket } = useSelector(
    (state: RootState) => state.application,
  )
  const { token: stateToken } = useSelector((state: RootState) => state.auth)

  useEffect(() => {
    if (!adminMode) {
      initialize && initialize()
    }
    // eslint-disable-next-line
  }, [adminMode])

  useEffect(() => {
    if (selectedChallenge) {
      dispatch(fetchChallenge(selectedChallenge))
    }
  }, [dispatch, selectedChallenge])

  useEffect(() => {
    if (paramToken) {
      dispatch(authorizeEmail(paramToken))
    }
  }, [paramToken, dispatch])

  const { challenges, loading } = useSelector(
    (state: RootState) => state.challenges,
  )

  const challenge = selectedChallenge ? challenges[selectedChallenge] : null

  const hasChallenge = !loading && challenge != null
  const hasApplication = !applicationLoading && applicationId != null
  const orgChallenge =
    hasChallenge && challenge!.applicationMode === 'FID_ORGANISATION'
  const emailChallenge = hasChallenge && challenge!.applicationMode === 'E-MAIL'
  const individualChallenge =
    hasChallenge && challenge!.applicationMode === 'FID_INDIVIDUAL'

  const checkInformation = () => {
    if (hasChallenge && orgChallenge && !selectedOrganization) {
      return true
    }
    if (!hasChallenge || !hasApplication) {
      return undefined
    }
    if (readOnly) {
      return false
    }
    if (emailChallenge) {
      return !paramToken && !stateToken;

    }
    if (usesGoldenTicket && goldenTicket === undefined) {
      return true
    }
    return orgChallenge && !fakeStartup && !selectedOrganization;

  }

  const requiresInformation = checkInformation()

  const redirectToMetaCollect = () => {
    if (orgChallenge) {
      if (singleChallenge) {
        return <Redirect to='/selectOrganisation' />
      }
      return (
        <Redirect to={`/programs/${selectedChallenge}/selectOrganisation`} />
      )
    }
    if (emailChallenge) {
      if (singleChallenge) {
        return <Redirect to='/application/validateEmail' />
      }
      return (
        <Redirect
          to={`/programs/${selectedChallenge}/application/validateEmail`}
        />
      )
    }

    // FID individual challenge
    if (individualChallenge) {
      if (singleChallenge) {
        return <Redirect to='/programs/application/individual' />
      }

      return (
        <Redirect
          to={`/programs/${selectedChallenge}/application/individual`}
        />
      )
    }
    return <Redirect to='/' />
  }

  if (adminMode) {
    return <FormEditor />
  }
  if (requiresInformation === undefined) {
    return <Loader />
  }
  if (requiresInformation === true) {
    return redirectToMetaCollect()
  }

  return <ApplicationForm />
}

export default ApplicationPanel
