import React from 'react'
import { createProgramFromModal } from '../../actions/programOwnerActions'
import { connect } from 'react-redux'
import warningIcon from '../../assets/icons/warning.svg'
import styles from './CreateProgramOwnerProfileModal.module.scss'
import Modal from '../Modal/Modal'
import { Translate, getTranslate } from 'react-localize-redux'
import Button from 'components/common/shared/Button'

class CreateProgramOwnerModal extends React.Component<any> {
  state: any = {
    name: '',
    error: '',
  }

  inputRef: any

  constructor(props: any) {
    super(props)
    this.inputRef = React.createRef()
  }

  onChange = (e: any) => {
    if (!e.currentTarget.value.trim() && !this.state.name.length) {
      return
    }
    this.setState({
      name: e.currentTarget.value,
      error: '',
      errorId: '',
    })
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    if (!this.state.name.trim() || !(this.state.name.length > 1)) {
      return this.setState({
        errorId: 'admin.nameMin2Chars',
      })
    }
    this.props.createProgramFromModal(
      this.state.name.trim(),
      this.props.close,
      (error: string) => this.setState({ error }),
    )
  }

  componentDidMount() {
    if (this.inputRef.current) this.inputRef.current.focus()
  }

  render() {
    return (
      <Modal close={this.props.close}>
        <div className={styles.container}>
          <h4 className={styles.title}>
            <Translate id='admin.createNewPO' />
          </h4>
          <form onSubmit={this.handleSubmit}>
            <div className='inputGroup'>
              <input
                type='text'
                className={styles.input}
                placeholder={this.props.translate('admin.nameOfYourProfile')}
                onChange={this.onChange}
                value={this.state.name}
                ref={this.inputRef}
              />
              {(this.state.errorId || this.state.error) && (
                <div className='inputErrorLabel'>
                  <img src={warningIcon} alt='Warning Icon' />
                  <span>
                    {this.state.errorId !== ''
                      ? this.props.translate(this.state.errorId)
                      : this.state.error}
                  </span>
                </div>
              )}
            </div>
            <Button
              type='solid'
              onClick={this.handleSubmit}
              className={`button ${styles.button}`}
            >
              <Translate id='create' />
            </Button>
          </form>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  translate: getTranslate(state.localize),
})

export default connect(mapStateToProps, { createProgramFromModal })(
  CreateProgramOwnerModal,
)
