import React from 'react'
import classes from './Comparison.module.scss'
import TextInput from './TextInput'
import MultiCheckbox from './MultiCheckbox'
import WrappedInput from './WrappedInput'
import { ComparisonProps } from "./types";
import { keyMap } from "./constants";

const Comparison = (props: ComparisonProps) => {
  const { id, value, onChange } = props
  const handleChangeComparitor = (newValue: number[]) => {
    onChange({
      ...value,
      // @ts-ignore
      comparitor: keyMap[newValue[0]],
    })
  }

  const handleChangeValue = (newValue: string) => {
    onChange({
      ...value,
      value: newValue,
    })
  }

  return (
    <WrappedInput {...props}>
      <div className={classes.container}>
        <MultiCheckbox
          id={`${id}-comparitor`}
          // @ts-ignore
          value={[keyMap.indexOf(value.comparitor)]}
          onChange={handleChangeComparitor}
          options={[
            {
              option: '<',
              optionId: '<',
            },
            {
              option: '>',
              optionId: '>',
            },
            {
              option: '=',
              optionId: '===',
            },
          ]}
          type='radio'
          inline
        />
        <TextInput
          id={`${id}-value`}
          value={value.value}
          onChange={handleChangeValue}
          type='number'
        />
      </div>
    </WrappedInput>
  )
}

export default Comparison
