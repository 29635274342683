import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './EditStageGeneral.module.scss'
import Form from '../../Form/Form'
import FormSection from '../../Form/FormSection'
import TextInput from '../../FormInput/TextInput'
import Dropdown from '../../FormInput/Dropdown'
import { Translate, withLocalize } from 'react-localize-redux'
import gateStyles from '../GateSettings.module.scss'
import { ICommonState } from '../../utils/state'
import Toggle from '../../../Toggle'
import Summary from '../../AutoReview/Summary'
import { EditStageGeneralProps } from "./types";
import { getAutoReviewCriteria } from '../../actions/gateSettingsActions'

const usesGoldenTicket = process.env.REACT_APP_USES_GOLDEN_TICKET === 'true'
const isMultilingual = process.env.REACT_APP_MONOLINGUAL !== 'true'

const EditStageGeneral = ({
  onFieldChange,
  translate,
  hideGoldenTicket,
  onPublicPrivateChange,
  programOwnerId,
}: EditStageGeneralProps) => {
  const { changedStages, stageIndex, autoReviewCriteria } = useSelector(
    (state: ICommonState) => state.gate,
  )
  const currentStage = changedStages[stageIndex]
  const {
    nameAlt = '',
    name = '',
    goldenTicket = '',
    description = '',
    descriptionAlt = '',
    autoReviewCriteria: autoReviewCriteriaId,
  } = currentStage
  const publicPrivateIndex = currentStage.private ? 1 : 0

  const [ autoReviewActive, setAutoReviewActive ] = useState(!!autoReviewCriteriaId)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAutoReviewCriteria(programOwnerId))
  }, [dispatch, programOwnerId])

  const criteriaArray = Object.values(autoReviewCriteria)
  const selectedAutoReview = criteriaArray.findIndex(({ id }) => id === autoReviewCriteriaId)

  return (
    <Form>
      <FormSection>
        <div className={gateStyles.field}>
          <TextInput
            id='name'
            showValidation={false}
            label={translate('gate.name') as string}
            value={name}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'name',
              })}
            type='text'
            required
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='name-alt'
              rtl
              showValidation={false}
              label={translate('gate.nameAlt') as string}
              value={nameAlt}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'nameAlt',
                })}
              type='text'
            />
          </div>
        )}
        {usesGoldenTicket && !hideGoldenTicket && (
          <div className={gateStyles.field}>
            <TextInput
              id='golden-ticket'
              readOnly
              showValidation={false}
              label={translate('gate.goldenTicket') as string}
              value={goldenTicket}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'goldenTicket',
                })}
              type='text'
            />
          </div>
        )}
        <div className={gateStyles.field}>
          <Dropdown
            id='status'
            showValidation={false}
            label={translate('gate.status') as string}
            value={publicPrivateIndex}
            onChange={onPublicPrivateChange}
            options={[
              translate('gate.public') as string,
              translate('gate.private') as string,
            ]}
          />
        </div>
        {currentStage.private && (
          <span className={gateStyles.warning}>This is a private gate. No emails will be sent out</span>
        )}
        <div className={gateStyles.field}>
          <TextInput
            id='description'
            showValidation={false}
            label={translate('gate.description') as string}
            value={description}
            onChange={(value) =>
              onFieldChange({
                newValue: value,
                field: 'description',
              })}
            type='text-area'
          />
        </div>
        {isMultilingual && (
          <div className={gateStyles.field}>
            <TextInput
              id='description-alt'
              rtl
              showValidation={false}
              label={translate('gate.descriptionAlt') as string}
              value={descriptionAlt}
              onChange={(value) =>
                onFieldChange({
                  newValue: value,
                  field: 'descriptionAlt',
                })}
              type='text-area'
            />
          </div>
        )}
      </FormSection>
      <FormSection>
        <div className={styles.autoReviewHeader}>
          <Translate id='gate.autoReview' />
          <Toggle
            onClick={() => {
              if (autoReviewActive) {
                onFieldChange({
                  newValue: '',
                  field: 'autoReviewCriteria',
                })
              }
              setAutoReviewActive(!autoReviewActive)
            }}
            value={autoReviewActive}
            offLabel='off'
            onLabel='on'
          />
        </div>
        <Dropdown
          id='auto-review'
          value={selectedAutoReview}
          onChange={(value) => {
            if (!Array.isArray(value)) {
              onFieldChange({
                newValue: criteriaArray[value] && criteriaArray[value].id,
                field: 'autoReviewCriteria',
              })
            }
          }}
          options={criteriaArray.map(({ name }) => name)}
          readOnly={!autoReviewActive}
        />
        {selectedAutoReview >= 0 && (
          <Summary template={criteriaArray[selectedAutoReview]} />
        )}
      </FormSection>
    </Form>
  )
}

export default withLocalize(EditStageGeneral)
