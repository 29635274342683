import React from 'react'
import styles from './NavLink.module.scss'
import { Translate } from 'react-localize-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import classNames from 'classnames'
import { NavLinkProps } from "./types";

const NavLink = ({
  path,
  newTab,
  translationKey,
  isInDrawer,
}: NavLinkProps) => {
  const location = useLocation()

  if (path !== undefined) {
    if (path.startsWith('http')) {
      return (
        <a
          href={path}
          target={newTab ? '_blank' : undefined}
          className={classNames(styles.link, {
            [styles.inDrawer]: isInDrawer,
          })}
        >
          <Translate id={`header.${translationKey}`} />
        </a>
      )
    }
    return (
      <Link
        to={path}
        className={classNames(styles.link, {
          [styles.inDrawer]: isInDrawer,
          [styles.active]: !isInDrawer && location.pathname === path,
        })}
      >
        <Translate id={`header.${translationKey}`} />
      </Link>
    )
  }
  return null
}

export default NavLink
