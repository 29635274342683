import { useCallback, useState } from 'react'
import Cookies from 'js-cookie'
import { IUser } from "../../../models/user/user";
import { LSKEY_TOKEN } from "../actions/authActions";
import { tokenKey } from "../../../utils/localStorage";

const userCookieKey = 'user'

export const defaultCookieOptions = {
  domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
  expires: 60,
}

export const setCookieItem = (key: string, item: any) =>
  Cookies.set(key, item, defaultCookieOptions)
const getItem = (key: string) => Cookies.get(key)

export function useCookie<TValue = string>(
  key: string,
  defaultValue?: TValue,
): [TValue, (value: TValue) => void] {
  const getCookie = () => (getItem(key) || defaultValue) as TValue
  const [cookie, setCookie] = useState(getCookie())

  const updateCookie = useCallback(
    (value: any) => {
      setCookie(value)
      setCookieItem(key, value)
    },
    [key, setCookie],
  )

  return [cookie, updateCookie]
}

export const setLoggedInCookie = () => setCookieItem('fid-is-loggedin', 'true')

export const setUserCookie = (user: IUser) => setCookieItem(userCookieKey, user)
export const setTokenCookie = (token: string) =>
  setCookieItem(LSKEY_TOKEN, token)

export const getUserCookie = (): IUser | undefined => {
  const user = getItem(userCookieKey)
  if (user) {
    return JSON.parse(user) as IUser
  }
}
export const getTokenCookie = (): string | undefined => getItem(LSKEY_TOKEN)

export const deleteUserCookie = () => Cookies.remove(userCookieKey)
export const deleteTokenCookie = () => Cookies.remove(LSKEY_TOKEN, defaultCookieOptions)
export const deleteSessionCookie = () => Cookies.remove(tokenKey, defaultCookieOptions)
export const deleteLoggedInCookie = () =>  Cookies.remove('fid-is-loggedin', defaultCookieOptions)
