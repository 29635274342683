import React from 'react'
import styles from './TNCPopup.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'reducers'
import { acceptTNC } from '../actions/userActions'
import useTranslate from 'hooks/useTranslate'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'

const EXCLUDED_PATHS = ['/terms', '/privacy']
const rootUrl = process.env.REACT_APP_DFF_URL

const TNCPopup = () => {
  const { showTncPopup, userId } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const translate = useTranslate()

  const markAccepted = () => dispatch(acceptTNC(userId))
  const tncRejected = () =>
    window.location.replace(process.env.REACT_APP_PROGRAMS_URL as string)

  // check that if the user is not logged in (!userId) or the popup shouldnt show (!showTncPopup)
  if (
    !showTncPopup ||
    !userId ||
    EXCLUDED_PATHS.some((path) => window.location.pathname.startsWith(path))
  ) {
    return null
  }

  return (
    <NotificationModal
      onCancel={tncRejected}
      onAccept={markAccepted}
      header={translate('tnc.header')}
      acceptText={translate('tnc.agree')}
    >
      <p>
        {translate('tnc.text', {
          tnc: (
            <a
              className={styles.link}
              target='_blank'
              href={`${rootUrl}terms-of-service`}
              rel='noopener noreferrer'
            >
              {translate('tnc.tnc')}
            </a>
          ),
          policy: (
            <a
              className={styles.link}
              target='_blank'
              href={`${rootUrl}privacy-policy`}
              rel='noopener noreferrer'
            >
              {translate('tnc.privacyPolicy')}
            </a>
          ),
        })}
      </p>
    </NotificationModal>
  )
}

export default TNCPopup
