export const getScoreString = (
  possibleScore?: number,
  sectionScore?: number,
  isRelative?: boolean
) => {
  if (!isRelative) {
    return possibleScore
  }
  if (possibleScore === undefined) {
    return null
  } else if (sectionScore !== undefined) {
    return `${
      possibleScore === 0
        ? 100
        : Math.round((sectionScore / possibleScore) * 100)
    }%`
  } else {
    return '0%'
  }
}
