import React, { Component } from 'react'
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler'

// @ts-ignore
import Slick from 'react-slick'
import './slick.scss'
import './slick-theme.scss'
import './slick-theme-vertical.scss'
import styles from './Slider.module.scss'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { getActiveLanguage } from 'react-localize-redux'
import { getDirection } from '../utils/translations'
import { RootState } from '../../../reducers'
import classNames from 'classnames'
import { SliderProps, SliderState } from "./types";

class Slider extends Component<SliderProps, SliderState> {
  private slider: any
  private settings: object
  private currentSlideIndex: number = 0
  state = {
    isChanging: false,
    width: window.innerWidth,
  }

  constructor(props: SliderProps) {
    super(props)
    this.settings = {
      initialSlide: props.initialSlide,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      speed: props.speed || 150,
      edgeFriction: 0,
      waitForAnimate: true,
      beforeChange: this.beforeChange,
      dots: props.dots,
      arrows: props.arrows !== false,
      vertical: props.vertical,
      verticalSwiping: props.vertical,
      afterChange: this.afterSlideChange,
    }
  }

  afterSlideChange = (index: number) => {
    this.setState({ isChanging: false })
    if (this.props.afterChange) {
      this.props.afterChange()
    }
    // this.isChanging = false
  }

  componentDidMount() {
    if (this.props.vertical) {
      const slickListDiv = document.getElementsByClassName('slick-list')[0]
      slickListDiv.addEventListener('wheel', (event: any) => {
        if (!event.ctrlKey) { // User tries to zoom in / out with mouse wheel
          event.preventDefault()
          if ((event.deltaY > 2 || event.deltaY < -2) && !this.state.isChanging) {
            this.setState({ isChanging: true })
            if (event.deltaY > 2) this.slider.slickNext()
            else if (event.deltaY < -2) this.slider.slickPrev()
          }
        }
      })

      document.onkeydown = (event: any) => {
        switch (event.keyCode) {
          case 38:
            this.prev()
            break
          case 40:
            this.next()
            break
        }
      }
    }
    window.addEventListener('resize', this.updateDimensions, false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions, false)
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  // Slick wrapper functions
  prev = () => {
    this.slider && this.slider.slickPrev()
  }

  next = () => {
    this.slider && this.slider.slickNext()
  }

  goTo = (index: number, props: SliderProps) => {
    this.slider.slickGoTo(index, true)

    // Firefox display bug
    if (this.dontAnimate(props)) {
      const el = document.querySelector('.slick-slide:last-child') as HTMLElement
      el.style.display = 'none'
      setTimeout(() => {
        el.style.display = 'block'
      }, 1)
    }
  }

  dontAnimate = (props: SliderProps) => {
    return ['/login'].includes(props.location.pathname)
  }

  rtlCompensatedIndex(index: number, isRtl: boolean) {
    return isRtl
      ? this.props.children.length - 1 - index
      : index
  }

  // React to change
  componentWillReceiveProps(nextProps: SliderProps) {
    const { initialSlide } = nextProps
    this.goTo(this.rtlCompensatedIndex(initialSlide, nextProps.isRtl), nextProps)
  }

  beforeChange = (oldIndex: number, newIndex: number) => {
    if (oldIndex === newIndex || this.props.inPage) return
    this.currentSlideIndex = newIndex
    this.updatePath(newIndex)
  }

  // Helper functions
  updatePath = (index: number) => {
    let path = (this.getSlide(index).props as any).path
    if (this.props.isRtl) {
      path = (this.getSlide(this.props.children.length - 1 - index).props as any).path
    }
    if (path === '/' || !this.props.location.pathname.includes(path)) this.props.push(path)
  }

  getSlide = (index: number) => {
    const slides = this.props.children
    return slides[index]
  }

  render() {
    const {slideScrollPop, className, headerPadding, vertical, draggable, isRtl, children, swipe} = this.props;
    return (
      <>
        <Slick
          className={classNames(styles.slider, className, {
            [styles.popped]: slideScrollPop,
            [styles.paddedSlider]: headerPadding,
          })}
          ref={(s: any) => (this.slider = s)}
          {...{
            ...this.settings,
            swipe: this.state.width > 768,
          }}
          dotsClass={vertical ? 'vertical-dots' : 'slick-dots'}
          draggable={draggable}
          rtl={isRtl && !vertical}
          swipe={swipe}
        >
          {children}
        </Slick>
        <KeyboardEventHandler handleKeys={['left']} onKeyEvent={this.prev} />
        <KeyboardEventHandler handleKeys={['right']} onKeyEvent={this.next} />
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  location: state.router.location,
  isRtl: getDirection(getActiveLanguage(state.localize)) === 'rtl',
  slideScrollPop: state.slider.scrollPop,
})

export default connect(
  mapStateToProps,
  { push },
)(Slider)
