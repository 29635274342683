import React from 'react'
import styles from './AllowedAnswers.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Translate } from 'react-localize-redux'
import { IQuestion } from '../../../../models/application/IQuestion'
import { IOption, IOptionGroup } from '../../../../models/application/IOption'
import TextInput from '../../../../FormInput/TextInput'
import Dropdown from '../../../../FormInput/Dropdown'
import Button from '../../../../shared/Button'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'

interface IAllowedAnswersProps {
  question: IQuestion
  questionIndex: number
  updatedQuestions: IQuestion[]
  updateQuestionField: (fieldId: string, newValue: IOption[]) => void
  isAltLang?: boolean
}

const hideApiKeys = process.env.REACT_APP_HIDE_OPTION_API_KEYS === 'true'

const addOptionToGroup = (group: IOptionGroup, option: IOption) => {
  return {
    ...group,
    options: [
      ...group.options,
      option.option,
    ],
    optionIds: option.optionId !== undefined ? [
      ...group.optionIds,
      option.optionId,
    ] : group.optionIds,
    altOptions: option.optionAlt !== undefined ? [
      ...group.altOptions,
      option.optionAlt,
    ] : group.altOptions,
  }
}

const reduceOptionsToGroups = (optionGroups: IOptionGroup[], option: IOption) => {
  if (optionGroups.length !== 0) {
    const lastGroup = optionGroups[optionGroups.length - 1]
    if (lastGroup.score === option.score &&
      lastGroup.jumpToQuestion === option.jumpToQuestion
    ) {
      return [
        ...optionGroups.slice(0, -1),
        addOptionToGroup(lastGroup, option),
      ]
    }
  }
  return [
    ...optionGroups,
    {
      options: [ option.option ],
      altOptions: option.optionAlt ? [ option.optionAlt ] : [],
      score: option.score,
      jumpToQuestion: option.jumpToQuestion,
      optionIds: option.optionId ? [ option.optionId ] : [],
    },
  ]
}

export const groupOptions = (options: IOption[]): IOptionGroup[] => {
  return options.reduce(reduceOptionsToGroups, [] as IOptionGroup[])
}

export const ungroupOptionGroups = (optionGroups: IOptionGroup[]): IOption[] => {
  const newOptions: IOption[] = []
  optionGroups.forEach(optionGroup => {
    optionGroup.options.forEach((option, index) =>
      newOptions.push({
        option,
        optionAlt: optionGroup.altOptions[index],
        score: optionGroup.score,
        jumpToQuestion: optionGroup.jumpToQuestion,
        optionId: optionGroup.optionIds[index],
      })
    )
  })

  return newOptions
}

const AllowedAnswers = (props: IAllowedAnswersProps) => {
  const {
    question,
    updatedQuestions,
    questionIndex,
    updateQuestionField,
    isAltLang,
  } = props

  const optionGroups = question.options
    ? groupOptions(question.options)
    : []

  const updateOptionField = (
    optionGroupIndex: number,
    fieldId: string,
    newValue: string,
  ) => {
    const newOptionGroups = optionGroups.map((optionGroup, index) => {
      if (index === optionGroupIndex) {
        return {
          ...optionGroup,
          [fieldId]: newValue ? parseInt(newValue, 10) : undefined,
        }
      }
      return optionGroup
    })

    const newOptions = ungroupOptionGroups(
      newOptionGroups,
    )
    updateQuestionField('options', newOptions)
  }

  const getDropdownOptions = (questionNumber: number) => {
    return [
      ...updatedQuestions
        .filter((question, index) => index > questionNumber)
        .map(
          (question, index) =>
            // for 0 -> 1 display +1
            // for next question  +1
            //                  = +2
            `Question ${questionNumber + index + 2}`,
        ),
      'All remaining questions',
    ]
  }

  const handleDeleteOption = (deleteIndex: number) => {
    const newOptionGroups = optionGroups.filter(
      (optionGroup, index) => index !== deleteIndex,
    )
    const newOptions = ungroupOptionGroups(
      newOptionGroups,
    )
    updateQuestionField('options', newOptions)
  }

  const handleAddOption = () => {
    const oldOptions = question.options || []
    const newOptions = [
      ...oldOptions,
      {
        option: '',
        optionAlt: '',
      },
    ]
    updateQuestionField('options', newOptions)
  }

  const parseValueForOptionGroup = (
    oldValue: string,
    newValue: string,
  ): string[] => {
    const change = newValue.replace(oldValue, '')
    const pasted = Math.abs(change.length) !== 1
    if (pasted && newValue[newValue.length - 1] === '\n') {
      return newValue.slice(0, -1).split('\n')
    }
    return newValue.split('\n')
  }

  const handleBulkChange = (
    field: 'options' | 'altOptions' | 'optionIds',
    optionGroupIndex: number,
  ) => (
    newValue: string
  ) => {
    const newOptionGroups = optionGroups.map((optionGroup, index) => {
      if (index === optionGroupIndex) {
        const newValues = parseValueForOptionGroup(
          optionGroup[field].join('\n'),
          newValue,
        )
        return {
          ...optionGroup,
          [field]: newValue === '' ? [] : newValues,
        }
      }
      return optionGroup
    })

    const newOptions = ungroupOptionGroups(
      newOptionGroups,
    )
    updateQuestionField('options', newOptions)
  }

  const lastOptionGroup = optionGroups.length > 0
    ? optionGroups[optionGroups.length - 1]
    : undefined

  const matchesDefaultGroup = lastOptionGroup === undefined
    ? false
    : (
      lastOptionGroup.score === undefined &&
      lastOptionGroup.jumpToQuestion === undefined
    )

  const handleJumpChange = (optionGroupIndex: number) => (newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    updateOptionField(
      optionGroupIndex,
      'jumpToQuestion',
      `${newValue !== -1 ? newValue + questionIndex + 1 : ''}`,
    )
  }

  return (
    <React.Fragment>
      {optionGroups.map((optionGroup, optionGroupIndex) => {
        const relativeJumpToIndex = optionGroup.jumpToQuestion
          ? optionGroup.jumpToQuestion - questionIndex - 1
          : -1
        const displayOptions = isAltLang ? optionGroup.altOptions : optionGroup.options

        return (
          <div
            className={styles.optionRow}
            key={`${question.id}-${optionGroupIndex}`}
          >
            <TextInput
              id={`${question.id}-${optionGroupIndex}-options`}
              label={<Translate id='visaApplication.options' />}
              className={styles.answerText}
              value={displayOptions.join('\n')}
              type='text-area'
              onChange={handleBulkChange(
                isAltLang ? 'altOptions' : 'options',
                optionGroupIndex
              )}
              showValidation={false}
            />
            {!hideApiKeys && (
              <TextInput
                id={`${question.id}-${optionGroupIndex}-api-key`}
                label={<Translate id='visaApplication.apiKeys' />}
                className={styles.answerText}
                value={optionGroup.optionIds.join('\n')}
                type='text-area'
                onChange={handleBulkChange('optionIds', optionGroupIndex)}
                showValidation={false}
              />
            )}
            <Dropdown
              id={`${question.id}-${optionGroupIndex}-jump-to-question`}
              label={<Translate id='visaApplication.jumpToQuestion' />}
              className={styles.jumpSelect}
              value={relativeJumpToIndex}
              options={getDropdownOptions(questionIndex)}
              onChange={handleJumpChange(optionGroupIndex)}
              showValidation={false}
              isClearable
            />
            <TextInput
              id={`${question.id}-${optionGroupIndex}-score`}
              label={<Translate id='visaApplication.score' />}
              className={styles.scoreInput}
              value={optionGroup.score ?? ''}
              type='number'
              onChange={(value) => updateOptionField(optionGroupIndex, 'score', value)}
              showValidation={false}
              min={0}
            />
            <Button
              type='primary'
              hardClassOverride={styles.deleteButton}
              onClick={() => handleDeleteOption(optionGroupIndex)}
            >
              <FontAwesomeIcon icon={faTimes} className={'fa'} />
            </Button>
          </div>
        )
      })}
      <InfoTooltip
        overlay={
          <Translate id='visaApplication.answerGroupDisabled' />
        }
      >
        <Button
          type='primary'
          className={styles.addButton}
          onClick={handleAddOption}
          disabled={matchesDefaultGroup}
        >
          <Translate id='visaApplication.addAnswer' />
        </Button>
      </InfoTooltip>
    </React.Fragment>
  )
}

export default AllowedAnswers
