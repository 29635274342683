import React from 'react'
import classNames from 'classnames'
import styles from './WrappedInput.module.scss'
import { ReactComponent as LogoTick } from '../assets/icons/checkmark.svg'
import { WrappedInputProps } from "./types";

const WrappedInput = ({
                        label,
                        description,
                        disabled,
                        keepDisplaying,
                        rtl,
                        children,
                        showValidation = true,
                        isValid,
                        className,
                        fullHeight,
                        id,
                      }: WrappedInputProps) => (
  <div
    className={classNames(styles.questionContainer, className, {
      [styles.disabled]: disabled && !keepDisplaying,
      [styles.disabledWithDisplay]: keepDisplaying && disabled,
      [styles.rtl]: rtl,
      [styles.fullHeight]: fullHeight,
    })}
  >
    {label && (
      <div className={styles.labelContainer}>
        {showValidation && (
          <LogoTick
            className={classNames(styles.logoTick, {
              [styles.disabled]: !isValid,
            })}
          />
        )}
        <label className={styles.questionLabel} htmlFor={id}>{label}</label>
        {description && (
          <div className={styles.questionDescription}>{description}</div>
        )}
      </div>
    )}
    {children}
  </div>
)


export default WrappedInput
