import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter, RouterState } from 'connected-react-router'
import userReducer from '../components/common/reducers/userReducer'
import authReducer from '../components/common/reducers/authReducer'
import toastsReducer from '../components/common/reducers/toastsReducer'
import sliderReducer from '../components/common/reducers/sliderReducer'
import gateReducer from '../components/common/reducers/gateSettingsReducer'
import reviewReducer from '../components/common/reducers/programManagerReducer'
import startupReducer from './startupReducer'
import passwordReducer from './passwordReducer'
import userProfileReducer from './userProfileReducer'
import adminReducer from './adminReducer'
import applicationReducer from '../components/common/reducers/applicationReducer'
import challengesReducer from '../components/common/reducers/challengesReducer'
import programOwnerReducer from './programOwnerReducer'
import programManagerReducer from './programManagerReducer'
import facilitatorReducer from '../components/common/reducers/facilitatorReducer'
import { localizeReducer } from 'react-localize-redux'
import onboardingReducer from './onboardingReducer'
import { ICommonState } from "components/common/utils/state";
import {
  AdminState,
  IProgramManagerState,
  OnboardingState,
  PasswordState,
  ProgramOwnerState,
  StartupState,
  UserProfileState,
} from "./types";

export interface RootState extends ICommonState {
  router: RouterState;
  personalProfile: UserProfileState;
  password: PasswordState;
  startup: StartupState;
  admin: AdminState;
  onboarding: OnboardingState;
  programOwner: ProgramOwnerState;
  programManager: IProgramManagerState;
}

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    auth: authReducer,
    toasts: toastsReducer,
    slider: sliderReducer,
    password: passwordReducer,
    startup: startupReducer,
    admin: adminReducer,
    personalProfile: userProfileReducer,
    application: applicationReducer,
    onboarding: onboardingReducer,
    challenges: challengesReducer,
    programOwner: programOwnerReducer,
    programManager: programManagerReducer,
    facilitators: facilitatorReducer,
    localize: localizeReducer,
    gate: gateReducer,
    review: reviewReducer,
  } as any)

export interface State {
  router: RouterState;
}

export default rootReducer
