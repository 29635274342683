import { DateTime } from 'luxon'

export const formatChallengeDateISO = (date: string) => {
  return date ? DateTime.fromISO(date).toISODate() : ''
}

export const formatChallengeDate = (date: Date) => {
  // it looks like some things are lying about what they are...
  // if it's not actually a Date, assume it's an ISO string
  const d = date instanceof Date ? DateTime.fromISO(date.toISOString()) : DateTime.fromISO(date)
  return d.isValid ? d.toLocaleString(DateTime.DATE_MED) : 'Not set'
}

// assume uae if no zone available
export const applyTimeZone = (utcDate: string, zone: string = 'UTC+4') => {
  return DateTime.fromISO(utcDate).setZone(zone).toISO()
}
