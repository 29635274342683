import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './ScoringTool.module.scss'
import { Translate, withLocalize, } from 'react-localize-redux'
import { range } from 'lodash'
import Dropdown from 'components/common/FormInput/Dropdown'
import TextInput from 'components/common/FormInput/TextInput'
import Button from 'components/common/shared/Button'
import Checkbox from 'components/common/FormInput/Checkbox'
import Tabs from 'components/common/Tabs/Tabs'
import { ICommonState } from 'components/common/utils/state'
import AdminTab from './AdminTab'
import { scoreApplication } from 'components/common/actions/programManagerActions'
import { useHistory } from 'react-router-dom'
import { addErrorToast } from 'components/common/actions/toastActions'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { ScoringToolProps } from "./types";

const ScoringTool = ({ stage, translate }: ScoringToolProps) => {
  const [comment, setComment] = useState('')
  const [scoreIndex, setScoreIndex] = useState(-1)
  const [fastTrack, setFastTrack] = useState(false)
  const [useOptionalStage, setUseOptionalStage] = useState(false)
  const [selectedReviewIndex, setSelectedReviewIndex] = useState(-1)
  const [confirmTooltipVisible, setConfirmTooltipVisible] = useState(false)

  const { role, userId } = useSelector((state: ICommonState) => state.user)
  const { rank } = role
  const { v2ReviewApplication, scoringPending } = useSelector(
    (state: ICommonState) => state.review,
  )

  const dispatch = useDispatch()
  const history = useHistory()

  if (!v2ReviewApplication) {
    return null
  }

  const {
    id: applicationId,
    metaData,
    challenge,
  } = v2ReviewApplication.application

  const { reviewedApplications } = v2ReviewApplication
  if (selectedReviewIndex === -1) {
    const myReviewIndex = reviewedApplications.findIndex(
      (ra) => ra.programManager && ra.programManager.id === userId,
    )
    if (myReviewIndex !== -1) {
      setSelectedReviewIndex(myReviewIndex)
    } else {
      setSelectedReviewIndex(0)
    }
  }

  const selectedReview =
    v2ReviewApplication.reviewedApplications[selectedReviewIndex]
  const isMine =
    selectedReview &&
    selectedReview.programManager &&
    selectedReview.programManager.id === userId

  const isFinal = metaData.status === 'final'
  const isAlreadyScored = selectedReview && selectedReview.scoring !== undefined

  const {
    scoringTool,
    canBeFastTracked,
    nextStageOptional,
    nextStageOptionalLabel,
  } = stage

  const getScoringOptions = (): any[] => {
    switch (scoringTool.name) {
      case 'YesNo':
        return [translate('utility.reject'), translate('utility.approve')]
      case 'Rating':
        // +1 for range upper bound being exclusive
        return range(scoringTool.min, scoringTool.max + 1).map((score) => [
          score.toString(),
        ])
      default:
        throw new Error('Unsupported scoring method')
    }
  }

  const interpretScoreIndex = () => {
    switch (scoringTool.name) {
      case 'YesNo':
        return scoreIndex
      case 'Rating':
        return scoringTool.min + scoreIndex
      default:
        throw new Error('Unsupported scoring method')
    }
  }

  const options = getScoringOptions()

  const handleScoreChange = (optionIndex: number | number[]) => {
    if (Array.isArray(optionIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    setScoreIndex(optionIndex)
  }

  const submitReview = () => {
    if (selectedReview) {
      const reviewedApplicationId = selectedReview.id
      dispatch(
        scoreApplication({
          comment,
          challengeId: challenge.id,
          fastTrack,
          useOptionalStage,
          applicationId,
          reviewedApplicationId,
          stageId: stage.id,
          score: interpretScoreIndex(),
          onSuccess: () => {
            setComment('')
            setScoreIndex(-1)
            history.goBack()
          },
        }),
      )
    } else {
      dispatch(addErrorToast(translate('utility.genericError').toString()))
    }
  }

  const handleReviewChange = (optionIndex: number | number[]) => {
    if (Array.isArray(optionIndex)) {
      throw new Error('Multi valued selection when expecting single value')
    }
    setSelectedReviewIndex(optionIndex)
  }

  const reviewerTab = {
    heading: 'Reviewer',
    content: (
      <div className={style.container}>
        {rank >= 2 && (
          <Dropdown
            id='id'
            showValidation={false}
            label={translate('review.selectReview') as string}
            value={selectedReviewIndex}
            options={
              v2ReviewApplication
                ? v2ReviewApplication.reviewedApplications.map((ra) => {
                    const name =
                      ra.programManager && ra.programManager.displayName
                        ? ra.programManager.displayName
                        : translate('review.unassigned').toString()
                    if (ra.scoring != null) {
                      return `${name} (${translate('review.alreadyReviewed')})`
                    }
                    return name
                  })
                : []
            }
            onChange={handleReviewChange}
          />
        )}
        <div className={style.reviewOptionGroup}>
          <Dropdown
            id='rating'
            showValidation={false}
            label={translate('review.review') as string}
            value={scoreIndex}
            options={options}
            onChange={handleScoreChange}
            readOnly={isFinal || isAlreadyScored}
          />
          {scoringTool.description && (
            <InfoTooltip overlay={<p>{scoringTool.description}</p>}>
              <span className={style.descriptionTooltip}>
                <Translate id='review.showGuideline' />
              </span>
            </InfoTooltip>
          )}
        </div>
        <TextInput
          id='comment'
          type='text-area'
          label={translate('review.comment') as string}
          placeholder={translate('review.commentPlaceholder').toString()}
          value={comment}
          onChange={setComment}
          className={style.comment}
          showValidation={false}
          readOnly={isFinal || isAlreadyScored}
        />
        {canBeFastTracked && (
          <Checkbox
            id='fastTrack'
            label={translate('review.fastTrack') as string}
            value={fastTrack}
            onChange={setFastTrack}
            readOnly={isFinal || isAlreadyScored}
          />
        )}
        {nextStageOptional && (
          <Checkbox
            id='useOptional'
            label={nextStageOptionalLabel || ''}
            value={useOptionalStage}
            onChange={setUseOptionalStage}
            readOnly={isFinal || isAlreadyScored}
          />
        )}
        <div className={style.buttonContainer}>
          <Button
            type='solid'
            onClick={() => {
              if (isMine) {
                submitReview()
              } else {
                setConfirmTooltipVisible(true)
              }
            }}
            className={style.button}
            disabled={
              scoringPending || isFinal || isAlreadyScored || scoreIndex === -1
            }
          >
            <Translate id='utility.submit' />
          </Button>
          {confirmTooltipVisible && (
            <NotificationModal
              onAccept={submitReview}
              onCancel={() => setConfirmTooltipVisible(false)}
            >
              <Translate id='admin.confirmUnassignedReview' />
            </NotificationModal>
          )}
        </div>
      </div>
    ),
  }

  const adminTab = {
    heading: 'Admin',
    content: (
      <div className={style.container}>
        <AdminTab stage={stage} />
      </div>
    ),
  }

  const tabs = rank >= 4 ? [reviewerTab, adminTab] : [reviewerTab]

  return <Tabs tabs={tabs} />
}

export default withLocalize(ScoringTool)
