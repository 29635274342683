import React from 'react';
import { TranslateFunction } from 'react-localize-redux';
import { CellProps } from 'react-table';
import { IApplicationsRow } from './types';
import { IAllApplicationResponse, IApplicationToReview } from 'models/application/application-to-review'
import moment from 'moment';
import CopyableLink from '../../../components/CopyableLink/CopyableLink';

export const dataConverter = (applications: IApplicationToReview[]): IApplicationsRow[] => 
  applications.map((application) => {
    const isReviewed = application.status === 'reviewed'
    const { company, applicant, submittedAt, program, score, _id: applicationId } = application.application
    return {
      isReviewed,
      company,
      applicant,
      program,
      score,
      applicationId,
      dateApplication: submittedAt
        ? moment(submittedAt).format('YYYY/MM/DD')
        : '-',
      gate: application.stage.name,
      status: application.status,
    }
  })

export const dataConverterAll = (
  applications: IAllApplicationResponse[],
): IApplicationsRow[] => {
  return applications.map((application) => {
    const { 
      company, 
      applicant, 
      status, 
      submittedAt, 
      program, 
      score, 
      stage, 
      _id: applicationId, 
      reviewerNames, 
    } = application

    return {
    company,
    applicant,
    program,
    score,
    applicationId,
    status,
    isReviewed: status === 'reviewed',
    dateApplication: submittedAt
      ? moment(submittedAt || new Date()).format('YYYY/MM/DD')
      : '-',
    gate: stage.name,
    reviewer: reviewerNames ? reviewerNames.join(', ') : '-',
  }})
}
export const getBaseColumns = (translate: TranslateFunction) => [
  {
    Header: translate('applications.applicant').toString(),
    accessor: 'applicant',
    width: 160,
    Cell: ({cell, row}: CellProps<IApplicationsRow>) => {
      const {applicationId, isReviewed} = row.original
      const applicant = cell.value
      const displayString = applicant || '-'
      if (isReviewed) {
        return <p>{displayString}</p>
      }
      return (
        <CopyableLink to={`/review/${applicationId}`}>
          {displayString}
        </CopyableLink>
      )

    },
  },
  {
    Header: translate('applications.company').toString(),
    accessor: 'company',
    width: 160,
    Cell: ({cell, row}: CellProps<IApplicationsRow>) => {
      if (cell.value) {
        const company = cell.value
        const displayString = company.name || '-'
        const {applicationId, isReviewed} = row.original
        if (isReviewed) {
          return <p>{displayString}</p>
        }
        return (
          <CopyableLink to={`/review/${applicationId}`}>
            {displayString}
          </CopyableLink>
        )

      }
      return '-'
    },
  },
  {
    Header: translate('applications.program').toString(),
    accessor: 'program',
    width: 160,
    Cell: ({cell}: CellProps<IApplicationsRow>) => {
      const {name, _id} = cell.value
      return <CopyableLink to={`/programs/${_id}`}>{name}</CopyableLink>
    },
  },

  {
    Header: translate('applications.dateApplication').toString(),
    accessor: 'dateApplication',
    width: 80,
    minWidth: 80,
  },

  {
    Header: translate('applications.score').toString(),
    accessor: 'score',
    width: 50,
    minWidth: 50,
  },
  {
    Header: translate('applications.gate').toString(),
    accessor: 'gate',
    width: 50,
    minWidth: 50,
  },
  {
    Header: translate('applications.status').toString(),
    accessor: 'status',
    width: 50,
    minWidth: 50,
    Cell: ({cell}: CellProps<IApplicationsRow>) => {
      const status = cell.value
        ? cell.value.toString().charAt(0).toUpperCase() +
        cell.value.toString().slice(1)
        : '-'
      return <p>{status}</p>
    },
  },
]
export const getAllColumns = (translate: TranslateFunction) => {
  const baseColumns = getBaseColumns(translate)
  return [
    ...baseColumns.slice(0, 3),
    {
      Header: 'Reviewer',
      accessor: 'reviewer',
      width: 80,
      Cell: ({cell}: CellProps<IApplicationsRow>) => {
        return cell.value || '-'
      },
    },
    ...baseColumns.slice(3),
  ]
}
