import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Line } from 'rc-progress'
import styles from './ApplicationPanel.module.scss'
import { RootState } from '../../../reducers'
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux'
import { submitApplication } from './ApplicationForm/utils/api'
import { addErrorToast } from '../actions/toastActions'
import ActionBar from '../ActionBar/ActionBar'
import Button from '../shared/Button'
import { push } from 'connected-react-router'
import { applicationStateContext } from './ApplicationForm/ApplicationFormState'
import Spinner from 'components/Spinner/Spinner'
import InfoTooltip from 'components/common/InfoTooltip/InfoTooltip'

interface IFooterProps extends LocalizeContextProps {
  readOnly?: boolean
  submitLabel: string
  onSubmit?: Function
  hasPercentage?: boolean
}

const allowReadSubmitted = process.env.REACT_APP_ALLOW_READ_SUBMITTED === 'true'

const ApplicationFooter = ({ translate, readOnly, submitLabel, onSubmit, hasPercentage }: IFooterProps) => {
  const {
    applicationId,
    applicationMode,
    initialize,
    answersSyncing,
  } = React.useContext(applicationStateContext)

  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const {
    completionPercent,
    selectedOrganization,
    selectedChallenge,
  } = useSelector((state: RootState) => state.application)
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    setIsSubmitting(true)
    if (onSubmit) {
      await onSubmit()
      setIsSubmitting(false)
      return
    }

    try {
      if (applicationMode === 'FID_ORGANISATION' && !selectedOrganization) {
        throw new Error('No organization selected')
      }
      if (!selectedChallenge) {
        throw new Error('No program selected')
      }

      if (!applicationId) {
        // give it one more chance to fetch the existing data
        initialize && (await initialize())
      }
      await submitApplication({
        applicationMode,
        applicationId: applicationId!,
      })
      if (allowReadSubmitted) {
        dispatch(push(`/programs/${selectedChallenge}/success`))
      } else {
        dispatch(push('/status'))
      }
    } catch (error) {
      if (error.response) {
        dispatch(
          addErrorToast(
            `${error.response.data.message}: ${error.response.data.errors}`,
          ),
        )
      } else {
        dispatch(addErrorToast(error))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const modifiedCompletion =
    completionPercent >= 100 ? 100 : Math.min(94, completionPercent)

  return (
    <ActionBar>
      {readOnly ? (
        <label className={styles.progressTextCol}>
          <Translate id='visaApplication.readOnly' />
        </label>
      ) : (
        <>
          {hasPercentage ? <div className={styles.progressBarAndLabelWrapper}>
            <div className={styles.progressBarCol}>
              <Line
                percent={modifiedCompletion}
                strokeWidth={4}
                strokeColor={styles.lineColor}
                trailWidth={4}
                trailColor={styles.lineBackground}
              />
            </div>
            <label className={styles.progressTextCol}>
              <Translate id='visaApplication.changesSaved' />
            </label>
          </div> : <div />}
          <div className={styles.footerButtonWrapper}>
            <InfoTooltip
              overlay={
                <p>
                  {translate(
                    `visaApplication.submitButton.${
                      completionPercent >= 100
                        ? 'messageSubmit'
                        : 'messageSubmitWarning'
                    }`,
                  ).toString()}
                </p>
              }
            >
              <div>
                <Button
                  type='solid'
                  disabled={
                    hasPercentage &&
                    (readOnly ||
                    completionPercent < 100 ||
                    answersSyncing.length > 0 ||
                    isSubmitting)
                  }
                  hardClassOverride={styles.submitButton}
                  onClick={handleSubmit}
                >
                  {answersSyncing.length > 0 || isSubmitting ? (
                    <Spinner small />
                  ) : (
                    <Translate id={submitLabel} />
                  )}
                </Button>
              </div>
            </InfoTooltip>
          </div>
        </>
      )}
    </ActionBar>
  )
}

export default withLocalize(ApplicationFooter)
