import { IScoringTool, ISendEmail, IStage } from '../models/application/IStage'
import { IParticipant } from '../models/application/IParticipant'
import { api, requestSuccess } from '../../../api'
import { handleError } from './errorHandlerActions'
import { addErrorToast, addSuccessToast } from './toastActions'
import { translate } from '../utils/translations'
import { IChallenge } from '../models/application/IChallenge'
import { cleanStageForSave } from '../GateSettings/utils'
import { ICriteria } from '../../../models/review/criteria'
import { IAutoReviewTemplate } from 'components/common/GateSettings/types'
import { AppThunk } from "./types";
import { convertTemplateToCriteria } from "./utils";

export const ACTIONS = {
  SET_STAGE_INDEX: '@dff_common_action/SET_GATE_STAGE_INDEX',
  SET_SECTION_INDEX: '@dff_common_action/SET_GATE_SECTION_INDEX',
  SET_UNCHANGED_STAGES: '@dff_common_action/SET_GATE_UNCHANGED_STAGES',
  SET_CHANGED_STAGES: '@dff_common_action/SET_GATE_CHANGED_STAGES',
  CHANGE_STAGE: '@dff_common_action/CHANGE_GATE_STAGE',
  SET_AVAILABLE_REVIEWERS: '@dff_common_action/SET_GATE_AVAILABLE_REVIEWERS',
  SYNC_STAGE_START: '@dff_common_action/SYNC_STAGE_START',
  SYNC_STAGE: '@dff_common_action/SYNC_GATE_STAGE',

  GET_AUTO_REVIEW_CRITERIA_START: '@dff_common_action/GET_AUTO_REVIEW_CRITERIA_START',
  GET_AUTO_REVIEW_CRITERIA_END: '@dff_common_action/GET_AUTO_REVIEW_CRITERIA_END',
  GET_SINGLE_AUTO_REVIEW_CRITERIA_START: '@dff_common_action/GET_SINGLE_AUTO_REVIEW_CRITERIA_START',
  GET_SINGLE_AUTO_REVIEW_CRITERIA_END: '@dff_common_action/GET_SINGLE_AUTO_REVIEW_CRITERIA_END',
  DELETE_AUTO_REVIEW_CRITERIA: '@dff_common_action/DELETE_AUTO_REVIEW_CRITERIA',
  GET_COUNTRY_LIST: '@dff_common_action/GET_COUNTRY_LIST',
  GET_SECTOR_LIST: '@dff_common_action/GET_SECTOR_LIST',
  SELECT_CHALLENGE: '@dff_common_action/SELECT_CHALLENGE',
}

export interface IStageIndexAction {
    payload: IStageIndexPayload
    type: typeof ACTIONS.SET_STAGE_INDEX
}

interface IStageIndexPayload {
    stageIndex: number
}

export interface ISectionIndexAction {
    payload: ISectionIndexPayload
    type: typeof ACTIONS.SET_SECTION_INDEX
}

interface ISectionIndexPayload {
    sectionIndex: number
}

export interface IUnchangedStagesAction {
    payload: IUnchangedStagesPayload
    type: typeof ACTIONS.SET_UNCHANGED_STAGES
}

interface IUnchangedStagesPayload {
    unchangedStages: IStage[]
}

export interface IChangedStagesAction {
    payload: IChangedStagesPayload
    type: typeof ACTIONS.SET_CHANGED_STAGES
}

interface IChangedStagesPayload {
    changedStages: IStage[]
}

export interface IChangeStageAction {
    payload: IChangeStagePayload
    type: typeof ACTIONS.CHANGE_STAGE
}
interface IChangeStagePayload {
    index: number
    value: string | number | boolean | IScoringTool | IParticipant[] | ISendEmail
    field: string
}

export interface ISyncStageStartAction {
  payload: string
  type: typeof ACTIONS.SYNC_STAGE_START
}

export interface ISyncStageAction {
  payload: IStage
  type: typeof ACTIONS.SYNC_STAGE
}

export interface IAvailableReviewersAction {
    payload: IAvailableReviewersPayload
    type: typeof ACTIONS.SET_AVAILABLE_REVIEWERS
}

interface IAvailableReviewersPayload {
    availableReviewers: IParticipant[]
}

interface ISelectChallengePayload {
    challenge: IChallenge
}

export interface ISelectChallengeAction {
    type: typeof ACTIONS.SELECT_CHALLENGE
    payload: ISelectChallengePayload
}

interface IGetAutoReviewCriteriaStart {
  type: typeof ACTIONS.GET_AUTO_REVIEW_CRITERIA_START
}

interface IGetAutoReviewCriteriaEnd {
  type: typeof ACTIONS.GET_AUTO_REVIEW_CRITERIA_END
  payload: ICriteria[]
}

interface IGetSingleAutoReviewCriteriaStart {
  type: typeof ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_START
  payload: string
}

interface IGetSingleAutoReviewCriteriaEnd {
  type: typeof ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_END
  payload: ICriteria
}

interface IDeleteCriteria {
  type: typeof ACTIONS.DELETE_AUTO_REVIEW_CRITERIA
  payload: string
}

export interface ICountry {
  code: string
  en: string
}

interface IGetCountryList {
  type: typeof ACTIONS.GET_COUNTRY_LIST
  payload: ICountry[]
}

export interface ISector {
  code: string
  name: string
}

interface IGetSectorList {
  type: typeof ACTIONS.GET_SECTOR_LIST
  payload: ISector[]
}

export type GateAction = IAvailableReviewersAction
  | IChangedStagesAction
  | IUnchangedStagesAction
  | IChangeStageAction
  | IStageIndexAction
  | ISectionIndexAction
  | ISelectChallengeAction
  | ISyncStageAction
  | ISyncStageStartAction
  | IGetAutoReviewCriteriaStart
  | IGetAutoReviewCriteriaEnd
  | IGetSingleAutoReviewCriteriaStart
  | IGetSingleAutoReviewCriteriaEnd
  | IDeleteCriteria
  | IGetCountryList
  | IGetSectorList

export const selectChallenge = (challenge: IChallenge) => {
  return {
    type: ACTIONS.SELECT_CHALLENGE,
    payload: challenge,
  }
}

export const getAndSelectChallenge = (challengeId: string): AppThunk => async(
  dispatch,
) => {
  try {
    const res = await api.get(`/challenges/${challengeId}`)
    dispatch(selectChallenge(res.data))
  } catch (e) {
    dispatch(handleError(e))
  }
}

export const resendStageEmail = (challengeId: string, stageId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const res = await api.post(
      `/v2/challenges/${challengeId}/review/stages/${stageId}/emails`,
      null,
    )
    if (!requestSuccess(res)) {
      dispatch(addErrorToast(translate('gate.resendEmailFailure', getState()) as string))
    }
    dispatch(addSuccessToast(translate('gate.resendEmailSuccess', getState()) as string))
  } catch (e) {
    dispatch(handleError(e))
  }
}

export const sendPendingRejections = (challengeId: string, stageId: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const res = await api.patch(
      `/challenges/${challengeId}/review/stages/${stageId}/reject`,
      null,
    )
    if (!requestSuccess(res)) {
      dispatch(addErrorToast(translate('gate.sendRejectionsFailure', getState()) as string))
    }
    dispatch(addSuccessToast(translate('gate.sendRejectionsSuccess', getState()) as string))
  } catch (e) {
    dispatch(addErrorToast(translate('gate.sendRejectionsFailure', getState()) as string))
    dispatch(handleError(e))
  }
}

export const syncStage = (challengeId: string, stageId: string, stage: Partial<IStage>, isScoringToolSame: boolean): AppThunk => async(
  dispatch,
  getState,
) => {
  const strippedStage = cleanStageForSave(stage, isScoringToolSame)

  try {
    dispatch({
      type: ACTIONS.SYNC_STAGE_START,
      payload: stage.id,
    })
    await api.patch(
      `/v2/challenges/${challengeId}/review/stages/${stageId}`,
      strippedStage,
    )
    dispatch({
      type: ACTIONS.SYNC_STAGE,
      payload: strippedStage,
    })
  } catch (error) {
    dispatch(addErrorToast(translate('stages.error.save', getState()) as string))
    throw error
  }
}

export const getAutoReviewCriteria = (programOwnerId: string, id?: string): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    if (id) {
      dispatch({
        type: ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_START,
        payload: id,
      })
      const response = await api.get(
        `/v3/criteria/${programOwnerId}/${id}`,
      )
      dispatch({
        type: ACTIONS.GET_SINGLE_AUTO_REVIEW_CRITERIA_END,
        payload: response.data,
      })
    } else {
      dispatch({
        type: ACTIONS.GET_AUTO_REVIEW_CRITERIA_START,
      })
      const response = await api.get(
        `/v3/criteria/${programOwnerId}`,
      )
      dispatch({
        type: ACTIONS.GET_AUTO_REVIEW_CRITERIA_END,
        payload: response.data,
      })
    }
  } catch (error) {
    dispatch(addErrorToast(translate('gate.getCriteriaFailure', getState()) as string))
    throw error
  }
}

export const updateAutoReviewCriteria = (programOwnerId: string, template: IAutoReviewTemplate, onFinish: () => void): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const criteria = convertTemplateToCriteria(template)
    await api.patch(
      `/v3/criteria/${programOwnerId}/${template.id}`,
      criteria,
    )
    if (onFinish) onFinish()
  } catch (error) {
    dispatch(addErrorToast(translate('gate.addCriteriaFailure', getState()) as string))
    throw error
  }
}

export const createAutoReviewCriteria = (programOwnerId: string, template: IAutoReviewTemplate, onFinish: () => void): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const criteria = convertTemplateToCriteria(template)
    await api.post(
      `/v3/criteria/${programOwnerId}`,
      criteria,
    )
    if (onFinish) onFinish()
  } catch (error) {
    dispatch(addErrorToast(translate('gate.addCriteriaFailure', getState()) as string))
    throw error
  }
}

export const deleteAutoReviewCriteria = (programOwnerId: string, id: string, onFinish: () => void): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    await api.delete(
      `/v3/criteria/${programOwnerId}/${id}`,
    )
    dispatch({
      type: ACTIONS.DELETE_AUTO_REVIEW_CRITERIA,
      payload: id,
    })
    if (onFinish) onFinish()
  } catch (error) {
    dispatch(addErrorToast(translate('gate.deleteCriteriaFailure', getState()) as string))
    throw error
  }
}

export const getCountryList = (): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const { countryList } = getState().gate
    if (countryList.length === 0) {
      const { data } = await api.get(
        `/v3/criteria/options/country`,
      )
      dispatch({
        type: ACTIONS.GET_COUNTRY_LIST,
        payload: data,
      })
    }
  } catch (error) {
    dispatch(addErrorToast(translate('gate.getLocationError', getState()) as string))
    throw error
  }
}

export const getSectorList = (): AppThunk => async(
  dispatch,
  getState,
) => {
  try {
    const { sectorList } = getState().gate
    if (sectorList.length === 0) {
      const { data } = await api.get(
        `/v3/criteria/options/sector`,
      )
      dispatch({
        type: ACTIONS.GET_SECTOR_LIST,
        payload: data,
      })
    }
  } catch (error) {
    dispatch(addErrorToast(translate('gate.getSectorError', getState()) as string))
    throw error
  }
}
