import { addErrorToast } from './toastActions'
import { performLogout, sendVerificationEmail } from './authActions'
import * as HttpStatus from 'http-status-codes'
import { translate } from '../utils/translations'
import { AppThunk } from "./types";


export const handleError = (error: any): AppThunk =>
  async(dispatch, getState, afterLogin = false) => {
  console.error(error)
  if (error.response) {
    const serverResponse = error.response
    if (serverResponse.data && serverResponse.data.message && serverResponse.data.errors) {
      const errors = serverResponse.data.errors
      const errorsIsPrintable = typeof errors === 'string' || Array.isArray(errors)
      let errorMessage = errorsIsPrintable
        ? `${serverResponse.data.message}: ${errors}` as any
        : serverResponse.data.message
      console.error(serverResponse, serverResponse.status)
      switch (serverResponse.status) {
        case HttpStatus.LOCKED:
          if (error.userInfo) {
            errorMessage = {
              'message': errorMessage,
              'buttonText': translate('actions.sendAgain', getState()),
              'buttonHandler': () => dispatch(sendVerificationEmail(error.userInfo.email)),
            }
          }
          dispatch(addErrorToast(errorMessage))
          break

        case HttpStatus.UNAUTHORIZED:
          dispatch(performLogout())
          // only add error toast when trying to login with invalid password
          if (afterLogin) dispatch(addErrorToast(errorMessage))
          break

        default:
          dispatch(addErrorToast(errorMessage))
      }
    }
  } else {
    dispatch(addErrorToast(error))
  }
}
