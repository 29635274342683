import { Translate } from "react-localize-redux";
import React from "react";

export const GovLinks = () => (
  <>
    <a href='http://www.dubai.ae' target='_blank' rel='noopener noreferrer'>
      <Translate id='footer.dubai_government' />
    </a>
    <a href='https://government.ae' target='_blank' rel='noopener noreferrer'>
      <Translate id='footer.uae_government' />
    </a>
  </>
)
