import React from 'react';
import mainStyles from 'components/common/Main/Main.module.scss';
import styles from './../About.module.scss';
import { Translate } from 'react-localize-redux';
import about4 from '../../../assets/icons/about4.svg';
import about5 from '../../../assets/icons/about5.svg';
import about6 from '../../../assets/icons/about6.svg';

export default () => {
  return (
    <div
      className={[
        mainStyles.container,
        styles.container,
        'slideContainer',
      ].join(' ')}
    >
      <div className={styles.wrapper}>
        <div className={styles.items}>
          <div className={styles.item}>
            <div className={styles.itemImg}>
              <img alt='' src={about4} />
            </div>
            <div className={styles.itemText}>
              <p className={styles.label}>
                <Translate id='about.slide3.title1' />
              </p>
              <p className={styles.paragraph}>
                <Translate id='about.slide3.p1' />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemImg}>
              <img alt='' src={about5} />
            </div>
            <div className={styles.itemText}>
              <p className={styles.label}>
                <Translate id='about.slide3.title2' />
              </p>
              <p className={styles.paragraph}>
                <Translate id='about.slide3.p2' />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemImg}>
              <img alt='' src={about6} />
            </div>
            <div className={styles.itemText}>
              <p className={styles.label}>
                <Translate id='about.slide3.title3' />
              </p>
              <p className={styles.paragraph}>
                <Translate id='about.slide3.p3' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
