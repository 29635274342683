/* global FormData */
import { IApiAnswer } from '../../../../models/application/IAnswer'
import { prefillWithFidData } from '../fidPrefill/fidPrefill'
import { ITemplate } from '../../../../models/application/ITemplate'
import { UserState } from '../../../../utils/state'
import { api } from '../../../../../../api'
import { IApplication } from 'models/application/application'
import jwtDecode from 'jwt-decode'
import { ApplicationMode } from 'components/common/models/application/IChallenge'
import { IQuestionSection } from '../../../../models/application/ISection'
import { IQuestion } from '../../../../models/application/IQuestion'

const IS_VISA = process.env.REACT_APP_ICA_STATUS

export const getBasePath = (applicationMode?: ApplicationMode) => {
  const { applicationId } = jwtDecode(api.defaults.headers.common.Authorization)
  // if it's a logged in user, the token will not be specific for an application, and normal endpoints can be used
  const isEmailApplicant = applicationId !== undefined
  return applicationMode === 'E-MAIL' && isEmailApplicant
    ? 'v3/email/applications/'
    : 'v3/applications/'
}

export const cleanApiAnswers = (answers: IApiAnswer[]): IApiAnswer[] => {
  return answers.map((answer) => {
    if (answer.selectedOptions && answer.selectedOptions.length > 0) {
      return {
        ...answer,
        uploadProgress: undefined,
      }
    }
    return {
      ...answer,
      answer: answer.answer || '',
      uploadProgress: undefined,
    }
  })
}

interface ISyncAnswerProps {
  answerIndex: number
  applicationId: string

  answer: string
  handleError: (message: string) => void
  selectedOptions?: number[]
  applicationMode?: ApplicationMode

  onSuccess?: () => void
}

export const syncAnswer = async ({
  answerIndex,
  applicationId,
  answer,
  handleError,
  selectedOptions,
  applicationMode,
  onSuccess,
}: ISyncAnswerProps) => {
  try {
    const basePath = getBasePath(applicationMode)
    const isMultiChoice = !!selectedOptions
    const payload = isMultiChoice
      ? {
          answer: '',
          selectedOptions,
        }
      : {
          answer,
        }
    await api.patch(
      `${basePath}${applicationId}/answers/${answerIndex}`,
      payload,
    )
    if (onSuccess) onSuccess()
  } catch (error) {
    console.error(error)
    handleError(`Error submitting application: ${error.message}`)
  }
}

interface ICreateApplicationProps {
  selectedOrganization?: string
  selectedChallenge?: string
  participants?: string[]
  goldenTicket?: string
}

export const createApplication = async ({
  selectedOrganization,
  selectedChallenge,
  participants,
  goldenTicket,
}: ICreateApplicationProps) => {
  const response = await api.post(`/v3/applications/`, {
    participants,
    goldenTicket,
    startupId: selectedOrganization || '',
    programId: selectedChallenge,
  })
  return response
}

interface ICreateEmailApplicationProps {
  selectedChallenge?: string
  goldenTicket?: string
  email: string
}

export const createEmailApplication = ({
  selectedChallenge,
  email,
  goldenTicket,
}: ICreateEmailApplicationProps) => {
  return api.post(`/v3/email/applications/`, {
    goldenTicket,
    email,
    programId: selectedChallenge,
  })
}

interface IVerifyApplicationEmailProps {
  email: string
  verificationCode: string
  applicationId: string
}

export const verifyApplicationEmail = ({
  email,
  verificationCode,
  applicationId,
}: IVerifyApplicationEmailProps) => {
  return api.post(
    `/v3/email/applications/${applicationId}/verifyCodeForApplication`,
    {
      email,
      verificationCode,
    },
  )
}

interface IVerifyEmailTokenProps {
  email: string
  applicationId: string
  token: string
}

export const verifyApplicationToken = ({
  email,
  applicationId,
  token,
}: IVerifyEmailTokenProps) => {
  return api.post(
    `/v3/email/applications/${applicationId}/verifyEmailForApplication`,
    {
      email,
      token,
    },
  )
}

interface ICreateApplicationWithFidPrefill extends ICreateApplicationProps {
  user?: UserState
  handleError: (message: string) => void
  applicationMode: ApplicationMode
}

/**
 * Create apploication and fill with prefilled answers
 * selected organization is can be none.
 */
export const createApplicationWithFidPrefill = async ({
  selectedOrganization,
  selectedChallenge,
  participants,
  goldenTicket,
  user,
  handleError,
  applicationMode,
}: ICreateApplicationWithFidPrefill): Promise<{
  applicationId?: string
  template: ITemplate
  answers: IApiAnswer[]
  submitted: boolean
  applicationMode: ApplicationMode
}> => {
  const { data } = await createApplication({
    selectedOrganization,
    selectedChallenge,
    participants,
  })
  const cleanedAnswers = cleanApiAnswers(data.answers)

  const { data: orgData } =
    applicationMode === 'FID_ORGANISATION'
      ? await api.get(`/startups/${selectedOrganization}`)
      : { data: undefined }
  const prefilledAnswers =
    !IS_VISA && user
      ? await prefillWithFidData(
          data.answers,
          data.applicationTemplate.questions,
          user,
          orgData,
          data.id!,
          handleError,
        )
      : cleanedAnswers

  const changedAnswerIndicies: number[] = prefilledAnswers.reduce(
    (changedAnswerIndicies, answer, index) => {
      if (
        answer.answer ||
        (answer.selectedOptions && answer.selectedOptions.length > 0)
      ) {
        return [...changedAnswerIndicies, index]
      }
      return changedAnswerIndicies
    },
    [] as number[],
  )
  changedAnswerIndicies.forEach((index) => {
    syncAnswer({
      handleError,
      applicationId: data.id,
      answerIndex: index,
      answer: prefilledAnswers[index].answer!,
      selectedOptions: prefilledAnswers[index].selectedOptions,
    })
  })
  return {
    applicationMode,
    applicationId: data.id,
    template: data.applicationTemplate,
    answers: prefilledAnswers,
    submitted: false,
  }
}

interface IUpdateApplicationFieldProps {
  applicationId: string
  field: string
  value: string
}

export const updateApplicationField = ({
  applicationId,
  field,
  value,
}: IUpdateApplicationFieldProps) => {
  return api.patch(`v3/applications/${applicationId}`, {
    [field]: value,
  })
}

type GetApplicationsOfUserToSelectedChallengeProps = {
  userId: string
  selectedChallenge?: string
}

export const getApplicationsOfUserToSelectedChallenge = ({
  userId,
  selectedChallenge,
}: GetApplicationsOfUserToSelectedChallengeProps) => {
  return api.get(
    `/v3/users/${userId}/programs/${selectedChallenge}/application`,
  )
}

/**
 * Retrieve application from an organization to a selected challenge (program)
 * Since under v3 endpoints the list of applications can only be provided by querying from the user's perspective,
 * userId is also needed for such purpose
 */
type ApplicationFromOrganizationProps = GetApplicationsOfUserToSelectedChallengeProps & {
  selectedOrganization: string
}
export const getApplicationFromOrganization = async ({
  userId,
  selectedChallenge,
  selectedOrganization,
}: ApplicationFromOrganizationProps) => {
  const {
    data: userApplications,
  } = await getApplicationsOfUserToSelectedChallenge({
    userId,
    selectedChallenge,
  })
  return userApplications.find(
    (application: IApplication) =>
      application.startup?.id === selectedOrganization,
  )
}

export const getApplicationFromIndividual = async ({
  userId,
  selectedChallenge,
}: GetApplicationsOfUserToSelectedChallengeProps) => {
  try {
    const {
      data: userApplications,
    } = await getApplicationsOfUserToSelectedChallenge({
      userId,
      selectedChallenge,
    })
    return userApplications.length > 0 && userApplications[0]
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return undefined
    }
    throw error
  }
}

interface IGetTemplateProps {
  handleError: (message: string) => void
  selectedOrganization: string
  selectedChallenge: string
  user?: UserState
}

/**
 * Get template to existing application or create an application WITH THE USER'S FID ORGANIZATION
 * First it checks if the application from the organization to that challenge exists
 * If yes then just return the existing template of application
 * Otherwise create one in the name of that organziation and return a new template
 */
export const getTemplate = async ({
  handleError,
  selectedOrganization,
  selectedChallenge,
  user,
}: IGetTemplateProps): Promise<{
  // it seems that this gets the template of an application no matter its a new application or existing one
  // so the ID should be optional here...
  applicationId?: string
  template: ITemplate
  answers: IApiAnswer[]
  submitted: boolean
  applicationMode: ApplicationMode
}> => {
  /**
   * Check if there are applications made from the given organization id
   */
  const { data: challenge } = await api.get(`/challenges/${selectedChallenge}`)
  const { applicationMode } = challenge
  let existingApplication

  if (applicationMode === 'FID_INDIVIDUAL' || applicationMode === 'FID_ORGANISATION') {
    if (applicationMode === 'FID_INDIVIDUAL') {
      existingApplication = await getApplicationFromIndividual({
        selectedChallenge,
        userId: user!.userId,
      })
    }

    if (applicationMode === 'FID_ORGANISATION' && selectedOrganization) {
      existingApplication = await getApplicationFromOrganization({
        selectedOrganization,
        selectedChallenge,
        userId: user!.userId,
      })
    }

    if (applicationMode === 'FID_ORGANISATION' && !existingApplication) {
      // Return an empty response in case if selectedOrganisation is not defined
      return {
        applicationId: '',
        template: {
          sections: [] as IQuestionSection[],
          questions: [] as IQuestion[]
        } as ITemplate,
        answers: [] as IApiAnswer[],
        applicationMode,
        submitted: false,
      }
    }

    if (!existingApplication) {
      /**
       * Otherwise create application FOR FID ORGANIZATION
       */
      return createApplicationWithFidPrefill({
        user,
        handleError,
        selectedOrganization,
        selectedChallenge,
        applicationMode,
        participants: [user!.userId],
      })
    } else {
      /**
       * If yes then return such application to save some time
       */
      const cleanedAnswers = cleanApiAnswers(existingApplication.answers)

      return {
        applicationId: existingApplication.id,
        template: existingApplication.applicationTemplate,
        answers: cleanedAnswers,
        applicationMode,
        submitted: existingApplication.submitted,
      }
    }
  }

  if (applicationMode === 'E-MAIL') {
    const { applicationId } = jwtDecode(
      api.defaults.headers.common.Authorization,
    )
    const basePath = getBasePath(applicationMode)
    if (applicationId) {
      try {
        const { data } = await api.get(`${basePath}${applicationId}`)

        const { applicationTemplate, answers, submitted } = data

        return {
          answers,
          submitted,
          applicationId,
          template: applicationTemplate,
          applicationMode: 'E-MAIL',
        }
      } catch (e) {
        if (e?.response?.status === 409) {
          setTimeout(
            () =>
              window.location.replace(`${process.env.REACT_APP_PROGRAMS_URL}`),
            3000,
          )
          throw new Error('You have already applied for this programme')
        }
        throw e
      }
    } else {
      // this is a logged in user applying to an email type application
      const existingApplication = await getApplicationFromIndividual({
        userId: user!.userId,
        selectedChallenge,
      })
      if (existingApplication) {
        const cleanedAnswers = cleanApiAnswers(existingApplication.answers)
        return {
          applicationMode,
          applicationId: existingApplication.id,
          template: existingApplication.applicationTemplate,
          answers: cleanedAnswers,
          submitted: existingApplication.submitted,
        }
      }
      return createApplicationWithFidPrefill({
        user,
        handleError,
        selectedChallenge,
        applicationMode,
        participants: [user!.userId],
      })
    }
  }
  throw new Error('unsupported application mode')
}

interface ISubmitApplicationProps {
  applicationId: string
  applicationMode?: ApplicationMode
}

export const submitApplication = async ({
  applicationId,
  applicationMode,
}: ISubmitApplicationProps) => {
  const basePath = getBasePath(applicationMode)
  await api.post(`${basePath}${applicationId}/submit`, {})
}

interface IUploadFileProps {
  answerIndex: number
  file: File
  onUploadProgress: (progressEvent: ProgressEvent) => void
  onError: () => void
  applicationId: string
  applicationMode?: ApplicationMode
}

export const uploadFileToBackend = async ({
  answerIndex,
  file,
  onUploadProgress,
  onError,
  applicationId,
  applicationMode,
}: IUploadFileProps): Promise<string> => {
  const formData = new FormData()
  formData.append('file', file)
  const basePath = getBasePath(applicationMode)

  const { data } = await api.post(
    `${basePath}${applicationId}/answers/${answerIndex}/files`,
    formData,
    {
      onUploadProgress,
    },
  )
  return data.answers[answerIndex].answer
}

interface IDeleteFileProps {
  answerIndex: number
  applicationId: string
  applicationMode?: ApplicationMode
}

export const deleteFileFromBackend = async ({
  answerIndex,
  applicationId,
  applicationMode,
}: IDeleteFileProps) => {
  try {
    const basePath = getBasePath(applicationMode)
    await api.delete(`${basePath}${applicationId}/answers/${answerIndex}/files`)
  } catch (error) {
    console.error(error)
    throw error
  }
}
