import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../AdminTables.module.scss'
import programOwnerStyles from './ProgramOwners.module.scss'
import { deleteProgramOwnerProfile, fetchOwnedProgramOwnerProfiles } from 'actions/programOwnerActions'
import Table from 'components/common/Table/Table'
import ActionIconGroup from 'components/common/Table/ActionIconGroup'
import CreateProgramOwnerProfileModal from 'components/ProgramOwner/CreateProgramOwnerProfileModal'
import ConfirmActionModal from 'components/Modal/ConfirmActionModal'
import Pagination from 'components/common/TableFilter/Pagination'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { IProgramOwner } from 'models/programOwner/program-owner'
import { CellProps } from 'react-table'
import CopyableLink from 'components/CopyableLink/CopyableLink'
import FilterSearch from 'components/common/TableFilter/FilterSearch'
import classNames from 'classnames'
import { useLocation } from 'react-router'
import FitScreen from 'components/common/Layout/FitScreen'
import { IProgramOwnersRow } from "./types";
import { RootState } from "../../../reducers";

const convertData = (rawData: IProgramOwner[] | null): IProgramOwnersRow[] => {
  if (rawData) {
    return rawData
      .map((programOwnerProfile) => ({
        name: programOwnerProfile.name || '',
        challengesCount: programOwnerProfile.challenges
          ? programOwnerProfile.challenges.length
          : 0,
        url: `/facilitators/${programOwnerProfile.id}/settings`,
        id: programOwnerProfile.id || '',
        ownersCount: programOwnerProfile.owners
          ? programOwnerProfile.owners.length
          : 0,
      }))
      .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
  }
  return []
}

const ProgramOwners = ({ translate }: LocalizeContextProps) => {
  const dispatch = useDispatch()

  // retrieve filters from search query
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const [selectedRow, selectRow] = useState<string | null>(null)
  const [addFacilitatorModalVisible, setAddFacilitatorModalVisible] = useState(
    false,
  )
  const [
    deleteFacilitatorModalVisible,
    setDeleteFacilitatorModalVisible,
  ] = useState(false)

  const { programOwnerProfiles } = useSelector(
    (state: RootState) => state.programOwner,
  )
  const data = convertData(programOwnerProfiles)

  const toggleFacilitatorModal = () => {
    setAddFacilitatorModalVisible(!addFacilitatorModalVisible)
  }

  const toggleDeleteFacilitatorModal = () => {
    setDeleteFacilitatorModalVisible(!deleteFacilitatorModalVisible)
  }

  const columns = [
    {
      Header: ' ',
      columns: [
        {
          Header: translate('programOwnerProfiles.name').toString(),
          accessor: 'name',
          Cell: ({ cell, row }: CellProps<IProgramOwnersRow>) => {
            const { url } = row.original
            return <CopyableLink to={url}>{cell.value}</CopyableLink>
          },
        },
        {
          Header: translate('programOwnerProfiles.numPrograms').toString(),
          accessor: 'challengesCount',
          Cell: ({ cell, row }: CellProps<IProgramOwnersRow>) => {
            return (
              <CopyableLink
                to={`/programs-list/?managedBy=${row.original.name}`}
              >
                {cell.value}
              </CopyableLink>
            )
          },
          width: 30,
        },
        {
          Header: translate('programOwnerProfiles.numAdmins').toString(),
          accessor: 'ownersCount',
          Cell: ({ cell }: CellProps<IProgramOwnersRow>) => {
            return cell.value
          },
          width: 30,
        },
        {
          // action columns
          Header: '',
          accessor: 'id',
          Cell: ({ cell }: CellProps<IProgramOwnersRow>) => {
            return (
              <ActionIconGroup
                className={styles.actionIconGroup}
                actions={[
                  {
                    id: 'delete-tip',
                    icon: faTrashAlt,
                    tooltip: 'delete',
                    onClick: handleDeleteModalOpen(cell.value),
                  },
                ]}
              />
            )
          },
          width: 5,
        },
      ],
    },
  ]

  useEffect(() => {
    dispatch(fetchOwnedProgramOwnerProfiles(null, null))
  }, [dispatch])

  const handleAddFacilitator = () => {
    toggleFacilitatorModal()
  }

  const handleDeleteModalOpen = (profileId: string) => () => {
    selectRow(profileId)
    toggleDeleteFacilitatorModal()
  }

  const handleDelete = (profileId: string | null) => () => {
    if (profileId) {
      dispatch(deleteProgramOwnerProfile(profileId))
      toggleDeleteFacilitatorModal()
    }
  }

  if (data) {
    const nameFilter = query.get('name')
    // in case its something garbage or its not provided, default the value as 1
    const pageNumber = parseInt(query.get('page') as string, 10) || 1
    const filteredData = nameFilter
      ? data.filter((row) =>
          row.name
            .toLowerCase()
            .trim()
            .includes(nameFilter.toLowerCase().trim()),
        )
      : data

    const displayFilter = filteredData.slice(
      (pageNumber - 1) * 10,
      pageNumber * 10,
    )
    return (
      <FitScreen>
        <div
          className={classNames(styles.container, programOwnerStyles.container)}
        >
          <FilterSearch
            dark
            supportedFilters={{
              name: 'Name',
            }}
            defaultFilter='name'
          />
          <div
            className={classNames(
              styles.tableContainer,
              styles.tableWithSearch,
            )}
          >
            <Table
              data={displayFilter}
              columns={columns}
              title={translate('admin.facilitators').toString()}
              onAdd={handleAddFacilitator}
              addLabel={translate('admin.newFacilitator').toString()}
              clientSorting
            />
            <Pagination resultCount={filteredData.length} />
          </div>
        </div>
        {addFacilitatorModalVisible && (
          <CreateProgramOwnerProfileModal close={toggleFacilitatorModal} />
        )}
        {deleteFacilitatorModalVisible && (
          <ConfirmActionModal
            title={translate(
              'admin.deleteProgramOwnerProfileConfirmation',
            ).toString()}
            confirmText={translate('delete').toString()}
            cancelText={translate('cancel').toString()}
            close={toggleDeleteFacilitatorModal}
            confirmAction={handleDelete(selectedRow)}
          />
        )}
      </FitScreen>
    )
  }
  return null
}

export default withLocalize(ProgramOwners)
