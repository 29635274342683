import React, { Fragment, useEffect, useState } from 'react'
import styles from './Main.module.scss'
import TwoButtonLandingPage from '../LandingPage/TwoButtonLandingPage'
import LandingSimple from '../LandingPage/LandingSimple'
import Slider from '../Slider/Slider'
import Slide from '../Slider/Slide'
import classNames from 'classnames'
import { animated, config, useTransition } from 'react-spring'
import { range, shuffle } from 'lodash'
import { Translate } from 'react-localize-redux'
import { MainProps, Route } from "./types";
import { checkRouteValid, getUrlForIndex } from "./utils";

const shuffledImages: number[] = shuffle(range(1, 48))

/*
 we can use this if we use html images
 instead of css background images

const getSrcSetForIndex = (index: number) => {
  const resultArray: string[] = [];
  [100, 66, 33, 20].forEach((quality: number) => {
    const url = getUrlForIndex(index);
    const width = getWidthForIndex(index, quality);
    resultArray.push(`${url} ${width}x`)
  })
  return resultArray.join(' , ')
}
 */

const Main = ({
  initialSlide,
  rightSlideComponent,
  rightSlidePath,
  push,
  isLoggedIn,
  slider,
  routes,
  shrinkRegisterBackground,
  children,
  className,
  afterSlideChange,
  background,
}: MainProps) => {
  const [activeBackground, setActiveBackground] = useState(0)
  useEffect(() => {
    if (!background) {
      setInterval(() => {
        setActiveBackground(oldBackground => (oldBackground + 1) % shuffledImages.length)
      }, 35000)
    }
  }, [background])

  const getRouteForPage = (route: Route) => {
    if (route.loggedIn && isLoggedIn) {
      return route.loggedIn
    }
    return route.loggedOut
  }

  const slides = shuffledImages.map((imageNumber, index) => ({
    id: index,
    url: getUrlForIndex(imageNumber),
  }))

  const transitions = useTransition(slides[activeBackground] || '', item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  if (!routes.every(checkRouteValid)) {
    throw new Error('Routes incorrectly configured')
  }

  return (
    <>
      {background ? (
        <div
          className={styles.background}
          style={{
            backgroundImage: `url(${background})`,
            // last slide is forced to be the half width registration page
            width:
              shrinkRegisterBackground && initialSlide === routes.length
                ? '50%'
                : undefined,
          }}
        />
      ) : (
        <>
          {transitions.map(({ item, props, key }) => {
            const shuffleIndex = shuffledImages[(item.id + 1) % shuffledImages.length]
            return (
              <Fragment
                key={key}
              >
                <animated.div
                  className={styles.background}
                  style={{
                    ...props,
                    backgroundImage: `url(${item.url})`,
                    // last slide is forced to be the half width registration page
                    width:
                      shrinkRegisterBackground && initialSlide === routes.length
                        ? '50%'
                        : undefined,
                  }}
                />
                <div
                  className={classNames(styles.preloadBackground, styles.background)}
                  style={{
                    backgroundImage: `url(${getUrlForIndex(shuffleIndex)})`,
                  }}
                />
              </Fragment>
            )
          })}
          <div className={styles.photoCredit}>
            <Translate id='landingPage.photoCredit' />
          </div>
        </>
      )}
      {routes.length > 0 && (
        <div className={classNames(className, styles.sliderContainer)}>
          {/*
          // @ts-ignore */}
          <Slider
            className={classNames(styles.slider,
                {
                  'slick-slider-draggable': slider.draggable,
                },
            )}
            initialSlide={initialSlide}
            draggable={slider.draggable}
            afterChange={afterSlideChange}
            swipe
          >
            {[
                ...routes.map((route: Route) => {
                  if (route.type === 'simple') {
                    return (
                      <Slide
                        key={route.loggedOut}
                        path={getRouteForPage(route)}
                      >
                        <LandingSimple
                          headingKey={route.headingKey!}
                          descriptionKey={route.descriptionKey}
                        />
                      </Slide>
                    )
                  }
                  if (route.type === 'visa') {
                    return (
                      <Slide
                        key={route.loggedOut}
                        path={getRouteForPage(route)}
                      >
                        {children}
                      </Slide>
                    )
                  }
                  if (route.type === 'two-button') {
                    return (
                      <Slide
                        key={route.loggedOut}
                        path={getRouteForPage(route)}
                      >
                        <TwoButtonLandingPage
                          isLoggedIn={isLoggedIn}
                          onClickButton1={() => push(route.button1Target)}
                          onClickButton2={() => push(route.button2Target)}
                          button1Text={route.button1Text || ''}
                          button2Text={route.button2Text || ''}
                          headingKey={route.headingKey!}
                          descriptionKey={route.descriptionKey}
                        />
                      </Slide>
                    )
                  }
                  return null
                }),
              <Slide key='right-slide' path={rightSlidePath}>
                {rightSlideComponent}
              </Slide>,
              ]}
          </Slider>
        </div>
      )}
    </>
  )
}

export default Main
