/* global localStorage */
import { getTokenCookie } from "../components/common/utils/cookies";
import {
  LSKEY_TOKEN_EXPIRES,
  LSKEY_USER_ACCEPTED_TNC,
  LSKEY_USER_EMAIL,
  LSKEY_USER_ID,
  LSKEY_USER_NAME,
  LSKEY_USER_PICTURE
} from "../components/common/actions/authActions";

export const tokenKey = 'session_token'

// TMP duplicated it from common submodule
export const getUserFromLocalStorage = () => {
  const token = getTokenCookie()
  return {
    token,
    expires: localStorage.getItem(LSKEY_TOKEN_EXPIRES),
    email: localStorage.getItem(LSKEY_USER_EMAIL),
    userId: localStorage.getItem(LSKEY_USER_ID),
    userName: localStorage.getItem(LSKEY_USER_NAME),
    profilePicture: localStorage.getItem(LSKEY_USER_PICTURE),
    acceptedTnc: localStorage.getItem(LSKEY_USER_ACCEPTED_TNC),
  }
}


export const isLoggedInABrowser = () => {
  const token = getTokenCookie()
  const email = localStorage.getItem(LSKEY_USER_EMAIL)
  const userId = localStorage.getItem(LSKEY_USER_ID)
  return !!(token && email && userId)
}

