import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'
import styles from './CloseButton.module.scss'

const CloseButton = ({className, ...rest}: HTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={classnames(styles.button, className)}
      type="button"
      {...rest}
    >
      +
    </button>
  )
}

export default CloseButton;