import React from 'react'
import style from './Form.module.scss'
import classNames from 'classnames'

interface IProps {
  className?: string
  children: JSX.Element | (JSX.Element[] | JSX.Element | false | null)[] | null
}

const Form = ({ children, className }: IProps) => {
  return (
    <div className={classNames(style.formSection, className)}>
      {children}
    </div>
  )
}

export default Form
