import { Action } from "redux";
import { ACTIONS } from '../actions/onboardingActions'
import { LOGOUT } from '../components/common/actions/authActions'
import { createReducer } from "./createReducer";
import { OnboardingState, Reducer } from "./types";

const defaultState: OnboardingState = {
  onboardingTemplates: [],
  onboardings: [],
}

const reducer: Reducer<OnboardingState, Action> = {
  [ACTIONS.FETCH_ONBOARDING_TEMPLATE_SUCCESS]: (state, {payload}) => {
    const index = state.onboardingTemplates.findIndex(
      (at: any) => at.id === payload.id,
    )
    if (index >= 0) {
      return {
        ...state,
        onboardingTemplates: [
          ...state.onboardingTemplates.slice(0, index),
          payload,
          ...state.onboardingTemplates.slice(index + 1),
        ],
      }
    }
    return {...state, onboardingTemplates: [...state.onboardingTemplates, payload]}
  },
  [ACTIONS.FETCH_ONBOARDING_SUCCESS]: (state, {payload}) => {
    const index = state.onboardings.findIndex(
      (at: any) => at.id === payload.id,
    )
    if (index >= 0) {
      return {
        ...state,
        onboardings: [
          ...state.onboardings.slice(0, index),
          payload,
          ...state.onboardings.slice(index + 1),
        ],
      }
    }
    return {...state, onboardings: [...state.onboardings, payload]}
  },
  [LOGOUT]: () => defaultState,
}

export default createReducer<OnboardingState, Action>(reducer, defaultState);