import { Action } from "redux";

import {
  CREATE_NEW_PASSWORD_SUCCESS,
  LOGOUT,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
} from '../components/common/actions/authActions'
import { createReducer } from "./createReducer";
import { PasswordState, Reducer } from "./types";


const defaultState: PasswordState = {
  hasPerformedPasswordReset: false,
  resetPasswordSuccess: false,
  resetPasswordFailure: false,
  hasPerformedCreateNewPassword: false,
  createNewPasswordSuccess: false,
  createNewPasswordFailure: false,
}

const reducer: Reducer<PasswordState, Action> = {
  [RESET_PASSWORD_SUCCESS]: (state) => {
    return {
      ...state,
      hasPerformedPasswordReset: true,
      resetPasswordSuccess: true,
      resetPasswordFailure: false,
    }
  },
  [RESET_PASSWORD_FAILED]: (state) => {
    return {
      ...state,
      hasPerformedCreateNewPassword: true,
      createNewPasswordSuccess: false,
      createNewPasswordFailure: true,
    }
  },
  [CREATE_NEW_PASSWORD_SUCCESS]: (state) => {
    return {
      ...state,
      hasPerformedCreateNewPassword: true,
      createNewPasswordSuccess: false,
      createNewPasswordFailure: true,
    }
  },
  [LOGOUT]: () => defaultState,
}

export default createReducer<PasswordState, Action>(reducer, defaultState);
