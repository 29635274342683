import { IApplication } from "../models/application/IApplication";
import { Language } from 'react-localize-redux'
import { INewState, ReviewState } from "./types";

const applicationMatchesSearch = (searchTerm: string) => (
  application: IApplication,
): boolean => {
  return application.participants.some(applicant =>
    applicant.email.toLowerCase().includes(searchTerm.toLowerCase()),
  )
}
export const applyFilter = (
  applications: IApplication[],
  showPrimaryLanguage: boolean,
  showSecondaryLanguage: boolean,
  languages: Language[],
  searchTerm: string,
) =>
  applications
    .filter(application =>
      !showPrimaryLanguage && languages && languages.length >= 1
        ? application.language !== languages[0].code
        : true,
    )
    .filter(application =>
      !showSecondaryLanguage && languages && languages.length >= 2
        ? application.language !== languages[1].code
        : true,
    )
    .filter(applicationMatchesSearch(searchTerm))


export const getNewApplicationData = (
  oldState: ReviewState,
  newState: INewState,
  languages: Language[],
) => {
  const state = {
    ...oldState,
    ...newState,
  }
  const {
    showPrimaryLanguage,
    showSecondaryLanguage,
    searchTerm,
    rejectedSelected,
    applicationIndex,
    applicationsData,
  } = state

  const applications = applicationsData ? applicationsData.applications : []
  const reviewedApplications = applicationsData
    ? applicationsData.reviewedApplications
    : []

  const filteredApplications = applyFilter(
    applications,
    showPrimaryLanguage,
    showSecondaryLanguage,
    languages,
    searchTerm,
  )

  const rejectedApplications = applicationsData
    ? applicationsData.rejectedApplications
    : []

  const filteredRejectedApplications = applyFilter(
    rejectedApplications,
    showPrimaryLanguage,
    showSecondaryLanguage,
    languages,
    searchTerm,
  )

  const selectedApplication = rejectedSelected
    ? filteredRejectedApplications[applicationIndex]
    : filteredApplications[applicationIndex]

  const selectedReviewedApplication = selectedApplication
    ? reviewedApplications.find(
      (reviewedApplication: any) =>
        reviewedApplication.application.id === selectedApplication.id,
    )
    : undefined

  const applicants = filteredApplications.map((application) => ({
    ...application.participants[0],
    language: application.language,
  }))
  const rejectedApplicants = filteredRejectedApplications.map(
    (application) => ({
      ...application.participants[0],
      language: application.language,
    }),
  )
  return {
    selectedApplication,
    selectedReviewedApplication,
    applicants,
    rejectedApplicants,
  }
}
