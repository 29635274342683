import React from 'react'
import FilterSearch from '../common/TableFilter/FilterSearch'
import Table from 'components/common/Table/Table'

import styles from './ApplicationListMain.module.scss'
import { fetchAllApplications, fetchApplicationsToReview } from 'actions/programManagerActions'
import { useDispatch, useSelector } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Pagination from 'components/common/TableFilter/Pagination'
import { useLocation, useParams } from 'react-router'
import { exportChallenge } from 'actions/adminActions'
import Breadcrumbs from 'components/common/Breadcrumbs/Breadcrumbs'
import HomeIcon from "../common/ProgramSettings/HomeIcon";
import { ApplicationListMainProps } from "./types";
import { RootState } from "../../reducers";
import { dataConverter, dataConverterAll, getAllColumns } from "../../pages/AdminTables/Applications/utils";

const ApplicationListMain = ({
  facilitatorName,
  programName,
  programId,
  stageId,
  translate, // withLocalize
}: ApplicationListMainProps) => {
  // retrieve the search string
  const location = useLocation()
  const dispatch = useDispatch()
  const { id: challengeId } = useParams()
  // denote whether list of assigned applications is seen all all applications are seen
  const [activeMode, setMode] = React.useState(1)

  // get the user id for fetching list of applications
  const {
    user: { userId, role },
  } = useSelector((state: RootState) => state)

  // get the applications in the store
  const {
    allApplications,
    allApplicationsCount,

    applicationsToReviewCount,
    applicationsToReview,
  } = useSelector((state: RootState) => state.programManager)

  // convert data for table to render
  const allData = dataConverterAll(allApplications)
  const assignedData = dataConverter(applicationsToReview)
  const { rank } = role

  /**
   * Function for fetching list of applications give the stage Id
   * If none is given, applications of the whole program will be fetched
   */
  const fetchApplicationList = React.useCallback(() => {
    const query = `${
      // filters from search bar
      location.search ? `${location.search}&` : '?'
      // limit search result to the program only
    }programId=${programId}${
      // in addition, if there are stage Id, limit results to that stage only
      stageId ? `&stageId=${stageId}` : ''
    }`

    // depending on whether "assigned" or "all" tabs are selected
    if (rank >= 2) {
      // admin or above
      activeMode === 0
        ? // fetch reviewed applications only
          dispatch(fetchApplicationsToReview({ userId, query }))
        : // fetch all applications
          dispatch(fetchAllApplications({ userId, query }))
    } else {
      // reviwer only
      dispatch(fetchApplicationsToReview({ userId, query }))
    }
  }, [programId, stageId, location, activeMode, dispatch, userId, rank])

  // handler for "Export applications" button
  const onExportApplications = () => {
    dispatch(exportChallenge(challengeId))
  }

  // trigger application list fetch when component just loaded
  React.useEffect(() => {
    fetchApplicationList()
  }, [stageId, programName, location, fetchApplicationList])

  // check whether assigned data is used or all data is used
  const isAssignedDataInUse = rank === 1 || activeMode === 0
  // prepare columns for the table
  const baseColumns = [
    {
      Header: ' ',
      // drop the program column since the program of the application is clear (this page is for that program only)
      // also if "assigned applications" are being view, reviewers need not to be shown (since if you see the assigned application, then you are the reviewer...)
      columns: getAllColumns(translate).filter(
        (col) =>
          col.accessor !== 'program' &&
          !(isAssignedDataInUse && col.accessor === 'reviewer'),
      ),
    },
  ]

  // the data source used in the table
  const tableDataSource = isAssignedDataInUse ? assignedData : allData

  const dataSourceCount = isAssignedDataInUse
    ? applicationsToReviewCount
    : allApplicationsCount

  return (
    <div className={styles.container}>
      <FilterSearch
        supportedFilters={{
          company: 'Company',
          stage: 'Gate',
          applicant: 'Applicant',
          ...(isAssignedDataInUse
            ? {}
            : // additionally, if "all applications" are in view, then add the option to filter out reviewer
              {
                reviewer: 'Reviewer',
              }),
        }}
        dark
        defaultFilter='company'
      />

      <div className={styles.spacer} />

      <Breadcrumbs
        crumbs={[
            {
                label: <HomeIcon />,
                link: '/',
            },
            {
            link: `/programs-list/?managedBy=${facilitatorName}`,
            label: facilitatorName,
          },
          {
            label: programName,
            link: `/programs/${programId}/settings`,

          },
          {
            label: 'Applications',
          },
        ]}
      />
      <div className={styles.content}>
        <div className={styles.tableContainer}>
          <Table
            data={tableDataSource}
            columns={baseColumns}
            modes={rank >= 2 ? ['Assigned', 'All'] : undefined}
            onModeChange={rank >= 2 ? setMode : undefined}
            activeModeIndex={activeMode}
            addLabel={translate('admin.buttonExportApplications').toString()}
            onAdd={onExportApplications}
          />
        </div>
        <Pagination resultCount={dataSourceCount} />
      </div>
    </div>
  )
}

export default withLocalize(ApplicationListMain)
