import { ACTIONS } from '../actions/startupActions'
import { LOGOUT } from '../components/common/actions/authActions'
import { createReducer } from "./createReducer";
import { Action } from "redux";
import { Reducer, StartupState } from "./types";

const defaultState = {
  selectedStartup: undefined,
  selectedStartupLoading: false,
  memberStartups: {
    loading: false,
    selectedIndex: 0,
    startupProfiles: [],
  },
  ownerStartups: {
    loading: false,
    startupProfiles: [],
  },
}

const reducer: Reducer<StartupState, Action> = {
  [ACTIONS.LOADING_STARTUP_PROFILES]: (state, {payload}) => {
    return {...state, memberStartups: {...state.memberStartups, loading: payload}}
  },
  [ACTIONS.LOADING_OWNED_STARTUP_PROFILES]: (state, {payload}) => {
    return {...state, ownerStartups: {...state.ownerStartups, loading: payload}}
  },
  [ACTIONS.FETCH_STARTUP_PROFILES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      memberStartups: {...state.memberStartups, startupProfiles: payload},
    }
  },
  [ACTIONS.FETCH_OWNED_STARTUPS_PROFILES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      ownerStartups: {...state.ownerStartups, startupProfiles: payload},
    }
  },
  [ACTIONS.STARTUP_UPDATED]: (state, {payload}) => {
    const newMemberStartups = [...state.memberStartups.startupProfiles]
      .map((s: any) => s.id === payload.id ? payload : s)
    const newOwnerStartups = [...state.ownerStartups.startupProfiles]
      .map((s: any) => {
        return s.id === payload.id ? payload : s
      })
    return {
      ...state,
      selectedStartup: payload,
      memberStartups: {...state.memberStartups, startupProfiles: newMemberStartups},
      ownerStartups: {...state.ownerStartups, startupProfiles: newOwnerStartups},
    }
  },
  [ACTIONS.FETCHED_FULL_STARTUP_SUCCESS]: (state, {payload}) => ({...state, selectedStartup: payload}),
  [ACTIONS.LOADING_FULL_STARTUP_PROFILE]: (state, {payload}) => ({...state, selectedStartupLoading: payload}),
  [ACTIONS.CREATE_STARTUP]: (state, {payload}) => ({
    ...state,
    ownerStartups: {
      ...state.ownerStartups,
      startupProfiles: [...state.ownerStartups.startupProfiles, payload],
    },
    selectedStartup: payload,
  }),
  [ACTIONS.DROP_STARTUP]: (state, {payload}) => ({...state, droppedStartup: payload}),
  [ACTIONS.DROP_CHALLENGE]: (state, {payload}) => ({...state, droppedChallenge: payload}),
  [ACTIONS.CLEAR_DROPPED_ITEMS]: (state) => ({...state, droppedStartup: undefined, droppedChallenge: undefined}),
  [ACTIONS.CLEAR_DROPPED_STARTUP]: (state) => ({...state, droppedStartup: undefined}),
  '@@router/LOCATION_CHANGE': (state) => ({...state, droppedStartup: undefined, droppedChallenge: undefined}),
  [LOGOUT]: () => defaultState,
}

export default createReducer<StartupState, Action>(reducer, defaultState);