/**
 * Centralize form info and its mutation in this file
 * to avoid passing down callback across multiple levels in component hierachy
 * as well as to contain the form info within all files in this directory
 *
 * What this context does?
 *  - Hold the value of the application form
 *  - Provide mutation functions to alter the application value
 *  - Provide mutation functions to alter the Redux store
 *  - Abstract away the background call (from within the mutations) from the UI
 *
 * What this context does NOT do?
 *  - keeping track of the section: This is stored in redux currently
 *  - making API calls: The mutations function will call it, no API calls here.
 *
 * Problem:
 *      There are also FormEditors, which reuses components like FormPanel, SectionList and such...
 *      This prevents the components from using the context directly
 *
 * Proposed solution:
 *      - A separate context can be created in the parent of FormEditor (or similar ideas that make sense)
 *        and make the children take suitable context as param
 *      - Add a flag and mark the context to use different version of API calls
 */
import React from 'react'

// Redux-related imports
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../../reducers'

// type-related imports
import { IAnswer } from '../../../models/application/IAnswer'
import { IQuestionSection } from '../../../models/application/ISection'
import { IQuestion } from '../../../models/application/IQuestion'

// mutation imports
import { initializeApplicationForm } from './initialize'
import { computeCompletionPercent } from '../../../utils/computeCompletionPercent'
import {
  // raw mutators, need to feed in the additional form value to be fully functional
  handleFileUpdate,
  handleUpdateMultipleChoiceAnswer,
  handleUpdateSingleAnswer,
} from './updateAnswers'
import { selectOrganization } from 'components/common/actions/applicationActions'

import { syncAnswer as rawSyncAnswer } from '../utils/api'
import { throttle, debounce } from 'throttle-debounce'

// misc ( error reporting, utils, ...)
import { addErrorToast } from '../../../actions/toastActions'
import { withLocalize, LocalizeContextProps } from 'react-localize-redux'
import {
  getListOfQuestionsDisabled,
  isSectionCompleted as rawIsSectionCompleted,
} from '../../ApplicationPanel'
import { ApplicationMode } from 'components/common/models/application/IChallenge'

import { IChallenge } from 'models/challenge/challenge'
import useFIDAuth, { UseFIDAuthType } from "../../../utils/useFIDLogin";

// type representing all actual info of the application form
export type ApplicationFormState = {
  answers: IAnswer[]
  sections: IQuestionSection[]
  questions: IQuestion[]
  loading: boolean
  readOnly: boolean
  applicationMode?: ApplicationMode
}

// type representing the properties of the application itself
// such as its own application ID
export type ApplicationMetaState = {
  applicationId?: string
  initialized?: boolean
  answersSyncing: number[]
}
/**
 * A type describing an object that provides the application form
 * AND set of functions that mutates the form
 * AND set of computed properties
 */
export type ApplicationFormStateWithMutation = ApplicationFormState &
  ApplicationMetaState & {
    // form initialization
    initialize?: () => void

    // the challenge itself
    challenges?: { [id: string]: IChallenge }
    // mutations
    setLoading?: (loading: boolean) => void
    setReadOnly?: (isReadOnly: boolean) => void
    updateSingleAnswer?: (answerIndex: number, value: string) => void
    updateMultipleChoiceAnswer?: (answerIndex: number, value: number[]) => void
    updateFile?: (answerIndex: number, file?: File) => void

    // computed properties
    completionPercent?: number
    disabledQuestions?: number[]
    isSectionCompleted?: (section: number) => boolean
    downloadFile?: (answerIndex: number) => void
  }

const initialApplicationFormState: ApplicationFormState = {
  answers: [],
  sections: [],
  questions: [],
  loading: true,
  readOnly: false,
}

// create context for the application state
export const applicationStateContext = React.createContext<
  ApplicationFormStateWithMutation
>({
  ...initialApplicationFormState,
  answersSyncing: [],
})

// The raw provider to be used for the ApplicationFormProvider below
const { Provider } = applicationStateContext

/**
 * The actual provider that stores the state and offers mutations
 */
type Props = LocalizeContextProps & {
  children: React.ReactNode
}
export const ApplicationStateProvider = withLocalize(
  // This one below is a React component
  // therefore the withLocalize HOC above can be applied to this
  ({ translate, children }: Props) => {
    /****************************************************************
     * Redux related attributes
     ***************************************************************/
    const { user } = useSelector((state: RootState) => state)
    const { selectedOrganization, selectedChallenge } = useSelector(
      (state: RootState) => state.application,
    )
    const { challenges } = useSelector((state: RootState) => state.challenges)

    /****************************************************************
     * State of the context
     ***************************************************************/
    const [applicationForm, setApplicationForm] = React.useState(
      initialApplicationFormState,
    )
    const [applicationId, setApplicationId] = React.useState(
      undefined as string | undefined,
    )
    const [applicationMode, setApplicationMode] = React.useState<
      ApplicationMode | undefined
    >()
    const [initialized, setInitialized] = React.useState(false)
    const [answersSyncing, setAnswersSyncing] = React.useState<number[]>([])
    const { requestLogin, isLoggedInWithFID } = useFIDAuth() as UseFIDAuthType
    const isLoggedIn = useSelector((state: RootState) => state.auth.loggedIn)

    const dispatch = useDispatch()

    // This is used to keep track of active debounced syncs
    // each syncSingleAnswer call needs to be a single debounced function.
    const syncCallbacks: {
      [key: number]: throttle<(answer: string) => void>
    } = React.useMemo(() => {
      return {}
    }, [])

    // the calculated percentage value is stored here
    // but the existing functions will do the calcultations instead of being done in this context
    const [completionPercent, setCompletionPercent] = React.useState(0)

    /****************************************************************
     * Computed properties
     ***************************************************************/
    const disabledQuestions = getListOfQuestionsDisabled(
      applicationForm.questions,
      applicationForm.answers,
    )

    // the active section is store in redux and I have no intension to replace it here
    // instead this function only combines the given function with what this context has (the form)
    const isSectionCompleted = rawIsSectionCompleted(
      applicationForm.answers,
      applicationForm.questions,
    )

    /****************************************************************
     * Mutation methods
     ***************************************************************/
    const initialize = async () => {
      if (isLoggedInWithFID !== 'true' || !isLoggedIn) {
        return requestLogin();
      }

      if (!initialized) {
        try {
          const {
            applicationId,
            applicationMode: localMode,
            ...formData
          } = await initializeApplicationForm({
            translate,
            user,
            handleError: addErrorToast,
            selectedChallenge: selectedChallenge!,
            selectedOrganization: selectedOrganization!,
            computeCompletionPercent,
            setCompletionPercent,
          })
          // populate state once initializing form complete
          setApplicationForm((_) => formData)
          setApplicationId((_) => applicationId)
          setApplicationMode(localMode)
          setInitialized(true)
        } catch (e) {
          if (e?.response?.status === 409) {
            dispatch(
              addErrorToast(
                translate('visaApplication.applicationExists').toString(),
              ),
            )
            // redirect a user after a while
            setTimeout(
              () =>
                window.location.replace(
                  `${process.env.REACT_APP_PROGRAMS_URL}`,
                ),
              3000,
            )

            dispatch(selectOrganization(''))
          } else {
            dispatch(addErrorToast(e.message || 'Error occured'))
          }
        }
      }
    }

    /****************************************************************
     * Update answer handler
     ***************************************************************/

    // sync answer callback with debouncing
    const syncSingleAnswer = React.useCallback(
      (index: number) => {
        if (syncCallbacks[index]) return syncCallbacks[index]
        const newCallback = debounce(1000, (answer: string) => {
          if (applicationMode) {
            rawSyncAnswer({
              answer,
              applicationMode,
              applicationId: applicationId as string,
              answerIndex: index,
              handleError: addErrorToast,
              onSuccess: () => {
                setAnswersSyncing((prevAnswers) =>
                  prevAnswers.filter((answerIndex) => answerIndex !== index),
                )
                delete syncCallbacks[index]
              },
            })
          }
        })
        syncCallbacks[index] = newCallback
        return syncCallbacks[index]
      },
      [applicationId, applicationMode, syncCallbacks],
    )

    // common binding for all possible question mutators
    const bindingFormValue = {
      addErrorToast,
      translate,
      syncSingleAnswer,
      applicationMode,
      setAnswersSyncing,
      answersSyncing,
      applicationId: applicationId!,
      questions: applicationForm.questions,
      answers: applicationForm.answers,
      onAnswerUpdated: (newAnswers: IAnswer[]) =>
        setApplicationForm({ ...applicationForm, answers: newAnswers }),
    }

    // the 3 possible mutations for all input fields
    const updateSingleAnswer = handleUpdateSingleAnswer(bindingFormValue)
    const updateMultipleChoiceAnswer = handleUpdateMultipleChoiceAnswer(
      bindingFormValue,
    )
    const updateFile = handleFileUpdate(bindingFormValue)

    // simple value mutation
    const setLoading = (newState: boolean) =>
      setApplicationForm({ ...applicationForm, loading: newState })

    const setReadOnly = (newState: boolean) =>
      setApplicationForm({ ...applicationForm, readOnly: newState })

    /****************************************************************
     * Finally, return the children with context provider
     ***************************************************************/
    return (
      <Provider
        value={{
          // the form itself
          ...applicationForm,

          // attributes, meta and computed properties
          applicationId,
          initialize,
          completionPercent,
          disabledQuestions,
          isSectionCompleted,
          applicationMode,
          answersSyncing,
          challenges,

          // mutators
          setLoading,
          setReadOnly,
          updateSingleAnswer,
          updateMultipleChoiceAnswer,
          updateFile,
        }}
      >
        {children}
      </Provider>
    )
  },
)
