export const keyMap = ['<', '>', '===']

export const zones = [
  'UTC-12',
  'UTC-11',
  'UTC-10',
  'UTC-9',
  'UTC-8',
  'UTC-7',
  'UTC-6',
  'UTC-5',
  'UTC-4',
  'UTC-3:30',
  'UTC-3',
  'UTC-2:30',
  'UTC-2',
  'UTC-1',
  'UTC',
  'UTC+1',
  'UTC+2',
  'UTC+3',
  'UTC+3:30',
  'UTC+4',
  'UTC+4:30',
  'UTC+5',
  'UTC+5:45',
  'UTC+6',
  'UTC+6:30',
  'UTC+7',
  'UTC+8',
  'UTC+9',
  'UTC+9:30',
  'UTC+10',
  'UTC+10:30',
  'UTC+11',
  'UTC+11:30',
  'UTC+12',
  'UTC+12:45',
  'UTC+13',
  'UTC+13:45',
  'UTC+14',
]
