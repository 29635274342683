import { LOGOUT } from '../components/common/actions/authActions'
import {
  CLEAR_USER_OWNER_STARTUP,
  FETCH_PERSONAL_PROFILE_SUCCESS,
  FETCH_USER_MEMBER_STARTUPS_SUCCESS,
  FETCH_USER_OWNER_STARTUP_SUCCESS,
  LOADING_MEMBER_STARTUPS,
  LOADING_PERSONAL_PROFILE,
  USER_UPDATED,
} from '../components/common/actions/userActions'
import { createReducer } from "./createReducer";
import { Action } from "redux";
import { Reducer, UserProfileState } from "./types";

const defaultState: UserProfileState = {
  loading: false,
  user: null,
  memberStartups: [],
  ownerStartups: [],
}

const reducer: Reducer<UserProfileState, Action> = {
  [LOADING_PERSONAL_PROFILE]: (state, {payload}) => ({...state, loading: payload}),
  [FETCH_PERSONAL_PROFILE_SUCCESS]: (state, {payload}) => ({...state, user: payload}),
  [LOGOUT]: () => defaultState,
  [USER_UPDATED]: (state, {payload}) => ({...state, user: payload}),
  [LOADING_MEMBER_STARTUPS]: (state, {payload}) => ({...state, loading: payload}),
  [FETCH_USER_MEMBER_STARTUPS_SUCCESS]: (state, {payload}) => ({
    ...state,
    memberStartups: payload,
  }),
  [FETCH_USER_OWNER_STARTUP_SUCCESS]: (state, {payload}) => ({
    ...state,
    ownerStartups: payload,
  }),
  [CLEAR_USER_OWNER_STARTUP]: (state, {payload}) => ({
    ...state,
    ownerStartups: [],
  }),
}


export default createReducer<UserProfileState, Action>(reducer, defaultState);
