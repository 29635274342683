import ApiWrapperService from "../service/ApiWrapperService";
import { requestSuccess, ROUTES } from '../api'
import { IChallenge } from '../models/challenge/challenge'
import { IOnboardingTemplate } from '../models/onboarding/onboarding-template'
import { IStartupProfile } from '../models/startup/startup-profile'
import { IUserSummary } from '../models/user/user-summary'
import { handleError } from '../components/common/actions/errorHandlerActions'
import { Dispatch } from "redux";
import { RootState } from "../reducers";
import { AppThunk } from "../index";
import { get, set, omit } from 'lodash';
import FileDownload from "js-file-download";

// TODO: Investigate at dev how does it possible to make function works for objects L:74
const pullAt = require('lodash.pullat')

// *** ACTIONS ***
export const ACTIONS = {
  FETCH_ONBOARDING_TEMPLATE_SUCCESS: '@dff_challenges_action/fetch_onboarding_template_success',
  FETCH_ONBOARDING_SUCCESS: '@dff_challenges_action/fetch_onboarding_success',
}

export const fetchOnboardingTemplate = (challenge: IChallenge) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const response = await ApiWrapperService.get({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboardingTemplate`,
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not fetch onboarding template.')
    }
    dispatch({ type: ACTIONS.FETCH_ONBOARDING_TEMPLATE_SUCCESS, payload: response.data })
    return response.data
  } catch (e) {
    if (e.response.status !== 404) {
      await handleError(e)(dispatch, getState, false)
    }
  }
}

export const updateOnboardingTemplate = (
  onboardingTemplate: IOnboardingTemplate,
  field: string,
  value: string | null | any[],
  args: {
    patchField?: string
    deleteIndex?: number
  } = {
    patchField: undefined,
    deleteIndex: undefined,
  },
): AppThunk => async(dispatch, getState) => {
  try {
    const { patchField, deleteIndex } = args

    let data

    if (patchField) {
      let newOnboardingTemplate = onboardingTemplate
      if (value !== null) {
        newOnboardingTemplate = set(onboardingTemplate, field, value)
      }
      const patchData = get(newOnboardingTemplate, patchField)
      if (deleteIndex !== undefined) pullAt(patchData, deleteIndex)
      data = set({}, patchField, patchData)
    } else {
      if (value !== null) {
        data = set({}, field, value)
      } else {
        data = omit({}, field)
        if (deleteIndex !== undefined) pullAt(data, deleteIndex)
      }
    }

    const challengeId = onboardingTemplate.challenge
    if (!challengeId) {
      throw new Error('No challenge associated with onboarding template')
    }

    const response = await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challengeId}/onboardingTemplate`,
      body: data,
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not update questions for onboarding template.')
    }

    dispatch(fetchOnboardingTemplate({ id: challengeId }))
    return response.data
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const createOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  participants: IUserSummary[],
  goldenTicket: string,
): AppThunk => async(dispatch, getState) => {
  try {
    return ApiWrapperService
      .post({
        url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding`,
        body: {
          goldenTicket,
          startupId: startup.id,
          participants: participants.map((p) => p.id),
        },
        state: getState(),
      })
      .then((response) => {
        dispatch({ type: ACTIONS.FETCH_ONBOARDING_SUCCESS, payload: response.data })

      })
      .catch((error) => {
        if (error.response.status === 409) {
          dispatch(fetchOnboarding(challenge, startup))
        } else {
          throw new Error('Could not create a new onboarding.')
        }
      })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile | string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const startupId = typeof startup === 'string' ? startup : startup.id

    const response = await ApiWrapperService.get({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startupId}`,
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not fetch onboarding for startup.')
    }

    dispatch({ type: ACTIONS.FETCH_ONBOARDING_SUCCESS, payload: response.data })
    return response.data
  } catch (e) {
    // dispatch(addErrorToast(`${e.response.data.message}: ${e.response.data.errors}`));
  }
}

export const submitOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const response = await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/submit`,
      body: {},
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not submit onboarding for challenge.')
    }

    dispatch({ type: ACTIONS.FETCH_ONBOARDING_SUCCESS, payload: response.data })
    return response.data
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateAnswerInOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  index: number,
  answer: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const response = await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/answers/${index}`,
      body: {
        answer,
      },
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not update answer for onboarding question.')
    }

    return response.data
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateSelectedOptionInOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  index: number,
  selectedOptions: boolean[],
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const response = await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/answers/${index}`,
      body: {
        selectedOptions,
        answer: '',
      },
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Could not update answer for onboarding question.')
    }

    return response.data
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const addFileToAnswerInOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  index: number,
  file: File,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const formData = new FormData()
    formData.append('file', file)

    const response = await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/answers/${index}/files`,
      body: formData,
      state: getState(),
    })

    if (!requestSuccess(response)) {
      throw new Error('Failed to upload file for answer.')
    }

    await dispatch({ type: ACTIONS.FETCH_ONBOARDING_SUCCESS, payload: response.data })
    return response.data
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const downloadFileFromAnswerInOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  index: number,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    ApiWrapperService
      .get({
        url: `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/answers/${index}/files`,
        state: getState(),
        optionalConfig: {responseType: 'blob'}
      })
      .then((response) => {
        FileDownload(response.data, 'download.pdf')
      })
      .catch((error) => {
        throw new Error('Failed to download file from answer.')
      })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteFileFromAnswerInOnboarding = (
  challenge: IChallenge,
  startup: IStartupProfile,
  index: number,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const response = await ApiWrapperService.delete(
      `${ROUTES.CHALLENGES}/${challenge.id}/onboarding/${startup.id}/answers/${index}/files`,
      getState(),
    )

    if (!requestSuccess(response)) {
      throw new Error('Failed to delete file from answer.')
    }

    await dispatch({ type: ACTIONS.FETCH_ONBOARDING_SUCCESS, payload: response.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}
