import { api } from '../../../../../api'
import { History } from '../model'

export const getApplicationHistory = async (applicationId: string) => {
  try {
    const { data } = await api.get(`/v3/applications/${applicationId}/history`)
    return data as History[]
  } catch (e) {
    console.error(e)
    return [] as History[]
  }
}
