import { IApiAnswer } from '../../../../models/application/IAnswer'
import { IFullKeyQuestion } from '../../../../models/application/IQuestion'
import { IOrganization } from '../../../../models/application/IOrganization'
import { IOption } from '../../../../models/application/IOption'
import { UserState } from '../../../../utils/state'
import countries from './countries.json'
import coreBusiness from './corebusiness.json'
import education from './education.json'
import funding from './funding.json'
import marketStage from './marketstage.json'
import { uploadFileToBackend } from '../api'
import axios from 'axios'
import mime from '../../../../utils/mime-lookup'

interface IListObject {
  code: string
  name: string
}

export const findAndSelectOption = (
  selectedOption: string,
  questionOptions: IOption[],
  fidOptions: IListObject[],
  answer: IApiAnswer,
): IApiAnswer => {
  const selectedFidOption = fidOptions.find(option => option.code && selectedOption && option.code.toLowerCase() === selectedOption.toLowerCase())
  if (selectedFidOption) {
    const fidText = selectedFidOption.name
    const optionIndex = questionOptions.findIndex(option => option.option.toLowerCase() === fidText.toLowerCase())

    if (optionIndex >= 0) {
      return {
        ...answer,
        selectedOptions: [optionIndex],
      }
    }
  }
  return answer
}

type userKey =
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'displayName'
  | 'location'
  | 'headline'
  | 'description'
  | 'dob'
  | 'nationality'
  | 'highestEducation'
  | 'gender'
  | 'profilePicture'
  | 'linkedInProfileUrl'
  | 'mobileNumber'
  | 'skills'
  | 'jobTitles'

const getProfilePicture = async (url: string) => {
  if (url) {
    const { data, request } = await axios.get(url, {
      responseType: 'blob',
    })
    const contentType = request.getResponseHeader('Content-Type')
    const fileName = `profilePicture.${mime.extension(contentType)}`
    return {
      fileName,
      blob: data,
    }
  }
}

const prefillUserData = async (
  userKey: userKey,
  question: IFullKeyQuestion,
  answer: IApiAnswer,
  userData: UserState,
  answerIndex: number,
  applicationId: string,
  handleError: (message: string) => void,
): Promise<IApiAnswer> => {
  const multipleChoiceOptions = {
    nationality: countries,
    highestEducation: education,
  }

  if (userKey === 'gender') {
    if (userData.gender !== undefined) {
      return {
        ...answer,
        selectedOptions: [userData.gender === 'm' ? 0 : 1],
      }
    }
    return answer
  } else if (userKey === 'profilePicture') {
    if (userData.profilePicture) {
      const data = await getProfilePicture(userData.profilePicture)
      if (data) {
        // eslint-disable-next-line
        const file = new File([data.blob], data.fileName, {
          type: data.blob.type,
        })
        try {
          await uploadFileToBackend({
            answerIndex,
            file,
            applicationId,
            onUploadProgress: () => {},
            onError: () => {},
          })
          return { answer: userData.profilePicture }
        } catch (error) {
          handleError(error)
          return { answer: '' }
        }
      }
    }
    return { answer: '' }
  } else if (userKey === 'jobTitles') {
    if (userData.jobTitles.length >= 1 && userData.jobTitles[0]) {
      // @ts-ignore the above should prevent it being null
      return { answer: userData.jobTitles[0] }
    }
  } else if (userKey === 'skills') {
    return {
      answer: userData.skills.join(', '),
    }
  } else if (Object.keys(multipleChoiceOptions).includes(userKey)) {
    return findAndSelectOption(
      // @ts-ignore I believe the above condition does make this safe
      userData[userKey],
      question.options,
      // @ts-ignore I believe the above condition does make this safe
      multipleChoiceOptions[userKey],
      answer,
    )
  }

  if (userData[userKey]) {
    return {
      // @ts-ignore the above should prevent it being null
      answer: userData[userKey],
    }
  }
  return answer
}

type orgKey =
  | 'name'
  | 'briefDescription'
  | 'website'
  | 'location'
  | 'incorporateDate'
  | 'coreBusiness'
  | 'fullDescription'
  | 'fullTimeRange'
  | 'financeType'
  | 'stageProduct'
  | 'logo'
  | 'technologies'
  | 'linkedIn'
  | 'twitter'
  | 'facebook'
  | 'members'

const socialMediaFields = ['linkedIn', 'twitter', 'facebook']

const prefillOrgData = async (
  orgKey: orgKey,
  question: IFullKeyQuestion,
  answer: IApiAnswer,
  orgData: IOrganization,
  answerIndex: number,
  applicationId: string,
  handleError: (message: string) => void,
): Promise<IApiAnswer> => {
  const multipleChoiceOptions = {
    coreBusiness,
    stageProduct: marketStage,
    financeType: funding,
  }

  if (Object.keys(multipleChoiceOptions).includes(orgKey)) {
    return findAndSelectOption(
      // @ts-ignore I believe the above condition does make this safe
      orgData[orgKey],
      question.options!,
      // @ts-ignore I believe the above condition does make this safe
      multipleChoiceOptions[orgKey],
      answer,
    )
  } else if (orgKey === 'logo') {
    if (orgData.logo) {
      const data = await getProfilePicture(orgData.logo)
      if (data) {
        // eslint-disable-next-line
        const file = new File([data.blob], data.fileName, {
          type: data.blob.type,
        })
        try {
          await uploadFileToBackend({
            answerIndex,
            file,
            applicationId,
            onError: () => {},
            onUploadProgress: () => {},
          })
          return { answer: orgData.logo }
        } catch (error) {
          handleError(error)
          return { answer: '' }
        }
      }
      return { answer: '' }
    }
  } else if (orgKey === 'technologies') {
    return {
      answer: orgData.technologies ? orgData.technologies.join(', ') : '',
    }
  } else if (orgKey === 'members') {
    return {
      answer: [...orgData.owners, ...orgData.members]
        .map((user) => user.displayName)
        .join(', '),
    }
  } else if (socialMediaFields.includes(orgKey)) {
    return {
      // @ts-ignore these fields have been captured by the array
      answer: orgData.socialMedia ? orgData.socialMedia[orgKey] : '',
    }
    // @ts-ignore the socia media fields have been captured by the above
  } else if (orgData[orgKey]) {
    return {
      // @ts-ignore the socia media fields have been captured by the above
      answer: orgData[orgKey],
    }
  }
  return answer
}

export const prefillWithFidData = async (
  answers: IApiAnswer[],
  questions: IFullKeyQuestion[],
  userData: UserState,
  orgData: IOrganization | undefined,
  applicationId: string,
  handleError: (message: string) => void,
): Promise<IApiAnswer[]> => {
  const promises = answers.map(async (answer, index) => {
    const question = questions[index]
    const thisQuestionKey = question.apiKey

    if (thisQuestionKey && thisQuestionKey.keyName.includes('.')) {
      const splitKey = thisQuestionKey.keyName.split('.')
      if (splitKey[0] === 'user') {
        return prefillUserData(
          splitKey[1] as userKey,
          question,
          answer,
          userData,
          index,
          applicationId,
          handleError,
        )
      } else if (orgData && splitKey[0] === 'company') {
        return prefillOrgData(
          splitKey[1] as orgKey,
          question,
          answer,
          orgData,
          index,
          applicationId,
          handleError,
        )
      }
    }
    return answer
  })

  return Promise.all(promises)
}
