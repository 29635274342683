import { RootState } from "../../reducers";
import { connect } from 'react-redux'
import {
  removeErrorToast,
  removeSuccessToast,
} from '../common/actions/toastActions'
import Toasts from '../common/Toasts/Toasts'

const mapStateToProps = (state: RootState) => ({
  successToasts: state.toasts.successToasts,
  errorToasts: state.toasts.errorToasts,
})

export default connect(
  mapStateToProps,
  { removeSuccessToast, removeErrorToast },
)(Toasts)
