import { fromEvent } from 'rxjs'

export const keyStream = fromEvent(document, 'keydown')
export const clickStream = fromEvent(document, 'click')
export const scrollStream = fromEvent(document, 'scroll')
export const createScrollStream = (elementId: string) => {
  const element = document.getElementById(elementId)
  const anyEl = element as any
  return fromEvent(anyEl, 'scroll')
}
