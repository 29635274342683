import React from 'react'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import DatePicker from 'react-datepicker'
import styles from './TextInput.module.scss'
import InputMask from 'react-input-mask'
import RichTextInput from './RichTextInput'
import InputTag from './InputTag'
import { TextInputProps } from "./types";

const TextInputField = (props: TextInputProps) => {
  const {
    value,
    disabled,
    onChange,
    maxLength,
    type,
    onBlur,
    readOnly,
    min,
    max,
    placeholder,
    activeLanguage,
    autocomplete,
    required,
    isValid,
    id,
  } = props

  const handleTextBlur = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const value = event.currentTarget.value
    if (onBlur) {
      onBlur(value)
    }
  }

  const a11yProps = {
    required: required ? true : undefined,
    'aria-invalid': !isValid ? true : undefined,
  }
  switch (type) {
    case 'rich-text-area':
      return <RichTextInput {...props} />
    case 'input-tag':
      return <InputTag {...props} />
    case 'text-area':
      return (
        <textarea
          {...a11yProps}
          id={id}
          value={value}
          onChange={onChange && ((event) => onChange(event.target.value))}
          className={classNames(styles.textArea, styles.textish, {
            [styles.readOnly]: readOnly,
            [styles.invalid]: !isValid,
          })}
          maxLength={maxLength}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          onBlur={handleTextBlur}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
      )
    case 'date':
      const valueAsDateTime =
        value === '' ? null : DateTime.fromISO(value as string)
      const valueAsDate =
        valueAsDateTime && valueAsDateTime.isValid
          ? valueAsDateTime.toJSDate()
          : null
      const handleDateChange = (date: Date | null) => {
        if (onChange) {
          if (date === null) {
            onChange('')
            if (onBlur) {
              onBlur('')
            }
          } else {
            const dateAsDateTime = DateTime.fromJSDate(date)
            const dateAsIso = dateAsDateTime.toISODate()
            onChange(dateAsIso)
            if (onBlur) {
              onBlur(dateAsIso)
            }
          }
        }
      }
      return (
        <DatePicker
          id={id}
          selected={valueAsDate}
          onChange={handleDateChange}
          className={classNames(styles.date, styles.textish, {
            [styles.readOnly]: readOnly,
            [styles.invalid]: !isValid,
          })}
          placeholderText='DD/MM/YYYY'
          calendarClassName={styles.datePicker}
          showYearDropdown
          dropdownMode='select'
          peekNextMonth
          showMonthDropdown
          locale={activeLanguage.code}
          dateFormat='dd/MM/yyyy'
          isClearable
          customInput={<InputMask mask='99/99/9999' />}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          autoComplete={autocomplete}
        />
      )
    default:
      return (
        <input
          {...a11yProps}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange && ((event) => onChange(event.target.value))}
          className={classNames(styles[type], styles.textish, {
            [styles.readOnly]: readOnly,
            [styles.invalid]: !isValid,
          })}
          maxLength={maxLength}
          disabled={disabled || readOnly}
          readOnly={readOnly}
          onBlur={handleTextBlur}
          type={type}
          min={min}
          max={max}
          autoComplete={autocomplete}
        />
      )
  }
}

export default TextInputField
