import React from 'react'
import styles from './ActionBar.module.scss'
import classNames from 'classnames'
import { IProps } from "./types";

const ActionBar = ({ children, flexEnd }: IProps) => {
  return (
    <div className={(styles.actionBar)}>
      <div
        className={classNames(styles.actionBarContainer, {
          [styles.flexEnd]: flexEnd,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default ActionBar
