import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Translate, withLocalize } from 'react-localize-redux'
import styles from './ProgramDetailsForm.module.scss'
import Form from '../Form/Form'
import TextInput from '../FormInput/TextInput'
import DateTimePicker from '../FormInput/DateTimePicker'
import { IChallenge, rejectionModeOptions } from '../../../models/challenge/challenge'
import { applyTimeZone, formatChallengeDate } from './utils'
import detailStyles from '../../Challenges/DetailPage.module.scss'
import Dropdown from '../FormInput/Dropdown'
import WrappedInput from '../FormInput/WrappedInput'
import ProgramDetailsFooter from './ProgramDetailsFooter'
import { api } from '../../../api'
import ImageUploadWithPreview from '../ImageUploadWithPreview/ImageUploadWithPreview'
import ViewChallenge from '../Challenge/ViewChallenge'
import { retrieveQuestionsSectionsFromAPI } from '../Application/FormEditor/utils/api'
import { addErrorToast } from '../actions/toastActions'
import { useDispatch, useSelector } from 'react-redux'
import './ProgramDetailsForm.scss'
import { RootState } from '../../../reducers'
import { DateTime } from 'luxon'
import { finishApplicationProcess } from '../../../actions/programOwnerActions'
import ConfirmActionModal from '../../Modal/ConfirmActionModal'
import Button from '../shared/Button'
import classNames from 'classnames'
import { ProgramDetailsFormProps } from "./types";

const availableModes = ['FID_INDIVIDUAL', 'FID_ORGANISATION', 'E-MAIL']

const ProgramDetailsForm = ({
  translate,
  challenge,
  user,
  isEditMode,
  setIsEditMode,
  onSaveForm,
}: ProgramDetailsFormProps) => {
  const [data, setData] = useState<any>()
  const [applicationCount, setApplicationCount] = useState<number>()
  const [files, setFiles] = useState<any>({})
  const [filesToDelete, setFilesToDelete] = useState<any>([])
  const [coverImage, setCoverImage] = useState<any>([])
  const [isFinish, setIsFinish] = useState(false)
  const dispatch = useDispatch()
  const review = useSelector((state: RootState) => state.programOwner.review)
  useEffect(() => {
    if (challenge) {
      setData(challenge as IChallenge)
      api
        .get(
          `v3/users/${user.userId}/applications/?limit=1&page=1&onlySubmitted=false&programId=${challenge?.id}`,
        )
        .then((res: any) => {
          setApplicationCount(res.data.count)
        })
    }
  }, [challenge, user.userId])

  const toggleEditMode = () => setIsEditMode(!isEditMode)

  const onFieldChange = (field: string) => (newValue: any) => {
    retrieveQuestionsSectionsFromAPI(data.id).then((template) => {
      const { createdAt, updatedAt } = template
      if (createdAt !== updatedAt && newValue === 1) {
        dispatch(
          addErrorToast(
            translate('programSettings.error.unpublishedChanges').toString(),
          ),
        )
      }
    })
    const newData = { ...data, [field]: newValue }
    setData(newData)
  }

  const onUpdateImage = (field: string, file: File) => {
    setFiles({ ...files, [field]: file })
  }

  const handleFinishProcess = async() => {
    await dispatch(finishApplicationProcess(data.id))
    cancelFinish()
  }

  const finishProcess = () => setIsFinish(true)
  const cancelFinish = () => setIsFinish(false)

  const onSave = () => {
    setFiles({})
    setFilesToDelete([])
    onSaveForm()
  }

  if (!data) {
    return null
  }

  const previewPortal = document.getElementById('previewChallengePortal')
  const handleDeadlineChange = (newValue: string) => {
    const zone = DateTime.fromISO(newValue, { setZone: true }).zoneName
    setData({
      ...data,
      applicationDeadline: newValue,
      applicationDeadlineTZ: zone,
    })
  }

  const applyUrl = `${document.location.origin}/programs/${challenge?.id}/${
    challenge?.applicationMode === 'FID_ORGANISATION' 
    ? 'selectOrganisation' : 'application'}`

  return (
    <>
      <Form className={styles.root} outerClassName={styles.rootContainer}>
        <div className={styles.container}>
          <div className={styles.section}>
            <div className={styles.field}>
              <TextInput
                id='title'
                showValidation={false}
                label={translate('programSettings.title').toString()}
                value={data.name || ''}
                onChange={onFieldChange('name')}
                type='text'
                required
              />
            </div>
            <div className={styles.images}>
              <WrappedInput
                id='cover-image'
                label={translate('programSettings.previewImage').toString()}
              >
                <ImageUploadWithPreview
                  id='cover-image'
                  images={coverImage}
                  setImages={setCoverImage}
                  className={styles.imageUploader}
                  links={data.coverImage}
                  onLoad={(file: File) => {
                    onUpdateImage('coverImage', file)
                  }}
                />
              </WrappedInput>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.field}>
              <TextInput
                id='program-link'
                showValidation={false}
                label={translate('programSettings.url').toString()}
                value={applyUrl}
                readOnly
                type='text'
              />
            </div>
            <div className={styles.field}>
              <DateTimePicker
                id='deadline'
                showValidation={false}
                label={translate(
                  'programSettings.applicationDeadline',
                ).toString()}
                value={applyTimeZone(data.applicationDeadline, data.applicationDeadlineTZ)}
                onChange={handleDeadlineChange}
                withTime
                withTimezone
              />
            </div>
            <div className={styles.field}>
              <Dropdown
                id='application-mode'
                className={detailStyles.applicationMode}
                readOnly={data.isPublic || applicationCount !== 0}
                value={availableModes.indexOf(data.applicationMode || '')}
                options={[
                  translate('admin.applicationMode.individual').toString(),
                  translate('admin.applicationMode.organisation').toString(),
                  translate('admin.applicationMode.email').toString(),
                ]}
                label={translate('admin.applicationMode.label').toString()}
                onChange={(newMode) =>
                  onFieldChange('applicationMode')(
                    availableModes[newMode as number],
                )}
                showValidation={false}
              />
            </div>
            <div className={classNames(styles.field, styles.rejectionLine)}>
              <Dropdown
                id='rejection-mode'
                readOnly
                showValidation={false}
                label={translate('admin.rejectionMode').toString()}
                value={rejectionModeOptions.findIndex(mode => mode.value === challenge!.rejectionMode)}
                options={
                  rejectionModeOptions.map(mode => mode.label)
                }
                onChange={() => {}}
                className={styles.rejectionModeDisplay}
              />
              {challenge!.rejectionMode === 'onChallenge' && (
                <Button
                  className={classNames(styles.actionButton, styles.rejectionSendButton)}
                  type='primary'
                  onClick={finishProcess}
                  disabled={review?.applicationProcessFinished || !data.isPublic}
                >
                  <Translate id='programSettings.finishApplicationProcess' />
                </Button>
              )}
            </div>
            <div className={styles.field}>
              <Dropdown
                id='status'
                className={detailStyles.applicationMode}
                value={data.isPublic ? 1 : 0}
                options={[
                  translate('admin.isPrivate').toString(),
                  translate('admin.isPublic').toString(),
                ]}
                label={translate('programSettings.visibility').toString()}
                onChange={onFieldChange('isPublic')}
                showValidation={false}
              />
              {challenge?.isPublic && challenge?.publishedAt ? (
                <span className={styles.infoLabel}>
                  {translate('programSettings.publishedOn')}{' '}
                  {formatChallengeDate(challenge.publishedAt)}
                </span>
              ) : (
                ''
              )}
              {review?.applicationProcessFinished ? (
                <span className={styles.infoLabel}>
                  {translate('programSettings.applicationProcessFinished')}
                </span>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Form>
      {previewPortal &&
        !isEditMode &&
        ReactDOM.createPortal(
          <ViewChallenge
            challengeProp={data}
            supportImages={[]}
            previewCoverImage={coverImage?.[0] || undefined}
            paddingBottom={30}
          />,
          previewPortal,
        )}
      <ProgramDetailsFooter
        preventUpdate={!data.name?.length}
        isEditMode={isEditMode}
        toggleEditMode={toggleEditMode}
        data={data}
        files={files}
        filesToDelete={filesToDelete}
        onSave={onSave}
        review={review}
      />
      {isFinish && (
        <ConfirmActionModal
          title={<Translate id='admin.finishApplicationProcessConfirmation' />}
          confirmText={<Translate id='finish' />}
          cancelText={<Translate id='cancel' />}
          close={cancelFinish}
          confirmAction={handleFinishProcess}
        />
      )}
    </>
  )
}

export default withLocalize(ProgramDetailsForm)
