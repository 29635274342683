import React, { useState } from 'react'
import { ITabProps } from "./types";
import { faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import styles from "./AppTabBar.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Tab = ({
    active,
    label,
    value,
    maxValue,
    setIndex,
    index,
    showProgressBar,
    onDelete,
    showActiveTabIndicator,
  }: ITabProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const onClick = () => {
    setIndex(index)
  }

  const getBarWidth = () => {
    if (value === undefined || maxValue === undefined) {
      return 0
    }
    return Math.round((value / maxValue) * 100)
  }

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    setShowTooltip(true)
  }

  const handleDeleteReject = () => {
    setShowTooltip(false)
  }

  const handleDeleteApprove = () => {
    if (onDelete) {
      onDelete()
    }
    setShowTooltip(false)
  }

  return (
    <Translate>
      {({translate}) => (
        <div
          className={classNames(styles.tab, {[styles.tabActive]: active})}
          onClick={onClick}
        >
          {onDelete && (
            <div onClick={handleDeleteClick} className={styles.deleteButton}>
              <FontAwesomeIcon icon={faTimes}/>
              {showTooltip && (
                <NotificationModal
                  onAccept={handleDeleteApprove}
                  onCancel={handleDeleteReject}
                >
                  <Translate id='gate.deleteTooltipDescription'/>
                </NotificationModal>
              )}
            </div>
          )}
          <div className={styles.tabBlock}>
            <h3 className={styles.tabLabel}>{label}</h3>
            {showProgressBar && <h4 className={styles.tabValue}>{value}</h4>}
            {showProgressBar && (
              <div className={styles.tabBar}>
                {getBarWidth() > 0 && (
                  <div
                    className={styles.tabBarProgress}
                    style={{width: `${getBarWidth()}%`}}
                  />
                )}
              </div>
            )}
          </div>
          <div
            className={classNames(styles.connectionBlock, {
              [styles.activeBlock]: active,
            })}
          >
            {
              showActiveTabIndicator &&
              active &&
                <FontAwesomeIcon icon={faChevronUp}/>
            }
          </div>
        </div>
      )}
    </Translate>
  )
}
