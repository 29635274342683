import { AppConfig } from './ConfigService'

export const fidLogin = async(token: string): Promise<any> => {
  const url = `${AppConfig.backendAuth}/fid-login `
  return window.fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': token,
    },
    body: JSON.stringify({
      sessionToken: token,
    }),
  })
}
