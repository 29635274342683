/**
 * list of functions contributing the following relation:
 *  (existingAnswersList, newAnswer) => (newAnswerList)
 */
import { throttle } from 'throttle-debounce'
import { IQuestion } from '../../../models/application/IQuestion'
import { IAnswer, isAnswered } from '../../../models/application/IAnswer'
import {
  syncAnswer,
  uploadFileToBackend,
  deleteFileFromBackend,
} from '../utils/api'
import { getListOfQuestionsDisabled } from '../../ApplicationPanel'

import { TranslateFunction } from 'react-localize-redux'
import { ApplicationMode } from 'components/common/models/application/IChallenge'

/**
 * Parameters passed to the mutations that comes with the application form itself.
 * The mutation should have no access to these info,
 * therefore they have to consume values of this type to create another function that mutates the form
 */
type FormValueType = {
  applicationId: string
  applicationMode?: ApplicationMode
  questions: IQuestion[]
  answers: IAnswer[]
  onAnswerUpdated: (newAnswers: IAnswer[]) => void
  addErrorToast: (message: string) => void
  translate: TranslateFunction
  answersSyncing: number[]
  setAnswersSyncing: (newArray: number[]) => void

  // acquire syncSingleAnswer from within contexts to prevent reseting debouncing
  syncSingleAnswer: (index: number) => throttle<(answer: string) => void>
}

/**
 * Generic update operations covering single questions, multiple-choice questions and file update questions
 */
export type UpdateAnswerProps = AnswerProps & {
  newSelectedOptions?: number[]
  newUploadProgress?: number
  sync?: boolean
}

/**
 * Common combo for the mutators, therefore it's grouped as a new type.
 */
export type AnswerProps = {
  answerIndex: number
  newAnswer: string
}

export const updateAnswer = (
  // existing state
  {
    applicationId,
    applicationMode,
    addErrorToast,
    questions,
    answers,
    onAnswerUpdated,
    syncSingleAnswer,
    setAnswersSyncing,
    answersSyncing,
  }: FormValueType,
  // incoming changes
  {
    answerIndex,
    newAnswer,
    newSelectedOptions,
    newUploadProgress,
    sync,
  }: UpdateAnswerProps,
) => {
  /**
   * Map through each question,
   * if the question does not have an index matching the given answerIndex, return as-is
   * Otherwise retu
   */
  const answersAfterThisChange = answers.map((stateAnswer, index) => {
    if (index === answerIndex) {
      return {
        ...stateAnswer,
        answer: newAnswer,
        selectedOptions: newSelectedOptions,
        uploadProgress: newUploadProgress,
      }
    }
    return stateAnswer
  })
  const {
    updatedAnswers: clearedBlanks,
    changedIndices: blankedIndices,
  } = blankDisabledQuestions(questions, answersAfterThisChange)

  onAnswerUpdated(clearedBlanks)

  /****************************************************************
   * Sync answer
   ***************************************************************/

  if (sync) {
    setAnswersSyncing([
      ...answersSyncing.filter(present => answerIndex !== present),
      answerIndex,
    ])
    if (newSelectedOptions) {
      syncAnswer({
        answerIndex,
        applicationMode,
        applicationId: applicationId as string,
        answer: '',
        handleError: addErrorToast,
        selectedOptions: newSelectedOptions,
        onSuccess: () => {
          setAnswersSyncing(answersSyncing.filter(thisAnswer => thisAnswer !== answerIndex))
        },
      })
      blankedIndices.forEach((index) => {
        setAnswersSyncing([
          ...answersSyncing.filter(present => index !== present),
          answerIndex,
        ])
        syncAnswer({
          applicationMode,
          answerIndex: index,
          applicationId: applicationId as string,
          answer: '',
          handleError: addErrorToast,
          selectedOptions: [],
          onSuccess: () => {
            setAnswersSyncing(answersSyncing.filter(thisAnswer => thisAnswer !== index))
          },
        })
      })
    } else {
      const answer = clearedBlanks[answerIndex].answer || ''
      syncSingleAnswer(answerIndex)(answer)
    }
  }
}

const blankDisabledQuestions = (questions: IQuestion[], answers: IAnswer[]) => {
  const disabledAnswers = getListOfQuestionsDisabled(questions, answers)
  const changedIndices: number[] = []
  const updatedAnswers = answers.map((previousAnswer, index) => {
    if (disabledAnswers.includes(index) && isAnswered(previousAnswer)) {
      changedIndices.push(index)
      return {
        ...previousAnswer,
        answer: '',
        selectedOptions: [],
      }
    }
    return previousAnswer
  })
  return {
    updatedAnswers,
    changedIndices,
  }
}

export const handleFileUpdate = (form: FormValueType) => async (
  answerIndex: number,
  file?: File,
) => {
  const { applicationId, applicationMode, addErrorToast, translate } = form

  if (file) {
    try {
      const answer = await uploadFileToBackend({
        answerIndex,
        file,
        applicationId,
        applicationMode,
        onUploadProgress: (progressEvent: ProgressEvent) => {
        updateAnswer(form, {
          answerIndex,
          newUploadProgress: progressEvent.loaded / progressEvent.total,
          newAnswer: '',
          })
        },
        onError: () =>
          updateAnswer(form, {
            answerIndex,
            newAnswer: '',
            newUploadProgress: 0,
          }),
      })
      updateAnswer(form, {
        answerIndex,
        newAnswer: answer,
      })
    } catch (error) {
      updateAnswer(form, {
        answerIndex,
        newAnswer: '',
        newUploadProgress: 0,
      })
      addErrorToast(translate('visaApplication.fileUploadFailed') as string)
    }
  } else {
    try {
      await deleteFileFromBackend({
        answerIndex,
        applicationId,
        applicationMode,
      })
      updateAnswer(form, {
        answerIndex,
        newUploadProgress: 0,
        newAnswer: '',
      })
    } catch (error) {
      addErrorToast(translate('visaApplication.fileDeleteFailed') as string)
    }
  }
}

export const handleUpdateMultipleChoiceAnswer = (form: FormValueType) => (
  answerIndex: number,
  value: number[],
): void => {
  updateAnswer(form, {
    answerIndex,
    newAnswer: '',
    newSelectedOptions: value,
    sync: true,
  })
}

export const handleUpdateSingleAnswer = (form: FormValueType) => (
  answerIndex: number,
  value: string,
): void => {
  updateAnswer(form, {
    answerIndex,
    newAnswer: value,
    sync: true,
  })
}
