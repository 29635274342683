import React, { useState } from 'react'
import styles from './AppHeader.module.scss'
import MenuLinks from './MenuLinks'
import HeaderContainer from './HeaderContainer'
import HeaderLogo from './HeaderLogo'
import { MenuTriggerButton, ProfileButton, ReachUsButton, ThemeButton } from './HeaderButtons'
import { useHistory } from 'react-router'
import { useScrollPosition } from '../utils/useScrollPosition'
import classNames from 'classnames'
import { withLocalize } from 'react-localize-redux'
import HeaderNav from './HeaderNav'
import { AppHeaderProps } from "./types";

const AppHeader = ({ links }: AppHeaderProps) => {
  const { y } = useScrollPosition()
  const isScrolled = y > 0
  const history = useHistory()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [profileActive, setProfileActive] = useState(false)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const handleLogout = () => {
    history.push('/logout')
  }

  const handleProfileClick = () => {
    setProfileActive(!profileActive)
  }

  return (
    <>
      <div
        className={classNames(styles.drawerBackground, {
          [styles.open]: isMenuOpen,
        })}
      />
      <HeaderContainer isNavbarScrolledDown={isScrolled}>
        <>
          <MenuTriggerButton isOpen={isMenuOpen} onClick={toggleMenu} />
          <div
            className={classNames(styles.modeButtons, {
              [styles.open]: isMenuOpen,
            })}
          >
            <ProfileButton
              onClick={handleProfileClick}
              isForced={profileActive}
            >
              <MenuLinks onLogout={handleLogout} />
            </ProfileButton>
            <ThemeButton />
          </div>
          <HeaderLogo isOpen={isMenuOpen} isNavbarScrolledDown={isScrolled} />

          <HeaderNav links={links} />
          <div className={styles.menu}>
            <ReachUsButton />
            <ProfileButton>
              <MenuLinks onLogout={handleLogout} />
            </ProfileButton>
            <ThemeButton />
          </div>
          <div
            className={classNames(styles.linksContainer, {
              [styles.scrolledDown]: isScrolled,
              [styles.open]: isMenuOpen,
            })}
          >
            <HeaderNav links={links} isInDrawer />
            <ReachUsButton />
          </div>
        </>
      </HeaderContainer>
    </>
  )
}

export default React.memo(withLocalize(AppHeader))
