import { push } from 'connected-react-router'
import { api, requestSuccess, ROUTES } from '../api'
import { addSuccessToast } from '../components/common/actions/toastActions'
import { getReviewedApplications } from './programManagerActions'
import { handleError } from '../components/common/actions/errorHandlerActions'
import { translate } from 'utils/translations'
import { fetchAllChallenges } from 'components/common/actions/challengesActions'
import { fetchUserChallenges } from 'components/common/actions/userActions'
import { AnyAction, Dispatch } from "redux";
import { RootState } from "../reducers";
import { AppThunk } from "../index";
import ApiWrapperService from "../service/ApiWrapperService";

// *** ACTIONS ***
export const ACTIONS = {
  LOADING_PROGRAM_OWNER_PROFILES: '@dff_challenges_action/loading_program_owner_profile',
  LOADING_PROGRAM_OWNER_PROFILES_SUCCESS: '@dff_challenges_action/loading_program_owner_profile_success',
  PROGRAM_OWNER_PROFILE_UPDATED: '@dff_challenges_action/program_owner_profile_updated',
  PROGRAM_OWNER_PROFILE_DELETED: '@dff_challenges_action/program_owner_profile_deleted',
  PROGRAM_CREATED: '@dff_challenges_action/program_created',
  LOADING_REVIEW: '@dff_challenges_action/loading_review',
  LOADING_REVIEW_SUCCESS: '@dff_challenges_action/loading_review_success',
  CLEAR_REVIEW: '@dff_challenges_action/clear_review',
  STAGE_CREATED: '@dff_challenges_action/stage_created',
  STAGE_UPDATED: '@dff_challenges_action/stage_updated',
  STAGE_DELETED: '@dff_challenges_action/stage_deleted',
  CHALLENGE_CREATED: '@dff_challenges_action/challenge_created',
  CHALLENGE_DELETED: '@dff_challenges_action/challenge_deleted',
  LOADING_PROGRAM_MANAGERS: '@dff_challenges_action/loading_program_managers',
  LOADED_PROGRAM_MANAGERS: '@dff_challenges_action/loaded_program_managers',
  SET_ACTIVE_STAGE: '@dff_challenges_action/active_stage_was_set',
  CLEAR_ACTIVE_STAGE: '@dff_challenges_action/clear_active_stage',
  SET_ACTIVE_PO_PROFILE: '@dff_challenges_action/set_active_po_profile',
  CHALLENGE_UPDATED: '@dff_challenges_action/po_challenge_updated',
}

// *** ASYNC ACTION CREATORS ***
export const fetchOwnedProgramOwnerProfiles = (
  programId: any,
  challengeId: any,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: true })
    const res = await ApiWrapperService.get({
      url: `${ROUTES.USERS}/${getState().user.userId}/programOwners/owner`,
      state: getState(),
  })
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch owned program owner profiles')
    }
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: false })
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES_SUCCESS, payload: res.data })

    if (res.data?.length) {
      if (!programId) {
        dispatch({ type: ACTIONS.SET_ACTIVE_PO_PROFILE, payload: res.data[0] })
        if (res?.data?.[0]?.challenges?.[0]) {
          fetchReview(res.data[0].challenges[0].id)(dispatch, getState)
          getReviewedApplications(res.data[0].challenges[0].id)(
            dispatch,
            getState,
          )
        }
      } else if (!challengeId) {
        const program =
          res.data.find((po: any) => {
            return po.id === programId
          }) || null

        if (program)  dispatch({ type: ACTIONS.SET_ACTIVE_PO_PROFILE, payload: program })
        else {
          dispatch({ type: ACTIONS.SET_ACTIVE_PO_PROFILE, payload: res.data[0] })
          if (res?.data?.[0]?.challenges?.[0]) {
            fetchReview(res.data[0].challenges[0].id)(dispatch, getState)
            getReviewedApplications(res.data[0].challenges[0].id)(
              dispatch,
              getState,
            )
          }
        }
      } else {
        const program =
          res.data.find((po: any) => {
            return po.id === programId
          }) || null
        const challenge = program
          ? program.challenges.find((c: any) => {
            return c.id === challengeId
          })
          : null

        if (program && challenge) {
          dispatch({ type: ACTIONS.SET_ACTIVE_PO_PROFILE, payload: program })
          fetchReview(challenge.id)(dispatch, getState)
          getReviewedApplications(challenge.id)(dispatch, getState)
        } else {
          dispatch({ type: ACTIONS.SET_ACTIVE_PO_PROFILE, payload: res.data[0] })

          if (res.data?.[0]?.challenges?.[0]) {
            fetchReview(res.data[0].challenges[0].id)(dispatch, getState)
            getReviewedApplications(res.data[0].challenges[0].id)(
              dispatch,
              getState,
            )
          }
        }
      }
    }
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchAssignedProgramOwnerProfiles = (
  programId: any,
  challengeId: any,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: true })
    const res = await ApiWrapperService.get({
      url: `${ROUTES.USERS}/${getState().user.userId}/programOwners/programManager`,
      state: getState(),
  })
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch assigned program owner profiles')
    }

    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: false })
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES_SUCCESS, payload: res.data })
    if (res?.data?.[0]?.challenges?.[0]) {
      if (!programId || !challengeId) {
        getReviewedApplications(res.data[0].challenges[0].id)(
          dispatch,
          getState,
        )
      }
      const program =
        res.data.find((po: any) => {
          return po.id === programId
        }) || null
      const challenge = program
        ? program.challenges.find((c: any) => {
          return c.id === challengeId
        })
        : null

      if (program && challenge) {
        getReviewedApplications(challenge.id)(dispatch, getState)
      } else {
        getReviewedApplications(res.data[0].challenges[0].id)(
          dispatch,
          getState,
        )
      }
    }
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_OWNER_PROFILES, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const createProgramFromModal = (
  name: string,
  completedAction: Function,
  errorAction: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.post({
      url: ROUTES.PROGRAM_OWNERS,
      body: {name},
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to create program owner profile.')
    }
    dispatch({ type: ACTIONS.PROGRAM_CREATED, payload: res.data })
    completedAction()
    dispatch(push(`/facilitators/${res.data.id}/settings`))
  } catch (e) {
    errorAction(e && e.response && e.response.data && e.response.data.errors)
  }
}

export const createChallengeFromModal = (
  programOwnerId: string,
  name: string,
  reviewStageTemplateId: string,
  applicationTemplateId: string,
  rejectionMode: string,
  applicationMode: string,
  fidIntegration: boolean,
  completedAction: Function,
  errorAction: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await api.post(`${ROUTES.PROGRAM_OWNERS}/${programOwnerId}/challenges`, {
      name,
      reviewStageTemplateId,
      applicationTemplateId,
      rejectionMode,
      applicationMode,
      autoCreateprofiles: fidIntegration,
    })
    fetchAllChallenges() // ToDo when creating a new challenge and opening the challenge description, challenge detail is empty. this fixes the issue sometimes
    dispatch({ type: ACTIONS.CHALLENGE_CREATED, payload: res.data })
    completedAction(res.data.id)
  } catch (e) {
    errorAction(e && e.response && e.response.data && e.response.data.errors)
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchApplicationList = (
  challengeId: any,
  onSuccess?: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.get({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages`,
      state: getState(),
  })

    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch review.')
    }
    onSuccess && onSuccess(res.data)
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchReview = (challengeId: any, onSuccess?: Function) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({ type: ACTIONS.LOADING_REVIEW, payload: true })
    const res = await ApiWrapperService.get({ url: `${ROUTES.CHALLENGES}/${challengeId}/review`, state: getState() })
    if (!requestSuccess(res)) {
      throw new Error('Failed to fetch review.')
    }

    dispatch({ type: ACTIONS.LOADING_REVIEW, payload: false })
    dispatch({ type: ACTIONS.LOADING_REVIEW_SUCCESS, payload: res.data })
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_REVIEW, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const createStage = (challengeId: string, onSuccess: Function) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages`,
      body: {}, // backend requires no payload for this request. So putting empty object here
      state: getState(),
    })
    dispatch({ type: ACTIONS.STAGE_CREATED, payload: res.data })

    // give the payload as result
    onSuccess(res.data)
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteProgramOwnerProfile = (profileId: string) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    await ApiWrapperService.delete(`${ROUTES.PROGRAM_OWNERS}/${profileId}`, getState())
    dispatch({ type: ACTIONS.PROGRAM_OWNER_PROFILE_DELETED, payload: profileId })
    // dispatch(addSuccessToast('Successfully deleted the Program Owner profile'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteStage = (
  challengeId: string,
  stageId: string,
  onDeleted?: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.delete(
      `/v2${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}`,
      getState(),
    )
    dispatch({ type: ACTIONS.STAGE_DELETED, payload: res.data })

    onDeleted && onDeleted(res.data)
    // dispatch(addSuccessToast('Successfully deleted the stage'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const deleteChallenge = (
  challengeId: string,
  completeAction?: Function,
): AppThunk => async(dispatch, getState) => {
  try {
    await ApiWrapperService.delete(`${ROUTES.CHALLENGES}/${challengeId}`, getState())
    dispatch(
      addSuccessToast(
        translate('admin.deleteChallengeNotice', getState()) as string,
      ),
    )
    dispatch({ type: ACTIONS.CHALLENGE_DELETED, payload: challengeId })
    dispatch(fetchUserChallenges({
      userId: getState().user.userId,
      query: window.location.search,
    }))
    completeAction && completeAction()
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const fetchProgramManagers = () => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_MANAGERS, payload: true })
    const res = await ApiWrapperService.get({url: ROUTES.PROGRAM_MANAGERS, state: getState()})
    dispatch({ type: ACTIONS.LOADING_PROGRAM_MANAGERS, payload: false })
    dispatch({ type: ACTIONS.LOADED_PROGRAM_MANAGERS, payload: res.data })
  } catch (e) {
    dispatch({ type: ACTIONS.LOADING_PROGRAM_MANAGERS, payload: false })
    await handleError(e)(dispatch, getState, false)
  }
}

export const addProgramManager = (
  challengeId: string,
  stageId: string,
  userId: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}/programManagers`,
      body: {userId},
      state: getState(),
    })
    dispatch({ type: ACTIONS.STAGE_UPDATED, payload: res.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const removeProgramManager = (
  challengeId: string,
  stageId: string,
  userId: string,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    const res = await ApiWrapperService.delete(
      `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}/programManagers/${userId}`,
      getState(),
    )
    dispatch({ type: ACTIONS.STAGE_UPDATED, payload: res.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateStageAndScoringTool = (
  challengeId: string,
  stageId: string,
  scoringTool: any,
  stage: any,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}`,
      body: stage,
      state: getState(),
    })
    const scoringToolResponse = await ApiWrapperService.patch({
      url: `/challenges/${challengeId}/review/stages/${stageId}/scoringTool`,
      body: scoringTool,
      state: getState(),
    })
    dispatch({ type: ACTIONS.STAGE_UPDATED, payload: scoringToolResponse.data })
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateDraft = (challenge: any) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    await api.post(
      `${ROUTES.CHALLENGES}/${challenge.id}/applicationTemplate/publishDraft`,
    )
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const updateChallenge = (challenge: any) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const response = await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challenge.id}`,
      body: challenge, state: getState()
    })
    dispatch({ type: ACTIONS.CHALLENGE_UPDATED, payload: response.data })
  } catch (e) {
    // console.log(e);
    await handleError(e)(dispatch, getState, false)
  }
}

export const resendStageEmail = (challengeId: any, stageId: any) => async(
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  try {
    const res = await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review/stages/${stageId}/emails`,
      body: null,
      state: getState(),
    })
    if (!requestSuccess(res)) {
      throw new Error('Failed to resend stage email.')
    }
    dispatch(
      addSuccessToast(
        translate('actions.successResendEmail', getState()) as string,
      ),
    ) // 'Successfully added the user as a program owner.'));
  } catch (e) {
    await handleError(e)(dispatch, getState, false)
  }
}

export const finishApplicationProcess = (challengeId: string) => async(
  dispatch: Dispatch<AnyAction>,
  getState: () => RootState,
) => {
  try {
    const response = await ApiWrapperService.patch({
      url: `${ROUTES.CHALLENGES}/${challengeId}/review`,
      body: null,
      state: getState(),
    })
    dispatch({ type: ACTIONS.CHALLENGE_UPDATED, payload: response.data })
    dispatch(fetchReview(challengeId) as any)
    dispatch(
      addSuccessToast(
        translate('actions.applicationProcessFinished', getState()) as string,
      ),
    )
  } catch (e) {
    // console.log(e);
    await handleError(e)(dispatch, getState, false)
  }
}

export const copyApplicationTemplate = (
  challengeId: string,
  applicationTemplateId: string,
  completedAction: Function,
  errorAction: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/applicationTemplate`,
      body: {applicationTemplateId},
      state: getState(),
    })
    completedAction()
  } catch (e) {
    errorAction(e && e.response && e.response.data && e.response.data.errors)
    await handleError(e)(dispatch, getState, false)
  }
}

export const copyOnboardingTemplate = (
  challengeId: string,
  onboardingTemplateId: string,
  completedAction: Function,
  errorAction: Function,
) => async(dispatch: Dispatch, getState: () => RootState) => {
  try {
    await ApiWrapperService.post({
      url: `${ROUTES.CHALLENGES}/${challengeId}/onboardingTemplate`,
      body: {onboardingTemplateId},
      state: getState(),
    })
    completedAction()
  } catch (e) {
    errorAction(e && e.response && e.response.data && e.response.data.errors)
    await handleError(e)(dispatch, getState, false)
  }
}
