import React from 'react';
import mainStyles from 'components/common/Main/Main.module.scss';
import styles from './../About.module.scss';
import { Translate } from 'react-localize-redux';

export default () => {
  return (
    <div
      className={[
        mainStyles.container,
        styles.container,
        styles.containerBlue,
        'slideContainer',
        'containerBlue',
      ].join(' ')}
    >
      <div className={styles.wrapper}>
        <h1 className={styles.slideHeader}>
          <Translate id='about.slide2.title' />
        </h1>
        <div className={styles.containerLeft}>
          <div className={styles.imgContainer2} />
          <p className={styles.paragraph}>
            <Translate
              id='about.slide2.p1'
              options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
            />
          </p>
        </div>
        <div className={styles.containerRight}>
          <p className={styles.paragraph}>
            <Translate
              id='about.slide2.p2'
              options={{ renderInnerHtml: true, renderToStaticMarkup: false }}
            />
          </p>
          <div className={styles.imgContainer3} />
        </div>
      </div>
    </div>
  );
};
