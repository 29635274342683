export const canEditRole = (loggedinUser: any, user: any, role: any) => {
  if (!loggedinUser?.role?.rank) {
    return false
  }
  // Can not edit yourself
  if (loggedinUser.id === user.id) {
    return false
  }
  // Can edit everyone else if you are a super admin
  if (loggedinUser.role.rank >= 4) {
    return true
  }
  // Can not edit users of a higher or the same rank
  if (loggedinUser.role.rank <= user.role.rank) {
    return false
  }
  // Can not promote people to a rank above youself
  return role.rank < loggedinUser.role.rank;
}
