import { getTranslate } from 'react-localize-redux'

export const translate = (text: any, state: any, data?: any) => {
  const translate = getTranslate(state.localize)
  if (data) return translate(text, data)
  return translate(text)
}

export const getDirectionClass = (lang: any) => {
  return lang && lang.code === 'ar' ? 'rtl isRtl' : 'ltr'
}
export const getDirection = (lang: any) => {
  return lang && lang.code === 'ar' ? 'rtl' : 'ltr'
}
