import React from 'react'
import styles from './HeaderNav.module.scss'
import { useSelector } from 'react-redux'
import { ICommonState } from 'components/common/utils/state'
import NavLink from './NavLink'
import { HeaderNavProps } from "./types";

const HeaderNav = ({ links, isInDrawer }: HeaderNavProps) => {
  const userRole = useSelector((state: ICommonState) => state.user.role)

  const permittedLinks = links.filter(link => 
    userRole && (Array.isArray(link.rank)
      ? link.rank.includes(userRole.rank)
      : link.rank <= userRole.rank))

  return (
    <nav className={isInDrawer ? styles.drawerContainer : styles.standardContainer}>
      <ul className={styles.primaryNav}>
        {permittedLinks.map(link => (
          <li key={link.translationKey} className={styles.linkContainer}>
            <NavLink {...link} isInDrawer={isInDrawer} />
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default HeaderNav
