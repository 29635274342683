import { ACTIONS, AddToastAction, ToastAction, } from '../actions/toastActions'
import { IToastStored, Reducer, ToastState } from "./types";
import { createReducer } from "./createReducer";

const defaultState: ToastState = {
  successToasts: [],
  errorToasts: [],
}

let toastId = 0

const getNewToast = (payload: AddToastAction["payload"]): IToastStored => {
  if (
    typeof payload === 'string'
  ) {
    return {
      message: payload,
      id: toastId,
    }
  }
  return {
    message: payload.message,
    buttonText: payload.buttonText,
    buttonHandler: payload.buttonHandler,
    button2Text: payload.button2Text,
    button2Handler: payload.button2Handler,
    id: toastId,
  }
}

export const reducer: Reducer<ToastState, ToastAction> = {
    [ACTIONS.ADD_SUCCESS_TOAST]: (state, { payload }) => {
      const successToasts: IToastStored[] = [...state.successToasts, getNewToast(payload)]
      toastId = toastId + 1
      return { ...state, successToasts }
    },
    [ACTIONS.ADD_ERROR_TOAST]: (state, { payload }) => {
      const errorToasts: IToastStored[] = [...state.errorToasts, getNewToast(payload)]
      toastId = toastId + 1
      return { ...state, errorToasts }
    },
    [ACTIONS.CLEAR_SUCCESS_TOASTS]: (state) => {
      const successToasts: IToastStored[] = []
      return { ...state, successToasts }
    },
    [ACTIONS.CLEAR_ERROR_TOASTS]: (state, { payload }) => {
      const errorToasts: IToastStored[] = []
      return { ...state, errorToasts }
    },
    [ACTIONS.REMOVE_SUCCESS_TOAST]: (state, { payload }) => {
      const successToasts: IToastStored[] = state.successToasts.filter((toast: IToastStored) => {
        return toast.id !== payload
      })
      return { ...state, successToasts }
    },
    [ACTIONS.REMOVE_ERROR_TOAST]: (state, { payload }) => {
      const errorToasts: IToastStored[] = state.errorToasts.filter((toast: IToastStored) => {
        return toast.id !== payload
      })
      return { ...state, errorToasts }
    }
}

export default createReducer<ToastState, ToastAction>(reducer, defaultState);
