import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MetricDisplay from '../../../MetricDisplay/MetricDisplay'
import ProfilesIcon from '../../../../assets/icons/Profile.svg'
import ProgramsIcon from '../../../../assets/icons/Program.svg'
import ApplicationsIcon from '../../../../assets/icons/Application.svg'
import NewApplicationsIcon from '../../../../assets/icons/ApplicationNew.svg'
import { LocalizeContextProps, withLocalize } from 'react-localize-redux'
import { RootState } from 'reducers/index'
import { getUserMetrics } from 'components/common/actions/userActions'
import { defaultMetrics } from "./constants";

const AdminMetrics = ({ translate }: LocalizeContextProps) => {
  const { userId, metrics } = useSelector((state: RootState) => state.user)
  const {
    facilitatorCount,
    programCount,
    submittedApplicationsCount,
    assignedPendingApplicationCount,
  } = metrics || defaultMetrics
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUserMetrics(userId))
  }, [dispatch, userId])

  return (
    <>
      <MetricDisplay
        to='/facilitators'
        label={translate('internalDashboard.facilitators').toString()}
        value={facilitatorCount}
        imageSource={ProfilesIcon}
      />
      <MetricDisplay
        to='/programs-list'
        label={translate('internalDashboard.programs').toString()}
        value={programCount}
        imageSource={ProgramsIcon}
      />
      <MetricDisplay
        to='/applications'
        label={translate('internalDashboard.applications').toString()}
        value={submittedApplicationsCount}
        imageSource={ApplicationsIcon}
      />
      <MetricDisplay
        to='/applications?__showAllApplications=0'
        label={translate(
          'internalDashboard.assignedPendingApplications',
        ).toString()}
        value={assignedPendingApplicationCount}
        imageSource={NewApplicationsIcon}
      />
    </>
  )
}

export default withLocalize(AdminMetrics)
