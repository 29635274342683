import React from 'react'
import { Subscription } from 'rxjs'
import { clickStream } from '../../utils/domStreams'
import checkMark from '../../assets/icons/checkmark.svg'
import styles from './UsersRoleList.module.scss'
import { withLocalize } from 'react-localize-redux'
import { UserDropdownProps } from "./types";
import { canEditRole } from "./utils";

class UserDropdown extends React.Component<UserDropdownProps> {
  clickSubscription = new Subscription()

  componentDidMount() {
    this.clickSubscription = clickStream.subscribe(this.props.close)
  }

  componentWillUnmount() {
    this.clickSubscription.unsubscribe()
  }

  handleClick = (loggedInUser: any, user: any, role: any) => {
    if (!canEditRole(loggedInUser, user, role)) {
      return
    }
    this.props.updateRole && this.props.updateRole(user.id, role)
  }

  render() {
    return (
      <ul className={styles.dropdown}>
        {this.props.roles.map((role: any, index: number) => (
          <li
            className={
              canEditRole(this.props.loggedInUser, this.props.user, role)
                ? styles.role
                : styles.disabledRole
            }
            key={index}
            onClick={() =>
              this.handleClick(this.props.loggedInUser, this.props.user, role)}
          >
            <p>{this.props.translate(`admin.roles.${role.rank}`)}</p>
            {role.id === this.props.user?.role?.id && (
              <img
                className={styles.checkmark}
                src={checkMark}
                alt='checkmark'
              />
            )}
          </li>
        ))}
      </ul>
    )
  }
}

export default withLocalize(UserDropdown)
