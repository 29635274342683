import React from 'react'
import { CellProps } from 'react-table'
import { Translate } from 'react-localize-redux'
import CopyableLink from '../../../../CopyableLink/CopyableLink'
import { DataApplicantRow } from "../../types";

export const ColumnsApplicationsTable = [
  {
    Header: ' ',
    columns: [
      {
        Header: <Translate id='applications.applicant' />,
        accessor: 'applicant',
        id: 'applicationsCompany',
        width: 70,
        disableSortBy: true,
        Cell: ({ cell, row }: CellProps<DataApplicantRow>) => {
          const { applicationId } = row.original
          return (
            <CopyableLink to={`/review/${applicationId}`}>
              {cell.value || '-'}
            </CopyableLink>
          )
        },
      },
      {
        Header: <Translate id='dashboard.tables.program' />,
        accessor: 'program',
        width: 70,
        id: 'applicationsProgram',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<DataApplicantRow>) => {
          const { name, _id } = cell.value
          return <CopyableLink to={`/programs/${_id}`}>{name}</CopyableLink>
        },
      },
      {
        Header: <Translate id='dashboard.tables.gate' />,
        accessor: 'status',
        width: 70,
        id: 'applicationsStatus',
        disableSortBy: true,
      },
    ],
  },
]
