import React from 'react'
import styles from './GoBackButton.module.scss'
import { useHistory } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import { GoBackButtonProps } from "./types";

const GoBackButton = ({ customAction }: GoBackButtonProps) => {
  const history = useHistory()
  const handleClick = customAction || history.goBack

  return (
    <div>
      <button className={styles.root} onClick={handleClick}>
        {`< `}
        <Translate id='utility.back' />
      </button>
    </div>
  )
}

export default GoBackButton
