import React from 'react'
import styles from './Tab.module.scss'
import classNames from 'classnames'
import { TabProps } from "./types";

const Tab = ({
  active,
  onClick,
  children,
  className,
}: TabProps) => {
  return (
    <span
      className={classNames(styles.tab, {
        [styles.active]: active,
      }, className)}
      onClick={onClick}
    >
      {children}
    </span>
  )
}

export default Tab
