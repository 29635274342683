import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import style from './ReviewForm.module.scss'
import SectionList from 'components/common/SectionList/SectionList'
import Form from 'components/common/Form/Form'
import FormSection from 'components/common/Form/FormSection'
import ReadOnlyQuestion from 'components/common/ReadOnlyQuestion/ReadOnlyQuestion'
import {
  getSectionBounds,
  getPossibleScoresForSections,
} from 'components/common/models/application/ISection'
import { downloadAnswerFile } from 'components/common/actions/programManagerActions'
import { ICommonState } from 'components/common/utils/state'
import ApplicationSummary from './ApplicationSummary'
import { ReviewFormProps } from "./types";

const ReviewForm = ({ application, status }: ReviewFormProps) => {
  const [selectedSection, setSelectedSection] = useState(0)

  const {
    id: applicationId,
    sectionScores,
    language,
    applicationTemplate,
  } = application
  const { sections: rawSections, questions } = applicationTemplate

  const sections = [
    // one extra sections for overview
    {
      id: 'summary',
      title: 'Overview',
      description: 'Overview',
      questions: 0,
    },
    ...(rawSections.length === 0
      ? [
          {
            id: 'fake-question',
            title: 'Questions',
            description: 'Questions',
            questions: questions.length,
          },
        ]
      : []),
    ...rawSections,
  ]

  const [questionLowerBound, questionUpperBound] = getSectionBounds(
    sections,
    selectedSection,
  )

  const { filesDownloading } = useSelector(
    (state: ICommonState) => state.review,
  )

  const dispatch = useDispatch()

  const possibleScores = getPossibleScoresForSections(sections, questions)

  const handleSectionChange = (newSection: number) => {
    setSelectedSection(newSection)
  }

  return (
    <div className={style.reviewContent}>
      <div className={style.sectionsColumn}>
        <SectionList
          sections={sections}
          sectionScores={sectionScores}
          possibleScores={possibleScores}
          displayStatus={false}
          onSelected={handleSectionChange}
          selectedSection={selectedSection}
        />
      </div>
      <div className={style.columnR}>
        {selectedSection === 0 ? (
          <ApplicationSummary application={application} status={status} />
        ) : (
          <Form borderless>
            <FormSection>
              <ul className={style.questionList}>
                {questions.map((question, index) => {
                  if (
                    index >= questionLowerBound &&
                    index < questionUpperBound
                  ) {
                    return (
                      <ReadOnlyQuestion
                        key={`question-${index}`}
                        question={question}
                        answer={application.answers[index]}
                        downloadFile={() =>
                          dispatch(
                            downloadAnswerFile(
                              applicationId,
                              index,
                            ),
                        )}
                        downloading={filesDownloading.includes(
                          `${applicationId}-${index}`,
                        )}
                        isAltLang={language !== 'en'}
                        isRTL={language === 'ar'}
                        reducedDataSet
                      />
                    )
                  }
                  return null
                })}
              </ul>
            </FormSection>
          </Form>
        )}
      </div>
    </div>
  )
}

export default ReviewForm
