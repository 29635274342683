import { IAutoReviewTemplate } from 'components/common/GateSettings/types'

export const convertTemplateToCriteria = (template: IAutoReviewTemplate) => ({
  name: template.name,
  // @ts-ignore TODO update model
  filters: [
    ...(template.applicationEligibility.company != null ? [{
      filterName: 'company',
      operator: '===',
      target: {
        type: 'boolean',
        targetValue: template.applicationEligibility.company,
      },
    }] : []),
    ...(template.applicationEligibility.location ? [{
      filterName: 'location',
      operator: '===',
      target: {
        type: 'string',
        targetValues: template.applicationEligibility.location,
      },
    }] : []),
    ...(template.applicationEligibility.sector ? [{
      filterName: 'sector',
      operator: '===',
      target: {
        type: 'string',
        targetValues: template.applicationEligibility.sector,
      },
    }] : []),
    ...(template.applicationEligibility.employees && template.applicationEligibility.employees.value ? [{
      filterName: 'employees',
      operator: template.applicationEligibility.employees.comparitor,
      target: {
        type: 'number',
        targetValue: template.applicationEligibility.employees.value,
      },
    }] : []),
    ...(template.applicationEligibility.minimumScore ? [{
      filterName: 'score',
      operator: '>',
      target: {
        type: 'number',
        targetValue: template.applicationEligibility.minimumScore,
      },
    }] : []),
    ...(template.applicationEligibility.minDate ? [{
      filterName: 'date',
      operator: '>',
      target: {
        type: 'string',
        targetValue: template.applicationEligibility.minDate,
      },
    }] : []),
    ...(template.applicationEligibility.maxDate ? [{
      filterName: 'date',
      operator: '<',
      target: {
        type: 'string',
        targetValue: template.applicationEligibility.maxDate,
      },
    }] : []),
    ...(template.accountEligibility.futureIdCompletion ? [{
      filterName: 'futureIdCompletion',
      operator: '>',
      target: {
        type: 'number',
        targetValue: template.accountEligibility.futureIdCompletion,
      },
    }] : []),
    ...(template.accountEligibility.futureId != null ? [{
      filterName: 'futureId',
      operator: '===',
      target: {
        type: 'boolean',
        targetValue: template.accountEligibility.futureId,
      },
    }] : []),
    ...(template.previousApplications.goldenTicket != null ? [{
      filterName: 'goldenTicket',
      operator: '===',
      target: {
        type: 'boolean',
        targetValue: template.previousApplications.goldenTicket,
      },
    }] : []),
    ...(template.previousApplications.gateApprovals && template.previousApplications.gateApprovals.value ? [{
      filterName: 'gateApprovals',
      operator: template.previousApplications.gateApprovals.comparitor,
      target: {
        type: 'number',
        targetValue: template.previousApplications.gateApprovals.value,
      },
    }] : []),
    ...(template.previousApplications.pendingApplications && template.previousApplications.pendingApplications.value ? [{
      filterName: 'pendingApplications',
      operator: template.previousApplications.pendingApplications.comparitor,
      target: {
        type: 'number',
        targetValue: template.previousApplications.pendingApplications.value,
      },
    }] : []),
  ],
})
