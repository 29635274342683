import React from 'react'
import classNames from 'classnames'

import styles from './Button.module.scss'
import { ButtonProps } from "./types";

const Button = ({
  type,
  children,
  className,
  hardClassOverride,
  onClick,
  disabled,
  title,
}: ButtonProps) => {
  const wrappedOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onClick(event)
  }

  const wrappedNoOp = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  return (
    <button
      className={classNames(className, hardClassOverride, {
        [styles.button]: hardClassOverride === undefined,
        [styles.disabled]: disabled,
        [styles.primary]: type === 'primary',
        [styles.solid]: type === 'solid',
        [styles.border]: type === 'border',
      })}
      onClick={disabled ? wrappedNoOp : wrappedOnClick}
      title={title}
    >
      {children}
    </button>
  )
}

export default Button
