import { ACTIONS, ApplicationAction } from '../actions/applicationActions'
import { AUTH_ACTIONS } from '../actions/authActions'
import { ApplicationState } from '../utils/state'
import { LogoutAction, Reducer } from "./types";
import { CHALLENGE_ID, defaultLimit } from "./constants";
import { createReducer } from "./createReducer";

const defaultState: ApplicationState = {
  fileUploadLimits: {
    document: defaultLimit,
    image: defaultLimit,
    video: defaultLimit,
    all: defaultLimit,
  },
  apiKeys: [],
  apiKeysLoading: false,
  completionPercent: 0,
  lastStage: 0,
  applicationTemplates: [],
  applications: [],
  activeSection: 0,
  selectedOrganization: undefined,
  selectedChallenge: CHALLENGE_ID || undefined,
  goldenTicket: undefined,
}

const reducer: Reducer<ApplicationState, ApplicationAction & LogoutAction> = {
  [ACTIONS.SET_LAST_STAGE_TAB_INDEX]: (state, { payload }) => ({ ...state, lastStage: payload }),
  [ACTIONS.SET_FILE_LIMITS]: (state, {payload: { document, image, video }}) => ({
    ...state,
    fileUploadLimits: {
      document,
      image,
      video,
      all: {
        fileLimit: Math.max(document.fileLimit, image.fileLimit, video.fileLimit),
        mimeTypes: [
          ...document.mimeTypes,
          ...image.mimeTypes,
          ...video.mimeTypes,
        ],
      },
    },
  }),
  [ACTIONS.SET_API_KEYS_LOADING]: (state) => ({
    ...state,
    apiKeys: [],
    apiKeysLoading: true,
  }),
  [ACTIONS.SET_API_KEYS]: (state, { payload }) => {
    const sortedKeys = payload.sort((a: Record<string, string>, b: Record<string, string>) => {
      if (a.keyName.startsWith('user') && b.keyName.startsWith('company')) {
        return -1
      }
      if ((a.keyName.startsWith('company') && b.keyName.startsWith('user')) || a.keyName > b.keyName) {
        return 1
      }
      if (b.keyName > a.keyName) {
        return -1
      }
      return 0
    })

    return {
      ...state,
      apiKeys: sortedKeys,
      apiKeysLoading: false,
    }
  },
  [ACTIONS.SET_COMPLETION_PERCENT]: (state, { payload }) => ({...state, completionPercent: payload }),
  [ACTIONS.SET_ACTIVE_SECTION]: (state, { payload }) => ({ ...state, activeSection: payload }),
  [ACTIONS.SELECT_ORGANIZATION]: (state, { payload }) => ({ ...state, selectedOrganization: payload }),
  [ACTIONS.SELECT_CHALLENGE]: (state, { payload }) => ({ ...state, selectedChallenge: payload }),
  [ACTIONS.SET_GOLDEN_TICKET]: (state, { payload }) => ({ ...state, goldenTicket: payload }),
  [AUTH_ACTIONS.LOGOUT]: () => defaultState,
}

export default createReducer<ApplicationState, ApplicationAction>(reducer, defaultState);
