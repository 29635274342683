import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../reducers'
import { ApplicationStateProvider } from '../components/common/Application/ApplicationForm/ApplicationFormState'
import ApplicationPanel from '../components/common/Application/ApplicationPanel'
import { useParams } from 'react-router-dom'
import { selectChallenge } from '../components/common/actions/applicationActions'
import styles from './ApplicationPage.module.scss'

const ApplicationPage = () => {
  const user = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()
  const { id: challengeId } = useParams()

  if (challengeId) {
    dispatch(selectChallenge(challengeId))
  }
  return (
    <div className={styles.applicationForm}>
      <ApplicationStateProvider>
        <ApplicationPanel adminMode={user && user.role.rank >= 2} />
      </ApplicationStateProvider>
    </div>
  )
}

export default ApplicationPage
