import React, { useEffect, useMemo, useState } from 'react'
import classes from './TemplateForm.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import Form from 'components/common/Form/Form'
import TextInput from 'components/common/FormInput/TextInput'
import MultiCheckbox from 'components/common/FormInput/MultiCheckbox'
import Dropdown from 'components/common/FormInput/Dropdown'
import Button from 'components/common/shared/Button'
import Comparison from 'components/common/FormInput/Comparison'
import { IComparisonValue } from 'components/common/FormInput/types'
import Accordion from 'components/common/Accordion/Accordion'
import Summary from './Summary'
import GoBackButton from 'components/common/GoBackButton/GoBackButton'
import { IAutoReviewTemplate } from 'components/common/GateSettings/types'
import {
  deleteAutoReviewCriteria,
  getAutoReviewCriteria,
  getCountryList,
  getSectorList,
} from 'components/common/actions/gateSettingsActions'
import { RootState } from 'reducers'
import NotificationModal from 'components/common/NotificationModal/NotificationModal'
import { TemplateFormProps } from "./types";
import { boolToYesNoOption, yesNoOptionToBool } from "./utils";


const TemplateForm = ({ translate, selectedId, backAction, onSave, programOwnerId }: TemplateFormProps) => {
  const [name, setName] = useState('')
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState(0)
  const [selectedLocation, setSelectedLocation] = useState([] as number[])
  const [selectedSector, setSelectedSector] = useState([] as number[])
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')
  const [employees, setEmployees] = useState<IComparisonValue>({ value: '', comparitor: undefined })
  const [minimumScore, setMinimumScore] = useState('')
  const [hasGoldenTicket, setHasGoldenTicket] = useState(0)
  const [gateApprovals, setGateApprovals] = useState<IComparisonValue>({ value: '', comparitor: undefined })
  const [pendingApplications, setPendingApplications] = useState<IComparisonValue>({ value: '', comparitor: undefined })
  const [futureIdCompletion, setFutureIdCompletion] = useState('')
  const [hasFutureId, setHasFutureId] = useState(0)
  const [ confirmDeleteVisible, setConfirmDeleteVisible ] = useState(false)
  const [ confirmEditVisible, setConfirmEditVisible ] = useState(false)

  const dispatch = useDispatch()

  const handleBackPressed = () => {
    backAction()
  }
  useEffect(() => {
    dispatch(getAutoReviewCriteria(programOwnerId, selectedId))
  }, [dispatch, selectedId, programOwnerId])

  useEffect(() => {
    dispatch(getCountryList())
    dispatch(getSectorList())
  }, [dispatch])

  const {
    autoReviewCriteria,
    countryList,
    sectorList,
  } = useSelector((state: RootState) => state.gate)

  const initialValues = useMemo(() => {
    if (selectedId) return autoReviewCriteria[selectedId]
    return undefined
  },  [autoReviewCriteria, selectedId])

  const handleMultiSelect = (selector: (selection: number[]) => void) =>
    (newSelection: number | number[]) => {
    if (Array.isArray(newSelection)) {
      selector(newSelection)
    } else {
      selector([newSelection])
    }
  }

  useEffect(() => {
    if (initialValues && countryList && sectorList) {
      setName(initialValues.name || '')
      // === application eligibility ===
      if (initialValues.applicationEligibility.company) {
        setSelectedCompanyOptions(boolToYesNoOption(initialValues.applicationEligibility.company))
      }
      if (initialValues.applicationEligibility.location) {
        setSelectedLocation(initialValues.applicationEligibility.location
          .map(code => countryList.findIndex(country => country.code === code)))
      }
      if (initialValues.applicationEligibility.sector) {
        setSelectedSector(initialValues.applicationEligibility.sector
          .map(code => sectorList.findIndex(sector => sector.code === code)))
      }
      setMinDate(initialValues.applicationEligibility.minDate || '')
      setMaxDate(initialValues.applicationEligibility.maxDate || '')
      if(initialValues.applicationEligibility.employees) {
        setEmployees(initialValues.applicationEligibility.employees)
      }
      setMinimumScore(`${initialValues.applicationEligibility.minimumScore || ''}`)
      // === account eligibility ===
      setFutureIdCompletion(`${initialValues.accountEligibility.futureIdCompletion || ''}`)
      if (initialValues.accountEligibility.futureId) {
        setHasFutureId(boolToYesNoOption(initialValues.accountEligibility.futureId))
      }

      // === previous applications ===
      if (initialValues.previousApplications.goldenTicket) {
        setHasGoldenTicket(boolToYesNoOption(initialValues.previousApplications.goldenTicket))
      }
      if (initialValues.previousApplications.gateApprovals) {
        setGateApprovals(initialValues.previousApplications.gateApprovals)
      }
      if (initialValues.previousApplications.pendingApplications) {
        setPendingApplications(initialValues.previousApplications.pendingApplications)
      }
    }
  }, [initialValues, countryList, sectorList])

  const template: IAutoReviewTemplate = {
    name,
    onStages: initialValues ? initialValues.onStages : [],
    id: initialValues ? initialValues.id : 'new',
    applicationEligibility: {
      employees,
      minDate,
      maxDate,
      company: yesNoOptionToBool(selectedCompanyOptions),
      location: selectedLocation.map(index => countryList[index].code),
      sector: selectedSector.map(index => sectorList[index].code),
      minimumScore: minimumScore ? parseInt(minimumScore, 10) : undefined,
    },
    accountEligibility: {
      futureId: yesNoOptionToBool(hasFutureId),
      futureIdCompletion: futureIdCompletion ? parseInt(futureIdCompletion, 10) : undefined,
    },
    previousApplications: {
      gateApprovals,
      pendingApplications,
      goldenTicket: yesNoOptionToBool(hasGoldenTicket),
    },
  }

  return (
    <>
      <Form borderless>
        <GoBackButton customAction={() => handleBackPressed()} />
        <h1>
          {initialValues ? (
            <Translate id='autoReview.edit' />
          ) : (
            <Translate id='autoReview.createNew' />
          )}
        </h1>
        <TextInput
          id='template-name'
          showValidation={false}
          label={translate('autoReview.name') as string}
          value={name}
          onChange={setName}
          type='text'
        />
        <Accordion
          label={translate('autoReview.applicationEligibility.heading') as string}
        >
          <MultiCheckbox
            id='has-company'
            value={[selectedCompanyOptions]}
            onChange={(newValue: number[]) => setSelectedCompanyOptions(newValue[0])}
            options={[
              {
                option: 'Irrelevant',
                optionId: 'irrelevant',
              },
              {
                option: 'Yes',
                optionId: 'yes',
              },
              {
                option: 'No',
                optionId: 'no',
              },
            ]}
            label={translate('autoReview.applicationEligibility.company')}
            type='radio'
          />
          <Dropdown
            id='location'
            label={translate('autoReview.applicationEligibility.location')}
            isMulti
            options={countryList.map(({ en }) => en)}
            value={selectedLocation}
            onChange={handleMultiSelect(setSelectedLocation)}
          />
          <Dropdown
            id='sector'
            label={translate('autoReview.applicationEligibility.sector')}
            isMulti
            options={sectorList.map(({ name }) => name)}
            value={selectedSector}
            onChange={handleMultiSelect(setSelectedSector)}
          />
          <TextInput
            id='min-date'
            showValidation={false}
            label={translate('autoReview.applicationEligibility.minDate') as string}
            value={minDate}
            onChange={setMinDate}
            type='date'
          />
          <TextInput
            id='max-date'
            showValidation={false}
            label={translate('autoReview.applicationEligibility.maxDate') as string}
            value={maxDate}
            onChange={setMaxDate}
            type='date'
          />
          <Comparison
            id='employees'
            value={employees}
            onChange={setEmployees}
            label={translate('autoReview.applicationEligibility.employees')}
          />
          <TextInput
            id='minimum-score'
            showValidation={false}
            label={translate('autoReview.applicationEligibility.minimumScore') as string}
            value={minimumScore}
            onChange={setMinimumScore}
            type='number'
          />
        </Accordion>
        <Accordion
          label={translate('autoReview.previousApplications.heading') as string}
        >
          <MultiCheckbox
            id='golden-ticket'
            value={[hasGoldenTicket]}
            onChange={(newValue: number[]) => setHasGoldenTicket(newValue[0])}
            options={[
              {
                option: 'Irrelevant',
                optionId: 'irrelevant',
              },
              {
                option: 'Yes',
                optionId: 'yes',
              },
              {
                option: 'No',
                optionId: 'no',
              },
            ]}
            label={translate('autoReview.previousApplications.goldenTicket')}
            type='radio'
          />
          <Comparison
            id='gate-approvals'
            value={gateApprovals}
            onChange={setGateApprovals}
            label={translate('autoReview.previousApplications.gateApprovals')}
          />
          <Comparison
            id='pending-applications'
            value={pendingApplications}
            onChange={setPendingApplications}
            label={translate('autoReview.previousApplications.pendingApplications')}
          />
        </Accordion>
        <Accordion
          label={translate('autoReview.accountEligibility.heading') as string}
        >
          <TextInput
            id='future-id-completion'
            showValidation={false}
            label={translate('autoReview.accountEligibility.futureIdCompletion')}
            value={futureIdCompletion}
            onChange={setFutureIdCompletion}
            type='number'
          />
          <MultiCheckbox
            id='future-id'
            value={[hasFutureId]}
            onChange={(newValue: number[]) => setHasFutureId(newValue[0])}
            options={[
              {
                option: 'Irrelevant',
                optionId: 'irrelevant',
              },
              {
                option: 'Yes',
                optionId: 'yes',
              },
              {
                option: 'No',
                optionId: 'no',
              },
            ]}
            label={translate('autoReview.accountEligibility.futureId')}
            type='radio'
          />
        </Accordion>
        <Accordion
          label={translate('autoReview.summary') as string}
        >
          <Summary
            template={template}
          />
        </Accordion>
        <Button
          className={classes.button}
          type='solid'
          onClick={() => {
            if (template.onStages.length > 0) {
              setConfirmEditVisible(true)
            } else {
              onSave(template)
            }
          }}
          disabled={template.name.length === 0}
        >
          <Translate id='utility.save' />
        </Button>
        {template.id !== 'new' && (
          <Button
            type='solid'
            onClick={() => setConfirmDeleteVisible(true)}
            className={classes.button}
          >
            <Translate id='utility.delete' />
          </Button>
        )}
      </Form>
      {confirmDeleteVisible && (
        <NotificationModal
          onAccept={() => {
            dispatch(deleteAutoReviewCriteria(programOwnerId, template.id, () => {
              setConfirmDeleteVisible(false)
              backAction()
            }))
          }}
          onCancel={() => setConfirmDeleteVisible(false)}
        >
          <Translate id='autoReview.confirmDeleteCriteria' />
        </NotificationModal>
      )}
      {confirmEditVisible && (
        <NotificationModal
          onAccept={() => onSave(template)}
          onCancel={() => setConfirmEditVisible(false)}
        >
          <Translate id='autoReview.confirmEditCriteria' data={{name: template.name}} />
        </NotificationModal>
      )}
    </>
  )
}

export default withLocalize(TemplateForm)
