import json from "./bg-image-sizes.json";
import { Route } from "./types";

const bgImagePath = process.env.REACT_APP_MAIN_BG_PICTURES
const dpr = window.devicePixelRatio

const getNearestQuality = (index: number) => {
  const qualities = [100, 66, 33, 20]
  const screenWidthInPx = window.innerWidth * dpr
  const diffFromOptimum: number[] = []
  qualities.forEach((quality: number) => {
    const widthForIndex = +getWidthForIndex(index, quality)
    const factor = screenWidthInPx / widthForIndex
    const diffFromOne = Math.abs(1 - factor)
    diffFromOptimum.push(diffFromOne)
  })

  const indexOfSmallestDiff = diffFromOptimum.indexOf(Math.min.apply(null, diffFromOptimum))
  const quality = qualities[indexOfSmallestDiff]
  return quality
}

const getWidthForIndex = (index: number, quality: number): string => {
  return (json as Record<string, Record<string, string>>)[`${quality}`][index]
}

export const getUrlForIndex = (index: number): string => {
  const quality = getNearestQuality(index)
  return `${bgImagePath}/${quality}/${index}.jpg`
}

export const checkRouteValid = (route: Route) => !(route.type !== 'visa' && route.headingKey === undefined)
