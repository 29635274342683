import { useState, useEffect } from 'react'

interface ScrollPosition {
  x: number
  y: number
}

function getScrollPosition(): ScrollPosition {
  return { x: window.pageXOffset, y: window.pageYOffset }
}

export function useScrollPosition(): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition(),
  )

  useEffect(() => {
    let running: number | null = null
    function handleScroll() {
      if (running === null) {
        running = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition())
          running = null
        })
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return position
}
